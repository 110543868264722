
import CONSTANTS from './constants'
import { values } from 'lodash'

export const menu = [
    {
        key: 'Profile',
        image: 'Profile',
        label: 'Perfil',
        icon: 'user',
        permissions: values(CONSTANTS.USER_ROLE),
    },
    {
        key: 'Organization',
        image: 'Organization',
        label: 'Organización',
        icon: 'trello',
        permissions: values(CONSTANTS.USER_ROLE),
    },
    {
        key: 'Calendar',
        image: 'Calendar',
        label: 'Calendario',
        icon: 'calendar',
        permissions: values(CONSTANTS.USER_ROLE),
    },
    // {
    //     key: 'Inventory',
    //     image: 'Inventory',
    //     label: 'Inventario',
    //     icon: 'package',
    //     permissions: [CONSTANTS.USER_ROLE.ADMIN, CONSTANTS.USER_ROLE.COACH, CONSTANTS.USER_ROLE.COMITE],
    // },
    /// ////
    // {
    //     key: 'Informations',
    //     image: 'Inventory',
    //     label: 'Informaciones',
    //     icon: 'layers',
    //     permissions: values(CONSTANTS.USER_ROLE),
    // },
    {
        key: 'Stats',
        image: 'Regulation', // TODO:
        label: 'Estadísticas',
        icon: 'file-text', // TODO:
        permissions: values(CONSTANTS.USER_ROLE),
    },
    {
        key: 'Regulation',
        image: 'Regulation',
        label: 'Reglamento',
        icon: 'file-text',
        permissions: values(CONSTANTS.USER_ROLE),
    },
    {
        key: 'Economy',
        image: 'Economy',
        label: 'Economía',
        icon: 'dollar-sign',
        permissions: [CONSTANTS.USER_ROLE.ADMIN, CONSTANTS.USER_ROLE.COMITE],
    },
    {
        key: 'Attendance',
        image: 'Attendance',
        label: 'Asistencias',
        icon: 'user-plus',
        permissions: [CONSTANTS.USER_ROLE.ADMIN, CONSTANTS.USER_ROLE.COACH],
    },
    // {
    //     key: 'Exercises',
    //     image: 'Exercises',
    //     label: 'Ejercicios',
    //     icon: 'activity',
    //     permissions: [CONSTANTS.USER_ROLE.ADMIN, CONSTANTS.USER_ROLE.COACH],
    // },
    /// ////
    {
        key: 'Lineups',
        image: 'Lineups',
        label: 'Alineación',
        icon: 'package',
        permissions: [CONSTANTS.USER_ROLE.ADMIN, CONSTANTS.USER_ROLE.COACH],
    },
]
