<template>
	<div class="Stats">
		<PillSelectorInput v-model="sortStatsByKey" :options="keys.map((key) => ({ key, label: `${key}: ${keysObj[key]}` }))" border="gray" />
		<div class="ScreenHeader"></div>
		<div class="List">
			<div v-for="(person, index) in sortedStatsValue" :key="`value-${index}`" class="ListItem">
				<!-- :url="require(`@/assets/imgs/players/${(person || {}).imagePath || 'default.jpg'}`)" -->
				<div class="Person">
					<Avatar :url="require(`@/assets/imgs/players/${(person || {}).imagePath || 'default.jpg'}`)" :size="{ height: 50, width: 50 }" :iconSize="30" />
					<p class="Name">
						{{ person.shortName }}
					</p>
				</div>
				<div class="Row">
					<div v-for="statKey in keys" :key="statKey" class="Cell" :class="statKey === sortStatsByKey ? 'active' : ''">
						<Tooltip placement="bottom" :text="`${keysObj[statKey]}: ${formatValue({ key: statKey, value: person[statKey] || 0 })}`">
							<p class="Key">{{ statKey }}</p>
							<p class="Value">{{ formatValue({ key: statKey, value: person[statKey] || 0 }) }}</p>
						</Tooltip>
					</div>
				</div>
			</div>
			<div class="ListItem Total">
				<div class="Person">
					<p class="Name">TOTAL</p>
				</div>
				<div class="Row">
					<div v-for="statKey in keys" :key="statKey" class="Cell" :class="statKey === sortStatsByKey ? 'active' : ''">
						<Tooltip placement="bottom" :text="`${keysObj[statKey]}: ${formatValue({ key: statKey, value: totalStatsValue[statKey] })}`">
							<p class="Key">{{ statKey }}</p>
							<p class="Value">{{ formatValue({ key: statKey, value: totalStatsValue[statKey] }) }}</p>
						</Tooltip>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mediaQueryMixin } from "@/mixins";
import VueTypes from "vue-types";
import {
	kickingStatType,
	fieldingStatType,
	pitchingStatType,
	kickingStatsKeys,
	formatKickingValueByKey,
	fieldingStatsKeys,
	formatFieldingValueByKey,
	pitchingStatsKeys,
	formatPitchingValueByKey,
} from "@/entities/stats";

export default {
	name: "Stats",
	mixins: [mediaQueryMixin],
	props: {
		event: VueTypes.any,
		statType: VueTypes.string.def(kickingStatType),
	},
	data() {
		return {
			sortStatsByKey: "",
		};
	},
	computed: {
		isMobile() {
			return this.$mq.w < this.$mq.sm.min;
		},
		sortedStatsValue() {
			return _.sortBy(
				this.event[this.statType].filter((i) => i.shortName !== "TOTAL"),
				this.sortStatsByKey
			).reverse();
		},
		totalStatsValue() {
			return this.event[this.statType].find((i) => i.shortName === "TOTAL");
		},
		keys() {
			if (this.event && this.event.isTotal) {
				// UN TORNEO
				if (this.statType === kickingStatType) return Object.keys(kickingStatsKeys).filter((key) => key !== "SEQ"); // sin "Orden de pateo"
				else if (this.statType === fieldingStatType) return Object.keys(fieldingStatsKeys);
				else if (this.statType === pitchingStatType) return Object.keys(pitchingStatsKeys);
			} else {
				// UN PARTIDO
				const pitchingkeys = ["G", "W", "L", "SV", "HLD"];
				if (this.statType === kickingStatType) return Object.keys(kickingStatsKeys).filter((key) => key !== "G"); // sin "Partidos jugados"
				else if (this.statType === fieldingStatType) return Object.keys(fieldingStatsKeys).filter((key) => key !== "G"); // sin "Partidos jugados"
				else if (this.statType === pitchingStatType) return Object.keys(pitchingStatsKeys).filter((key) => !pitchingkeys.includes(key));
			}
		},
		keysObj() {
			if (this.statType === kickingStatType) return kickingStatsKeys;
			else if (this.statType === fieldingStatType) return fieldingStatsKeys;
			else if (this.statType === pitchingStatType) return pitchingStatsKeys;
		},
	},
	async mounted() {},
	created() {},
	methods: {
		hanldeSortBy(key) {
			this.sortStatsByKey = key;
		},
		formatValue({ key, value }) {
			if (this.statType === kickingStatType) return formatKickingValueByKey({ key, value });
			else if (this.statType === fieldingStatType) return formatFieldingValueByKey({ key, value });
			else if (this.statType === pitchingStatType) return formatPitchingValueByKey({ key, value });
			else return value;
		},
	},
};
</script>
<style lang="scss" scoped>
.Stats {
	display: flex;
	flex-direction: column;
	@media screen and (max-width: $md) {
		margin: 10px 20px;
	}
	@media screen and (max-width: $sm) {
		margin: 10px 0;
	}
}
.List {
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
	gap: 1rem;
	.Person {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		min-width: 150px;
		gap: 1rem;
	}
	.ListItem {
		display: flex;
		align-items: center;
		background: white;
		height: 65px;
		padding: 0 20px;
		border-radius: 1rem;
		gap: 2rem;
		height: auto;
		text-align: center;
		padding: 10px;
		@media screen and (max-width: $sm) {
			flex-direction: column;
			gap: 1rem;
		}
	}
}

.Row {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
	@media screen and (max-width: $sm) {
		justify-content: center;
	}
	.Cell {
		background: $gray-light;
		border-radius: 10px;
		padding: 4px 8px;
		min-width: 60px;
		&.active {
			background: $primary;
		}
		.Tooltip {
			width: 100%;
		}
		.Key {
			background: $white;
			padding: 2px 6px;
			width: 100%;
			border-radius: 5px;
			font-size: 12px;
		}
		.Value {
			font-weight: bold;
			font-size: 14px;
		}
	}
	.Tooltip {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 4px;
	}
}
.ListItem.Total {
	background: $primary;
	.Person {
		font-weight: bolder;
		font-size: 18px;
	}
	.Cell {
		background: rgba($gray-light, 0.5);
		&.active {
			background: $gray-light;
		}
		.Key {
			background: rgba($white, 0.8);
		}
	}
}
</style>
