import axios from 'axios'

export default {
    login: async (email, password) => {
        const user = await axios.post('/auth/login', { email, password })

        return user && user.data
    },

    logout: () => {
        return axios.get('/auth/logout')
    },

    requestPasswordRecovery: (body) => {
        return axios.post('/auth/request-password-recovery', body)
    },

    changePassword: (body) => {
        return axios.post('/auth/confirm-password', body)
    },
}
