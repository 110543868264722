<template>
	<div class="TermsScreen">
		<div class="Wrapper">
			<div class="ImageSection"
				:style="{ backgroundImage: `url(${require('@/assets/imgs/pictures/photo-10.jpg')})` }">
				<h3>Guía</h3>
				<PillSelectorInput v-model="activeTab" :options="tabs" border="gray" />
			</div>
			<div class="BlockSection" v-if="activeTab === 'ofensiva'">
				<h2>Ofensiva</h2>
				<!-- <Icon :size="16" icon="video" color="primary" /> -->
				<!-- TODO: TOFINISH:?¿ terreno_bueno cajon_de_pateo pie_de_apoyo pisa_y_corre habilitada estar_obligada infield outfield-->

				<p>Cuando el equipo está pateando y corriendo las bases, está "atacando".</p>
				<PillSelectorInput v-model="offensiveActiveTab" :options="offensiveTabs" border="gray" />
				<div v-if="offensiveActiveTab === 'pateando'">
					<p id="turno_al_bate">Un turno al bate consta de 3 strikes y 4 bolas.</p>
					<div class="TermSection Strike">
						<h3 class="Term">Strike</h3>
						<p class="Definition">Un pitcheo que pasa por la zona del home (incluyendo las esquinas), a una
							altura
							máxima de 25cm. TOFINISH:?¿</p>
						<p class="Utility">Al tercer strike, la pateadora es out.</p>
						<div class="SubTerm">
							<h3 class="Term" id="strike_visto">Strike visto</h3>
							<p class="Definition">Un pitcheo bueno que no es pateado.</p>
							<p class="NiceToKnow">Cuando la pateadora se <a href="#ponche">poncha</a> con un strike visto, y
								éste
								se
								le cae al catcher, la pateadora puede correr a la 1ra base, intentando así, llegar sin ser
								eliminada.
								Esto en caso de que no haya una corredora ocupando la base. TOFINISH:?¿
								<Icon :size="16" icon="video" color="primary" @click="handleToggleImage('strike_visto')" />
							</p>
							<video loop controls preload ref="strike_visto"
								:poster="require(`@/assets/imgs/guide/placeholder.png`)">
								<source :src="require(`@/assets/imgs/guide/ofensiva/pateando/ponche_strike_visto.mp4`)"
									type="video/mp4" />
							</video>
						</div>
						<div class="SubTerm">
							<h3 class="Term">Swing</h3>
							<p class="Definition">La pateadora hace un intento de pateo, en el que no logra hacer contacto
								con el
								balón.</p>
							<p class="Warning">
								Si el pitcheo viene desviado, y la pateadora hace un gesto que el árbitro interpreta como
								intento
								de
								pateo, éste será considerado "strike por swing".
							</p>
						</div>
						<div class="SubTerm">
							<h3 class="Term">Foul</h3>
							<p class="Definition">Cuando el pateo no es "bueno". Un foul, cuenta como un strike.</p>
							<p class="NiceToKnow">Un foul atrapado de aire, es out.</p>
							<div class="SubTerm">
								<h3 class="Term">Foul</h3>
								<p class="Definition">La pelota pateada sale del <a href="#terreno_bueno">"terreno
										bueno"</a>.
									<Icon :size="16" icon="video" color="primary"
										@click="handleToggleImage('image_foul')" />
								</p>
								<div class="MediaContent" ref="image_foul">
									<img :src="require(`@/assets/imgs/guide/foul.gif`)" />
									<video loop controls preload :poster="require(`@/assets/imgs/guide/placeholder.png`)">
										<source :src="require(`@/assets/imgs/guide/ofensiva/pateando/foul_1ra.mp4`)"
											type="video/mp4" />
									</video>
									<video loop controls preload :poster="require(`@/assets/imgs/guide/placeholder.png`)">
										<source :src="require(`@/assets/imgs/guide/ofensiva/pateando/foul_3ra.mp4`)"
											type="video/mp4" />
									</video>
								</div>
							</div>
							<div class="SubTerm">
								<h3 class="Term" id="foul_adelante">Foul adelante</h3>
								<p class="Definition">El pateo se efectúa dentro del campo, fuera del <a
										href="#cajon_de_pateo">cajón
										de pateo</a>.
									<Icon :size="16" icon="video" color="primary"
										@click="handleToggleImage('foul_adelante')" />
								</p>
								<div class="MediaContent" ref="foul_adelante">
									<img :src="require(`@/assets/imgs/guide/foul_adelante.gif`)" />
									<video loop controls preload :poster="require(`@/assets/imgs/guide/placeholder.png`)">
										<source :src="require(`@/assets/imgs/guide/ofensiva/pateando/foul_adelante.mp4`)"
											type="video/mp4" />
									</video>
								</div>

								<p class="Utility">Se determina por dónde esté ubicado el <a href="#pie_de_apoyo">pie de
										apoyo</a>
									en
									el momento en que se patea el balón.</p>
								<p class="NiceToKnow">El movimiento del pateo se suele terminar dentro del campo, esto no se
									determina
									foul.</p>
								<p class="Bonus">
									Si este tipo de foul es atrapado de aire, la bola muere. Es decir, que no se permite
									hacer <a href="#pisa_y_corre">"pisa y corre"</a> de las
									corredoras en base.
								</p>
							</div>
							<div class="SubTerm">
								<h3 class="Term">Foul atrás</h3>
								<p class="Definition">El pateo se efectúa en la parte baja del cajón de pateo.
									<Icon :size="16" icon="video" color="primary"
										@click="handleToggleImage('foul_atras')" />
								</p>
								<div class="MediaContent" ref="foul_atras">
									<img :src="require(`@/assets/imgs/guide/foul_atras.gif`)" />
									<video loop controls preload :poster="require(`@/assets/imgs/guide/placeholder.png`)">
										<source :src="require(`@/assets/imgs/guide/ofensiva/pateando/foul_atras.mp4`)"
											type="video/mp4" />
									</video>
								</div>
								<p class="Bonus">
									Si este tipo de foul es atrapado de aire, la bola muere. Es decir, que no se permite
									hacer <a href="#pisa_y_corre">"pisa y corre"</a> de las
									corredoras en base.
								</p>
							</div>
							<div class="SubTerm">
								<h3 class="Term" id="doble_contacto">Doble contacto</h3>
								<p class="Definition">
									La pateadora toca la pelota más de una vez, ya sea con el pie o con cualquier otra parte
									del
									cuerpo, dentro del cajón de pateo.
								</p>
								<p class="Warning">Si el doble contacto se efectúa dentro del campo, la pateadora es out.
								</p>
							</div>
						</div>
					</div>
					<div class="TermSection Bola" id="bola">
						<h3 class="Term">Bola</h3>
						<p class="Definition">Un pitcheo que NO es "bueno" o válido.</p>
						<div class="SubTerm">
							<h3 class="Term">Bola</h3>
							<p class="Definition">La pelota NO pasa por la zona del home (incluyendo las esquinas).</p>
						</div>
						<div class="SubTerm">
							<h3 class="Term">Bola alta</h3>
							<p class="Definition">La pelota va rebotando a una altura superior a 25cm. TOFINISH:?¿</p>
						</div>
						<div class="SubTerm">
							<h3 class="Term">Ilegal</h3>
							<p class="Definition">
								El equipo en defensiva rompe la normativa. En la mayor parte de los casos, ocurre con
								infracciones
								del
								pitcher. Un ilegal, cuenta como una bola.
							</p>
							<p class="Utility">
								Si la pateadora patea un ilegal, éste le cuenta como turno bueno en caso de no ser out
								(llegar
								quieta
								a 1ra base). Si le hacen el out, éste le cuenta
								como "bola" en su turno al bate.
							</p>
							<p class="Warning">
								El ilegal no protege a las corredoras. Si hay corredora en base, y el equipo en defensiva le
								hace
								out
								a una corredora, ésta es eliminada. Ejemplo: en
								caso de que se atrape el balón de aire y se haga doble play, la pateadora vuelve a patear,
								pero la
								corredora es eliminada.
							</p>
							<p class="Tip">
								Si estás pateando y el árbitro dice "ilegal", patea la pelota, venga como venga.
							</p>
							<p class="NiceToKnow">
								Cuando la pateadora llega quieta a 1ra base, el coach puede decidir si se queda la jugada, o
								hace
								que
								la pateadora vuelva a patear. TOFINISH:?¿
							</p>
							<div class="SubTerm">
								<h3 class="Term">Posición de la defensa</h3>
								<p class="Definition">Alguna de las 10 jugadoras en defensiva está mal ubicada en el momento
									que el
									pitcher efectúa el lanzamiento.</p>
								<p class="Utility">Las 9 jugadoras de campo, deben estar en <a href="#terreno_bueno">terreno
										bueno</a>, y el catcher debe estar en su cajón.
									<Icon :size="16" icon="image" color="primary"
										@click="handleToggleImage('posicion_sin_ilegal')" />
								</p>
								<img :src="require(`@/assets/imgs/guide/posicion_sin_ilegal.gif`)" ref="posicion_sin_ilegal"
									:style="{ margin: '.5rem 0' }" />
								<p class="NiceToKnow">Para "estar", se debe tener al menos un pie en la zona (ya sea campo o
									cajón).
								</p>
							</div>
							<div class="SubTerm">
								<h3 class="Term">Lanzamiento interrumpido TOFINISH:?¿</h3>
								<p class="Definition">El pitcheo es interrumpido por una defensora.</p>
								<p class="Utility">Un lanzamiento debe ir directamente del pitcher al catcher.</p>
								<p class="NiceToKnow">Suele ocurrir cuando el balón toca al short field antes de llegar al
									home.
								</p>
							</div>
							<div class="SubTerm">
								<h3 class="Term">Ilegal de raya TOFINISH:?¿</h3>
								<p class="Definition">La pelota no rebota antes del círculo.
									<Icon :size="16" icon="image" color="primary"
										@click="handleToggleImage('ilegal_de_raya')" />
								</p>
								<img :src="require(`@/assets/imgs/guide/foul_adelante.gif`)" ref="ilegal_de_raya"
									:style="{ margin: '.5rem 0' }" />

							</div>
							<div class="SubTerm">
								<h3 class="Term">Pitcheo no efectuado</h3>
								<p class="Definition">El pitcher presenta el balón, y luego no efectúa el lanzamiento.</p>
								<p class="Warning">
									Si el árbitro sentencia este ilegal, y posteriormente, el pitcher lanza el balón, la
									pelota está
									viva. En caso de haber corredoras en base, éstas están <a
										href="#habilitada">habilitadas</a>.
								</p>
								<p class="Warning">Si una corredora abre con el movimiento del pitcher (que no soltó el
									balón),
									ésta
									es out.</p>
								<p class="NiceToKnow">Está prohibido hacer esto de manera intencional. Si el árbitro
									identifica la
									intención del pitcher, TOFINISH:?¿</p>
							</div>
							<div class="SubTerm">
								<h3 class="Term">Altura del brazo</h3>
								<p class="Definition">
									El lanzamiento del pitcher va por encima de su cadera.
								</p>
								<p class="Utility">Esto pasa cuando el pitcher suelta la pelota de lado, con la intención de
									aumentar
									la velocidad del lanzamiento.</p>
							</div>
							<div class="SubTerm">
								<h3 class="Term">Toque de superficie</h3>
								<p class="Definition">
									El pitcher se toca el cabello, se escupe la mano, etc, justo antes de efectuar el
									lanzamiento.
									En
									caso de tocar alguna superficie, debe pasarse la
									mano por la pierna, antes de tocar la pelota. TOFINISH:?¿
								</p>
								<p class="Utility">La tierra en las manos hace que la pelota se resbale. Por esto, algunas
									lanzadoras
									se humedecen las manos para tener mejor agarre del balón. Algunas se escupen, se tocan
									el sudor
									del
									cuerpo, o humedecen con un paño mojado.</p>

							</div>
							<div class="SubTerm">
								<h3 class="Term">Abandono del círculo</h3>
								<p class="Definition">El pitcher finaliza el lanzamiento con un pie fuera de su círculo.
									TOFINISH:?¿
									<Icon :size="16" icon="image" color="primary"
										@click="handleToggleImage('circulo_del_pitcher')" />
								</p>
								<img :src="require(`@/assets/imgs/guide/circulo_del_pitcher.gif`)" ref="circulo_del_pitcher"
									:style="{ margin: '.5rem 0' }" />

							</div>
							<div class="SubTerm">
								<h3 class="Term">Mala presentación del balón</h3>
								<p class="Definition">FINISH:?¿</p>
								<p class="Utility">El pitcher debe presentar el balón antes de lanzarlo.</p>
							</div>
						</div>
					</div>
					<div class="TermSection MaxCount">
						<h3 class="Term">Términos</h3>
						<div class="SubTerm">
							<h3 class="Term">Cuenta máxima o cuenta completa</h3>
							<p class="Definition">La pateadora tiene 3 bolas y 2 strikes.</p>
							<p class="Utility">El siguiente lanzamiento es él ultimo que recibirá la pateadora en su turno.
								En
								caso
								de ser bola, se le otorgará <a href="#base_por_bola">"base por bola"</a> a la pateadora. En
								caso de
								ser strike, la pateadora deberá efetuar un pateo bueno para no <a
									href="#ponche">"poncharse"</a>.
							</p>
							<p class="NiceToKnow">Cuando hay 2 outs, y una corredora sabe que su compañera "está montada en
								3 y
								2",
								ésta puede correr a la siguiente base en cuanto el pitcher suelta la pelota, ya que debería
								hacerlo
								en
								independencia de lo que suceda.</p>
						</div>
						<div class="SubTerm" id="base_por_bola">
							<h3 class="Term">Base por bola</h3>
							<p class="Definition">Cuando el pitcher efectúa 4 lanzamientos malos (<a
									href="#bola">"bolas"</a>), se
								le
								otorga la base a la pateadora.
								<Icon :size="16" icon="video" color="primary" @click="handleToggleImage('base_por_bola')" />
							</p>
							<video loop controls preload ref="base_por_bola"
								:poster="require(`@/assets/imgs/guide/placeholder.png`)">
								<source :src="require(`@/assets/imgs/guide/ofensiva/pateando/base_por_bola.mp4`)"
									type="video/mp4" />
							</video>
							<p class="NiceToKnow">A veces pasa que cuando se produce una base por bola, el catcher se
								distrae y
								entra
								al campo. Cuando esto ocurre, las corredoras pueden robar bases como en cualquier otra
								jugada.</p>
						</div>
						<div class="SubTerm" id="ponche">
							<h3 class="Term">Ponche</h3>
							<p class="Definition">La pateadora es eliminada al tercer strike.</p>
							<p class="NiceToKnow">Cuando la pateadora se poncha con un <a href="#strike_visto">strike
									visto</a>, y
								éste se le cae al catcher, la pateadora puede correr a la 1ra base, intentando así, llegar
								sin ser
								eliminada. Esto en caso de que no haya una corredora ocupando la base. TOFINISH:?¿
								<Icon :size="16" icon="video" color="primary"
									@click="handleToggleImage('ponche_strike_visto')" />
							</p>
							<video loop controls preload ref="ponche_strike_visto"
								:poster="require(`@/assets/imgs/guide/placeholder.png`)">
								<source :src="require(`@/assets/imgs/guide/ofensiva/pateando/ponche_strike_visto.mp4`)"
									type="video/mp4" />
							</video>
						</div>
						<div class="SubTerm">
							<h3 class="Term">Impulsar</h3>
							<p class="Definition">Efectuar un pateo bueno, que permita que otra corredora anote carrera.</p>
							<p class="NiceToKnow">"Tráetela" es una expresión que se usa para animar a la pateadora a
								impulsar la
								carrera de su compañera.</p>
						</div>
						<div class="SubTerm">
							<h3 class="Term">Sacrificar a una compañera</h3>
							<p class="Definition">Un pateo que va hacia la misma dirección a la que avanza una corredora, y
								ésta
								acaba siendo eliminada.
								<Icon :size="16" icon="video" color="primary"
									@click="handleToggleImage('sacrificar_a_companera')" />
							</p>
							<div class="MediaContent" ref="sacrificar_a_companera">
								<video loop controls preload :poster="require(`@/assets/imgs/guide/placeholder.png`)">
									<source
										:src="require(`@/assets/imgs/guide/ofensiva/pateando/sacrificar_a_companera.mp4`)"
										type="video/mp4" />
								</video>
								<video loop controls preload :poster="require(`@/assets/imgs/guide/placeholder.png`)">
									<source
										:src="require(`@/assets/imgs/guide/ofensiva/pateando/sacrificar_a_companera2.mp4`)"
										type="video/mp4" />
								</video>
							</div>
							<p class="Tip">Se debe patear buscando impulsar a las compañeras que ya están embasadas. No es
								útil
								hacer
								un pateo que te permitar llegar quieta a base, sacrificando a una corredora que ya estaba
								más
								avanzada.</p>
						</div>
					</div>
					<div class="TermSection Out">
						<h3 class="Term">Out por pateo</h3>
						<p class="Definition">La pateadora es eliminada</p>
						<div class="SubTerm">
							<h3 class="Term">Doble contacto</h3>
							<p class="Definition">Si el <a href="#doble_contacto">doble contacto</a> se efectúa dentro del
								campo,
								la
								pateadora es out.</p>
						</div>
						<div class="SubTerm">
							<h3 class="Term">Salirse del cajón</h3>
							<p class="Definition">La pateadora se sale del cajón (hacia los lados) durante el lanzamiento
								del
								pitcher.
								<Icon :size="16" icon="video" color="primary" @click="handleToggleImage('out_por_cajon')" />
							</p>
							<div class="MediaContent" ref="out_por_cajon">
								<img :src="require(`@/assets/imgs/guide/robo_con_repise.gif`)" />
								<video loop controls preload :poster="require(`@/assets/imgs/guide/placeholder.png`)">
									<source :src="require(`@/assets/imgs/guide/ofensiva/pateando/salirse_del_cajon.mp4`)"
										type="video/mp4" />
								</video>
							</div>
							<p class="Warning">Pasa mucho cuando la pateadora quiere esquivar un pitcheo.</p>
							<p class="Warning">También ocurre cuando la pateadora se para en una esquina, y justo antes de
								atacar
								el
								pitcheo, se echa un poco hacia atrás.</p>
							<p class="NiceToKnow">Una vez que el catcher tiene la pelota, la pateadora se puede salir del
								cajón,
								sin
								riesgo a ser eliminada.</p>
							<p class="Bonus">Cuando hay jugada en home, la pateadora debe abandonar el cajón de pateo.
								Ejemplo: la
								corredora de 3ra se roba la base.</p>
						</div>
						<div class="SubTerm">
							<h3 class="Term">Patear con ambos pies</h3>
							<p class="Definition">Por cada turno de pateo sólo se puede usar un pie. En caso de usar
								intencionalmente
								ambos pies, la pateadora es out.</p>
							<p class="Utility">Ejemplo: el primer lanzamiento llegó a la esquina izquierda del home y la
								peateadora
								hizo foul con el pie izquierdo. En el siguiente lanzamiento, se sintió más cómoda para
								patear con
								el
								pie derecho. Es out.</p>
							<p class="NiceToKnow">Se puede cambiar de pierna para patear en <a href="#turno_al_bate">turnos
									al
									bate</a> diferentes.</p>
							<p class="Bonus">Si el lanzamiento toca un pie de la pateadora por accidente, no es considerado
								intento
								de pateo, y puede seguir pateando con su pie habitual.</p>
						</div>
						<div class="SubTerm">
							<h3 class="Term">Agarrar el balón con las manos</h3>
							<p class="Definition">La pateadora coge la pelota con las manos, y es out.</p>
							<p class="NiceToKnow">Es una situación muy improbable, pero ha pasado.</p>
						</div>
						<div class="SubTerm">
							<h3 class="Term">Pisar el balón</h3>
							<p class="Definition">La pateadora que pisa la pelota, es out.
								<Icon :size="16" icon="video" color="primary"
									@click="handleToggleImage('pisar_la_pelota')" />
							</p>
							<video loop controls preload muted ref="pisar_la_pelota"
								:poster="require(`@/assets/imgs/guide/placeholder.png`)">
								<source :src="require(`@/assets/imgs/guide/ofensiva/pateando/pisar_la_pelota.mp4`)"
									type="video/mp4" />
							</video>
						</div>
					</div>
					<div class="TermSection KickTypes">
						<h3 class="Term">Tipos de pateo</h3>
						<div class="SubTerm">
							<h3 class="Term" id="rolling">Rolling o "rastrera"</h3>
							<p class="Definition">El pateo hace que la pelota vaya por el suelo, no la eleva / levanta.</p>
							<p class="Utility">Sirve para obligar al equipo contrario a hacer lanzamientos a las bases;
								aumentando
								así, las probabilidades de que cometan un error.</p>
							<p class="Utility">Con menos de 2 outs, simplifica la reacción de las corredoras en base que <a
									href="#estar_obligada">estén obligadas</a> a correr, ya que éstas no deben esperar a ver
								si la
								pelota es atrapada de aire.</p>
						</div>
						<div class="SubTerm">
							<h3 class="Term">Línea</h3>
							<p class="Definition">Es un pateo duro, que va de aire, pero no eleva la pelota a gran alltura.
								Tiene
								una
								trayectoria linear.
								<Icon :size="16" icon="video" color="primary" @click="handleToggleImage('linea')" />
							</p>
							<video loop controls preload muted ref="linea"
								:poster="require(`@/assets/imgs/guide/placeholder.png`)">
								<source :src="require(`@/assets/imgs/guide/ofensiva/pateando/linea.mp4`)"
									type="video/mp4" />
							</video>
						</div>
						<div class="SubTerm">
							<h3 class="Term">Fly o "de aire"</h3>
							<p class="Definition">El pateo hace que la pelota se eleve.</p>
							<div class="SubTerm">
								<h3 class="Term">Fly corto</h3>
								<p class="Definition">La pelota se eleva, y cae en la zona del <a
										href="#infield">infield</a> o un
									poco más hacia atrás.</p>
							</div>
							<div class="SubTerm">
								<h3 class="Term">Fly largo</h3>
								<p class="Definition">La pelota se eleva, y cae en la zona del <a
										href="#outfield">outfield</a>.
								</p>
							</div>
							<div class="SubTerm">
								<h3 class="Term">Fly de sacrificio</h3>
								<p class="Definition">Es un fly largo, que probablemente será atrapado de aire, pero
									permitirá el
									avance de otras corredoras (mediante <a href="#pisa_y_corre">"pisa y corre"</a>),
									posiblemente
									anotando carreras.</p>
							</div>
						</div>
						<div class="SubTerm">
							<h3 class="Term">Toque de bola</h3>
							<p class="Definition">La pelota se queda cerca del home.</p>
							<div class="SubTerm">
								<h3 class="Term">Toque de sacrificio</h3>
								<p class="Definition">La pateadora toca la pelota cuando hay menos de 2 outs y una corredora
									rápida
									en
									1ra base.
									<Icon :size="16" icon="video" color="primary"
										@click="handleToggleImage('toque_sacrificio')" />
								</p>
								<div class="MediaContent" ref="toque_sacrificio">
									<video loop controls preload :poster="require(`@/assets/imgs/guide/placeholder.png`)">
										<source :src="require(`@/assets/imgs/guide/ofensiva/pateando/toque_sacrificio.mp4`)"
											type="video/mp4" />
									</video>
								</div>
								<p class="Utility">Muy probablemente la pateadora será eliminada en 1ra base, pero su
									compañera
									habrá
									avanzado DOS bases, alcanzado así la 3ra base.</p>
								<p class="Tip">El toque debe ser de rolling (rastrero). Si va de aire, la corredora no podrá
									avanzar
									hasta que éste no caiga, y probablemente no consiga alcanzar más de una base. Incluso,
									si el
									toque
									es atrapado de aire, es posible que la corredora también sea eliminada con un
									lanzamiento a 1ra
									base.
									<Icon :size="16" icon="video" color="primary"
										@click="handleToggleImage('toque de aire')" />
								</p>
								<video loop controls preload ref="toque de aire"
									:poster="require(`@/assets/imgs/guide/placeholder.png`)">
									<source :src="require(`@/assets/imgs/guide/ofensiva/pateando/toque de aire.mp4`)"
										type="video/mp4" />
								</video>
								<p class="NiceToKnow">En defensiva, en esta jugada ese busca hacer el "doble play".</p>
								<p class="Bonus">En defensiva, cuando se espera un toque de sacrificio, se puede hacer que
									la
									primera
									juegue abajo; para intentar tocar a la pateadora y evitar que la corredora avance hasta
									la 3ra
									base. TOFINISH:?¿ TODELETE:?¿</p>
							</div>
							<div class="SubTerm">
								<h3 class="Term">Toque sorpresa</h3>
								<p class="Definition">La pateadora toca la pelota para embasarse.</p>
								<p class="Utility">Se hace cuando la pateadora es rápida y/o el short field está parado un
									poco
									lejos
									del home.
									<Icon :size="16" icon="video" color="primary"
										@click="handleToggleImage('toque_sorpresa')" />
								</p>
								<div class="MediaContent" ref="toque_sorpresa">
									<video loop controls preload :poster="require(`@/assets/imgs/guide/placeholder.png`)">
										<source :src="require(`@/assets/imgs/guide/ofensiva/pateando/toque_sorpresa.mp4`)"
											type="video/mp4" />
									</video>
								</div>
							</div>
							<div class="SubTerm">
								<h3 class="Term">Dragueo</h3>
								<p class="Definition">Hacer un toque largo hacia primera base.
									<Icon :size="16" icon="video" color="primary" @click="handleToggleImage('dragueo')" />
								</p>
								<video loop controls preload :poster="require(`@/assets/imgs/guide/placeholder.png`)"
									ref="dragueo">
									<source :src="require(`@/assets/imgs/guide/ofensiva/pateando/dragueo.mp4`)"
										type="video/mp4" />
								</video>
							</div>
						</div>
					</div>
					<div class="TermSection KickDefinition">
						<h3 class="Term">Resultado del pateo</h3>
						<div class="SubTerm">
							<h3 class="Term">Hit</h3>
							<p class="Definition">La pelota queda en terreno bueno y la pateadora llega quieta a la base. No
								por
								motivo de un error, ni por elección de un fildeador, sino como resultado
								de un buen pateo.</p>
						</div>
						<div class="SubTerm">
							<h3 class="Term">Doble</h3>
							<p class="Definition">La pelota supera los límites del campo, en la zona del outfield, después
								de
								haber
								picado (botado) al menos una vez en terreno bueno.</p>
						</div>
						<div class="SubTerm">
							<h3 class="Term">Home run de pierna</h3>
							<p class="Definition">La pateadora consigue avanzar todas las bases y llegar de vuelta al home
								en una
								misma jugada, sin que la pelota de salga del campo.</p>
							<p class="Definition">Suele ocurrir cuando el equipo contrario comete muchos errores.</p>
						</div>
						<div class="SubTerm">
							<h3 class="Term">Home run</h3>
							<p class="Definition">La pelota supera los límites del campo, en la zona del outfield, sin haber
								picado
								antes.</p>
							<p class="Utility">Como consecuencia, la pateadora puede correr las bases y volver al home,
								anotando
								así
								una carrera.</p>
							<div class="SubTerm">
								<h3 class="Term">Grand slam</h3>
								<p class="Definition">Hacer un home run con las bases llenas.</p>
							</div>
						</div>
					</div>

					<div class="TermSection Signs">
						<h3 class="Term">Señas de pateo</h3>
						<p class="Definition">Indicaciones que te puede dar tu coach.</p>
						<div class="SubTerm">
							<h3 class="Term">Aguanta una</h3>
							<p class="Definition">Dejar pasar un pitcheo bueno (<a href="#strike_visto">strike visto</a>).
							</p>
							<p class="NiceToKnow">El coach te puede decir "aguanta una", por varios motivos. Ejemplos:
							<ol>
								<li>El pitcher acaba de dar base por bola a otra compañera.</li>
								<li>Tu turno al bate tiene 2 bolas.</li>
								<li>Tu turno al bate tiene 3 bolas y 1 strike.</li>
								<li>Se necesita cansar al pitcher.</li>
								<li>Una compañera va a intentar robarse la base.</li>
							</ol>
							</p>
							<p class="Warning">Dejar pasar una bola, NO cuenta como "aguantar".</p>
							<p class="Bonus">"Deja que pitche" es una expresión para invitar a "aguantar", a no patear la
								primera
								pelota que te llega.</p>
						</div>
						<div class="SubTerm">
							<h3 class="Term">Pateo y corrido</h3>
							<p class="Definition">La pateadora deberá patear el primer lanzamiento del pitcher (ya sea bola
								o
								strike,
								le guste o no), porque las corredoras avanzarán a la siguiente base en cuanto el pitcher
								suelte la
								pelota, sin esperar a que se efectúe el pateo.
							</p>
							<p class="Warning">Si la pateadora no hace contacto con el balón, las corredoras serán out, ya
								que
								habrán pasado la línea sin una jugada válida.</p>
							<p class="Utility">Se usa para buscar que todas las corredoras avancen dos bases con un sólo
								pateo.
							</p>
							<p class="Tip">Lo ideal es que el pateo sea un <a href="#rolling">rolling</a> para que las
								corredoras
								estén habilitadas a correr. Si el pateo va de fly y es atrapado de aire, todas las
								corredoras serán
								out por haber pasado la raya.</p>
							<p class="Tip">Si el pitcheo llega a la zona de home muy desviado, la pateadora puede entrar al
								terreno
								y hacer un toque, que será decretado <a href="#foul_adelante">foul adelante</a>, pero
								evitará que
								sus
								compañeras sean eliminadas.
								<Icon :size="16" icon="video" color="primary"
									@click="handleToggleImage('pateo_y_corrido')" />
							</p>
							<video controls preload :poster="require(`@/assets/imgs/guide/placeholder.png`)"
								ref="pateo_y_corrido">
								<source :src="require(`@/assets/imgs/guide/ofensiva/pateando/pateo_y_corrido.mp4`)"
									type="video/mp4" />
							</video>
							<p class="Bonus">Esta jugada es provechosa de hacer cuando el pitcheo es muy lento, ya que todo
								ese
								tiempo lo ganarán las corredoras avanzando las bases.</p>
							<div class="SubTerm">
								<h3 class="Term">Toque y corrido</h3>
								<p class="Definition">Se toca la pelota, en lugar de patearla</p>

							</div>
						</div>
						<div class="SubTerm">
							<h3 class="Term">Bicicleta</h3>
							<p class="Definition">La pateadora se posiciona sobre el home, simulando que va a tocar la
								pelota, y
								por
								el contrario, la patea duro.</p>
							<p class="Definition">Es una jugada que busca engañar al otro equipo, intentando conseguir que
								pitcher
								y
								short field se acerquen al home.
								<Icon :size="16" icon="video" color="primary" @click="handleToggleImage('bicicleta')" />
							</p>
							<video loop controls preload :poster="require(`@/assets/imgs/guide/placeholder.png`)"
								ref="bicicleta">
								<source :src="require(`@/assets/imgs/guide/ofensiva/pateando/pegale_al_sf.mp4`)"
									type="video/mp4" />
							</video>


						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { teams } from "@/data/teams.js";


export default {
	name: "TermsScreen",
	data() {
		return {
			teams,
			activeTab: 'ofensiva',
			offensiveActiveTab: 'pateando',
			tabs: [],
			offensiveTabs: [],
			defensiveTabs: [],
		};
	},
	mounted() {
		this.tabs = [
			{
				key: 'ofensiva',
				label: 'Ofensiva',
			},
			{
				key: 'defensiva',
				label: 'Defensiva',
			},
			{
				key: 'general',
				label: 'General',
			},
		]
		this.offensiveTabs = [
			{
				key: 'pateando',
				label: 'Pateando',
			},
			{
				key: 'corriendo',
				label: 'Corriendo',
			},
		]
		this.defensiveTabs = [

		]
	},
	methods: {
		goTo(link) {
			if (link) window.open(link, "_blank");
		},
		handleToggleImage(id) {
			const classValue = this.$refs[id].classList.value
			const isImageHidden = !classValue || classValue !== 'visible'
			console.log('isImageHidden', isImageHidden, this.$refs[id].classList, this.$refs[id].classList.value)
			if (isImageHidden) this.$refs[id].classList.add('visible')
			else this.$refs[id].classList.remove('visible')
		},
		handleChangeTab(value) {
			if (activeTab !== value) this.activeTab = value
		},
	},
};
</script>

<style lang="scss" scoped>
* {
	transition: all 0.4s;
}

.TermsScreen {
	width: 100%;
	background: #333;

	@media screen and (min-width: $xl) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.Wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;

		@media screen and (min-width: $xl) {
			width: 1300px;
		}

		.PillSelectorInput {
			width: fit-content;

			&::v-deep .OptionGroup {

				background-color: $gray-darker;
				opacity: 1;

				.Option {
					color: $white;
					background: $gray-darker;

					@media screen and (max-width: $sm) {
						font-size: .8rem;
					}

					&.selected {
						color: $gray-darker;
						background: $primary;
					}
				}
			}
		}

		.ImageSection {
			height: 15rem;
			width: 100%;
			background-size: cover;
			background-repeat: no-repeat;
			background-position-x: center;
			background-position-y: center;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			padding: 6rem 2rem 2rem 4rem;

			@media screen and (max-width: $sm) {
				padding: 1rem;
				justify-content: flex-end;
			}

			h3 {
				font-size: 3rem;
				line-height: 3.5rem;
				font-weight: $font-weight-bold;
				color: $white;

				@media screen and (max-width: $sm) {
					font-size: 2.5rem;
				}
			}
		}

		.BlockSection {
			height: auto;
			padding: 2rem;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;

			@media screen and (max-width: $sm) {
				padding: 1rem;
			}

			a {
				color: white;
				text-decoration: underline;
			}

			h2 {
				font-size: 2rem;
				color: $primary;

				@media screen and (max-width: $sm) {
					font-size: 1.5rem;
				}
			}

			p {
				font-size: 1rem;
				text-align: justify;
				line-height: 1.4rem;
				color: white;

				@media screen and (max-width: $sm) {
					font-size: .9rem;
					text-align: left;
				}
			}

			.PillSelectorInput {
				margin-top: 2rem;
				margin-bottom: 1rem;
			}

			.TermSection {
				color: $white;
				margin-top: 2rem;
				margin-left: 2rem;

				@media screen and (max-width: $sm) {
					margin-left: 1rem;
				}

				.Icon {
					margin-left: .5rem;

					@media screen and (max-width: $sm) {
						margin-left: .2rem;
					}
				}

				img,
				video {
					display: none;

					&.visible {
						display: block
					}
				}

				img {
					height: 5rem;

					@media screen and (max-width: $sm) {
						height: 4rem;
					}
				}

				video {
					height: 10rem;

					@media screen and (max-width: $sm) {
						height: 8rem;
					}
				}

				.MediaContent {
					display: none;

					&.visible {
						margin: .5rem 0;
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						gap: 1rem;

						img,
						video {
							display: block
						}
					}
				}

				.Term {
					font-size: 1.4rem;
					line-height: 1.4rem;
					color: $primary;
				}

				.Definition,
				.Utility,
				.Warning,
				.Tip,
				.Bonus,
				.NiceToKnow {
					margin-bottom: 1rem;

					&:before {
						font-size: 0.7rem;
						background: $gray-dark;
						font-weight: bold;
						padding: 0.2rem 0.5rem;
						border-radius: 0.2rem;
						margin-right: 0.8rem;
						align-self: center;

						@media screen and (max-width: $sm) {
							font-size: 0.6rem;
							padding: 0.2rem 0.4rem;
						}
					}
				}

				.Definition {
					&:before {
						content: "DEFINICIÓN";
						background: $gray-dark;
					}
				}

				.Utility {
					&:before {
						content: "INFO ÚTIL";
						background: $gray-dark;
					}
				}

				.Warning {
					&:before {
						content: "ATENCIÓN";
						background: $gray-dark;
					}
				}

				.Tip {
					&:before {
						content: "TIP";
						background: $gray-dark;
					}
				}

				.NiceToKnow {
					&:before {
						content: "BUENO SABERLO";
						background: $gray-dark;
					}
				}

				.Bonus {
					&:before {
						content: "BONUS";
						background: $gray-dark;
					}
				}

				.SubTerm {
					margin-left: 2rem;
					margin-bottom: 1rem;
					margin-top: 1rem;

					@media screen and (max-width: $sm) {
						margin-left: 1rem;
					}

					.Term {
						font-size: 1rem;
						color: $primary;
						margin-bottom: 0.4rem;
					}

					.Definition,
					.Utility,
					.Warning,
					.Tip,
					.Bonus,
					.NiceToKnow {
						margin-bottom: 0.2rem;
					}
				}
			}
		}
	}
}
</style>
