<template>
	<div class="PublicNavbar">
		<div class="Menu">
			<Icon v-if="isMobile" :key="2" :size="25" icon="menu" :color="colorScreen" @click="handleShowDropdownModal" />
			<div v-else>
				<a v-for="({ path, label, key }, index) in menu" :key="index" :href="path"
					:class="{ active: activeScreen === key, dark: colorScreen === 'black' }">
					{{ label }}
				</a>
			</div>
		</div>
		<DropdownModal :isOpen="showDropdownModal" darkMode :options="menu" @onClick="handleClickOption"
			@onClose="showDropdownModal = false" />
	</div>
</template>

<script>
import { mediaQueryMixin } from "@/mixins";
import DropdownModal from "../DropdownModal/DropdownModal.vue";

export default {
	name: "PublicNavbar",
	components: {
		DropdownModal,
	},
	mixins: [mediaQueryMixin],
	data() {
		return {
			activeScreen: this.$route.name,
			showDropdownModal: false,
			menu: [
				{
					key: "Home",
					path: "/",
					label: "Inicio",
				},
				{
					key: "Players",
					path: "/nuestras-jugadoras",
					label: "Jugadoras",
				},
				{
					key: "Kickingball",
					path: "/que-es-el-kickingball",
					label: "¿Qué es el kickingball?",
				},
				{
					key: "Welcome",
					path: "/presentacion",
					label: "Presentación",
				},
				// {
				//     key: 'League',
				//     path: 'liga',
				//     label: '3ra Liga',
				// },
				// {
				// 	key: "League",
				// 	path: "liga",
				// 	label: "4ta Liga",
				// },
				// {
				//     key: 'League',
				//     path: 'liga',
				//     label: '2da Liga',
				// },
				// {
				// 	key: "TorneoOtono",
				// 	path: "/torneo-otono",
				// 	label: "Torneo Otoño"
				// },
				{
					key: "MadMix",
					path: "/madmix",
					label: "MadMix",
				},
				// {
				// 	key: "KickMix",
				// 	path: "/torneo-kickmix",
				// 	label: "KickMix",
				// },
				// {
				//     key: 'Trivia',
				//     path: 'trivia',
				//     label: 'Trivia',
				// },
				{
					key: "Signin",
					path: "signin",
					label: "Área privada",
				},
			],
		};
	},
	computed: {
		isMobile() {
			return this.$mq.w < this.$mq.md.min;
		},
		colorScreen() {
			console.log(this.activeScreen, this.activeScreen === "MadMixConditionss")
			return (this.activeScreen === "TorneoOtono" || this.activeScreen === "MadMixConditionss") ? "black" : "white";
		},
	},
	methods: {
		handleShowDropdownModal() {
			this.showDropdownModal = true;
		},
		handleClickOption(option) {
			const { key } = option;
			this.showDropdownModal = false;
			this.$router.push({ name: key });
		},
	},
};
</script>
<style lang="scss" scoped>
.PublicNavbar {
	.Menu {
		position: absolute;
		top: 2rem;
		z-index: 99999;
		display: flex;
		flex-wrap: wrap;
		right: 4rem;

		a {
			transition: all 0.5s;
			color: white;
			font-size: 1.3rem;
			position: relative;
			padding-left: 1.8rem;

			// $tabWidth: calc(100% / 3);
			// border-bottom: 1px solid $primary;
			&.active {
				&:after {
					content: "";
					position: absolute;
					bottom: -10px;
					left: 50%;
					width: 20px;
					// left: $tabWidth;
					// width: $tabWidth;
					height: 3px;
					background: $primary;
					transition: all 0.3s ease-in-out;
				}
			}

			&.dark {
				color: #333;
			}

			&:hover {
				color: $primary;
				cursor: pointer;
			}

			@media screen and (max-width: $lg) {
				font-size: 1.1rem;
			}
		}

		a+a {
			margin-left: 1.8rem;
			border-left: 3px solid $gray-dark;

			@media screen and (max-width: $lg) {
				margin-left: 1.2rem;
				padding-left: 1.2rem;
			}
		}
	}
}
</style>
