export const teams = [
    {
        id: 1,
        name: 'Las Panas',
    },
    {
        id: 2,
        name: 'Águilas',
    },
    {
        id: 3,
        name: 'Espartanas',
    },
    {
        id: 4,
        name: 'Tropicales',
    },
    {
        id: 5,
        name: 'Vikingas',
    },
    {
        id: 6,
        name: 'Murallas',
    },
    {
        id: 7,
        name: 'Panteras',
    },
    {
        id: 8,
        name: '1ro',
    },
    {
        id: 9,
        name: '2do',
    },
    {
        id: 10,
        name: '3ro',
    },
    {
        id: 11,
        name: '4to',
    },
    {
        id: 12,
        name: 'Clasificado a la Final',
    },
    {
        id: 13,
        name: 'Clasificado a la Final',
    },
]

export const locations = [
    {
        id: 1,
        name: 'Jarama',
        address: 'Av. de San Sebastián, 15',
    },
]

export const locationTypes = {
    1: {
        icon: 'map-pin',
        color: 'secondary',
        label: 'Jarama: Av. de San Sebastián, 15',
        link: 'https://goo.gl/maps/gxmee9ZKVYunv4rB9',
    },
}

export const scoreTypes = {
    1: {
        abbrev: 'PJ',
        color: 'secondary',
        label: 'Partidos jugados',
    },
    2: {
        abbrev: 'PG',
        color: 'secondary',
        label: 'Partidos Ganados',
    },
    3: {
        abbrev: 'PP',
        color: 'secondary',
        label: 'Partidos Perdidos',
    },
    4: {
        abbrev: 'PE',
        color: 'secondary',
        label: 'Partidos Empatados',
    },
    5: {
        abbrev: 'CF',
        color: 'secondary',
        label: 'Carreras a favor',
    },
    6: {
        abbrev: 'CC',
        color: 'secondary',
        label: 'Carreras en contra',
    },
    7: {
        abbrev: 'DIF',
        color: 'secondary',
        label: 'Diferencia',
    },
    8: {
        abbrev: 'PTS',
        color: 'secondary',
        label: 'Puntos',
    },
}

export const events = [
    [
        {
            id: 1,
            homeclubId: 1,
            visitorId: 2,
            locationId: 1,
            date: '2022-04-03T09:30:00.000Z',
            scoreHomeclub: 4,
            scoreVisitor: 8,
        },
        {
            id: 2,
            homeclubId: 4,
            visitorId: 7,
            locationId: 1,
            date: '2022-04-03T11:20:00.000Z',
            scoreHomeclub: 0,
            scoreVisitor: 11,
        },
        {
            id: 3,
            homeclubId: 6,
            visitorId: 5,
            locationId: 1,
            date: '2022-04-03T13:10:00.000Z',
            scoreHomeclub: 0,
            scoreVisitor: 7,
        },
    ],
    [
        {
            id: 4,
            homeclubId: 7,
            visitorId: 3,
            locationId: 1,
            date: '2022-04-10T09:30:00.000Z',
            scoreHomeclub: 2,
            scoreVisitor: 1,
        },
        {
            id: 5,
            homeclubId: 2,
            visitorId: 6,
            locationId: 1,
            date: '2022-04-10T11:20:00.000Z',
            scoreHomeclub: 0,
            scoreVisitor: 7,
        },
        {
            id: 6,
            homeclubId: 5,
            visitorId: 4,
            locationId: 1,
            date: '2022-04-10T13:10:00.000Z',
            scoreHomeclub: 1,
            scoreVisitor: 3,
        },
    ],
    [
        {
            id: 7,
            homeclubId: 4,
            visitorId: 2,
            locationId: 1,
            date: '2022-04-24T09:30:00.000Z',
            scoreHomeclub: null,
            scoreVisitor: null,
            cancelled: true,
        },
        {
            id: 8,
            homeclubId: 3,
            visitorId: 5,
            locationId: 1,
            date: '2022-04-24T11:20:00.000Z',
            scoreHomeclub: null,
            scoreVisitor: null,
            cancelled: true,
        },
        {
            id: 9,
            homeclubId: 6,
            visitorId: 1,
            locationId: 1,
            date: '2022-04-24T13:10:00.000Z',
            scoreHomeclub: null,
            scoreVisitor: null,
            cancelled: true,
        },
    ],
    [
        {
            id: 10,
            homeclubId: 1,
            visitorId: 4,
            locationId: 1,
            date: '2022-05-08T09:30:00.000Z',
            scoreHomeclub: 4,
            scoreVisitor: 2,
        },
        {
            id: 11,
            homeclubId: 2,
            visitorId: 3,
            locationId: 1,
            date: '2022-05-08T11:20:00.000Z',
            scoreHomeclub: 5,
            scoreVisitor: 5,
        },
        {
            id: 12,
            homeclubId: 5,
            visitorId: 7,
            locationId: 1,
            date: '2022-05-08T13:10:00.000Z',
            scoreHomeclub: 4,
            scoreVisitor: 2,
        },
    ],
    [
        {
            id: 13,
            homeclubId: 7,
            visitorId: 2,
            locationId: 1,
            date: '2022-05-15T09:30:00.000Z',
            scoreHomeclub: 5,
            scoreVisitor: 6,
        },
        {
            id: 14,
            homeclubId: 4,
            visitorId: 6,
            locationId: 1,
            date: '2022-05-15T11:20:00.000Z',
            scoreHomeclub: 10,
            scoreVisitor: 2,
        },
        {
            id: 15,
            homeclubId: 1,
            visitorId: 3,
            locationId: 1,
            date: '2022-05-15T13:10:00.000Z',
            scoreHomeclub: 5,
            scoreVisitor: 4,
        },
        {
            id: 29,
            homeclubId: 4,
            visitorId: 2,
            locationId: 1,
            date: '2022-05-15T15:00:00.000Z',
            scoreHomeclub: 1,
            scoreVisitor: 2,
        },
    ],
    [
        {
            id: 16,
            homeclubId: 6,
            visitorId: 3,
            locationId: 1,
            date: '2022-05-22T09:30:00.000Z',
            scoreHomeclub: 1,
            scoreVisitor: 14,
        },
        {
            id: 17,
            homeclubId: 1,
            visitorId: 7,
            locationId: 1,
            date: '2022-05-22T11:20:00.000Z',
            scoreHomeclub: 0,
            scoreVisitor: 10,
        },
        {
            id: 18,
            homeclubId: 2,
            visitorId: 5,
            locationId: 1,
            date: '2022-05-22T13:10:00.000Z',
            scoreHomeclub: 0,
            scoreVisitor: 4,
        },
        {
            id: 30,
            homeclubId: 6,
            visitorId: 1,
            locationId: 1,
            date: '2022-05-22T15:00:00.000Z',
            scoreHomeclub: 1,
            scoreVisitor: 6,
        },
    ],
    [
        {
            id: 19,
            homeclubId: 5,
            visitorId: 1,
            locationId: 1,
            date: '2022-05-29T09:30:00.000Z',
            scoreHomeclub: 6,
            scoreVisitor: 2,
        },
        {
            id: 20,
            homeclubId: 3,
            visitorId: 4,
            locationId: 1,
            date: '2022-05-29T11:20:00.000Z',
            scoreHomeclub: 1,
            scoreVisitor: 5,
        },
        {
            id: 21,
            homeclubId: 7,
            visitorId: 6,
            locationId: 1,
            date: '2022-05-29T13:10:00.000Z',
            scoreHomeclub: 10,
            scoreVisitor: 0,
        },
        {
            id: 31,
            homeclubId: 3,
            visitorId: 5,
            locationId: 1,
            date: '2022-05-29T15:00:00.000Z',
            scoreHomeclub: 2,
            scoreVisitor: 3,
        },
    ],
    [
        {
            id: 22,
            homeclubId: 5,
            visitorId: 1,
            locationId: 1,
            date: '2022-06-05T10:00:00.000Z',
            scoreHomeclub: null,
            scoreVisitor: null,
        },
        {
            id: 23,
            homeclubId: 7,
            visitorId: 2,
            locationId: 1,
            date: '2022-06-05T12:00:00.000Z',
            scoreHomeclub: null,
            scoreVisitor: null,
        },
    ],
    [
        {
            id: 24,
            homeclubId: 5,
            visitorId: 1,
            locationId: 1,
            date: '2022-06-12T10:00:00.000Z',
            scoreHomeclub: null,
            scoreVisitor: null,
        },
        {
            id: 25,
            homeclubId: 7,
            visitorId: 2,
            locationId: 1,
            date: '2022-06-12T12:00:00.000Z',
            scoreHomeclub: null,
            scoreVisitor: null,
        },
    ],
    [
        {
            id: 26,
            homeclubId: 5,
            visitorId: 1,
            locationId: 1,
            date: '2022-06-19T10:00:00.000Z',
            scoreHomeclub: null,
            scoreVisitor: null,
        },
        {
            id: 27,
            homeclubId: 7,
            visitorId: 2,
            locationId: 1,
            date: '2022-06-19T12:00:00.000Z',
            scoreHomeclub: null,
            scoreVisitor: null,
        },
    ],
    [
        {
            id: 28,
            homeclubId: 12,
            visitorId: 13,
            locationId: 1,
            date: '2022-06-26T10:00:00.000Z',
            scoreHomeclub: null,
            scoreVisitor: null,
        },
    ],
]
