import axios from 'axios'

export default {
    createTeam: async (body, params) => {
        const response = await axios.post('/teams', body, { params })

        return response && response.data && response.data.response
    },

    updateTeam: async (id, body) => {
        const response = await axios.put('/teams/' + id, body)

        return response && response.data && response.data.response
    },

    deleteTeam: async (id) => {
        const response = await axios.delete('/teams/' + id)

        return response && response.data && response.data.response
    },

    getTeam: async (id, params) => {
        const response = await axios.get('/teams/' + id, { params })

        return response && response.data && response.data.response
    },

    listTeams: async (params) => {
        const response = await axios.get('/teams', { params })

        return response && response.data && response.data.response
    },
}
