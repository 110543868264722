import MadMixPlayers from "./jugadoras.json";
import MadMixManagers from "./managers.json";
import { defensivePositions, currentScreenTabs, formatDate } from "../general";
import participants from "./participants.json";
import names from "./names.json";
import bernabeu from "./newTeams/bernabeu.json";
import cibeles from "./newTeams/cibeles.json";
import debod from "./newTeams/debod.json";
import matadero from "./newTeams/matadero.json";
import rastro from "./newTeams/rastro.json";
import retiro from "./newTeams/retiro.json";
import sol from "./newTeams/sol.json";
import playersRaffle24 from "./playersRaffle";

export const playersRaffle = playersRaffle24;

const compare = (a, b) => {
	if (a.positionId > b.positionId) return 1;
	if (b.positionId > a.positionId) return -1;
	return 0;
};
export const players = MadMixPlayers.map((player) => {
	const position = defensivePositions.find((p) => p.key === player.position);
	const team = participants.find((p) => p.name === player.team);
	return {
		...player,
		positionId: position?.number,
		teamId: (team || {}).id || "user",
	};
}); //.sort(compare);

export const managers = MadMixManagers.map((manager) => {
	const team = participants.find((p) => p.name === manager.team);
	return {
		...manager,
		teamId: (team || {}).id || "user",
	};
});

const newTeams = [matadero, rastro, retiro, sol, debod, bernabeu, cibeles];

export const finalTeams = newTeams.map((t) => {
	return t
		.map((p) => {
			const pCopy = p;
			if (p && p.position) {
				const position = defensivePositions.find((a) => a.key === p.position);
				const secondPosition = defensivePositions.find((a) => a.key === p.secondPosition);
				const team = participants.find((b) => b.name === p.team);
				pCopy.positionId = position.number;
				pCopy.secondPositionId = secondPosition && secondPosition.number;
				pCopy.teamId = (team || {}).id || "user";
			} else if (p && p.isManager) {
				const team = participants.find((b) => b.name === p.team);
				pCopy.teamId = (team || {}).id || "user";
			}
			return pCopy;
		})
		.sort(compare);
});

const teams = names;

const locations = [
	{
		id: 1,
		name: "Jarama",
		address: "Av. de San Sebastián, 15",
	},
];

const events = [
	[
		{
			id: 1,
			homeclubId: 1,
			visitorId: 2,
			locationId: 1,
			date: "2024-01-20T09:00:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
			// hideIds: true,
		},
		{
			id: 2,
			homeclubId: 3,
			visitorId: 4,
			locationId: 1,
			date: "2024-01-20T10:40:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
			// hideIds: true,
		},
		{
			id: 3,
			homeclubId: 5,
			visitorId: 6,
			locationId: 1,
			date: "2024-01-20T12:20:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
			// hideIds: true,
		},
		{
			id: 4,
			homeclubId: 4,
			visitorId: 7,
			locationId: 1,
			date: "2024-01-20T14:00:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
			// hideIds: true,
		},
	],
	[
		{
			id: 5,
			homeclubId: 6,
			visitorId: 3,
			locationId: 1,
			date: "2024-01-21T09:00:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
			// hideIds: true,
		},
		{
			id: 6,
			homeclubId: 7,
			visitorId: 1,
			locationId: 1,
			date: "2024-01-21T10:40:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
			// hideIds: true,
		},
		{
			id: 7,
			homeclubId: 2,
			visitorId: 5,
			locationId: 1,
			date: "2024-01-21T12:20:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
			// hideIds: true,
		},
		{
			id: 9,
			homeclubId: "F1",
			visitorId: "F2",
			locationId: 1,
			date: "2024-01-21T14:00:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
			// hideIds: true,
		},
	],
];

export default {
	locations,
	events,
	extraCompetition: {
		date: "2024-01-20T14:00:00.000Z",
	},
	extraCompetition: true,
	raffleDay: formatDate("2024-01-13T20:30:00.000Z"),
	participants,
	names,
	teams,
	finalTeams,
	managers,
	players,
	playersRaffle,
	tabs: currentScreenTabs,
};
