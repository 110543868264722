<template>
    <div class="ButtonSwitch">
        <div
            class="Switch"
            :class="{ disabled }"
        >
            <Button
                v-for="(option, index) in options"
                :key="index"
                :name="`switch-${index}`"
                :class="{ [option]: value === option, 'type-true': option === true, 'type-false': option === false }"
                :disabled="value === option ? disabled : false"
                @click="$emit('input', option)"
            />
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'ButtonSwitch',
    props: {
        value: VueTypes.bool.isRequired,
        disabled: VueTypes.bool.def(false),
    },
    data () {
        return {
            options: [ false, true ],
        }
    },
}
</script>

<style scoped lang="scss">
@import '@/theme/_variables.scss';

.ButtonSwitch {
	.Switch {
		border: 3px solid rgba($primary, .5);
		border-radius: 20px;
		padding: 2px;
		width: fit-content;
		min-height: $form-height;
		display: flex;
		flex-wrap: nowrap;
		&.disabled {
			cursor: not-allowed;
			pointer-events: none;
		}
		&::v-deep .Button {
			border-radius: 20px;
			padding: 0 10px;
			font-size: 12px;
			text-transform: uppercase;
			background: white;
			.type-true, .type-false {
				background: $white !important;
			}
			&.true {
				background: $primary;
			}
			&.false {
				background: #ededed;
				&:hover {
					background: #ededed !important;
				}
			}
		}
	}
}
</style>
