import { defensivePositions } from "../general";
import MadMixParticipants from "./participants.json";
import ARGplayers from "./playersOrdered.json";
import ARGnames from "./names.json";
import ARGparticipants from "./participants.json";

const compare = (a, b) => {
	if (a.positionId > b.positionId) return 1;
	if (b.positionId > a.positionId) return -1;
	return 0;
};
export const players = ARGplayers.map((player) => {
	const position = defensivePositions.find((p) => p.key === player.position);
	const team = ARGparticipants.find((p) => p.name === player.team);
	return {
		...player,
		positionId: position.number,
		teamId: (team || {}).id || "user",
	};
}).sort(compare);

export const managers = [];

export const teams = ARGnames;

export const participants = MadMixParticipants;
export const names = ARGnames;
