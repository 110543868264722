import CONSTANTS from './constants'

export const months = [
    {
        id: 1,
        label: 'Enero',
        key: 'January',
    },
    {
        id: 2,
        label: 'Febrero',
        key: 'February',
    },
    {
        id: 3,
        label: 'Marzo',
        key: 'March',
    },
    {
        id: 4,
        label: 'Abril',
        key: 'April',
    },
    {
        id: 5,
        label: 'Mayo',
        key: 'May',
    },
    {
        id: 6,
        label: 'Junio',
        key: 'June',
    },
    {
        id: 7,
        label: 'Julio',
        key: 'July',
    },
    {
        id: 8,
        label: 'Agosto',
        key: 'August',
    },
    {
        id: 9,
        label: 'Septiembre',
        key: 'September',
    },
    {
        id: 10,
        label: 'Octubre',
        key: 'October',
    },
    {
        id: 11,
        label: 'Noviembre',
        key: 'November',
    },
    {
        id: 12,
        label: 'Diciembre',
        key: 'December',
    },
]

export const years = [
    2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022,
]

export const days = [1, 2, 3, 4, 5, 6, 7, 8, 9]

export const eventPlaces = {
    Lago: 'Lago',
    Pio: 'Príncipe pío',
    Pacifico: 'Pacífico',
}

export const eventTypes = {
    [CONSTANTS.EVENT_TYPE.PHYSICAL_TRAINING]: 'Entrenamiento',
    [CONSTANTS.EVENT_TYPE.THEORIC_TRAINING]: 'Entrenamiento teórico',
    [CONSTANTS.EVENT_TYPE.GAME]: 'Partido',
    [CONSTANTS.EVENT_TYPE.ADDITIONAL_ACTIVITY]: 'Actividad',
    [CONSTANTS.EVENT_TYPE.COMITE_MEETING]: 'Reunión del comité',
}

export const eventUniforms = {
    [CONSTANTS.UNIFORM_COLOR.TURQUOISE]: 'Turquesa',
    [CONSTANTS.UNIFORM_COLOR.BLACK]: 'Negra',
    [CONSTANTS.UNIFORM_COLOR.TURQUOISE_NEW]: 'Turquesa Nueva',
}

export const eventDefaultValue = {
    name: '',
    type: '',
    description: '',
    date: '',
    location: '',
    state: CONSTANTS.EVENT_STATE.PENDING,
    eventUniforms: [],
    users: [],
    isFutureDay: true,
    uniform: undefined,
    formattedDate: '',
    formattedTime: '',
}

export const eventStatus = {
    [CONSTANTS.EVENT_STATE.ACCEPTED]: 'Confirmado',
    [CONSTANTS.EVENT_STATE.REJECTED]: 'Rechazado',
    [CONSTANTS.EVENT_STATE.CANCELED]: 'Cancelado',
    [CONSTANTS.EVENT_STATE.PENDING]: 'Solicitado',
}

export const calendarEventTypes = {
    [CONSTANTS.EVENT_TYPE.PHYSICAL_TRAINING]: {
        icon: 'target',
        color: 'secondary',
        label: 'Entrenamiento',
    },
    [CONSTANTS.EVENT_TYPE.GAME]: {
        icon: 'target',
        color: 'primary',
        label: 'Partido',
    },
    [CONSTANTS.EVENT_TYPE.ADDITIONAL_ACTIVITY]: {
        icon: 'target',
        color: 'success',
        label: 'Actividad',
    },
    [CONSTANTS.EVENT_TYPE.COMITE_MEETING]: {
        icon: 'target',
        color: 'blue',
        label: 'Reunión del comité',
    },
}

export const calendarEventStates = {
    PAST: {
        icon: 'target',
        color: 'gray01',
        label: 'Culminado',
    },
    [CONSTANTS.EVENT_STATE.PENDING]: {
        icon: 'target',
        color: 'tertiary',
        label: 'Pendiente de confirmar',
    },
    [CONSTANTS.EVENT_STATE.REJECTED]: {
        icon: 'target',
        color: 'gray01',
        label: 'Rechazado',
    },
    [CONSTANTS.EVENT_STATE.CANCELED]: {
        icon: 'target',
        color: 'danger',
        label: 'Cancelado',
    },
}
