export default {
    compareLineUps (lineUp1, lineUp2) {
        const info = []
        this.getDifferentUsers(lineUp1, lineUp2, info)
        this.getInternalChanges(lineUp1, lineUp2, info)
        if (info.length) return this.formatMessage(info)
        return ['No hay diferencias']
    },

    getDifferentUsers (lineUp1, lineUp2, info) {
        for (let i = 0; i < lineUp1.length; i++) {
            const personId1 = lineUp1[i] && lineUp1[i].id
            const personId2 = lineUp2[i] && lineUp2[i].id
            if (personId1 !== personId2) {
                const indexLineUp1 = lineUp1.findIndex(p => p.id === personId2)
                const indexLineUp2 = lineUp2.findIndex(p => p.id === personId1)
                if (indexLineUp1 !== -1) {
                    info.push({
                        type: 1,
                        person1: lineUp1[indexLineUp1],
                        person2: lineUp2[i],
                        index1: indexLineUp1,
                        index2: indexLineUp2,
                    })
                }
                if (indexLineUp2 !== -1) {
                    info.push({
                        type: 1,
                        person1: lineUp1[i],
                        person2: lineUp2[indexLineUp2],
                        index1: indexLineUp1,
                        index2: indexLineUp2,
                    })
                }
                if (indexLineUp1 === -1 && indexLineUp2 === -1) {
                    info.push({
                        type: 2,
                        person1: lineUp1[i],
                        person2: lineUp2[i],
                        index: i,
                    })
                }
            }
        }
    },

    getInternalChanges (lineUp1, lineUp2, info) {
        for (let i = 0; i < lineUp1.length; i++) {
            const personId1 = lineUp1[i] && lineUp1[i].id
            const personId2 = lineUp2[i] && lineUp2[i].id
            const lineUpPosition1 = lineUp1[i] && lineUp1[i].lineUpPosition && lineUp1[i].lineUpPosition.number
            const lineUpPosition2 = lineUp2[i] && lineUp2[i].lineUpPosition && lineUp2[i].lineUpPosition.number
            const isInternalChange = personId1 === personId2 && lineUpPosition1 !== lineUpPosition2
            if (isInternalChange) {
                info.push({
                    type: 3,
                    person: lineUp1[i],
                    lineUpPosition1: lineUp1[i].lineUpPosition,
                    lineUpPosition2: lineUp2[i].lineUpPosition,
                })
            }
        }
    },

    formatMessage (info) {
        const formattedInfo = []
        for (const i of info) {
            let line1
            let line2
            let line3
            let index1Value
            let index2Value
            switch (i.type) {
                case 1:
                    index1Value = i.index1 + 1
                    index2Value = i.index2 + 1
                    line1 = '* La jugadora ' + (i.person1 && i.person1.shortName) +
                        ' se encuentra en diferente orden en las alineacions.'
                    line2 = '  ** alineación 1: ' + (index1Value) + ' - ' +
                        (i.person1 && i.person1.lineUpPosition && i.person1.lineUpPosition.number) +
                        ' (' + (i.person1 && i.person1.lineUpPosition && i.person1.lineUpPosition.abbreviation) + ')'
                    line3 = '  ** alineación 2: ' + (index2Value) + ' - ' +
                        (i.person2 && i.person2.lineUpPosition && i.person2.lineUpPosition.number) +
                        ' (' + (i.person2 && i.person2.lineUpPosition && i.person2.lineUpPosition.abbreviation) + ')'
                    formattedInfo.push([line1, line2, line3])
                    break
                case 2:
                    index1Value = i.index + 1
                    line1 = '* Cambio de jugadoras.'
                    line2 = '  ** Sale ' + (i.person1 && i.person1.shortName) + ' (' + (i.person1 &&
                        i.person1.lineUpPosition && i.person1.lineUpPosition.abbreviation) +
                        ') -  Entra ' + (i.person2 && i.person2.shortName)
                    line3 = '  ** Sale ' + ((i.person1 && i.person1.userUniform && i.person1.userUniform.number) || 'SN') + ' - Entra ' +
                        ((i.person2 && i.person2.userUniform && i.person2.userUniform.number) || 'SN')
                    formattedInfo.push([line1, line2, line3])
                    break
                case 3:
                    line1 = '* Cambio interno.'
                    line2 = '  ** ' + (i.person && i.person.shortName) + ' estaba en ' + (i.lineUpPosition1 && i.lineUpPosition1.number) +
                        ' (' + (i.lineUpPosition1 && i.lineUpPosition1.abbreviation) + ') - cambia a ' +
                        (i.lineUpPosition2 && i.lineUpPosition2.number) +
                        ' (' + (i.lineUpPosition2 && i.lineUpPosition2.abbreviation) + ')'
                    formattedInfo.push([line1, line2, line3])
                    break
            }
        }
        return formattedInfo
    },
}
