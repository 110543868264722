import axios from 'axios'

export default {
    createEventUniform: async (body) => {
        const response = await axios.post('/event-uniforms', body)

        return response && response.data && response.data.response
    },

    updateEventUniform: async (id, body) => {
        const response = await axios.put('/event-uniforms/' + id, body)

        return response && response.data && response.data.response
    },

    deleteEventUniform: async (id) => {
        const response = await axios.delete('/event-uniforms/' + id)

        return response && response.data && response.data.response
    },
}
