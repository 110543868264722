import _ from "lodash";
const POSITIONS = ["1B", "2B", "3B", "SS", "P", "C", "SF", "CF", "RF", "LF"];
const NUMBER_OF_TEAMS = 4;

export default {
	shuffle(players, teamNames) {
		const playersInPositions = getPlayersInPostions(players);
		return createTeamsLoop(playersInPositions, teamNames);
	},

	shuffleN(options) {
		const newOptions = _.shuffle(options);
		return newOptions.slice(0, 4);
	},

	shuffleManagers(managers, teamNames) {
		let isOk = false;
		let newManagers;
		while (!isOk) {
			const managers1 = _.shuffle(managers.filter((m, i) => i%2 === 0))
            const managers2 = _.shuffle(managers.filter((m, i) => i%2 !== 0))
			newManagers = [];
			let isInvalid = false;

			for (let i = 0; i < NUMBER_OF_TEAMS; i++) {
				if (managers2[i] && managers1[i].team === managers2[i].team) {
					isInvalid = true;
					break;
				}
				const newSet = [managers1[i]];
				if (managers2[i]) newSet.push(managers2[i]);
				newManagers.push({
					team: teamNames[i],
					managers: newSet,
				});
			}

			isOk = !isInvalid;
		}
		return newManagers;
	},
};

function createNewTeams(teamNames) {
	const newTeams = [];
	for (let j = 0; j < NUMBER_OF_TEAMS; j++) {
		newTeams.push({
			name: teamNames[j],
			players: [],
		});
	}
	return newTeams;
}

function getPlayersInPostions(players) {
	const playersInPositions = {};

	POSITIONS.forEach((p) => {
		playersInPositions[p] = {};
		playersInPositions[p].players = players.filter((player) => player.position === p && !player.onlyOneDay);
		playersInPositions[p].oneDayPlayers = players.filter((player) => player.position === p && player.onlyOneDay);
		playersInPositions[p].totalCompletePlayers = playersInPositions[p].players.length;
		playersInPositions[p].totalOneDayPlayers = playersInPositions[p].oneDayPlayers.length;
		playersInPositions[p].totalPlayers = playersInPositions[p].totalCompletePlayers + playersInPositions[p].totalOneDayPlayers;
		playersInPositions[p].numberOfSets = (!(playersInPositions[p].totalPlayers % NUMBER_OF_TEAMS) && playersInPositions[p].totalPlayers / NUMBER_OF_TEAMS) || 1;
	});

	return playersInPositions;
}

function createTeamsLoop(playersInPositions, teamNames) {
	let isOk = false;
	let newTeams;
	let generalMap;
	while (!isOk) {
		newTeams = createTeams(playersInPositions, createNewTeams(teamNames));
		generalMap = getGeneralMap(newTeams);
		isOk = isValid(generalMap);
	}
	return newTeams;
}

function print(generalMap) {
	for (const t in generalMap) {
		console.log(t + " - " + generalMap[t].totalPlayers + " - " + generalMap[t].oneDayPlayersCounter);
		const teamMap = _.chain(generalMap[t].teamMap)
			.toPairs()
			.map((a) => a[0] + "-" + a[1])
			.value();
		const positionMap = _.chain(generalMap[t].positionMap)
			.toPairs()
			.map((a) => a[0] + "-" + a[1])
			.value();
		console.log(teamMap.join(" / "));
		console.log(positionMap.join(" / "));
		console.log("\n");
	}
	return true;
}

function isValid(generalMap) {
	for (const t in generalMap) {
		for (const ogTeam in generalMap[t].teamMap) {
			const ogTeamValue = generalMap[t].teamMap[ogTeam];
			const bigTeams = ['Las Panas', 'Panteras']
 			if (bigTeams.includes(ogTeam) && ogTeamValue > 5) return false
 			else if (!bigTeams.includes(ogTeam) && ogTeamValue > 4) return false
		}
	}
	return true;
}

function getGeneralMap(newTeams) {
	const generalMap = {};
	for (const t of newTeams) {
		const teamMap = {};
		const positionMap = {};
		let oneDayPlayersCounter = 0;

		for (const p of t.players) {
			if (p) {
				if (teamMap[p.team]) teamMap[p.team]++;
				else teamMap[p.team] = 1;

				if (positionMap[p.position]) positionMap[p.position]++;
				else positionMap[p.position] = 1;

				if (p.onlyOneDay) oneDayPlayersCounter++;
			}
		}

		generalMap[t.name] = {
			teamMap,
			oneDayPlayersCounter,
			totalPlayers: t.players.length,
			positionMap,
		};
	}
	return generalMap;
}

function createTeams(playersInPositions, newTeams) {
	const remainingPlayers = [];
	const oneDayPlayers = [];
	for (const p in playersInPositions) {
		const previousPlayers = getPreviousPlayers(newTeams);
		const groupOfPlayers = setPlayersInPositions(playersInPositions[p].players, previousPlayers);
		newTeams = addPlayersIntoNewTeams(groupOfPlayers.players, newTeams);

		if (playersInPositions[p].numberOfSets === 2) {
			const players = groupOfPlayers.remaining;
			players.push(...playersInPositions[p].oneDayPlayers);
			const previousPlayers2 = getPreviousPlayers(newTeams);
			const groupOfPlayers2 = setPlayersInPositions(players, previousPlayers2);
			newTeams = addPlayersIntoNewTeams(groupOfPlayers2.players, newTeams);
		} else {
			remainingPlayers.push(...groupOfPlayers.remaining);
			oneDayPlayers.push(...playersInPositions[p].oneDayPlayers);
		}
	}
	newTeams = addRemainingPlayers(remainingPlayers, newTeams, (a, b) => b.oneDayPlayersTotal - a.oneDayPlayersTotal);
	newTeams = addRemainingPlayers(oneDayPlayers, newTeams, (a, b) => a.players.length - b.players.length);
	return newTeams;
}

function getPreviousPlayers(newTeams) {
	return newTeams.map((t) => {
		const length = t.players.length;
		let players = [];
		if (length) {
			if (length > 1) players[1] = t.players[length - 2];
			players[0] = t.players[length - 1];
		}
		return players;
	});
}

function setPlayersInPositions(players, previousPlayers) {
	let canFinish = false;
	let counter = 0;
	let groupOfPlayers;

	while (!canFinish && counter < 20) {
		const playersRandomized = _.shuffle(players);
		if (!previousPlayers[0].length) {
			groupOfPlayers = getGroupOfPlayers(playersRandomized);
			canFinish = true;
		} else {
			groupOfPlayers = getGroupOfPlayers(playersRandomized);
			const indexes = matchWithPreviousPlayers(groupOfPlayers.players, previousPlayers);

			if (indexes.length) {
				if (indexes.length === groupOfPlayers.remaining.length) {
					groupOfPlayers = switchPlayers(groupOfPlayers, indexes, previousPlayers);
					canFinish = groupOfPlayers.valid;
				}
			} else canFinish = true;
		}
		if (counter === 5 && !canFinish) groupOfPlayers = getGroupOfPlayers(playersRandomized);
		counter++;
	}
	return groupOfPlayers;
}

function getGroupOfPlayers(players) {
	return {
		players: players.slice(0, NUMBER_OF_TEAMS),
		remaining: players.length > NUMBER_OF_TEAMS ? players.slice(NUMBER_OF_TEAMS, players.length) : [],
	};
}

function matchWithPreviousPlayers(players, previousPlayers) {
	const indexes = [];
	previousPlayers.forEach((pp, index) => {
		const isInSameTeam = pp.find((p) => p.team === players[index].team);
		if (isInSameTeam) indexes.push(index);
	});
	return indexes;
}

function switchPlayers(groupOfPlayers, indexes, previousPlayers) {
	let valid = true;
	for (const index of indexes) {
		const playerToReplace = groupOfPlayers.players[index];
		const validPlayerIndex = groupOfPlayers.remaining.findIndex((p) => {
			return !previousPlayers[index].find((a) => p.team === a.team);
		});
		if (validPlayerIndex === -1) {
			valid = false;
			break;
		}

		groupOfPlayers.players[index] = groupOfPlayers.remaining[validPlayerIndex];
		groupOfPlayers.remaining.splice(validPlayerIndex, 1);
		groupOfPlayers.remaining.push(playerToReplace);
	}

	return {
		valid,
		remaining: groupOfPlayers.remaining,
		players: groupOfPlayers.players,
	};
}

function addPlayersIntoNewTeams(players, newTeams) {
	return newTeams.map((t, i) => {
		if (players[i]) t.players.push(players[i]);
		return t;
	});
}

function addRemainingPlayers(remaining, newTeams, sortFunc) {
	let counter = parseInt(remaining.length / NUMBER_OF_TEAMS);
	if (remaining.length % NUMBER_OF_TEAMS > 0) counter++;

	newTeams = newTeams
		.map((t) => {
			t.oneDayPlayersTotal = t.players.filter((p) => p.onlyOneDay).length;
			return t;
		})
		.sort(sortFunc);
	for (let i = 0; i < counter; i++) {
		newTeams = addPlayersIntoNewTeams(remaining.slice(i * NUMBER_OF_TEAMS, i * NUMBER_OF_TEAMS + NUMBER_OF_TEAMS), newTeams);
	}
	return newTeams;
}

function timer(ms) {
	return new Promise((res) => setTimeout(res, ms));
}

async function printTeams(newTeams) {
	const generalMap = {};
	for (const t of newTeams) {
		console.log(JSON.stringify(t));
		console.log(t.name + "\n");
		// await timer(1000);
		const players = t.players.sort((a, b) => {
			if (a.position > b.position) return 1;
			if (a.position < b.position) return -1;
			return 0;
		});
		const teamMap = {};
		const positionMap = {};
		let oneDayPlayersCounter = 0;

		for (const p of t.players) {
			if (p) {
				// console.log(p.name + ' - Equipo ' + p.team +
				// ' - Posicion ' + p.position +
				// ' - Torneo completo ' + !p.onlyOneDay +
				// '\n')
				if (teamMap[p.team]) teamMap[p.team]++;
				else teamMap[p.team] = 1;

				if (positionMap[p.position]) positionMap[p.position]++;
				else positionMap[p.position] = 1;

				if (p.onlyOneDay) oneDayPlayersCounter++;
			}

			// await timer(500);
		}

		generalMap[t.name] = {
			teamMap,
			oneDayPlayersCounter,
			totalPlayers: t.players.length,
			positionMap,
		};
		console.log("\n");
	}
	console.log(generalMap);
}
