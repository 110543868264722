import positions from '../api/positions'
import constants from '../entities/constants'

export default {
    async getPositionsByType (setAbv = false) {
        const defensivePositions = (await positions.listPositions({ type: constants.POSITION_TYPE.DEFENSIVE })).items
        const offensivePositions = (await positions.listPositions({ type: constants.POSITION_TYPE.OFFENSIVE })).items

        return {
            defensivePositions: this.setKeyAndLabelForPosition(defensivePositions, setAbv),
            offensivePositions: this.setKeyAndLabelForPosition(offensivePositions, setAbv),
        }
    },

    setKeyAndLabelForPosition (positionsList, setAbv = false) {
        for (const p of positionsList) {
            p.key = setAbv ? p.id : p.abbreviation
            p.label = setAbv ? p.abbreviation : p.name
        }
        return positionsList
    },
}
