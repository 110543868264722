import axios from 'axios'

export default {
    createUserPosition: async (body) => {
        const response = await axios.post('/user-positions', body)

        return response && response.data && response.data.response
    },

    deleteUserPosition: async (id) => {
        const response = await axios.delete('/user-positions/' + id)

        return response && response.data && response.data.response
    },

    getUserPosition: async (id, params) => {
        const response = await axios.get('/user-positions/' + id, { params })

        return response && response.data && response.data.response
    },
}
