import axios from 'axios'

export default {
    createUserPayment: async (body) => {
        const response = await axios.post('/user-payments', body)

        return response && response.data && response.data.response
    },

    deleteUserPayment: async (id) => {
        const response = await axios.delete('/user-payments/' + id)

        return response && response.data && response.data.response
    },

    getUserPayment: async (id, params) => {
        const response = await axios.get('/user-payments/' + id, { params })

        return response && response.data && response.data.response
    },

    getUserBalance: async (id) => {
        const response = await axios.get('/user-payments/balance/' + id)

        return response && response.data && response.data.response
    },
}
