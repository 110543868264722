export const teams = [
	{
		id: 1,
		name: "Águilas",
	},
	{
		id: 2,
		name: "Tropicales",
	},
	{
		id: 3,
		name: "Vikingas",
	},
	{
		id: 4,
		name: "Las Panas",
	},
	{
		id: 5,
		name: "Panteras",
	},
	{
		id: 6,
		name: "Murallas",
	},
	{
		id: 7,
		name: "Espartanas",
	},
	{
		id: 8,
		name: "1ro",
	},
	{
		id: 9,
		name: "2do",
	},
	{
		id: 10,
		name: "3ro",
	},
	{
		id: 11,
		name: "4to",
	},
	{
		id: 12,
		name: "Clasificado a la Final",
	},
	{
		id: 13,
		name: "Clasificado a la Final",
	},
];

export const locations = [
	{
		id: 1,
		name: "Jarama",
		address: "Av. de San Sebastián, 15",
	},
	{
		id: 2,
		name: "Aravaca",
		address: "",
	},
	{
		id: 3,
		name: "Getafe",
		address: "",
	},
	{
		id: 4,
		name: "Lago",
		address: "",
	},
	{
		id: 5,
		name: "Paracuellos de Jarama",
		address: "",
	},
];

export const locationTypes = {
	1: {
		icon: "map-pin",
		color: "secondary",
		label: "Jarama: Av. de San Sebastián, 15",
		link: "https://goo.gl/maps/gxmee9ZKVYunv4rB9",
	},
	5: {
		icon: "map-pin",
		color: "secondary",
		label: "Paracuellos de Jarama",
		link: "https://maps.app.goo.gl/9cwEPispp1nxERwS8?g_st=iw",
	},
};

export const scoreTypes = {
	1: {
		abbrev: "PJ",
		color: "secondary",
		label: "Partidos jugados",
	},
	2: {
		abbrev: "PG",
		color: "secondary",
		label: "Partidos Ganados",
	},
	3: {
		abbrev: "PP",
		color: "secondary",
		label: "Partidos Perdidos",
	},
	4: {
		abbrev: "PE",
		color: "secondary",
		label: "Partidos Empatados",
	},
	5: {
		abbrev: "CF",
		color: "secondary",
		label: "Carreras a favor",
	},
	6: {
		abbrev: "CC",
		color: "secondary",
		label: "Carreras en contra",
	},
	7: {
		abbrev: "DIF",
		color: "secondary",
		label: "Diferencia",
	},
	8: {
		abbrev: "PTS",
		color: "secondary",
		label: "Puntos",
	},
};

export const events = [
	[
		{
			id: 1,
			homeclubId: 1,
			visitorId: 2,
			locationId: 1,
			date: "2023-03-12T09:30:00.000Z",
			scoreHomeclub: 1,
			scoreVisitor: 1,
		},
		{
			id: 2,
			homeclubId: 3,
			visitorId: 2,
			locationId: 1,
			date: "2023-03-12T11:20:00.000Z",
			scoreHomeclub: 2,
			scoreVisitor: 0,
		},
		{
			id: 3,
			homeclubId: 7,
			visitorId: 5,
			locationId: 1,
			date: "2023-03-12T13:10:00.000Z",
			scoreHomeclub: 6,
			scoreVisitor: 0,
		},
		{
			id: 4,
			homeclubId: 4,
			visitorId: 6,
			locationId: 1,
			date: "2023-03-12T15:00:00.000Z",
			scoreHomeclub: 7,
			scoreVisitor: 0,
		},
	],
	[
		{
			id: 5,
			homeclubId: 6,
			visitorId: 7,
			locationId: 1,
			date: "2023-03-26T09:30:00.000Z",
			scoreHomeclub: 0,
			scoreVisitor: 12,
		},
		{
			id: 6,
			homeclubId: 4,
			visitorId: 7,
			locationId: 1,
			date: "2023-03-26T11:20:00.000Z",
			scoreHomeclub: 3,
			scoreVisitor: 8,
		},
		{
			id: 7,
			homeclubId: 3,
			visitorId: 1,
			locationId: 1,
			date: "2023-03-26T13:10:00.000Z",
			scoreHomeclub: 7,
			scoreVisitor: 1,
		},
		{
			id: 8,
			homeclubId: 2,
			visitorId: 5,
			locationId: 1,
			date: "2023-03-26T15:00:00.000Z",
			scoreHomeclub: 4,
			scoreVisitor: 6,
		},
	],
	[
		{
			id: 9,
			homeclubId: 1,
			visitorId: 5,
			locationId: 1,
			date: "2023-04-16T09:30:00.000Z",
			scoreHomeclub: 5,
			scoreVisitor: 2,
		},
		{
			id: 10,
			homeclubId: 4,
			visitorId: 1,
			locationId: 1,
			date: "2023-04-16T11:20:00.000Z",
			scoreHomeclub: 6,
			scoreVisitor: 1,
		},
		{
			id: 11,
			homeclubId: 2,
			visitorId: 6,
			locationId: 1,
			date: "2023-04-16T13:10:00.000Z",
			scoreHomeclub: 4,
			scoreVisitor: 2,
		},
		{
			id: 12,
			homeclubId: 7,
			visitorId: 3,
			locationId: 1,
			date: "2023-04-16T15:00:00.000Z",
			scoreHomeclub: 4,
			scoreVisitor: 4,
		},
	],
	[
		{
			id: 13,
			homeclubId: 2,
			visitorId: 4,
			locationId: 1,
			date: "2023-04-22T09:30:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
			cancelled: true,
		},
		{
			id: 14,
			homeclubId: 5,
			visitorId: 6,
			locationId: 1,
			date: "2023-04-22T11:20:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
			cancelled: true,
		},
		{
			id: 15,
			homeclubId: 7,
			visitorId: 1,
			locationId: 1,
			date: "2023-04-22T13:10:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
			cancelled: true,
		},
	],
	[
		{
			id: 16,
			homeclubId: 3,
			visitorId: 4,
			locationId: 1,
			date: "2023-04-23T09:30:00.000Z",
			scoreHomeclub: 1,
			scoreVisitor: 3,
		},
		{
			id: 17,
			homeclubId: 5,
			visitorId: 3,
			locationId: 1,
			date: "2023-04-23T11:20:00.000Z",
			scoreHomeclub: 3,
			scoreVisitor: 2,
		},
		{
			id: 18,
			homeclubId: 2,
			visitorId: 7,
			locationId: 1,
			date: "2023-04-23T13:10:00.000Z",
			scoreHomeclub: 2,
			scoreVisitor: 13,
		},
		{
			id: 19,
			homeclubId: 1,
			visitorId: 6,
			locationId: 1,
			date: "2023-04-23T15:00:00.000Z",
			scoreHomeclub: 7,
			scoreVisitor: 1,
		},
	],
	[
		{
			id: 20,
			homeclubId: 6,
			visitorId: 3,
			locationId: 1,
			date: "2023-05-07T09:30:00.000Z",
			scoreHomeclub: 0,
			scoreVisitor: 10,
		},
		{
			id: 21,
			homeclubId: 5,
			visitorId: 4,
			locationId: 1,
			date: "2023-05-07T11:20:00.000Z",
			scoreHomeclub: 3,
			scoreVisitor: 0,
		},
		{
			id: 22,
			homeclubId: 5,
			visitorId: 2,
			locationId: 1,
			date: "2023-05-07T13:10:00.000Z",
			scoreHomeclub: 4,
			scoreVisitor: 5,
		},
		{
			id: 23,
			homeclubId: 1,
			visitorId: 7,
			locationId: 1,
			date: "2023-05-07T15:00:00.000Z",
			scoreHomeclub: 2,
			scoreVisitor: 4,
		},
	],
	[
		{
			id: 24,
			homeclubId: 5,
			visitorId: 7,
			locationId: 1,
			date: "2023-05-14T09:30:00.000Z",
			scoreHomeclub: 9,
			scoreVisitor: 0,
		},
		// {
		// 	id: 25,
		// 	homeclubId: 1,
		// 	visitorId: 4,
		// 	locationId: 1,
		// 	date: "2023-05-14T11:20:00.000Z",
		// 	scoreHomeclub: 3,
		// 	scoreVisitor: 6,
		// },
		{
			id: 26,
			homeclubId: 6,
			visitorId: 2,
			locationId: 1,
			date: "2023-05-14T13:10:00.000Z",
			scoreHomeclub: 4,
			scoreVisitor: 10,
		},
		{
			id: 27,
			homeclubId: 3,
			visitorId: 6,
			locationId: 1,
			date: "2023-05-14T15:00:00.000Z",
			scoreHomeclub: 7,
			scoreVisitor: 3,
		},
	],
	[
		{
			id: 28,
			homeclubId: 5,
			visitorId: 1,
			locationId: 2,
			date: "2023-05-20T09:30:00.000Z",
			scoreHomeclub: 10,
			scoreVisitor: 0,
		},
		{
			id: 29,
			homeclubId: 7,
			visitorId: 6,
			locationId: 2,
			date: "2023-05-20T11:20:00.000Z",
			scoreHomeclub: 7,
			scoreVisitor: 0,
		},
		{
			id: 30,
			homeclubId: 2,
			visitorId: 3,
			locationId: 2,
			date: "2023-05-20T13:10:00.000Z",
			scoreHomeclub: 8,
			scoreVisitor: 10,
		},
	],
	[
		{
			id: 31,
			homeclubId: 4,
			visitorId: 2,
			locationId: 3,
			date: "2023-05-21T09:30:00.000Z",
			scoreHomeclub: 2,
			scoreVisitor: 0,
		},
		{
			id: 32,
			homeclubId: 7,
			visitorId: 4,
			locationId: 3,
			date: "2023-05-21T11:20:00.000Z",
			scoreHomeclub: 0,
			scoreVisitor: 2,
		},
		{
			id: 33,
			homeclubId: 1,
			visitorId: 3,
			locationId: 3,
			date: "2023-05-21T13:10:00.000Z",
			scoreHomeclub: 0,
			scoreVisitor: 4,
		},
		{
			id: 34,
			homeclubId: 6,
			visitorId: 5,
			locationId: 3,
			date: "2023-05-21T15:00:00.000Z",
			scoreHomeclub: 1,
			scoreVisitor: 11,
		},
	],
	[
		{
			id: 35,
			homeclubId: 7,
			visitorId: 2,
			locationId: 2,
			date: "2023-05-28T09:30:00.000Z",
			scoreHomeclub: 12,
			scoreVisitor: 1,
		},
		{
			id: 36,
			homeclubId: 6,
			visitorId: 1,
			locationId: 2,
			date: "2023-05-28T11:20:00.000Z",
			scoreHomeclub: 0,
			scoreVisitor: 6,
		},
		{
			id: 37,
			homeclubId: 3,
			visitorId: 5,
			locationId: 2,
			date: "2023-05-28T13:10:00.000Z",
			scoreHomeclub: 5,
			scoreVisitor: 5,
		},
		{
			id: 38,
			homeclubId: 4,
			visitorId: 3,
			locationId: 2,
			date: "2023-05-28T15:00:00.000Z",
			scoreHomeclub: 11,
			scoreVisitor: 5,
		},
	],
	[
		{
			id: 39,
			homeclubId: 3,
			visitorId: 7,
			locationId: 2,
			date: "2023-06-03T10:00:00.000Z",
			scoreHomeclub: 0,
			scoreVisitor: 4,
		},
		{
			id: 42,
			homeclubId: 4,
			visitorId: 5,
			locationId: 2,
			date: "2023-06-03T11:20:00.000Z",
			scoreHomeclub: 0,
			scoreVisitor: 1,
		},
	],
	[
		{
			id: 40,
			homeclubId: 2,
			visitorId: 1,
			locationId: 4,
			date: "2023-06-04T11:30:00.000Z",
			scoreHomeclub: 4,
			scoreVisitor: 1,
		},
		{
			id: 41,
			homeclubId: 6,
			visitorId: 4,
			locationId: 4,
			date: "2023-06-04T13:20:00.000Z",
			scoreHomeclub: 0,
			scoreVisitor:  10,
		},
	],
	[
		{
			id: 14,
			homeclubId: 5,
			visitorId: 6,
			locationId: 5,
			date: "2023-06-11T10:00:00.000Z",
			scoreHomeclub: 7,
			scoreVisitor: 1,
		},
		{
			id: 13,
			homeclubId: 2,
			visitorId: 4,
			locationId: 5,
			date: "2023-06-11T11:20:00.000Z",
			scoreHomeclub: 2,
			scoreVisitor: 12,
		},
		{
			id: 25,
			homeclubId: 1,
			visitorId: 4,
			locationId: 5,
			date: "2023-06-11T13:10:00.000Z",
			scoreHomeclub: 5, // 3,
			scoreVisitor: 4, // 6,
		},
		{
			id: 15,
			homeclubId: 7,
			visitorId: 1,
			locationId: 5,
			date: "2023-06-11T15:00:00.000Z",
			scoreHomeclub: 1,
			scoreVisitor: 3,
		},
	],
	[
		{
			id: 43,
			homeclubId: 5, // 9,
			visitorId: 4, // 10,
			locationId: 1,
			date: "2023-06-18T10:00:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
		},
		{
			id: 44,
			homeclubId: 7, // 8,
			visitorId: 3, // 11,
			locationId: 1,
			date: "2023-06-18T12:15:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
		},
	],
	[
		{
			id: 45,
			homeclubId: 10,
			visitorId: 11,
			locationId: 1,
			date: "2023-06-25T10:10:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
		},
		{
			id: 46,
			homeclubId: 8,
			visitorId: 9,
			locationId: 1,
			date: "2023-06-25T12:15:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
			title: "FINAL",
		},
	],
];
