<template>
	<div class="TorneoOtonoScreen">
		<div class="ImageSection">
			<div class="Title">
				<div class="Organization">
					<img :src="require(`@/assets/imgs/events/torneo_otono/logo.png`)" />
				</div>
				<div class="Participants1">
					<div class="Border" />
					<div class="Participants2">
						<div class="Participants3">
							<Avatar
								v-for="({ id, link }, i) in teams"
								:key="i"
								:url="require(`@/assets/imgs/events/torneo_otono/teams/${id}.png`)"
								:size="{ height: isMobileXs ? 35 : 60, width: isMobileXs ? 35 : 60 }"
								:iconSize="20"
								:rounded="false"
								@click.native="goTo(link)"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="PillSelectors">
			<PillSelectorInput v-model="activeTab" :options="tabs" border="none" preventDeselect @input="handleChangeTab" />
		</div>
		<div v-if="activeTab === 'calendar'" class="Calendar">
			<div v-for="(eventList, i) in events" :key="i" class="List">
				<div class="ListHeader">
					<p>
						{{ formatDate(eventList[0].date).weekDay }} {{ formatDate(eventList[0].date).day }} {{ formatDate(eventList[0].date).month }}
						<span v-if="eventList[0].homeclubId">en {{ locations.find((l) => l.id === eventList[0].locationId).name }}</span>
						<span v-else-if="eventList[0].title">a las {{ formatDate(eventList[0].date).time }}</span>
					</p>
				</div>

				<div v-for="(event, index) in eventList" :key="index" class="ListItem" :class="isPastDate(event.date) ? 'isPastDate' : ''">
					<div v-if="event.scoreHomeclub && event.scoreHomeclub !== null && !isMobile" class="ScoreHomeClub">
						<p>
							{{ event.scoreHomeclub }}
						</p>
					</div>
					<div v-if="event.scoreVisitor && event.scoreVisitor !== null && !isMobile" class="ScoreVisitor">
						<p>
							{{ event.scoreVisitor }}
						</p>
					</div>
					<div v-if="event.homeclubId" class="Team">
						<Avatar
							:url="event.TBD ? '' : require(`@/assets/imgs/events/torneo_otono/teams/${event.homeclubId}.png`)"
							:size="{ height: isMobile ? 25 : 35, width: isMobile ? 25 : 35 }"
							:iconSize="20"
							:rounded="false"
						/>
						<div class="Homeclub">
							<p v-if="isPastDate(event.date)" class="bold">{{ event.scoreHomeclub }}</p>
							<p v-else>{{ participants.find((t) => t.id === event.homeclubId).name }}</p>
						</div>
					</div>
					<div v-if="event.title" class="Time center">
						<p>{{ event.title }}</p>
					</div>
					<div v-else-if="event.homeclubId" class="Time">
						<p>{{ formatDate(event.date).time }}</p>
					</div>
					<div v-if="event.visitorId" class="Team right">
						<div class="Visitor">
							<p v-if="isPastDate(event.date)" class="bold">{{ event.scoreVisitor }}</p>
							<p v-else>{{ participants.find((t) => t.id === event.visitorId).name }}</p>
						</div>

						<Avatar
							:url="event.TBD ? '' : require(`@/assets/imgs/events/torneo_otono/teams/${event.visitorId}.png`)"
							:size="{ height: isMobile ? 25 : 35, width: isMobile ? 25 : 35 }"
							:iconSize="20"
							:rounded="false"
						/>
					</div>
				</div>
			</div>
		</div>
		<Legend :legend="locationTypes" v-if="activeTab === 'calendar'" @clickOn="handleRedirectTo" />

		<div v-if="activeTab === 'groups'" class="Groups">
			<div v-for="({ id, name }, i) in groups" :key="`group-${i}`" class="List">
				<div class="ListHeader">
					<p>
						{{ name }}
					</p>
				</div>

				<div v-for="(team, index) in teams.filter((team) => team.groupId === id)" :key="`team-${index}`" class="ListItem GroupTeam">
					<div class="Team">
						<Avatar
							:url="require(`@/assets/imgs/events/torneo_otono/teams/${team.id}.png`)"
							:size="{ height: isMobile ? 25 : 35, width: isMobile ? 25 : 35 }"
							:iconSize="20"
							:rounded="false"
						/>
						<div class="Homeclub">
							<p>{{ team.name }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- ///////// -->
		<div v-if="activeTab === 'table'" class="ScoreListWrapper">
			<div class="ScoreList">
				<p class="OutsiderName">Tabla de clasificación</p>
				<div class="Team Header">
					<p class="Name">GRUPO A</p>
					<p v-for="{ abbrev } in scoreTypes" :key="`scoreType-${abbrev}`" class="Value">
						{{ abbrev }}
					</p>
				</div>
				<div v-for="(team, index) in updatedTeams.filter((g) => g.groupId === 1)" :key="`team-${index}`" class="Team">
					<Avatar
						:url="require(`@/assets/imgs/events/torneo_otono/teams/${team.id}.png`)"
						:size="{ height: isMobile ? 30 : 40, width: isMobile ? 30 : 40 }"
						:iconSize="20"
						:rounded="false"
					/>
					<p class="Name">
						{{ team.name }}
					</p>
					<p class="Value">
						{{ team.partidosJugados }}
					</p>
					<p class="Value">
						{{ team.partidosGanados }}
					</p>
					<p class="Value">
						{{ team.partidosPerdidos }}
					</p>
					<p class="Value">
						{{ team.partidosEmpatados }}
					</p>
					<p class="Value">
						{{ team.carrerasAfavor }}
					</p>
					<p class="Value">
						{{ team.carrerasEnContra }}
					</p>
					<p class="Value">
						{{ team.diferencia }}
					</p>
					<p class="Value">
						{{ team.puntos }}
					</p>
				</div>
			</div>
			<div class="ScoreList">
				<p class="OutsiderName">Tabla de clasificación</p>
				<div class="Team Header">
					<p class="Name">GRUPO B</p>
					<p v-for="{ abbrev } in scoreTypes" :key="`scoreType-${abbrev}`" class="Value">
						{{ abbrev }}
					</p>
				</div>
				<div v-for="(team, index) in updatedTeams.filter((g) => g.groupId === 2)" :key="`team-${index}`" class="Team">
					<Avatar
						:url="require(`@/assets/imgs/events/torneo_otono/teams/${team.id}.png`)"
						:size="{ height: isMobile ? 30 : 40, width: isMobile ? 30 : 40 }"
						:iconSize="20"
						:rounded="false"
					/>
					<p class="Name">
						{{ team.name }}
					</p>
					<p class="Value">
						{{ team.partidosJugados }}
					</p>
					<p class="Value">
						{{ team.partidosGanados }}
					</p>
					<p class="Value">
						{{ team.partidosPerdidos }}
					</p>
					<p class="Value">
						{{ team.partidosEmpatados }}
					</p>
					<p class="Value">
						{{ team.carrerasAfavor }}
					</p>
					<p class="Value">
						{{ team.carrerasEnContra }}
					</p>
					<p class="Value">
						{{ team.diferencia }}
					</p>
					<p class="Value">
						{{ team.puntos }}
					</p>
				</div>
			</div>
		</div>
		<Legend :legend="scoreTypes" v-if="activeTab === 'table'" class="ScoreLegend" />
		<!-- ///////// -->
		<div class="Organization Background">
			<p>Organizado por todos los equipos participantes</p>
		</div>
		<div class="Organization Sponsor">
			<p>Con el apoyo de</p>
			<a href="https://www.instagram.com/rumberos_en_espana/" target="_blank">
				<img :src="require(`@/assets/imgs/events/torneo_otono/rumberos_en_espana.png`)" alt="Rumberos en España" class="Logo" />
			</a>
		</div>
	</div>
</template>

<script>
import moment from "moment";
import { mediaQueryMixin } from "@/mixins";
import { teams, participants, locations, events, locationTypes, groups, scoreTypes } from "@/data/TorneoOtono/torneoOtono";

export default {
	name: "TorneoOtonoScreen",
	mixins: [mediaQueryMixin],
	data() {
		return {
			locationTypes,
			activeTab: "calendar",
			scoreTypes,
		};
	},
	computed: {
		isMobile() {
			return this.$mq.w < this.$mq.lg.min;
		},
		isMobileXs() {
			return this.$mq.w < this.$mq.sm.max;
		},
		updatedTeams() {
			let updatedTeams = [];
			teams.slice(0, 7).forEach((team) => {
				updatedTeams.push(this.findTeamStatus(team));
			});
			const teamsSortedByPoints = updatedTeams.sort((a, b) => {
				if (a.puntos < b.puntos) return 1;
				else if (a.puntos === b.puntos) {
					if (a.diferencia < b.diferencia) return 1;
					else return -1;
				} else return -1;
			});
			return teamsSortedByPoints;
			// product not to extract
		},
	},
	created() {
		this.teams = teams;
		this.groups = groups;
		this.participants = participants;
		this.locations = locations;
		this.events = events;
		this.tabs = [
			{
				key: "calendar",
				label: "Calendario",
				slug: "calendar",
			},
			// {
			//     key: 'teams',
			//     label: 'Rosters',
			//     slug: 'teams',
			// },
			{
				key: "table",
				label: "Tabla de clasificación",
				slug: "clasificacion",
			},
			// {
			// 	key: "groups",
			// 	label: "Grupos",
			// 	slug: "groups",
			// },
		];
	},
	methods: {
		goTo(link) {
			if (link) window.open(link, "_blank");
		},
		handleChangeTab(value) {
			this.activeTab = value;
		},
		formatDate(date) {
			return {
				weekDay: moment.utc(date).locale("es").format("dddd"),
				day: moment.utc(date).locale("es").format("D"),
				month: moment.utc(date).locale("es").format("MMMM"),
				time: moment.utc(date).locale("es").format("LT"),
			};
		},
		isPastDate(date) {
			const today = moment();
			return moment(date).isBefore(today);
		},
		handleRedirectTo(id) {
			if (id) window.open(locationTypes[id].link, "_blank");
		},
		findTeamStatus(team) {
			let partidosJugados = 0;
			let partidosGanados = 0;
			let partidosPerdidos = 0;
			let partidosEmpatados = 0;
			let carrerasAfavor = 0;
			let carrerasEnContra = 0;
			let puntos = 0;
			events.forEach((event) => {
				event.forEach((e) => {
					if (!e.finals) {
						if (this.isPastDate(e.date) && !e.cancelled) {
							if (e.homeclubId === team.id || e.visitorId === team.id) {
								partidosJugados += 1;
								if (e.homeclubId === team.id) {
									if (e.scoreHomeclub > e.scoreVisitor) {
										partidosGanados += 1;
										puntos += 3;
									}
									if (e.scoreHomeclub < e.scoreVisitor) partidosPerdidos += 1;
									if (e.scoreHomeclub === e.scoreVisitor) {
										partidosEmpatados += 1;
										puntos += 1;
									}
									carrerasAfavor += e.scoreHomeclub;
									carrerasEnContra += e.scoreVisitor;
								} else if (e.visitorId === team.id) {
									if (e.scoreVisitor > e.scoreHomeclub) {
										partidosGanados += 1;
										puntos += 3;
									}
									if (e.scoreVisitor < e.scoreHomeclub) partidosPerdidos += 1;
									if (e.scoreVisitor === e.scoreHomeclub) {
										partidosEmpatados += 1;
										puntos += 1;
									}
									carrerasAfavor += e.scoreVisitor;
									carrerasEnContra += e.scoreHomeclub;
								}
							}
						}
					}
				});
			});
			return {
				...team,
				partidosJugados,
				partidosGanados,
				partidosPerdidos,
				partidosEmpatados,
				carrerasAfavor,
				carrerasEnContra,
				diferencia: carrerasAfavor - carrerasEnContra,
				puntos,
			};
		},
		isDayCancelled(eventList) {
			const isCancelledArray = [];
			eventList.forEach((e) => {
				if (e.cancelled) isCancelledArray.push(e.cancelled);
				else isCancelledArray.push(false);
			});
			return !isCancelledArray.includes(false);
		},
	},
};
</script>

<style lang="scss" scoped>
.PublicNavbar {
	background: rgba(#333, 0.5) !important;
}
.TorneoOtonoScreen {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #333;
	background-color: white;
}
.ImageSection {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	height: 15rem;
	width: 100%;
	min-height: 30rem;
	// background-size: cover;
	// background-repeat: no-repeat;
	// background-position-x: center;
	// background-position-y: center;
	@media screen and (max-width: $sm) {
		height: 12rem;
		min-height: 28rem;
	}
}
.Title {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 8rem;
	@media screen and (max-width: $sm) {
		margin: 100px 0 50px 0;
	}
}
.Calendar,
.Groups {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: 1fr;
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	margin-bottom: 2rem;
	width: 50%;
	@media screen and (max-width: $xl) {
		width: 75%;
	}
	@media screen and (max-width: $lg) {
		width: 90%;
	}
	@media screen and (max-width: $md) {
		grid-template-columns: repeat(1, 1fr);
	}
}
.List {
	// margin-bottom: 30px;
	border-radius: 1rem;
	overflow: hidden;
	background-color: #ffb053;
	// width: 100%;
	// @media screen and (max-width: $md) {
	// 	width: 75%;
	// }
	// @media screen and (max-width: $sm) {
	// 	width: 90%;
	// }
	.ListHeader {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 10px 20px 0 20px;
		p {
			flex: 1;
			text-align: center;
			color: #333;
			text-transform: capitalize;
			font-weight: bold;
		}
		.Cancelled {
			color: #333;
			background: rgba($danger, 0.7);
			margin-top: 0px;
			padding: 4px 8px;
			border-radius: 5px;
			font-size: 12px;
			text-transform: uppercase;
		}
	}
	.isPastDate {
		opacity: 0.6;
	}
	.ListItem {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: rgba(#fff, 0.4);
		margin: 10px;
		border: 2px solid rgba(#fff, 0.2);
		// background: rgba(#333, 0.05);
		height: 50px;
		padding: 0 20px;
		border-radius: 0.3rem;
		position: relative;
		&.GroupTeam {
			justify-content: center;
		}
		.ScoreHomeClub,
		.ScoreVisitor {
			position: absolute; // #ff6646 // #ffb053
			background: rgba(#333, 0.05);
			border-radius: 15px;
			height: 55px;
			width: 55px;
			display: flex;
			align-items: center;
			justify-content: center;
			p {
				color: #333;
				font-size: 20px;
			}
		}
		.ScoreHomeClub {
			left: -75px;
		}
		.ScoreVisitor {
			right: -75px;
		}

		& + .ListItem {
			margin-top: 12px;
		}
		.Time {
			text-align: center;
			@media screen and (max-width: $sm) {
				font-size: 0.9rem;
			}
			&.center {
				flex: 1;
				font-weight: bold;
			}
			.Cancelled {
				color: #333;
				background: rgba($danger, 0.7);
				margin-top: 8px;
				padding: 3px 6px;
				border-radius: 5px;
				font-size: 10px;
				text-transform: uppercase;
			}
		}
		.Team {
			display: flex;
			align-items: center;
			min-width: 180px;
			@media screen and (max-width: $sm) {
				min-width: 120px;
			}
			&.right {
				justify-content: flex-end;
			}
		}
		.Homeclub,
		.Visitor {
			margin: 0 20px;
			@media screen and (max-width: $sm) {
				font-size: 0.9rem;
				margin: 0 10px;
			}
			.bold {
				font-weight: bold;
				font-size: 1.2rem;
			}
		}
	}
}
.Legend {
	@media screen and (max-width: $xl) {
		width: 75%;
	}
	@media screen and (max-width: $lg) {
		width: 90%;
	}
	@media screen and (max-width: $md) {
		flex-direction: column;
		justify-content: center;
	}
	@media screen and (max-width: $sm) {
		margin-top: 2rem;
	}
	margin-top: 10px;
	cursor: pointer;
	&::v-deep {
		.Title {
			color: #333;
			font-size: 20px;
			@media screen and (max-width: $sm) {
				font-size: 18px;
			}
		}
		.ItemsWrapper {
			background: transparent !important;
			justify-content: center;
			.Item {
				// background: rgba(#333, 0.1) !important;
				background: transparent !important;
				border: 1px solid #333;
				padding: 10px 20px 10px 12px;
				.Icon,
				.Abbrev {
					color: #ffb053 !important;
				}
				.ItemText {
					color: #333;
					font-size: 14px;
				}
			}
		}
	}
}

.Organization {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		width: auto;
		height: 220px;
		@media screen and (max-width: $sm) {
			height: 160px;
		}
	}
	&.Background {
		background: #ededed;
		padding: 3rem;
		margin-top: 2rem;
		font-weight: bold;
	}
	&.Sponsor {
		padding: 1.6rem 0;
		img {
			height: 160px;
			margin-left: 1rem;
			@media screen and (max-width: $sm) {
				height: 120px;
			}
		}
	}
}
.Participants3 {
	// width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0.5rem;
	padding: 1rem;
	// margin-top: 2rem;
	// margin-bottom: 5rem;
	position: relative;
	background: white;
	height: calc(100% - 1rem);
	// width: fit-content;
	z-index: 1;
	// flex-wrap: wrap;
	gap: 20px;

	.Avatar {
		// margin-right: 20px;
		transition: all 0.3s ease-in-out;
		cursor: pointer;
		&:hover {
			transform: scale(1.2);
		}
	}
}
.Participants2 {
	position: absolute;
	top: 0.5rem;
	left: 0.5rem;
	width: calc(100% + 0.5rem);
	height: 100%;
	z-index: 2;
	background: #ffb053;
}
.Border {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(100% - 0.5rem);
	border: 2px solid black;
	z-index: 3;
}
.Participants1 {
	margin-top: 3rem;
	margin-bottom: 1.2rem;
	position: relative;
	width: 100%;
	height: 100%;
	padding: 4rem;
	width: 50%;
	@media screen and (max-width: $md) {
		width: 75%;
	}
	@media screen and (max-width: $sm) {
		width: 90%;
	}
}
.PillSelectors {
	margin-top: 6rem;
	margin-bottom: 2rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid #333;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 50%;
	@media screen and (max-width: $xl) {
		width: 75%;
	}
	@media screen and (max-width: $lg) {
		width: 90%;
	}
	@media screen and (max-width: $md) {
		flex-direction: column;
		justify-content: center;
	}
	@media screen and (max-width: $sm) {
		margin-top: 2rem;
	}

	.PillSelectorInput {
		width: fit-content;
		& + .PillSelectorInput {
			margin-left: 0px;
			margin-top: 20px;
			@media screen and (min-width: $md) {
				margin-left: 20px;
				margin-top: 0px;
			}
		}
		&::v-deep .OptionGroup {
			// background: rgba(#333, 0.05);
			// background: #333;
			background: rgba(#ffb053, 1);
			border: 1px solid #333;
			.Option {
				color: #333;
				background: transparent;
				&.selected,
				&:hover {
					color: $black;
					background: white;
				}
			}
		}
	}
}
.ScoreList {
	padding: 10px;
	background: #ffb053;
	border-radius: 10px;
	// width: 50%;
	.OutsiderName {
		font-size: 18px;
		text-align: center;
		font-weight: bold;
		color: #333;
		margin-bottom: 15px;
		display: none;
		// @media screen and (max-width: $sm) {
		// 	display: block;
		// }
	}
	.Team {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		background: rgba(#fff, 0.4);
		border: 2px solid rgba(#fff, 0.2);
		padding: 6px 10px;
		border-radius: 10px;
		&.Header {
			border: none;
		}

		@media screen and (max-width: $sm) {
			padding: 8px;
		}
		&.Header {
			background: none;
			.Name {
				font-size: 16px;
				font-weight: bold;
				// @media screen and (max-width: $sm) {
				// 	font-size: 10px;
				// 	color: transparent;
				// }
			}
		}
		.Avatar {
			margin-right: 15px;
			min-width: 20px;
			@media screen and (max-width: $sm) {
				margin-right: 10px;
				min-width: 20px;
			}
		}
		.Name {
			color: #333;
			min-width: 120px;
			flex: 1;
			@media screen and (max-width: $md) {
				min-width: 100px;
				font-size: 14px;
			}
			@media screen and (max-width: $sm) {
				min-width: 70px;
				font-size: 12px;
			}
		}
		.Value {
			color: #333;
			min-width: 45px;
			@media screen and (max-width: $sm) {
				min-width: 28px;
				font-size: 12px;
			}
		}
	}
}
.ScoreLegend {
	margin-top: 10px;
	&::v-deep {
		.ItemText {
			font-size: 14px !important;
			@media screen and (max-width: $sm) {
				font-size: 12px !important;
			}
		}
		.Item {
			padding: 5px 15px 5px 8px;
		}
	}
}
.ScoreListWrapper {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	width: 60%;
	@media screen and (max-width: $xl) {
		width: 75%;
	}
	@media screen and (max-width: $lg) {
		width: 90%;
	}
	@media screen and (max-width: $md) {
		grid-template-columns: repeat(1, 1fr);
	}
}
</style>
