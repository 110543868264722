<script>
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor'

export default {
    name: 'FieldError',
    extends: singleErrorExtractorMixin,
    computed: {
        listeners () {
            return {
                input: () => {
                    if (this.preferredValidator.$touch) {
                        this.preferredValidator.$touch()
                    }
                },
            }
        },
    },
    render (h) {
        return h(
            'div', { class: 'FieldError' }, [
                this.$scopedSlots.default({
                    error: this.hasErrors,
                    errorMessage: this.firstErrorMessage,
                    errorMessages: this.activeErrorMessages,
                    listeners: this.listeners,
                }),
            ],
        )
    },
}
</script>
