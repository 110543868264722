<template>
    <div class="DateSelector">
        <div
            v-if="displayMonth"
            class="MonthSelector"
        >
            <div
                v-if="!isEditable"
                class="Back"
                :class="{ disabled: dateInfo.selectedMonth === 1 }"
            >
                <Icon
                    icon="chevron-left"
                    :size="20"
                    color="grayDark"
                    @click="handleSelectedMonth('back')"
                />
            </div>
            <div class="Month">
                {{ months[dateInfo.selectedMonth - 1] && months[dateInfo.selectedMonth - 1].label }}
            </div>
            <div
                v-if="!isEditable"
                class="Forward"
                :class="{ disabled: dateInfo.selectedMonth === 12 }"
            >
                <Icon
                    icon="chevron-right"
                    :size="20"
                    color="grayDark"
                    @click="handleSelectedMonth('forward')"
                />
            </div>
        </div>
        <div
            v-if="displayYear"
            class="YearSelector"
        >
            <div
                v-if="!isEditable"
                class="Back"
                :class="{ disabled: dateInfo.selectedYear === dateInfo.startYear }"
            >
                <Icon
                    icon="chevron-left"
                    :size="20"
                    color="grayDark"
                    @click="handleSelectedYear('back')"
                />
            </div>
            <div class="Year">
                {{ dateInfo.selectedYear }}
            </div>
            <div
                v-if="!isEditable"
                class="Forward"
                :class="{ disabled: dateInfo.selectedYear === dateInfo.endYear }"
            >
                <Icon
                    icon="chevron-right"
                    :size="20"
                    color="grayDark"
                    @click="handleSelectedYear('forward')"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { months } from '@/entities/calendar'
import VueTypes from 'vue-types'
import utils from '@/utils/utils'

export default {
    name: 'DateSelector',
    props: {
        isEditable: VueTypes.bool.def(false),
        displayMonth: VueTypes.bool.def(true),
        displayYear: VueTypes.bool.def(true),
        startYear: VueTypes.string,
        proposedDate: VueTypes.object.def(undefined),
    },
    data () {
        return {
            months,
            dateInfo: this.proposedDate || utils.getInitialDateInfo({ startYear: this.startYear }),
        }
    },
    methods: {
        async handleSelectedYear (value) {
            this.dateInfo = utils.handleSelectedYear(value, this.dateInfo)
            this.$emit('changeDate', this.dateInfo)
        },
        async handleSelectedMonth (value) {
            this.dateInfo = utils.handleSelectedMonth(value, this.dateInfo)
            this.$emit('changeDate', this.dateInfo)
        },
    },
}
</script>
<style lang="scss" scoped>
.DateSelector {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: 20px;
	.MonthSelector {
		margin-right: 20px;
	}
	.YearSelector, .MonthSelector {
		display: flex;
		.Back, .Forward, .Year, .Month {
			width: 30px;
			height: 30px;
			background: $gray-07;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			&.disabled {
				opacity: .5;
				cursor: default;
			}
			@media screen and (max-width: $sm) {
				width: 20px;
			}
		}
		.Month {
			width: 120px;
			background: white;
			cursor: default;
		}
		.Year {
			width: 80px;
			background: white;
			cursor: default;
		}
	}
}
</style>
