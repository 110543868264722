<template>
    <div class="LineupDraggableList">
        <div class="ListHeader">
            <p
                v-if="displayNumbers"
                class="Turn"
            >
                Nº
            </p>
            <div class="Person">
                <p class="Name">
                    Jugadoras
                </p>
                <p class="Position">
                    Posición
                </p>
            </div>
            <div
                v-if="displayBench && !!bench.length"
                class="Person"
            >
                <p class="Name">
                    Reserva
                </p>
            </div>
        </div>
        <div>
            <Loading
                key="loading"
                :visible="isLoading"
                :opacity="1"
                :duration="0"
                class="LoadingTable"
            />
            <div
                v-if="!isLoading"
                class="ListContent"
            >
                <div>
                    <draggable :list="lineUp">
                        <div
                            v-for="(person, index) in lineUp"
                            :key="`titular-${index}`"
                            class="ListItem"
                        >
                            <div
                                v-if="displayNumbers"
                                class="Turn"
                            >
                                <p>
                                    {{ index + 1 }}
                                </p>
                            </div>
                            <div class="Person">
                                <p class="Name">
                                    {{ person.alias }}
                                    <span v-if="person.userUniform && person.userUniform.number">
                                        #<span class="Number">{{ person.userUniform.number }}</span>
                                    </span>
                                </p>
                                <p class="Position">
                                    {{ person.lineUpPosition.abbreviation }}
                                    <span>
                                        ({{ person.lineUpPosition.number }})
                                    </span>
                                </p>
                            </div>
                        </div>
                    </draggable>
                </div>
                <div
                    v-if="displayBench && !!bench.length"
                    class="Block"
                >
                    <draggable :list="bench">
                        <div
                            v-for="(person, index) in bench"
                            :key="`banca-${index}`"
                            class="ListItem"
                        >
                            <div class="Person Bench">
                                <p class="Name">
                                    {{ person.alias }}
                                    <span v-if="person.userUniform && person.userUniform.number">
                                        #<span class="Number">{{ person.userUniform.number }}</span>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </draggable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import draggable from 'vuedraggable'

export default {
    name: 'LineupDraggableList',
    components: {
        draggable,
    },
    props: {
        lineUp: VueTypes.array,
        bench: VueTypes.array,
        isLoading: VueTypes.bool.def(false),
        displayBench: VueTypes.bool.def(true),
        displayNumbers: VueTypes.bool.def(true),
    },
}
</script>
<style lang="scss" scoped>
.LineupDraggableList {
	flex: 1;
	max-width: 100%;
	overflow: hidden;
	@media screen and (max-width: $sm) {
		margin-top: 30px;
	}
	.ListHeader {
		display: flex;
		margin-bottom: 10px;
		max-width: 100%;
		width: 100%;
		flex: 1;
		box-sizing: border-box;
		.Turn {
			min-width: 40px;
			text-align: center;
			font-size: 14px;
			color: $gray-02;
			@media screen and (max-width: $md) {
				min-width: 30px;
			}
			@media screen and (max-width: $sm) {
				font-size: 12px;
			}
		}
		.Version {
			text-transform: uppercase;
			font-size: 14px;
			color: $gray-02;

		}
		.Person {
			width: 250px;
			padding: 0 15px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			@media screen and (max-width: $md) {
				min-width: 210px;
				max-width: 210px;
				font-size: 14px;
				margin-left: 8px;
				flex: 1;
			}
			@media screen and (max-width: $sm) {
				min-width: 180px;
				max-width: 180px;
				padding: 0 10px;
			}
			&.Bench {
				@media screen and (max-width: $md) {
					width: 100px;
				}
			}
			.Name {
				margin: 0 10px;
				text-transform: uppercase;
				font-size: 14px;
				color: $gray-02;
				@media screen and (max-width: $sm) {
					margin: 0;
					font-size: 12px;
				}
			}
			.Position {
				text-transform: uppercase;
				font-size: 14px;
				color: $gray-02;
				@media screen and (max-width: $sm) {
					font-size: 12px;
				}
			}
		}
	}
	.LoadingTable {
		margin-top: 50px;
	}
	.ListContent {
		display: flex;
		max-width: 100%;
		box-sizing: border-box;
		.Block {
			box-sizing: border-box;
			flex: 1;
		}
		.ListItem {
			display: flex;
			align-items: center;
			height: 40px;
			& + .ListItem {
				margin-top: 10px;
			}
			.Turn {
				background: $primary;
				border-radius: 10px;
				color: $white;
				width: 40px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				@media screen and (max-width: $md) {
					width: 30px;
					font-size: 14px;
				}
			}
			.Person {
				display: flex;
				justify-content: space-between;
				align-items: center;
				background: white;
				padding: 0 15px;
				border-radius: 10px;
				width: 250px;
				height: 100%;
				margin-left: 10px;
				@media screen and (max-width: $md) {
					width: 210px;
					font-size: 14px;
					margin-left: 8px;
					flex: 1;
				}
				@media screen and (max-width: $sm) {
					width: 180px;
					padding: 0 10px;
				}
				&.Bench {
					@media screen and (max-width: $md) {
						width: 100px;
					}
					// @media screen and (max-width: $sm) {
					// 	.Name span {
					// 		display: none; // TODO: IS THIS NECESSARY?
					// 	}
					// }
				}
				.Name span {
					color: $primary;
					font-size: 12px;
					margin-left: 5px;
					.Number {
						font-size: 14px;
						margin-left: 2px;
					}
				}
			}
			.Position {
				background: rgba($primary, .1);
				padding: 4px 10px;
				border-radius: 8px;
				color: $primary;
				box-shadow: 0 2px 8px 0 rgba($black, 0.08);
				span {
					font-size: 12px;
					color: $gray-dark;
					margin-left: 2px;
				}
			}
		}
	}
}
</style>
