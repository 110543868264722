<template>
    <div class="EconomyScreen">
        <div class="ScreenHeader">
            <h1>Economía</h1>
            <PillSelectorInput
                v-model="activeTab"
                :options="tabs"
                border="gray"
                preventDeselect
                @input="handleChangeTab"
            />
        </div>
        <div class="Container">
            <EconomyExpenses
                v-if="activeTab === 'expenses'"
            />
            <EconomyPayments
                v-if="activeTab === 'incomes'"
            />
            <EconomyReports
                v-if="activeTab === 'reports'"
            />
        </div>
    </div>
</template>

<script>
import { EconomyPayments, EconomyReports, EconomyExpenses } from './EconomySections'
import { tabs } from '@/entities/economy'
import VueTypes from 'vue-types'

export default {
    name: 'EconomyScreen',
    components: {
        EconomyPayments,
        EconomyReports,
        EconomyExpenses,
    },
    props: {
        me: VueTypes.object,
    },
    data () {
        return {
            tabs,
            activeTab: '',
        }
    },
    watch: {
        $route (to) {
            const { economySlug } = to.params
            this.activeTab = economySlug ? (this.tabs.find(t => t.slug === economySlug) || {}).key : this.tabs[0].key
        },
    },
    mounted () {
        const { economySlug } = this.$route.params || {}
        this.activeTab = economySlug ? (this.tabs.find(t => t.slug === economySlug) || {}).key : this.tabs[0].key
    },
    methods: {
        handleChangeTab (value) {
            const { economySlug } = this.$route.params || {}
            const selectedSlug = (this.tabs.find(t => t.key === value) || {}).slug
            if (economySlug !== selectedSlug) {
                this.$router.push({
                    name: 'Economy',
                    params: { economySlug: selectedSlug },
                })
            }
        },
    },
}
</script>
<style lang="scss" scoped>

.ScreenHeader {
	width: 100%;
	display: flex;
	justify-content: space-between;
	.PillSelectorInput {
		width: fit-content !important;
	}
	@media screen and (max-width: $sm) {
		flex-direction: column;
		h1 {
			margin-bottom: 15px !important;
		}
		.PillSelectorInput {
			margin-bottom: 20px;
			align-self: flex-end;
		}
	}
}
</style>
