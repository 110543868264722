import team from '../api/team'
import location from '../api/location'

export default {
    async listTeams (city) {
        const teams = (await team.listTeams({ city })).items
        return teams.map(t => {
            const team = t
            const contact = []
            if (team.contactName) contact.push(team.contactName)
            if (team.phoneNumber) contact.push(team.phoneNumber)
            team.contact = contact.join(' - ')
            return team
        })
    },

    async listLocations (city, teamId) {
        const locations = (await location.listLocations({ city, teamId })).items
        return locations.map(l => {
            const loc = l
            loc.teamName = (loc.team && loc.team.name)
            return loc
        })
    },

    createTeam (teamInfo) {
        const teamToAdd = teamInfo
        if (teamInfo.contact) {
            const contact = teamInfo.contact.split(' - ')
            teamToAdd.contactName = contact[0]
            teamToAdd.phoneNumber = contact[1]
            delete teamToAdd.contact
        }

        return team.createTeam(teamInfo)
    },

    deleteTeam (teamId) {
        return team.deleteTeam(teamId)
    },

    async updateTeams (teams) {
        const dbTeams = (await team.listTeams()).items
        for (const dbTeam of dbTeams) {
            const teamToUpdate = teams.find(t => t.id === dbTeam.id)
            if (teamToUpdate && teamToUpdate.contact) {
                const contact = teamToUpdate.contact.split(' - ')
                teamToUpdate.contactName = contact[0]
                teamToUpdate.phoneNumber = contact[1]
            }
            delete teamToUpdate.contact
            if (!this.isSameObject(teamToUpdate, dbTeam)) {
                await team.updateTeam(teamToUpdate.id, teamToUpdate)
            }
        }
    },

    isSameObject (obj1, obj2) {
        let isSameObject = true
        for (const key in obj1) {
            if ((obj1[key] && !obj2[key]) || obj1[key] !== obj2[key]) {
                isSameObject = false
                break
            }
        }

        return isSameObject
    },

    createLocation (locationInfo) {
        return location.createLocation(locationInfo)
    },

    deleteLocation (locationId) {
        return location.deleteLocation(locationId)
    },
}
