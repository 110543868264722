<template>
    <div class="Statistics">
        Statistics
    </div>
</template>

<script>
import VueTypes from 'vue-types'
export default {
    name: 'Statistics',
    props: {
        statistics: VueTypes.array,
        isLoading: VueTypes.bool.def(false),
    },
}
</script>
<style lang="scss" scoped>

// .Statistics {

// }
</style>
