<template>
    <div class="AttendanceCalendar">
        <div>
            <p
                v-if="!isTableMode"
                class="Title"
            >
                {{ type }}
            </p>
            <div
                v-if="event.length"
                class="Container"
            >
                <SingleGoalRing
                    v-if="!hideGoalRing"
                    :progress="attendance.percentageValue"
                    :text="attendance && attendance.percentage"
                    suffix="%"
                    color="primary"
                />
                <div
                    class="Table"
                    :class="{ firstRow }"
                    :style="{'flex-direction': isMobile ? 'row' : 'column' }"
                >
                    <div
                        v-if="!isTableMode || firstRow || isMobile"
                        class="Header"
                        :style="{ [gridType]: `repeat(${isTableMode ? event.length + 1 : event.length}, 1fr)`, [gridOtherType]: '1fr', 'width': isMobile ? '50%' : '100%' }"
                    >
                        <div
                            v-if="isTableMode"
                            class="Day Name"
                        >
                            Nombre
                        </div>
                        <div
                            v-for="{ day } in event"
                            :key="`day-${day}`"
                            class="Day"
                        >
                            <p>{{ day }}</p>
                        </div>
                    </div>
                    <div
                        class="Body"
                        :style="{ [gridType]: `repeat(${isTableMode ? event.length + 1 : event.length}, 1fr)`, [gridOtherType]: '1fr', 'width': isMobile ? '50%' : '100%' }"
                    >
                        <div
                            v-if="isTableMode"
                            class="Check Name"
                        >
                            {{ type }}
                        </div>
                        <div
                            v-for="{ day, isFutureDay, id } in event"
                            :key="`check-${day}`"
                            class="Check"
                            :style="{ 'cursor': isEditable ? 'pointer' : 'default' }"
                            :class="{ blocked: (!isFutureDay && isPlayer), editable: isEditable }"
                        >
                            <Spinner
                                :visible="isLoading"
                                color="white"
                                small
                                noMargin
                            />
                            <Tooltip
                                v-if="!isLoading && attendanceState(id)"
                                placement="bottom"
                                :text="isEditable ? '' : attendanceOptions.find(o => o.key === attendanceState(id)).label"
                            >
                                <Icon
                                    v-if="!isEditable"
                                    :icon="getAttendanceStateIcon(attendanceState(id))"
                                    :color="getAttendanceStateColor(attendanceState(id))"
                                    :size="20"
                                />
                                <IconSelect
                                    v-else
                                    :options="attendanceOptions"
                                    :value="attendanceState(id)"
                                    @input="handleAttendanceSelection(id, $event)"
                                />
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else
                class="Empty"
            >
                No hay eventos
            </div>
        </div>
    </div>
</template>

<script>
import attendanceConstants from '@/entities/attendance'
import SingleGoalRing from '../SingleGoalRing/SingleGoalRing.vue'
import VueTypes from 'vue-types'
import { mediaQueryMixin } from '@/mixins'

export default {
    name: 'AttendanceCalendar',
    components: {
        SingleGoalRing,
    },
    mixins: [mediaQueryMixin],
    props: {
        event: VueTypes.array,
        attendance: VueTypes.object,
        type: VueTypes.string,
        isPlayer: VueTypes.bool,
        hideGoalRing: VueTypes.bool.def(false),
        isTableMode: VueTypes.bool.def(false),
        firstRow: VueTypes.bool,
        isEditable: VueTypes.bool.def(false),
        isLoading: VueTypes.bool.def(false),
    },
    data () {
        return {
            attendanceStates: attendanceConstants.attendanceStates,
            finalAttendanceStates: attendanceConstants.finalAttendanceState,
            attendanceOptions: [...Object.values(attendanceConstants.attendanceStates),
                ...Object.values(attendanceConstants.finalAttendanceState)],
            mutableAttendance: this.attendance,
        }
    },
    computed: {
        isMobile () {
            return this.$mq.w < this.$mq.sm.max
        },
        gridType () {
            return !this.isMobile ? 'grid-template-columns' : 'grid-template-rows'
        },
        gridOtherType () {
            return this.isMobile ? 'grid-template-columns' : 'grid-template-rows'
        },
    },
    mounted () {
        this.mutableAttendance = this.attendance
    },
    methods: {
        attendanceState (id) {
            const { attendance } = this
            if (attendance && attendance.data && attendance.data[id] && attendance.data[id].state) return attendance.data[id].state
        },
        getAttendanceStateIcon (state) {
            return (this.attendanceStates[state] && this.attendanceStates[state].icon) ||
                (this.finalAttendanceStates[state] && this.finalAttendanceStates[state].icon)
        },
        getAttendanceStateColor (state) {
            return (this.attendanceStates[state] && this.attendanceStates[state].color) ||
                (this.finalAttendanceStates[state] && this.finalAttendanceStates[state].color)
        },
        handleAttendanceSelection (eventId, statusKey) {
            this.mutableAttendance.data[eventId].state = statusKey
            this.mutableAttendance.data[eventId].toEdit = true
            this.mutableAttendance.toEdit = true
        },
    },
}
</script>
<style lang="scss" scoped>
.AttendanceCalendar {
	.Title {
		font-size: 18px;
		font-weight: bold;
		color: $gray-dark;
		@media screen and (max-width: $sm) {
			font-size: 16px;
		}
	}
	.Container {
		display: flex;
		align-items: flex-start;
		padding: 20px 0 !important;
		.SingleGoalRing {
			width: 80px;
			margin-right: 20px;
			@media screen and (max-width: $sm) {
				width: 60px;
			}
		}
		.Table {
			width: 100%;
			display: flex;
			border-radius: 8px;
			.Header, .Body {
				display: grid;
				grid-column-gap: 5px;
				grid-row-gap: 5px;
				overflow: visible;
				.Day {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 10px 0;
					background: $primary;
				}
				.Check {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 10px 0;
					background: $gray-07;
					&.blocked {
						background: rgba($gray-medium, .5);
					}
					&.editable {
						padding: 0;
					}
					.Tooltip {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
		}
	}
	.Empty {
		width: 100%;
		background: rgba($gray-light, .5);
		padding: 15px 25px;
		margin: 10px 0;
		color: $gray-dark;
		border-radius: 5px;
	}
}
</style>
