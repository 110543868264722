<template>
	<div class="MadMixRostersScreen">
		<div class="ImageSection" :style="{ backgroundImage: `url(${require('@/assets/imgs/MadMix/fondo4.png')})` }">
			<div class="Title">
				<img :src="require(`@/assets/imgs/MadMix/MadMix.png`)" class="Torneo MadMix de kickingball">
			</div>
		</div>

		<div class="TeamHeader">
			<p class="TeamName">
				{{ teamName }}
			</p>
		</div>

		<div class="TeamContent">
			<div v-for="(item, idx) in teamPlayers" :key="`item-player-${idx}`">
				<div class="TeamItem">
					<div class="TeamItemNumber">
						{{ idx + 1 }}
					</div>
					<div class="TeamMember">
						<div class="flex">
							<Avatar :url="require(`@/assets/imgs/MadMix/participants/${item.teamId}.png`)"
								:size="{ height: isMobile ? 30 : 35, width: isMobile ? 30 : 35 }" :rounded="false" />
							<p class="Name">
								{{ item.name }}
							</p>
						</div>
						<div class="flex">
							<p class="Position">
								{{ defensivePositions.find(({ number }) => number === item.positionId)[isMobile ? 'key' :
									'label'] }}
							</p>
							<p class="Position">
								{{ item.secondPositionId && defensivePositions.find(({ number }) => number ===
									item.secondPositionId)[isMobile ? 'key' : 'label'] || '-' }}
							</p>
						</div>
					</div>
				</div>
			</div>

			<p class="ManagerName">
				Técnicos
			</p>
			<div v-for="(item, idx) in teamManagers" :key="`item-manager-${idx}`">
				<div class="TeamItem">
					<div class="TeamItemNumber">
						{{ idx + 1 }}
					</div>
					<div :key="`item-${idx}`" class="TeamMember">
						<div class="flex">
							<Avatar :url="require(`@/assets/imgs/MadMix/participants/${item.teamId}.png`)"
								:size="{ height: isMobile ? 30 : 35, width: isMobile ? 30 : 35 }" :rounded="false" />
							<p class="Name">
								{{ item.name }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mediaQueryMixin } from '@/mixins'
import { defensivePositions } from '@/data/MadMix/general'
import data22 from '@/data/MadMix/2022/madmix'
import data23 from '@/data/MadMix/2023/madmix'
import data24 from '@/data/MadMix/2024/madmix'

export default {
	name: 'MadMixRostersScreen',
	mixins: [mediaQueryMixin],
	data() {
		return {
			defensivePositions,
			teamName: '',
			teamPlayers: [],
			teamManagers: [],
			editionsData: {
				2022: data22,
				2023: data23,
				2024: data24,
			}
		}
	},
	computed: {
		isMobile() {
			return this.$mq.w < this.$mq.sm.min
		},
	},
	mounted() {
		const { teamId, edition } = this.$route.params || {}
		const { finalTeams } = this.editionsData[edition]
		const team = teamId ? finalTeams.find(t => t[0].id === Number(teamId)) : finalTeams[0]
		this.teamName = team[0].name
		this.teamPlayers = team.filter(t => !!t.positionId)
		this.teamManagers = team.filter(t => !t.positionId && t.teamId)
	},
}
</script>

<style lang="scss" scoped>
.MadMixRostersScreen {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: $gray-01;
	padding-bottom: 4rem;
	background: white;
}

.ImageSection {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	height: 15rem;
	width: 100%;
	background-size: cover;
	background-color: white;
	background-repeat: no-repeat;
	background-position-x: center;
	background-position-y: center;
	min-height: 24rem;

	@media screen and (max-width: $sm) {
		height: 12rem;
		min-height: 22rem;
	}
}

.Title {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 8rem;

	@media screen and (max-width: $sm) {
		margin: 100px 0 50px 0;
	}

	img {
		width: auto;
		height: 220px;
	}
}

.TeamHeader {
	margin-bottom: 2rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid $gray-01;
	display: flex;
	width: 90%;

	@media screen and (min-width: $lg) {
		width: 50%;
	}

	@media screen and (min-width: $md) {
		flex-direction: row;
		justify-content: space-between;
	}

	.TeamName {
		font-size: 26px;
		font-weight: bold;
	}
}

.TeamContent {
	width: 90%;

	@media screen and (min-width: $lg) {
		width: 50%;
	}

	.TeamItem {
		width: 100%;
		display: flex;
		margin-bottom: 15px;

		.TeamItemNumber {
			background: #53B089;
			height: 45px;
			min-width: 45px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 10px;
			margin-right: 10px;
			color: white
		}

		.TeamMember {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 45px;
			background: rgba($gray-01, .1);
			padding: 8px 16px;
			border-radius: 10px;
			flex: 1;

			@media screen and (max-width: $sm) {
				padding: 8px;
			}

			.Avatar {
				margin-right: 15px;
				min-width: 15px;
				display: flex;
				align-items: center;

				@media screen and (max-width: $sm) {
					margin-right: 10px;
					min-width: 15px;
				}
			}

			.flex {
				display: flex;
				align-items: center;
			}

			.Position {
				background: white;
				border-radius: 10px;
				padding: 8px 16px;
				font-size: 14px;
				min-width: 120px;
				text-align: center;
				margin-left: 15px;

				@media screen and (max-width: $sm) {
					font-size: 12px;
					min-width: auto;
					margin-left: 8px;
				}
			}
		}
	}

	.ManagerName {
		font-size: 20px;
		font-weight: bold;
		margin-top: 2rem;
		margin-bottom: 1rem;
		padding-bottom: 1rem;
		border-bottom: 1px solid $gray-01;
	}
}
</style>
