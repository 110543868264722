import CONSTANTS from './constants'

export default {
    attendanceStates: {
        [CONSTANTS.USER_EVENT_STATE.GOING]: {
            id: 'going',
            key: CONSTANTS.USER_EVENT_STATE.GOING,
            icon: 'check',
            color: 'success',
            label: 'Asistiré',
        },
        [CONSTANTS.USER_EVENT_STATE.NOT_GOING]: {
            id: 'not_going',
            key: CONSTANTS.USER_EVENT_STATE.NOT_GOING,
            icon: 'close',
            color: 'error',
            label: 'No asistiré',
        },
        [CONSTANTS.USER_EVENT_STATE.MAYBE]: {
            id: 'supported',
            key: CONSTANTS.USER_EVENT_STATE.MAYBE,
            icon: 'shuffle',
            color: 'info',
            label: 'Quizás',
        },
        [CONSTANTS.USER_EVENT_STATE.PENDING]: {
            id: 'peding',
            key: CONSTANTS.USER_EVENT_STATE.PENDING,
            icon: 'alert-triangle',
            color: 'warning',
            label: 'Sin respuesta',
        },
        [CONSTANTS.USER_EVENT_STATE.INJURED]: {
            id: 'injured',
            key: CONSTANTS.USER_EVENT_STATE.INJURED,
            icon: 'frown',
            color: 'grayDark',
            label: 'Lesionada',
        },
        [CONSTANTS.USER_EVENT_STATE.OUT]: {
            id: 'out',
            key: CONSTANTS.USER_EVENT_STATE.OUT,
            icon: 'lock',
            color: 'grayDark',
            label: 'Fuera',
        },
        [CONSTANTS.USER_EVENT_STATE.SUPPORT]: {
            id: 'support',
            key: CONSTANTS.USER_EVENT_STATE.SUPPORT,
            icon: 'thumbs-up',
            color: 'grayDark',
            label: 'Apoyo',
        },
    },
    finalAttendanceState: {
        [CONSTANTS.USER_EVENT_FINAL_STATE.ACCEPTED]: {
            id: 'went',
            key: CONSTANTS.USER_EVENT_FINAL_STATE.ACCEPTED,
            icon: 'check-square',
            color: 'success',
            label: 'Participó',
        },
        [CONSTANTS.USER_EVENT_FINAL_STATE.REJECTED]: {
            id: 'didnt_go',
            key: CONSTANTS.USER_EVENT_FINAL_STATE.REJECTED,
            icon: 'x-square',
            color: 'error',
            label: 'No participó',
        },
    },
    attendaceStartYear: '2020',
    eventTypes: {
        [CONSTANTS.EVENT_TYPE.PHYSICAL_TRAINING]: {
            id: CONSTANTS.EVENT_TYPE.PHYSICAL_TRAINING,
            key: CONSTANTS.EVENT_TYPE.PHYSICAL_TRAINING,
            label: CONSTANTS.EVENT_TYPE_NAMES.PHYSICAL_TRAINING,
        },
        [CONSTANTS.EVENT_TYPE.THEORIC_TRAINING]: {
            id: CONSTANTS.EVENT_TYPE.THEORIC_TRAINING,
            key: CONSTANTS.EVENT_TYPE.THEORIC_TRAINING,
            label: CONSTANTS.EVENT_TYPE_NAMES.THEORIC_TRAINING,
        },
        [CONSTANTS.EVENT_TYPE.GAME]: {
            id: CONSTANTS.EVENT_TYPE.GAME,
            key: CONSTANTS.EVENT_TYPE.GAME,
            label: CONSTANTS.EVENT_TYPE_NAMES.GAME,
        },
        [CONSTANTS.EVENT_TYPE.ADDITIONAL_ACTIVITY]: {
            id: CONSTANTS.EVENT_TYPE.ADDITIONAL_ACTIVITY,
            key: CONSTANTS.EVENT_TYPE.ADDITIONAL_ACTIVITY,
            label: CONSTANTS.EVENT_TYPE_NAMES.ADDITIONAL_ACTIVITY,
        },
    },
    eventTabs: [
        {
            key: CONSTANTS.EVENT_TYPE.PHYSICAL_TRAINING,
            slug: 'entrenamientos-fisicos',
        },
        {
            key: CONSTANTS.EVENT_TYPE.THEORIC_TRAINING,
            slug: 'entrenamientos-teoricos',
        },
        {
            key: CONSTANTS.EVENT_TYPE.GAME,
            slug: 'partidos',
        },
        {
            key: CONSTANTS.EVENT_TYPE.ADDITIONAL_ACTIVITY,
            slug: 'actividades',
        },
    ],
}
