<template>
    <div class="SwitchInput">
        <p
            v-if="label"
            class="Label"
            :size="14"
        >
            {{ label }}
        </p>
        <div
            class="Switch"
            :class="{ disabled }"
        >
            <Button
                v-for="(option, index) in options"
                :key="index"
                :name="`switch-${index}`"
                :styleType="value === option.value ? 'primary' : 'white'"
                :disabled="value === option.value ? disabled : false"
                :text="option.label"
                v-bind="option.attrs"
                @click="$emit('input', option.value)"
            />
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'SwitchInput',
    props: {
        options: VueTypes.array,
        value: VueTypes.oneOfType([VueTypes.string, VueTypes.bool]).isRequired,
        name: VueTypes.string.optional,
        label: VueTypes.string.optional,
        disabled: VueTypes.bool.def(false),
    },
}
</script>

<style scoped lang="scss">
@import '@/theme/_variables.scss';

.SwitchInput {
	.Label {
		margin: 0 0 4px 16px;
		color: $gray-02;
	}
	.Switch {
		border: 3px solid rgba($primary, .5);
		border-radius: 20px;
		padding: 2px;
		width: fit-content;
		min-height: $form-height;
		display: flex;
		flex-wrap: nowrap;
		&.disabled {
			cursor: not-allowed;
			pointer-events: none;
		}
		&::v-deep .Button {
			border-radius: 20px;
			padding: 0 10px;
			font-size: 12px;
			text-transform: uppercase;
			&:hover {
				background: $primary !important;
				color: $white !important;
			}
			&.white:hover {
				background: $white !important;
				color: $gray-dark !important;
			}
		}
	}
}
</style>
