import MadMixPlayers from "./jugadoras.json";
import MadMixManagers from "./managers.json";
import { defensivePositions, defaultScreenTabs, formatDate } from "../general";
import participants from "./participants.json";
import names from "./names.json";
import BuahChaval from "./newTeams/buahchaval.json";
import Chachi from "./newTeams/chachi.json";
import ElCanteo from "./newTeams/elcanteo.json";
import Flipas from "./newTeams/flipas.json";
import LaMovida from "./newTeams/lamovida.json";
import LaPena from "./newTeams/lapena.json";
import MolaMazo from "./newTeams/molamazo.json";
import Tronquis from "./newTeams/tronquis.json";
import playersRaffle23 from "./playersRaffle";

export const playersRaffle = playersRaffle23;

const compare = (a, b) => {
	if (a.positionId > b.positionId) return 1;
	if (b.positionId > a.positionId) return -1;
	return 0;
};
export const players = MadMixPlayers.map((player) => {
	const position = defensivePositions.find((p) => p.key === player.position);
	const team = participants.find((p) => p.name === player.team);
	return {
		...player,
		positionId: position.number,
		teamId: (team || {}).id || "user",
	};
}).sort(compare);

export const managers = MadMixManagers.map((manager) => {
	const team = participants.find((p) => p.name === manager.team);
	return {
		...manager,
		teamId: (team || {}).id || "user",
	};
});

const newTeams = [BuahChaval, LaMovida, Flipas, ElCanteo, Tronquis, LaPena, Chachi, MolaMazo];

export const finalTeams = newTeams.map((t) => {
	return t.map((p) => {
		const pCopy = p;
		if (p && p.position) {
			const position = defensivePositions.find((a) => a.key === p.position);
			const secondPosition = defensivePositions.find((a) => a.key === p.secondPosition);
			const team = participants.find((b) => b.name === p.team);
			pCopy.positionId = position.number;
			pCopy.secondPositionId = secondPosition && secondPosition.number;
			pCopy.teamId = (team || {}).id || "user";
		} else if (p && p.isManager) {
			const team = participants.find((b) => b.name === p.team);
			pCopy.teamId = (team || {}).id || "user";
		}
		return pCopy;
	});
});

const teams = [
	{
		id: 1,
		name: "Buah Chaval",
	},
	{
		id: 2,
		name: "La Movida",
	},
	{
		id: 3,
		name: "Flipas",
	},
	{
		id: 4,
		name: "El Canteo",
	},
	{
		id: 5,
		name: "Tronquis",
	},
	{
		id: 6,
		name: "La Peña",
	},
	{
		id: 7,
		name: "Chachi",
	},
	{
		id: 8,
		name: "Mola Mazo",
	},
];

const locations = [
	{
		id: 1,
		name: "Jarama",
		address: "Av. de San Sebastián, 15",
	},
];

const events = [
	[
		{
			id: 1,
			homeclubId: 1,
			visitorId: 8,
			locationId: 1,
			date: "2023-01-28T09:00:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
		},
		{
			id: 2,
			homeclubId: 2,
			visitorId: 7,
			locationId: 1,
			date: "2023-01-28T11:00:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
		},
		{
			id: 3,
			homeclubId: 3,
			visitorId: 6,
			locationId: 1,
			date: "2023-01-28T13:00:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
		},
		{
			id: 4,
			homeclubId: 4,
			visitorId: 5,
			locationId: 1,
			date: "2023-01-28T15:00:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
		},
	],
	[
		{
			id: 5,
			homeclubId: "Ganador P1",
			visitorId: "Ganador P3",
			locationId: 1,
			date: "2023-01-29T10:00:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
		},
		{
			id: 6,
			homeclubId: "Ganador P2",
			visitorId: "Ganador P4",
			locationId: 1,
			date: "2023-01-29T12:00:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
		},
		{
			id: 7,
			homeclubId: "Ganador S1",
			visitorId: "Ganador S2",
			locationId: 1,
			date: "2023-01-29T14:00:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
		},
	],
];

export default {
	locations,
	events,
	raffleDay: formatDate("2023-01-15T21:00:00.000Z"),
	participants,
	names,
	teams,
	finalTeams,
	managers,
	players,
	playersRaffle,
	tabs: defaultScreenTabs,
};
