<template>
    <div class="Attendance">
        <div class="Header">
            <Icon
                v-if="isEditMode === false"
                v-permissions="{permissions: []}"
                icon="edit"
                :size="20"
                @click="isEditMode = true"
            />
            <Icon
                v-else
                icon="close"
                :size="20"
                @click="isEditMode = false"
            />
        </div>
        <p
            v-if="isEditMode"
            class="Date"
        >
            {{ months[dateInfo.selectedMonth - 1].label }}, {{ dateInfo.selectedYear }}
        </p>
        <DateSelector
            v-else
            :startYear="attendanceConstants.attendaceStartYear"
            :proposedDate="dateInfo"
            @changeDate="handleSelectedDate"
        />
        <AttendanceCalendar
            v-for="(type, key, index) in eventTypes"
            :key="index"
            :type="typeNames[key]"
            :event="events[type]"
            :attendance="attendance[type]"
            :isEditable="isEditMode"
            :isLoading="isChangingDate"
        />
        <div
            v-if="isEditMode"
            class="Footer"
        >
            <Button
                class="Submit"
                type="submit"
                text="GUARDAR"
                styleType="primary"
                iconLeft="save"
                :iconSize="16"
                :loading="isLoading"
                @click="handleSubmit"
            />
        </div>
        <Legend
            :legend="attendanceStates"
            title="Tipos de asistencia"
        />
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import AttendanceCalendar from '../../AttendanceCalendar/AttendanceCalendar.vue'
import attendanceConstants from '@/entities/attendance'
import constants from '@/entities/constants'
import { months } from '@/entities/calendar'

export default {
    name: 'Attendance',
    components: {
        AttendanceCalendar,
    },
    props: {
        events: VueTypes.object,
        attendance: VueTypes.object,
        dateInfo: VueTypes.object,
        isLoading: VueTypes.bool.def(false),
    },
    data () {
        return {
            months,
            constants,
            attendanceConstants,
            attendanceStates: { ...attendanceConstants.attendanceStates, ...attendanceConstants.finalAttendanceState },
            eventTypes: constants.EVENT_TYPE,
            typeNames: constants.EVENT_TYPE_NAMES,
            isEditMode: false,
            isChangingDate: false,
        }
    },
    watch: {
        attendance: {
            deep: true,
            handler () {
                this.isChangingDate = false
            },
        },
    },
    methods: {
        handleSelectedDate (value) {
            this.isChangingDate = true
            this.$emit('changeAttendanceDate', value)
        },
        handleSubmit () {
            this.$emit('updateAttendance', this.attendance)
            this.isEditMode = false
        },
    },
}
</script>
<style lang="scss" scoped>

.Attendance {
	.Header {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		margin-bottom: 30px;
		.Icon {
			cursor: pointer;
			&:hover {
				color: $primary !important;
			}
		}
	}
	.Date {
		width: 100%;
		text-align: right;
		font-size: 20px;
		margin-bottom: 30px;
		font-weight: bold;
		color: $gray-dark;
	}
	.Footer {
		display: flex;
		justify-content: flex-end;
		margin-top: 30px;
		.Button {
			width: fit-content;
			font-size: 16px;
			@media screen and (max-width: $sm) {
				font-size: 14px;
			}
		}
	}
	.AttendanceCalendar {
			& + .AttendanceCalendar { margin-top: 30px }
	}
	.Legend {
		margin-top: 50px;
	}
}
</style>
