<template>
    <Transition
        name="modal"
        :duration="500"
    >
        <div
            v-if="isOpen"
            class="DropdownModal"
        >
            <div
                class="Modal"
                :class="{ darkMode }"
            >
                <div class="Line" />
                <div class="Wrapper">
                    <div
                        v-for="option in options"
                        :id="option.key"
                        :key="option.key"
                        v-permissions="{permissions: option.permissions, skipPermissions }"
                        class="Option"
                        @click="handleClickOption(option)"
                    >
                        <Icon
                            v-if="option.icon"
                            :icon="option.icon"
                            :size="20"
                            color="primary"
                        />
                        <p
                            :size="16"
                            weight="medium"
                            class="Label ml-1"
                        >
                            {{ option.label }}
                        </p>
                    </div>
                    <div class="Footer">
                        <Button
                            text="Cerrar"
                            styleType="gray"
                            @click="$emit('onClose')"
                        />
                    </div>
                </div>
            </div>
            <div
                class="Backdrop"
                :class="{ darkMode }"
                @click="$emit('onClose')"
            />
        </div>
    </Transition>
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'DropdownModal',
    props: {
        isOpen: VueTypes.bool.def(false),
        options: VueTypes.arrayOf(VueTypes.shape({
            key: VueTypes.oneOfType([Number, String]).isRequired,
            label: VueTypes.oneOfType([Number, String]).isRequired,
            icon: VueTypes.string,
            type: VueTypes.string,
            url: VueTypes.string,
        }).loose).isRequired,
        darkMode: VueTypes.bool.def(false),
        skipPermissions: VueTypes.bool.def(true),
    },
    watch: {
        isOpen (val) {
            if (val) document.body.classList.add('no-scroll')
            else document.body.classList.remove('no-scroll')
        },
    },
    beforeDestroy () {
        document.body.classList.remove('no-scroll')
    },
    methods: {
        handleClickOption (option) {
            this.$emit('onClick', option)
            this.openedInternal = false
        },
    },
}
</script>

<style scoped lang="scss">

.Modal {
   position: fixed;
   bottom: 0;
   left: 0;
   overflow-x: hidden;
   overflow-y: auto;
   outline: 0;
   background: white;
   color: $gray-dark;
   width: 100%;
   z-index: 9;
   border-radius: 10px 10px 0 0;
   padding: 30px;
   .Line {
        position: absolute;
        top: 14px;
        left: calc(50% - 2px);
        width: 37px;
        height: 4px;
        background: $gray-06;
	}
   .Wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 20px;
        .Option {
            display: flex;
            align-items: center;
            margin-left: 30px;
            .Label {
                margin-left: 10px;
                font-size: 16px;
                color: #797878;
                line-height: 12px;
                font-weight: 00;
            }
            & + .Option {
                margin-top: 30px;
            }
        }
        .Footer {
            width: 100%;
            margin-top: 30px;
            .Button {
                width: 100%;
                text-transform: uppercase;
                font-size: 16px;
            }
        }
	}
	&.darkMode {
		background: #333;
		color: white !important;
		box-shadow: 0px 4px 10px rgba(white, 0.2);
		.Line { background: $gray-dark }
		.Label { color: white !important }
		.Button { background: $gray-dark; color: white; }
   }
}
.Backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 8;
	background: rgba($black, 0.1);
	&.darkMode { background: rgba(#333, 0.7) }
}

.modal-enter-active,
.modal-leave-active {
    .Modal {
		transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
    }
	.Backdrop {
        transition: opacity 0.5s ease-in-out;
    }
}

.modal-enter,
.modal-leave-to {
    .Modal {
        opacity: 0;
		bottom: -100%;
    }
	.Backdrop {
        opacity: 0;
    }
}
</style>
