import axios from 'axios'

export default {
    createEvent: async (body, params) => {
        const response = await axios.post('/events', body, { params })

        return response && response.data && response.data.response
    },

    updateEvent: async (id, body) => {
        const response = await axios.put('/events/' + id, body)

        return response && response.data && response.data.response
    },

    deleteEvent: async (id) => {
        const response = await axios.delete('/events/' + id)

        return response && response.data && response.data.response
    },

    getEvent: async (id, params) => {
        const response = await axios.get('/events/' + id, { params })

        return response && response.data && response.data.response
    },

    listEvent: async (params) => {
        const response = await axios.get('/events', { params })

        return response && response.data && response.data.response
    },
}
