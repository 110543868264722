<template>
    <div class="Informations">
        <br><br>
        - Marcar el terreno
        <br><br>
        - Links de eventos
        <br><br>
        - Direcciones de sitios
        <br><br>
        - Canciones
        <br><br>
    </div>
</template>

<script>

export default {
    name: 'Informations',
}
</script>
<style lang="scss" scoped>
// .Informations {
// }

</style>
