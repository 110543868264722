import {
	HomePageScreen,
	KickingballScreen,
	WelcomeScreen,
	PlayersScreen,
	// LeagueScreen,
	TorneoOtonoScreen,
	MadMixScreen,
	// LeagueThirdScreen,
	LeagueFourthScreen,
	MadMixRostersScreen,
	MadMixConditionsScreen,
	// KickMixScreen,
	TermsScreen,
} from "@/screens";
// import PlayerTendencyChart from "@/screens/public/Print/PlayerTendencyChart.vue";

export default [
	{
		name: "Home",
		path: "/",
		component: HomePageScreen,
	},
	{
		name: "Kickingball",
		path: "/que-es-el-kickingball",
		component: KickingballScreen,
	},
	{
		name: "Terms",
		path: "/guia",
		component: TermsScreen,
	},
	{
		name: "Welcome",
		path: "/presentacion",
		component: WelcomeScreen,
	},
	{
		name: "Players",
		path: "/nuestras-jugadoras",
		component: PlayersScreen,
	},
	// {
	//     name: 'League',
	//     path: '/liga',
	//     component: LeagueScreen,
	// },
	// {
	//     name: 'League',
	//     path: '/liga',
	//     component: LeagueThirdScreen,
	// },
	{
		name: "League",
		path: "/liga",
		component: LeagueFourthScreen,
	},
	{
		name: "MadMix",
		path: "/madmix",
		component: MadMixScreen,
	},
	// {
	// 	name: "KickMix",
	// 	path: "/torneo-kickmix",
	// 	component: KickMixScreen,
	// },
	{
		name: "TorneoOtono",
		path: "/torneo-otono",
		component: TorneoOtonoScreen,
	},
	{
		name: "MadMixRoster",
		path: "/madmix/:edition/roster/:teamId",
		component: MadMixRostersScreen,
	},
	{
		name: "MadMixConditionss",
		path: "/madmix-2024",
		component: MadMixConditionsScreen,
	},
];
