import Espartanas from "./rosters/espartanas.json";

const newTeams = [];
newTeams.push(Espartanas);

export const teams = [
	{
		id: 1,
		name: "Panteras",
		logo: "@/assets/imgs/teams/5.png",
		link: "https://www.instagram.com/kickingball_panterasdemadrid/",
		groupId: 1,
	},
	{
		id: 2,
		name: "Águilas",
		logo: "@/assets/imgs/teams/2.png",
		link: "https://www.instagram.com/aguilaskickingballmadrid/",
		groupId: 1,
	},
	{
		id: 3,
		name: "Espartanas",
		logo: "@/assets/imgs/teams/3.png",
		link: "https://www.instagram.com/espartanaskickingball/",
		groupId: 1,
	},
	{
		id: 4,
		name: "Vikingas",
		logo: "@/assets/imgs/teams/6.png",
		link: "https://www.instagram.com/vikingaskickingballmadrid/",
		groupId: 2,
	},
	{
		id: 5,
		name: "Las Panas",
		logo: "@/assets/imgs/teams/1.png",
		link: "https://www.instagram.com/laspanasdevenezuelakickingball/",
		groupId: 2,
	},
	{
		id: 6,
		name: "Tropicales",
		logo: "@/assets/imgs/teams/7å.png",
		link: "https://www.instagram.com/tropicaleskickingballmadrid/",
		groupId: 2,
	},
];
export const participants = [
	...teams,
	{
		id: "1A",
		name: "1ro del grupo A",
		logo: "@/assets/imgs/teams/1A.png",
		link: "",
	},
	{
		id: "2B",
		name: "2do del grupo B",
		logo: "@/assets/imgs/teams/2B.png",
		link: "",
	},
	{
		id: "1B",
		name: "1ro del grupo B",
		logo: "@/assets/imgs/teams/1B.png",
		link: "",
	},
	{
		id: "2A",
		name: "2do del grupo A",
		logo: "@/assets/imgs/teams/2A.png",
		link: "",
	},
];

export const groups = [
	{
		id: 1,
		name: "GRUPO A",
	},
	{
		id: 2,
		name: " GRUPO B",
	},
];
export const locations = [
	{
		id: 1,
		name: "Jarama",
		address: "Av. de San Sebastián, 15",
	},
];

export const locationTypes = {
	1: {
		icon: "map-pin",
		color: "secondary",
		label: "Jarama: Av. de San Sebastián, 15",
		link: "https://goo.gl/maps/gxmee9ZKVYunv4rB9",
	},
};

export const scoreTypes = {
	1: {
		abbrev: "PJ",
		color: "secondary",
		label: "Partidos jugados",
	},
	2: {
		abbrev: "PG",
		color: "secondary",
		label: "Partidos Ganados",
	},
	3: {
		abbrev: "PP",
		color: "secondary",
		label: "Partidos Perdidos",
	},
	4: {
		abbrev: "PE",
		color: "secondary",
		label: "Partidos Empatados",
	},
	5: {
		abbrev: "CF",
		color: "secondary",
		label: "Carreras a favor",
	},
	6: {
		abbrev: "CC",
		color: "secondary",
		label: "Carreras en contra",
	},
	7: {
		abbrev: "DIF",
		color: "secondary",
		label: "Diferencia",
	},
	8: {
		abbrev: "PTS",
		color: "secondary",
		label: "Puntos",
	},
};

export const events = [
	// [
	// 	{
	// 		id: 0,
	// 		date: "2022-09-21T22:00:00.000Z",
	// 		title: "Sorteo de equipos"
	// 	}
	// ],
	[
		{
			id: 1,
			homeclubId: 1,
			visitorId: 2,
			locationId: 1,
			date: "2022-10-16T09:00:00.000Z",
			scoreHomeclub: 10,
			scoreVisitor: 0,
		},
		{
			id: 2,
			homeclubId: 1,
			visitorId: 3,
			locationId: 1,
			date: "2022-10-16T11:00:00.000Z",
			scoreHomeclub: 3,
			scoreVisitor: 4,
		},
		{
			id: 3,
			homeclubId: 3,
			visitorId: 2,
			locationId: 1,
			date: "2022-10-16T13:00:00.000Z",
			scoreHomeclub: 0,
			scoreVisitor: 0,
		},
		{
			id: 4,
			homeclubId: 4,
			visitorId: 5,
			locationId: 1,
			date: "2022-10-16T15:00:00.000Z",
			scoreHomeclub: 3,
			scoreVisitor: 2,
		},
	],
	[
		{
			id: 5,
			homeclubId: 4,
			visitorId: 6,
			locationId: 1,
			date: "2022-10-23T09:00:00.000Z",
			scoreHomeclub: 5,
			scoreVisitor: 1,
		},
		{
			id: 6,
			homeclubId: 5,
			visitorId: 6,
			locationId: 1,
			date: "2022-10-23T11:00:00.000Z",
			scoreHomeclub: 5,
			scoreVisitor: 5,
		},
		{
			id: 7,
			homeclubId: 2,
			visitorId: 1,
			locationId: 1,
			date: "2022-10-23T13:00:00.000Z",
			scoreHomeclub: 1,
			scoreVisitor: 3,
		},
		{
			id: 8,
			homeclubId: 1,
			visitorId: 3,
			locationId: 1,
			date: "2022-10-23T15:00:00.000Z",
			scoreHomeclub: 3,
			scoreVisitor: 2,
		},
	],
	[
		{
			id: 9,
			homeclubId: 3,
			visitorId: 2,
			locationId: 1,
			date: "2022-11-06T09:00:00.000Z",
			scoreHomeclub: 5,
			scoreVisitor: 1,
		},
		{
			id: 10,
			homeclubId: 4,
			visitorId: 5,
			locationId: 1,
			date: "2022-11-06T11:00:00.000Z",
			scoreHomeclub: 3,
			scoreVisitor: 3,
		},
		{
			id: 11,
			homeclubId: 4,
			visitorId: 6,
			locationId: 1,
			date: "2022-11-06T13:00:00.000Z",
			scoreHomeclub: 4,
			scoreVisitor: 7,
		},
		{
			id: 12,
			homeclubId: 5,
			visitorId: 6,
			locationId: 1,
			date: "2022-11-06T15:00:00.000Z",
			scoreHomeclub: 5,
			scoreVisitor: 6,
		},
	],
	[
		{
			id: 13,
			homeclubId: 1,
			visitorId: 6,
			locationId: 1,
			date: "2022-11-20T09:00:00.000Z",
			scoreHomeclub: 8,
			scoreVisitor: 3,
			finals: true,
		},
		{
			id: 14,
			homeclubId: 4,
			visitorId: 3,
			locationId: 1,
			date: "2022-11-20T11:00:00.000Z",
			scoreHomeclub: 7,
			scoreVisitor: 6,
			finals: true,
		},
		{
			id: 15,
			homeclubId: 1,
			visitorId: 4,
			locationId: 1,
			date: "2022-11-20T13:00:00.000Z",
			scoreHomeclub: 2,
			scoreVisitor: 1,
			title: "FINAL",
			finals: true,
		},
	],
	// [
	// 	{
	// 		id: 13,
	// 		homeclubId: "1A",
	// 		visitorId: "2B",
	// 		locationId: 1,
	// 		date: "2022-11-20T09:00:00.000Z",
	// 		scoreHomeclub: null,
	// 		scoreVisitor: null,
	// 	},
	// 	{
	// 		id: 14,
	// 		homeclubId: "1B",
	// 		visitorId: "2A",
	// 		locationId: 1,
	// 		date: "2022-11-20T11:00:00.000Z",
	// 		scoreHomeclub: null,
	// 		scoreVisitor: null,
	// 	},
	// 	{
	// 		id: 15,
	// 		date: "2022-11-20T13:00:00.000Z",
	// 		title: "FINAL",
	// 	},
	// ],
];
