<template>
    <Transition
        name="router"
        mode="out-in"
        v-on="$listeners"
    >
        <slot />
    </Transition>
</template>

<script>

export default {
    name: 'RouterTransition',
}
</script>

<style lang="scss" scoped>
.router-enter-active,
.router-leave-active {
    transition-duration: 0.5s;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
}

.router-enter {
    opacity: 0;
    transform: translate(0, -15px);
}

.router-leave-active {
    opacity: 0;
    transform: translate(0, -15px);
}
</style>
