<template>
    <div class="SetPassword">
        <form @submit.prevent="handleSubmit">
            <h1 class="Title">
                Establecer contraseña
            </h1>
            <FieldError
                #default="{ errorMessage, listeners }"
                :validator="$v.password"
                label="La contraseña"
            >
                <Input
                    v-model="password"
                    type="password"
                    iconLeft="lock"
                    iconColor="primary"
                    placeholder="Nueva contraseña"
                    name="password"
                    :error="errorMessage"
                    :disabled="isLoading"
                    v-on="listeners"
                />
            </FieldError>
            <FieldError
                #default="{ errorMessage, listeners }"
                :validator="$v.passwordConfirmation"
                label="La contraseña"
            >
                <Input
                    v-model="passwordConfirmation"
                    type="password"
                    iconLeft="lock"
                    iconColor="primary"
                    placeholder="Confirmar contraseña"
                    name="passwordConfirmation"
                    :error="errorMessage"
                    :disabled="isLoading"
                    v-on="listeners"
                />
            </FieldError>
            <Button
                text="Enviar"
                type="submit"
                styleType="gradient"
                :loading="isLoading"
                :disabled="isLoading || $v.$invalid"
            />
        </form>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import auth from '@/api/auth'
// import { notificationsMixin } from '@/mixins'

export default {
    name: 'SetPasswordScreen',
    // mixins: [validationMixin, notificationsMixin],
    mixins: [validationMixin],
    validations () {
        return {
            password: { required, minLength: minLength(6) },
            passwordConfirmation: { required, sameAsPassword: sameAs('password') },
        }
    },
    data () {
        return {
            password: '',
            passwordConfirmation: '',
            // hash: null,
            // email: null,
            isLoading: false,
            // isChecking: true,
        }
    },
    computed: {
        token () {
            return this.$router.history.current.query.token
        },
    },
    methods: {
        async handleSubmit () {
            this.isLoading = true
            await auth.changePassword({
                token: this.token,
                password: this.password,
                repeatedPassword: this.passwordConfirmation,
            })
            this.$router.push({ name: 'Signin' })
        },
    },
}
</script>
<style scoped lang="scss">

.SetPassword {
    width: 100%;
    min-height: 100vh;
    background: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    form {
      width: 300px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

        .Title {
            color: white;
            line-height: 40px;
        }
		.FieldError {
			margin-top: 20px;
			& + .FieldError {
				margin-top: 40px;
			}
		}
        .Button {
            margin-top: 50px;
            width: 100%;
            &:hover {
                background: $white;
                color: #333;
            }
         }
   }
}
</style>
