<template>
    <div class="ProfileInput">
        <p
            v-if="title"
            class="Title"
        >
            {{ title }}
        </p>
        <div class="Grid">
            <div
                v-for="{ key, label, icon, type, permissions } in inputFields"
                :key="key"
                v-permissions="{permissions}"
                class="Field"
                :class="key"
            >
                <p class="Label">
                    {{ label }}
                </p>
                <FieldError
                    #default="{ errorMessage, listeners }"
                    :validator="$v.mutableProfile[key]"
                    :label="label"
                >
                    <Input
                        v-model="mutableProfile[key]"
                        :type="type"
                        :iconLeft="icon"
                        color="grayDark"
                        iconColor="primary"
                        :placeholder="label"
                        :name="key"
                        :error="errorMessage"
                        v-on="listeners"
                    />
                </FieldError>
            </div>
            <PillSelectorInput
                v-model="mutableProfile.defensivePositionsIds"
                v-permissions="{permissions: [constants.USER_ROLE.ADMIN, constants.USER_ROLE.COACH]}"
                label="Posiciones en defensiva"
                :options="defensivePositions"
                multiple
                border="gray"
                class="defensivePositions"
                @input="handleDefensivePositions"
            />
            <PillSelectorInput
                v-model="mutableProfile.offensivePositionsIds"
                v-permissions="{permissions: [constants.USER_ROLE.ADMIN, constants.USER_ROLE.COACH]}"
                label="Posiciones de pateo"
                :options="offensivePositions"
                useKeysAsLabels
                multiple
                border="gray"
                class="offensivePositions"
                @input="handleOffensivePositions"
            />
            <PillSelectorInput
                v-if="!isCreationMode"
                v-model="mutableProfile.status"
                v-permissions="{permissions: [constants.USER_ROLE.ADMIN, constants.USER_ROLE.COMITE]}"
                label="Status"
                :options="statuses"
                border="gray"
                class="status"
                @input="handleStatus"
            />
        </div>
        <Button
            class="Submit"
            type="submit"
            text="GUARDAR"
            styleType="gradient"
            iconLeft="save"
            :isLoading="isLoading"
            :disabled="$v.$invalid"
            @click="handleSubmit"
        />
    </div>
</template>

<script>
import { statuses, profileDefaultValue, inputFields } from '@/entities/profile'
import constants from '@/entities/constants'
import positionsIntegration from '@/integration/positionsIntegration'
import VueTypes from 'vue-types'
import { mediaQueryMixin } from '@/mixins'
import { clone } from 'lodash'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
// import { validPhone } from '@/utils/validations'

export default {
    name: 'ProfileInput',
    mixins: [mediaQueryMixin, validationMixin],
    validations () {
        return {
            mutableProfile: {
                name: { required },
                alias: { required },
                // phoneNumber: this.isCreationMode ? { validPhone } : {},
                // email: this.isCreationMode ? { email } : {},
            },
        }
    },
    props: {
        title: VueTypes.string,
        profile: VueTypes.object,
        isCreationMode: VueTypes.bool.def(true),
        isLoading: VueTypes.bool.def(false),
    },
    data () {
        return {
            statuses,
            mutableProfile: profileDefaultValue,
            defensivePositions: [],
            offensivePositions: [],
            inputFields: this.isCreationMode ? inputFields : inputFields.filter(i => i.key !== 'email' && i.key !== 'phoneNumber'),
            constants,
        }
    },
    computed: {
        isMobile () {
            return this.$mq.w < this.$mq.lg.max
        },
    },
    async beforeMount () {
        await this.listPositions()
    },
    mounted () {
        this.mutableProfile = clone(this.profile) || profileDefaultValue
    },
    methods: {
        handleSubmit () {
            this.$emit('submit', this.mutableProfile)
        },
        handleDefensivePositions (ids) {
            this.mutableProfile.defensivePositionsIds = ids
        },
        handleOffensivePositions (ids) {
            this.mutableProfile.offensivePositionsIds = ids
        },
        handleStatus (ids) {
            this.mutableProfile.status = ids
        },
        async listPositions () {
            const positions = await positionsIntegration.getPositionsByType()
            this.defensivePositions = positions.defensivePositions
            this.offensivePositions = positions.offensivePositions
        },
    },
}
</script>
<style lang="scss" scoped>

.ProfileInput {
   display: flex;
   flex-direction: column;

   .Title {
      font-size: 26px;
      color: $gray-dark;
      margin-bottom: 40px;
      font-weight: bold;
   }
   .Grid {
      display: grid;
      grid-column-gap: 16px;
      grid-row-gap: 16px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: auto;
      grid-template-areas:
         "name name shortName alias"
         "phoneNumber phoneNumber email email"
         "birthdate birthdate startDate startDate"
         "defensivePositions defensivePositions defensivePositions defensivePositions"
         "offensivePositions offensivePositions offensivePositions offensivePositions"
		"status status imagePath imagePath";

      @media screen and (max-width: $lg) {
         grid-template-areas:
         "name name name name"
         "shortName shortName shortName shortName"
         "alias alias alias alias"
         "phoneNumber phoneNumber email email"
         "birthdate birthdate startDate startDate"
         "defensivePositions defensivePositions defensivePositions defensivePositions"
         "offensivePositions offensivePositions offensivePositions offensivePositions"
         "status status imagePath imagePath";
      }
      @media screen and (max-width: $sm) {
         grid-template-areas:
            "name"
            "shortName"
            "alias"
            "phoneNumber"
            "email"
            "birthdate"
            "startDate"
            "defensivePositions"
            "offensivePositions"
			"status"
			"imagePath";
         grid-template-columns: 1fr;
      }
   }
   .name { grid-area: name }
   .shortName { grid-area: shortName }
   .alias { grid-area: alias }
   .phoneNumber { grid-area: phoneNumber }
   .email { grid-area: email }
   .birthdate { grid-area: birthdate }
   .startDate { grid-area: startDate }
   .defensivePositions { grid-area: defensivePositions }
   .offensivePositions { grid-area: offensivePositions }
   .status { grid-area: status }
   .imagePath { grid-area: imagePath }

    .Input {
      margin-top: 8px;
   }
   .Label {
      color: $gray-01;
      font-size: 14px;
      margin-left: 16px;
   }
   .PillSelectorInput::v-deep .Label {
      color: $gray-01;
      font-size: 14px;
      margin-left: 16px;
      font-weight: normal;
   }
   .Button {
      width: fit-content;
      align-self: flex-end;
      font-size: 16px;
      margin-top: 20px;
      @media screen and (max-width: $sm) {
         font-size: 14px;
      }
   }
}
.InputWrapper {
	padding: 5px;
	border: 1px solid $gray-05;
}
</style>
