<template>
    <div
        v-if="visible"
        :class="spinnerClass"
        class="Spinner"
    />
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'Spinner',
    props: {
        color: VueTypes.oneOf(['primary', 'secondary', 'white', 'grayMedium']).def('primary'),
        noMargin: VueTypes.bool.def(false),
        small: VueTypes.bool.def(false),
        visible: VueTypes.bool.def(false),
    },
    computed: {
        spinnerClass () {
            return [
                this.color,
                {
                    small: this.small,
                    noMargin: this.noMargin,
                },
            ]
        },
    },
}
</script>

<style scoped lang="scss">
@import '@/theme/_variables.scss';
.Spinner {
    margin: 60px auto;
    font-size: 6px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    &,
    &:after {
        border-radius: 50%;
        width: 10em;
        height: 10em;
    }

    text-align: center;
    &.noMargin {
        margin: 0;
    }
    &.small {
        font-size: 2px;
    }

    &:before,
    &:after {
        position: absolute;
        content: '';
        border-radius: 50%;
    }

    &.primary {
        border-left: 1.2em solid $gray-light;
        border-top: 1.1em solid $primary;
        border-right: 1.1em solid $primary;
        border-bottom: 1.1em solid $primary;
    }

    &.secondary {
        border-left: 1.2em solid $gray-light;
        border-top: 1.1em solid $secondary;
        border-right: 1.1em solid $secondary;
        border-bottom: 1.1em solid $secondary;
    }

    &.white {
        border-left: 1.2em solid $white;
        border-top: 1.1em solid $gray-medium;
        border-right: 1.1em solid $gray-medium;
        border-bottom: 1.1em solid $gray-medium;
	}

	&.grayMedium {
		border-left: 1.2em solid $gray-medium;
        border-top: 1.1em solid $gray-01;
        border-right: 1.1em solid $gray-01;
        border-bottom: 1.1em solid $gray-01;
	}
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
