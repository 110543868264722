import stats from '../api/stats'

export default {
    getEvents: async () => {
        const events = await stats.getStatsEvents()

        return _.map(events, (ev) => {
            return {
                label: ev,
                key: ev.replaceAll(' ', '-'),
                isTotal: ev.includes('Total')
            }
        })
    },

    getStatsForEvent: async (eventName) => {
        const kicking = await stats.getKickingStatsForEvent(eventName)
        const fielding = await stats.getFieldingStatsForEvent(eventName)
        const pitching = await stats.getPitchingStatsForEvent(eventName)
        
        return {
            kicking,
            fielding,
            pitching,
        }
    },
}
