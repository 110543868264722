export const TABS = {
    INFORMATION: 'INFORMATION',
    GENERATOR: 'GENERATOR',

}

export const actionTabsKeys = {
    READ: 'READ',
    EDIT: 'EDIT',
    DUPLICATE: 'DUPLICATE',
    COMPARE: 'COMPARE',
}

export const actionTabs = {
    READ: {
        key: 'read',
        label: 'Ver',
        slug: 'ver',
        icon: 'eye',
    },
    EDIT: {
        key: 'edit',
        label: 'Editar',
        slug: 'edit',
        icon: 'edit',
    },
    DUPLICATE: {
        key: 'duplicate',
        label: 'Duplicar',
        slug: 'duplicate',
        icon: 'plus-square',
    },
    COMPARE: {
        key: 'compare',
        label: 'Comparar',
        slug: 'compare',
        icon: 'copy',
    },
}
