<template>
    <g>
        <circle
            cx="0"
            cy="0"
            :r="radius"
            :stroke-width="strokeWidth"
            :stroke="strokeColor"
            :stroke-dasharray="perimeter"
            :stroke-dashoffset="strokeDashoffset"
            :transform="`rotate(${rotateAngle})`"
        />
    </g>
</template>

<script>
import VueTypes from 'vue-types'
import { colors } from '@/theme/colors'

export default {
    name: 'SvgCircleArc',
    props: {
        from: VueTypes.number.isRequired,
        length: VueTypes.number.isRequired,
        color: VueTypes.string.isRequired,
        radius: VueTypes.number.isRequired,
        strokeWidth: VueTypes.number.isRequired,
        show: VueTypes.bool.isRequired,
    },
    computed: {
        strokeColor () {
            return colors[this.color] || this.color
        },
        rotateAngle () {
            return (this.show ? this.from * 360 : 0) - 90
        },
        perimeter () {
            return 2 * Math.PI * this.radius
        },
        strokeDashoffset () {
            return this.perimeter * (1 - (this.show ? this.length : 0))
        },
    },
}
</script>

<style scoped lang="scss">
circle {
	fill: none;
	transition: all 1s;
	stroke-linecap: round;
}
</style>
