<template>
    <div class="Legend">
        <p
            v-if="title"
            class="Title"
        >
            {{ title }}
        </p>
        <div
            class="ItemsWrapper"
            :style="{
                'padding-bottom': darkMode ? '12px' : '0px',
                'background': darkMode ? '#f5f5f5' : 'white'
            }"
        >
            <div
                v-for="({ icon, color, label, abbrev }, key) in legend"
                :key="key"
                class="Item"
                :style="{ 'background': darkMode ? 'white' : '' }"
                @click="$emit('clickOn', key)"
            >
                <Icon
                    v-if="icon"
                    :icon="icon"
                    :color="color"
                    :size="16"
                />
                <p
                    v-if="abbrev"
                    class="Abbrev"
                    :style="{ color: findColor(color) }"
                >
                    {{ abbrev }}
                </p>
                <p
                    class="ItemText"
                >
                    {{ label }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { colors } from '@/theme/colors'

export default {
    name: 'Legend',
    props: {
        legend: VueTypes.oneOfType([Array, Object]),
        title: VueTypes.string,
        darkMode: VueTypes.bool.def(false),
    },
    methods: {
        findColor (color) {
            return colors[color] || color
        },
    },
}
</script>

<style lang="scss" scoped>

.Legend {
	width: 100%;
	.Title {
		font-weight: bold;
		color: $gray-dark;
		margin-bottom: 10px;
	}
	.ItemsWrapper {
		display: flex;
		flex-wrap: wrap;
		border-radius: 5px;
		.Item {
			display: flex;
			align-items: center;
			background: rgba($gray-07, .5);
			border-radius: 20px;
			padding: 5px 15px 5px 8px;
			margin-top: 12px;
			font-size: 14px;
			margin-left: 10px;
			.Icon {
				padding-right: 10px;
			}
			.Abbrev {
				margin-right: 8px;
			}
		}
	}
}
</style>
