<template>
    <div class="LineupScreen">
        <h1>Lineup</h1>
        <div class="ScreenHeader">
            <Select
                :disabled="activeTab === TABS.GENERATOR"
                name="Eventos"
                placeholder="Selecciona un partido"
                noResultText="No se han encontrado partidos."
                :options="events"
                :value="selectedEvent"
                searchable
                useDateProp
                @input="handleSelectEvent"
            />
            <Button
                v-if="activeTab !== TABS.GENERATOR && event && event.id"
                :text="isMobile ? '' : 'Crear Nuevo'"
                styleType="primary"
                size="regular"
                :iconLeft="isMobile ? 'plus': ''"
                @click.native="handleChangeTab(TABS.GENERATOR)"
            />
            <div
                v-else-if="activeTab === TABS.GENERATOR"
                class="HeaderGenerator"
            >
                <Button
                    :text="isMobile ? '' : 'Cerrar'"
                    :iconLeft="isMobile ? 'close': ''"
                    styleType="primary"
                    size="regular"
                    @click="handleChangeTab(TABS.INFORMATION)"
                />
            </div>
        </div>
        <div class="Content">
            <Transition
                name="main-router"
                mode="out-in"
            >
                <LineupGenerator
                    v-if="activeTab === TABS.GENERATOR"
                    :eventId="event && event.id"
                    @viewLineUp="handleViewLineup"
                />
                <LineupInformation
                    v-else
                    :eventId="event && event.id"
                />
            </Transition>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import eventIntegration from '@/integration/eventIntegration'
import attendanceConstants from '@/entities/attendance'
import utils from '@/utils/utils'
import { LineupGenerator, LineupInformation } from './LineupSections'
import constants from '@/entities/constants'
import { TABS } from '@/entities/lineUp'
import { mediaQueryMixin } from '@/mixins'

export default {
    name: 'LineupScreen',
    components: {
        LineupGenerator,
        LineupInformation,
    },
    mixins: [mediaQueryMixin],
    data () {
        return {
            TABS,
            activeTab: TABS.INFORMATION,
            events: [],
            event: null,
            filteredUsers: [],
            lineUp: [],
            lineUps: [],
            positions: {},
            offensivePosition: null,
            defensivePosition: null,
            attendanceConstants,
            constants,
            dateInfo: utils.getInitialDateInfo({ startYear: attendanceConstants.attendaceStartYear }),
            selectedEvent: '',
        }
    },
    computed: {
        isMobile () {
            return this.$mq.w < this.$mq.sm.min
        },
    },
    async mounted () {
        this.getEvents()
    },
    methods: {
        async getEvents () {
            const params = {
                type: constants.EVENT_TYPE.GAME,
                state: constants.EVENT_STATE.ACCEPTED,
            }
            const events = await eventIntegration.getEvents(params)
            this.events = events.map(e => {
                const event = e
                event.key = e.id
                event.label = e.name
                event.formattedName = e.name + ' - ' + moment.utc(event.date).locale('es').format('dddd L') +
                    ' - ' + moment.utc(event.date).local().locale('es').format('LT')
                return event
            })
            const { eventId } = this.$route.params || {}
            if (eventId) this.handleSelectEvent(Number(eventId))
        },
        handleSelectEvent (eventId) {
            this.selectedEvent = eventId
            this.event = this.events.find(e => e.id === eventId)
            this.handleChangeTab(TABS.INFORMATION)
        },
        handleChangeTab (value) {
            this.activeTab = value
            const { eventId } = this.$route.params || {}

            if (eventId !== (this.event || {}).id) {
                this.$router.push({
                    name: 'Lineups',
                    params: { eventId: (this.event || {}).id || null },
                }).catch(() => {})
            }
        },
        handleViewLineup () {
            this.$router.push({
                name: 'Lineups',
                params: { eventId: (this.event || {}).id || null },
            })
            this.handleChangeTab(TABS.INFORMATION)
        },
    },
}
</script>
<style lang="scss" scoped>
.LineupScreen {
	margin-bottom: 60px;
    .ScreenHeader {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;
		.Button {
			border-radius: 15px;
			@media screen and (min-width: $sm) {
				transform: scale(1);
				padding: 10px 18px;
			}
			@media screen and (max-width: $sm) {
				padding: 12px;
				border-radius: 50px;
			}
			&::v-deep {
				.Icon { margin: 0 }
			}
		}
		.HeaderGenerator {
			justify-content: flex-end;
		}
		h1 {
			@media screen and (max-width: $sm) {
				margin-bottom: 15px !important;
			}
		}
		.Select {
			flex: 1;
			margin-right: 30px;
			@media screen and (max-width: $sm) {
				margin-right: 10px;
			}
		}
	}
	.Content {
		margin-top: 30px;
	}
}
	.ButtonWrapper {
		display: flex;
		margin-top: 40px;
		border-radius: 15px;
		border: 1px solid $gray-04;
		width: fit-content;
		.Input {
			margin-left: 10px;
			&::v-deep input {
				border: none;
			}
		}
		.Button {
			background: transparent;
			transform: scale(.8);
			&:hover {
				color: $black;
			}
		}
	}
</style>
