import moment from 'moment'
import payment from '../api/payment'
import constants from '../entities/constants'

export default {
    async listUserPayments (dateInfo) {
        const from = moment.utc(dateInfo.selectedDate).startOf('year').format()
        const to = moment.utc(dateInfo.selectedDate).endOf('year').format()
        const paymentsByUser = await payment.listPayments({ from, to })
        const balancesByUser = await payment.listBalances()
        const months = moment.months()

        return paymentsByUser.items.map((user) => {
            const paymentMap = {}
            const userBalance = balancesByUser.items.find(u => u.id === user.id)
            for (const month of months) {
                const payment = user.userPayments
                    .filter(item => moment.utc(item.month).format('MMMM') === month)
                paymentMap[month] = (payment && payment.length && payment[0]) || {
                    state: constants.PAYMENT_STATE.OWES,
                }
            }

            return {
                id: user.id,
                name: user.name,
                shortName: user.shortName,
                payments: paymentMap,
                balance: userBalance && userBalance.balance,
            }
        })
    },
}
