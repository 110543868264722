export const fields = [
    {
        key: 1,
        name: 'Lago',
        city: 'Madrid',
        teamOwner: 'Espartanas',
        address: 'Paseo de puerta del angel, 13',
    },
    {
        key: 2,
        name: 'Príncipe Pío',
        city: 'Madrid',
        teamOwner: 'Espartanas',
        address: 'Calle Mozart',
    },
    {
        key: 3,
        name: 'Tijoco bajo',
        city: 'Tenerife',
        teamOwner: 'Dream Team',
        address: 'Calle manuel prieto medina',
    },
    {
        key: 4,
        name: 'Bellvitge',
        city: 'Barcelona',
        teamOwner: 'Amazonas',
        address: 'Campo de béisbol de Bellvitge',
    },
]
