<template>
    <div class="InventoryScreen">
        <div class="ScreenHeader">
            <h1>Inventario</h1>
            <PillSelectorInput
                v-model="activeTab"
                :options="tabs"
                border="gray"
                preventDeselect
                @input="handleChangeTab"
            />
        </div>
        <div class="Container">
            <Transition
                name="main-router"
                mode="out-in"
            >
                <InventoryMaterials
                    v-if="activeTab === 'materials'"
                    :teamMaterials="materials"
                />
                <InventoryUniforms
                    v-if="activeTab === 'uniforms'"
                    :teamUniforms="teamUniforms"
                />
            </Transition>
        </div>
    </div>
</template>

<script>
import {
    InventoryMaterials,
    InventoryUniforms,
} from './InventorySections'
import { tabs } from '@/entities/inventory'
import VueTypes from 'vue-types'
import { materials, teamUniforms } from '@/data/inventory'

export default {
    name: 'InventoryScreen',
    components: {
        InventoryMaterials,
        InventoryUniforms,
    },
    props: {
        me: VueTypes.object,
    },
    data () {
        return {
            tabs,
            materials,
            teamUniforms,
            activeTab: '',
        }
    },
    watch: {
        $route (to) {
            const { inventorySlug } = to.params
            this.activeTab = inventorySlug ? (this.tabs.find(t => t.slug === inventorySlug) || {}).key : this.tabs[0].key
        },
    },
    mounted () {
        const { inventorySlug } = this.$route.params || {}
        this.activeTab = inventorySlug ? (this.tabs.find(t => t.slug === inventorySlug) || {}).key : this.tabs[0].key
    },
    methods: {
        handleChangeTab (value) {
            const { inventorySlug } = this.$route.params || {}
            const selectedSlug = (this.tabs.find(t => t.key === value) || {}).slug
            if (inventorySlug !== selectedSlug) {
                this.$router.push({
                    name: 'Inventory',
                    params: { inventorySlug: selectedSlug },
                })
            }
        },
    },
}
</script>
<style lang="scss" scoped>

.ScreenHeader {
	width: 100%;
	display: flex;
	justify-content: space-between;
	.PillSelectorInput {
		width: fit-content !important;
	}
	@media screen and (max-width: $sm) {
		flex-direction: column;
		h1 {
			margin-bottom: 15px !important;
		}
		.PillSelectorInput {
			margin-bottom: 20px;
			align-self: flex-end;
		}
	}
}
</style>
