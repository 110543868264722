<template>
    <div class="Contact">
        <div class="Top">
            <div class="Header">
                <Icon
                    v-if="isEditMode === false"
                    v-permissions="{permissions: [constants.USER_ROLE.ADMIN,
                                                  constants.USER_ROLE.COMITE]}"
                    icon="edit"
                    :size="20"
                    @click="isEditMode = true"
                />
                <Icon
                    v-else
                    icon="close"
                    :size="20"
                    @click="isEditMode = false"
                />
            </div>
            <div v-if="isEditMode">
                <div class="EditableItemsWrapper">
                    <div class="EditableItem">
                        <p class="Label">
                            Teléfono
                        </p>
                        <FieldError
                            #default="{ errorMessage, listeners }"
                            :validator="$v.mutableProfile.phoneNumber"
                            label="Teléfono"
                        >
                            <Input
                                v-model="mutableProfile.phoneNumber"
                                type="number"
                                iconLeft="phone"
                                color="grayDark"
                                iconColor="primary"
                                name="phoneNumber"
                                :error="errorMessage"
                                v-on="listeners"
                            />
                        </FieldError>
                    </div>
                    <div class="EditableItem">
                        <p class="Label">
                            Email
                        </p>
                        <FieldError
                            #default="{ errorMessage, listeners }"
                            :validator="$v.mutableProfile.email"
                            label="Email"
                        >
                            <Input
                                v-model="mutableProfile.email"
                                type="text"
                                iconLeft="mail"
                                color="grayDark"
                                iconColor="primary"
                                name="email"
                                :error="errorMessage"
                                v-on="listeners"
                            />
                        </FieldError>
                    </div>
                </div>
                <div class="ButtonWrapper">
                    <Button
                        class="Submit"
                        type="submit"
                        text="GUARDAR"
                        styleType="gradient"
                        iconLeft="save"
                        :loading="isLoading"
                        :disabled="$v.mutableProfile.$invalid"
                        @click="handleEditPersonalInfo"
                    />
                </div>
            </div>
            <div v-else>
                <div class="Item">
                    <p class="Label">
                        Teléfono
                    </p>
                    <p
                        v-if="profile.phoneNumber"
                        class="Value"
                    >
                        {{ profile.phoneNumber }}
                    </p>
                    <p
                        v-else
                        class="Value"
                    >
                        No ha sido registrado.
                    </p>
                </div>
                <div class="Item">
                    <p class="Label">
                        Email
                    </p>
                    <p
                        v-if="profile.email"
                        class="Value"
                    >
                        {{ profile.email }}
                    </p>
                    <p
                        v-else
                        class="Value"
                    >
                        No ha sido registrado.
                    </p>
                </div>
            </div>
        </div>
        <div class="Bottom">
            <div
                v-if="((profile || {}).contacts || []).length"
                class="List"
            >
                <div class="ListHeader">
                    <p class="Name">
                        Contactos de emergencia
                    </p>
                    <p class="Relation">
                        Relación
                    </p>
                    <p class="Phone">
                        Teléfono
                    </p>
                </div>
                <div
                    v-for="(person, index) in profile.contacts"
                    :key="index"
                    class="ListItem"
                >
                    <Avatar
                        :size="{ height: 40, width: 40 }"
                        :iconSize="20"
                    />
                    <div class="Content">
                        <p class="Name">
                            {{ person.name }}
                        </p>
                        <p class="Relation">
                            {{ person.relation }}
                        </p>
                        <p class="Phone">
                            {{ person.phoneNumber }}
                        </p>
                    </div>
                    <p class="Action">
                        <Spinner
                            :visible="isLoadingId === person.id"
                            color="white"
                            small
                            noMargin
                        />
                        <Icon
                            v-if="isLoadingId !== person.id"
                            :size="15"
                            icon="close"
                            color="error"
                            @click="handleDeleteEmergencyContact(person.id)"
                        />
                    </p>
                </div>
            </div>

            <div
                v-else
                class="List"
            >
                <div class="ListHeader">
                    <p class="Name">
                        Contactos de emergencia
                    </p>
                </div>
                <div class="ListItem">
                    <div class="Content">
                        <p class="Name">
                            No se ha creado aún ningún registro.
                        </p>
                    </div>
                </div>
            </div>
            <div class="List">
                <p class="AddNew">
                    Agregar nuevo
                </p>
                <div class="InputItem">
                    <FieldError
                        #default="{ errorMessage, listeners }"
                        :validator="$v.newEmergencyContact.name"
                        label="Nombre"
                    >
                        <Input
                            v-model="(newEmergencyContact || {}).name"
                            type="text"
                            iconLeft="user"
                            color="grayDark"
                            iconColor="primary"
                            placeholder="Nombre"
                            name="name"
                            :error="errorMessage"
                            :disabled="isLoading || isLoadingContactForm"
                            v-on="listeners"
                        />
                    </FieldError>
                    <FieldError
                        #default="{ errorMessage, listeners }"
                        :validator="$v.newEmergencyContact.relation"
                        label="Relación"
                    >
                        <Input
                            v-model="(newEmergencyContact || {}).relation"
                            type="text"
                            iconLeft="link"
                            color="grayDark"
                            iconColor="primary"
                            placeholder="Relación (madre, amigo, pareja)"
                            name="relation"
                            :error="errorMessage"
                            :disabled="isLoading || isLoadingContactForm"
                            v-on="listeners"
                        />
                    </FieldError>
                    <FieldError
                        #default="{ errorMessage, listeners }"
                        :validator="$v.newEmergencyContact.phoneNumber"
                        label="Teléfono"
                    >
                        <Input
                            v-model="(newEmergencyContact || {}).phoneNumber"
                            type="number"
                            iconLeft="phone"
                            color="grayDark"
                            iconColor="primary"
                            placeholder="Teléfono"
                            name="phoneNumber"
                            :error="errorMessage"
                            :disabled="isLoading || isLoadingContactForm"
                            v-on="listeners"
                        />
                    </FieldError>
                    <Button
                        class="Submit"
                        type="submit"
                        text="Añadir"
                        styleType="gradient"
                        iconLeft="save"
                        :loading="isLoading || isLoadingContactForm"
                        :disabled="isLoading || isLoadingContactForm || $v.$invalid"
                        @click="handleAddEmergencyContact"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import constants from '@/entities/constants'
import { validationMixin } from 'vuelidate'
import { email, required } from 'vuelidate/lib/validators'
import { validPhone } from '@/utils/validations'

export default {
    name: 'Contact',
    mixins: [validationMixin],
    validations () {
        return {
            mutableProfile: {
                email: { email },
                phoneNumber: { validPhone },
            },
            newEmergencyContact: {
                name: { required },
                relation: { required },
                phoneNumber: { required, validPhone },
            },
        }
    },
    props: {
        profile: VueTypes.object,
        isLoading: VueTypes.bool.def(false),
    },
    data () {
        return {
            constants,
            isEditMode: false,
            isContactEditMode: false,
            mutableProfile: this.profile,
            newEmergencyContact: {
                name: '',
                relation: '',
                phoneNumber: undefined,
            },
            isLoadingContactForm: false,
            isLoadingId: undefined,
        }
    },
    watch: {
        profile () {
            this.resetEmergencyContactForm()
            this.isLoadingContactForm = false
            this.isLoadingId = undefined
        },
    },
    mounted () {
        this.mutableProfile = this.profile
    },
    methods: {
        handleEditPersonalInfo () {
            this.$emit('editPersonalInfo', this.mutableProfile)
            this.isEditMode = false
        },
        handleEditContactsInfo () {
            this.isContactEditMode = false
            // TODO: edit emergency contacts
        },
        handleAddEmergencyContact () {
            this.isLoadingContactForm = true
            this.$emit('addEmergencyContact', this.newEmergencyContact)
        },
        resetEmergencyContactForm () {
            this.newEmergencyContact = {
                name: '',
                relation: '',
                phoneNumber: undefined,
            }
            this.$v.newEmergencyContact.$reset()
        },
        handleDeleteEmergencyContact (id) {
            this.isLoadingId = id
            this.$emit('deleteEmergencyContact', id)
        },
    },
}
</script>
<style lang="scss" scoped>

.Contact {
	.Top {
		margin-bottom: 50px;
	}
	.Header {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		margin-bottom: 10px;
		.Icon {
			cursor: pointer;
			&:hover {
				color: $primary !important;
			}
		}
	}
	.Item {
		display: flex;
		border-bottom: 1px solid $gray-07;
		padding: 10px 0;
        min-height: 70px;
        align-items: center;
		overflow: hidden;
		.Label {
			color: $gray-02;
			text-transform: uppercase;
			font-size: 14px;
			margin-left: 15px;
			margin-right: 20px;
			min-width: 80px;
			@media screen and (max-width: $sm) {
				min-width: auto;
			}
		}
		.Value {
			color: #333;
			// word-break: break-all;
		}
	}
	.List {
		.ListHeader {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 10px 20px;
			margin-bottom: 10px;
			.Name {
				flex: 1;
				font-weight: bold;
				color: $gray-dark;
				font-size: 18px;
			}
			.Relation, .Phone {
				min-width: 100px;
				margin-left: 20px;
				@media screen and (max-width: $md) {
					display: none;
				}
			}
			.Phone {
				margin-right: 50px;
			}
		}
		.AddNew {
			margin: 40px 0 20px 80px;
			font-weight: 600;
			color: $gray-dark;
			@media screen and (max-width: $sm) {
				margin-left: 10px;
			}
		}
		.InputItem {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			min-height: 50px;
			margin-left: 70px;
			@media screen and (max-width: $sm) {
				flex-direction: column;
				align-items: flex-end;
				margin-left: 0px;
			}
			.FieldError {
				flex: 1;
				margin-right: 20px;
				min-width: 200px;
				@media screen and (max-width: $sm) {
					margin-right: 0px;
					width: 100%;
				}
			}
			.Button, .Input { margin-bottom: 40px }
		}
		.ListItem {
			display: flex;
			align-items: center;
			background: $gray-07;
			height: 50px;
			padding: 0 20px;
			border-radius: 1rem;
			position: relative;
			@media screen and (max-width: $sm) {
				height: auto;
				text-align: center;
				padding: 10px;
			}
			& + .ListItem {
				margin-top: 15px;
			}
			.Content {
				width: 100%;
				display: flex;
				margin: 10px 0;
				@media screen and (max-width: $sm) {
					flex-direction: column;
				}
			}
			.Name {
				flex: 1;
				@media screen and (max-width: $sm) {
					text-align: left;
					font-weight: 600;
				}
			}
			.Relation, .Phone {
				min-width: 100px;
				@media screen and (max-width: $md) {
					flex: 1;
					text-align: center;
				}
				@media screen and (max-width: $sm) {
					margin-top: 10px;
					text-align: left;
				}
			}
			.Name, .Relation, .Phone, .Action {
				margin-left: 20px;
			}
			.Action {
				min-width: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				@media screen and (max-width: $sm) {
					position: absolute;
					top: 10px;
					right: 5px;
				}
			}
		}
	}
	.EditableItemsWrapper {
		display: flex;
		@media screen and (max-width: $sm) {
			flex-direction: column;
		}

		.EditableItem {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			flex: 1;
			& + .EditableItem {
				margin-left: 20px;
				@media screen and (max-width: $sm) {
					margin-left: 0px;
					margin-top: 20px;
				}
			}
			.Label {
				color: #333;
				min-width: 100px;
				margin-bottom: 10px;
				margin-left: 16px;
			}
		}
	}
	.ButtonWrapper {
		display: flex;
		justify-content: flex-end;
		.Button {
			align-self: flex-end;
			font-size: 16px;
			margin-top: 35px;
			@media screen and (max-width: $sm) {
				font-size: 14px;
			}
		}
	}
}
</style>
