<template>
    <div class="TimePicker">
        <vc-date-picker
            v-model="selectedTime"
            mode="time"
            @input="$emit('input', selectedTime)"
        />
    </div>
</template>

<script>
// import VueTypes from 'vue-types'

export default {
    name: 'TimePicker',
    // props: {
    //     time: VueTypes.any,
    // },
    data () {
        return {
            selectedTime: undefined,
        }
    },
    watch: {
        time (value) {
            this.selectedTime = value
        },
    },
    mounted () {
        this.selectedTime = this.time
    },
}
</script>

<style scoped lang="scss">

.vc-container::v-deep {
   border: 1px solid $gray-05;
   border-radius: 50px;
   @media screen and (max-width: $md) {
        border-radius: 20px;
   }
   svg { display: none }
   .vc-date {
      display: none;
   }
   .vc-time {
      .vc-select select {
           padding: 7px;
           font-size: 14px;
           background: none;
           border: none;
           color: $gray-01;
           width: min-content;
      }
      .vc-am-pm {
         background-color: rgba($primary, 0.05);
         margin-right: 10px;
         button {
            background-color: rgba($primary, 0.05);
            color: $primary;
            border: none;
            &.active {
               background: $primary;
               color: $white;
            }
         }
      }
   }
}
</style>
