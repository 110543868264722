import Vue from 'vue'
import vuelidateErrorExtractor from 'vuelidate-error-extractor'

Vue.use(vuelidateErrorExtractor, {
    messages: {
        required: '{attribute} es obligatorio',
        minValue: '{attribute} tiene que ser mayor que {min}.',
        maxValue: '{attribute} tiene que ser menor que {max}.',
        minLength: '{attribute} tiene que ser mayor que {min}.',
        maxLength: '{attribute} tiene que ser menor que {max}.',
        between: 'Deber estar entre {min} y {max}.',
        email: 'Formato de email inválido.',
        sameAs: '{attribute} tiene que ser igual que {eq}.',
        sameAsPassword: 'Las contraseñas deben ser iguales',
        sameAsEmail: 'Los correos electrónicos deben ser iguales',
        validPhone: 'El formato teléfono debe ser válido',
    },
    // attributes: {
    //     email: 'Email',
    //     password: 'Contraseña',
    //     name: 'Nombre',
    // },
})
