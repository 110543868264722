import axios from 'axios'

export default {
    createUserContact: async (body) => {
        const response = await axios.post('/user-contacts', body)

        return response && response.data && response.data.response
    },

    deleteUserContact: async (id) => {
        const response = await axios.delete('/user-contacts/' + id)

        return response && response.data && response.data.response
    },

    getUserContact: async (id) => {
        const response = await axios.get('/user-contacts/' + id)

        return response && response.data && response.data.response
    },
}
