<template>
	<div class="KickMixScreen">
		<div class="ImageSection mad" :style="{ backgroundImage: `url(${require('@/assets/imgs/MadMix/fondo4.png')})` }">
			<div class="Title">
				<div class="Organization">
					<h2><span>Sorteo KickMix</span></h2>
				</div>
			</div>
		</div>

		<div class="PillSelectors">
			<div v-if="activeTab === 'raffle'">
				<p :style="{ marginBottom: '12px', marginLeft: '16px', marginTop: '20px', color: 'white' }">
					Sortear...
				</p>
				<div :style="{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexWrap: 'wrap' }">
					<Button v-for="(raffleTab, idx) in raffleTabs" :key="idx" type="submit" :text="raffleTab.label"
						styleType="white" size="sm" :iconLeft="raffleTab.icon" iconColor="grayDark"
						:disabled="isLoadingRaffle || raffleTab.disabled" :loading="raffleTab.loading"
						@click="handleRaffle(raffleTab)" />
				</div>
			</div>
		</div>

		<template v-if="activeTab === 'raffle'">
			<div class="ListItems All">
				<div v-for="(team, index) in newTeams" :key="`team-${index}`" class="ListItems">
					<div v-if="!team.length" class="EmptyMessage">
						<p class="Name">
							No hay datos aún
						</p>
					</div>
					<template v-else>
						<template v-for="(item, idx) in team">
							<template v-if="!(item || {}).positionId && !(item || {}).teamId && (item || {}).name">
								<div :key="`item-${idx}`" class="ListItem" :class="{ 'ListItemTeam': item.id }">
									<p class="TeamName">
										{{ item.name }}
									</p>
								</div>
							</template>
						</template>
						<template v-for="(item, idx) in team">
							<template v-if="(item || {}).positionId">
								<div :key="`item-${idx}`" class="ListItem" :class="{ 'ListItemTeam': item.id }">
									<p class="Name">
										{{ item.name }}
										<span class="NewTeamName">
											| {{ item.team }}
										</span>
									</p>

									<p class="Value">
										{{ defensivePositions.find(({ number }) => number === item.positionId)[isMobile ?
											'key' : 'label'] }}
									</p>
								</div>
							</template>
						</template>
					</template>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import { mediaQueryMixin } from '@/mixins'
import { teams, participants, names, players, managers } from '@/data/MadMix/ARG/kickmix'
import { defensivePositions } from '@/data/MadMix/general'
import playersRaffle from '@/utils/MadMix/kickMixPlayersRaffle'

export default {
	name: 'KickMixScreen',
	mixins: [mediaQueryMixin],
	data() {
		return {
			participants,
			names,
			players,
			newTeams: [],
			teamNames: [],
			managers,
			defensivePositions,
			activeTab: 'raffle',
			activeInscriptionsTab: 'players',
			activeRaffleTab: '',
			raffleTabs: [],
			isLoadingRaffle: false,
		}
	},
	computed: {
		isMobile() {
			return this.$mq.w < this.$mq.lg.min
		},
		updatedTeams() {
			let updatedTeams = []
			teams.slice(0, 6).forEach(team => {
				updatedTeams.push(this.findTeamStatus(team))
			})
			const teamsSortedByPoints = updatedTeams.sort((a, b) => {
				if (a.puntos < b.puntos) return 1
				else if (a.puntos === b.puntos) {
					if (a.diferencia < b.diferencia) return 1
					else return -1
				} else return -1
			})
			return teamsSortedByPoints
		},
	},
	mounted() {
		this.raffleTabs = [
			{
				key: 'players',
				label: 'Jugadoras',
				disabled: true,
				loading: false,
				icon: 'users',
				slug: 'players',
			},
			{
				key: 'names',
				label: 'Nombres',
				disabled: false,
				loading: false,
				icon: 'package',
				slug: 'names',
			},
		]
	},
	created() {
		this.teams = teams
	},
	methods: {
		goTo(link) {
			if (link) window.open(link, '_blank')
		},
		handleChangeTab(value) {
			this.activeTab = value
		},
		handleChangeInscriptionsTab(value) {
			this.activeInscriptionsTab = value
		},
		timer(ms) {
			return new Promise((resolve, reject) => setTimeout(resolve, ms))
		},
		async shuffleN() {
			const names = this.names
			for (const n of names) {
				this.teamNames.push(n.name)
				this.newTeams.push([n])
				await this.timer(500)
			}
			this.raffleTabs = this.raffleTabs.map(t => {
				const tCopy = t
				console.log(t)
				if (t.key === 'players') tCopy.disabled = false
				if (t.key === 'names') tCopy.disabled = true
				return tCopy
			})
		},
		async shuffleManagers() {
			const newManagers = playersRaffle.shuffleManagers(this.managers, this.teamNames)
			for (const t of this.newTeams) {
				const tCopy = t
				const team = newManagers.find(tt => tt.team === t[0].name)
				for (const m of team.managers) {
					tCopy.push(m)
					await this.timer(1000)
				}
			}
			this.raffleTabs = this.raffleTabs.map(t => {
				const tCopy = t
				if (t.key === 'players') tCopy.disabled = true
				if (t.key === 'names') tCopy.disabled = false
				return tCopy
			})
		},
		async shufflePlayers() {
			const newTeams = playersRaffle.shuffle(players, this.teamNames)
			console.log(newTeams)
			for (const t of this.newTeams) {
				const tCopy = t
				const team = newTeams.find(tt => tt.name === t[0].name)
				const players = team.players.sort((a, b) => {
					if (a.positionId > b.positionId) return 1
					if (a.positionId < b.positionId) return -1
					return 0
				})
				for (const p of players) {
					tCopy.push(p)
					await this.timer(1500)
				}
			}
			this.raffleTabs = this.raffleTabs.map(t => {
				const tCopy = t
				if (t.key === 'players') tCopy.disabled = true
				return tCopy
			})
		},
		async handleRaffle(value) {
			this.isLoadingRaffle = true
			// const restOfTabs = this.raffleTabs.filter(t => t.key !== value.key)
			this.raffleTabs = this.raffleTabs.map(t => {
				const tCopy = t
				if (t.key === value.key) tCopy.loading = true
				return tCopy
			})

			if (value.key === 'players') {
				await this.shufflePlayers()
				// } else if (value.key === 'managers') {
				//     await this.shuffleManagers()
			} else if (value.key === 'names') {
				await this.shuffleN()
			}

			this.isLoadingRaffle = false
			this.raffleTabs = this.raffleTabs.map(t => {
				const tCopy = t
				if (t.key === value.key) tCopy.loading = false
				return tCopy
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.KickMixScreen {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: white;
	margin-bottom: 4rem;
}

.ImageSection {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	height: 15rem;
	width: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position-x: center;
	background-position-y: center;

	@media screen and (max-width: $sm) {
		height: 12rem;
	}
}

.mad {
	min-height: 5rem;

	@media screen and (max-width: $sm) {
		// min-height: 28rem;
	}
}

.Title {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 8rem;

	@media screen and (max-width: $sm) {
		margin: 100px 0 50px 0;
	}

	&.down {
		margin-top: 80px;

		@media screen and (max-width: $sm) {
			margin: 60px 0 10px 0;
		}
	}

	h2 {
		text-align: center;
		font-weight: $font-weight-bold;
		font-size: 4rem;
		line-height: 5rem;
		color: $white;

		@media screen and (max-width: $sm) {
			font-size: 3rem;
		}
	}
}

.Organization {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: auto;
		height: 220px;
	}
}

.PillSelectors {
	margin-top: 1rem;
	margin-bottom: 2rem;
	padding-bottom: 2rem;
	border-bottom: 1px solid white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 90%;

	@media screen and (min-width: $lg) {
		width: 50%;
	}

	@media screen and (min-width: $md) {
		flex-direction: row;
		justify-content: space-between;
	}
}

.ListItems {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 15px;
	width: 90%;

	@media screen and (min-width: $lg) {
		width: 50%;
	}

	@media screen and (min-width: $sm) {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 20px;
	}

	@media screen and (min-width: $xl) {
		grid-template-columns: repeat(3, 1fr);
	}

	&.names {
		grid-template-columns: repeat(2, 1fr);

		@media screen and (min-width: $sm) {
			grid-template-columns: repeat(3, 1fr);
		}

		@media screen and (min-width: $xl) {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	// &.Players {}
	&.All {
		grid-template-columns: 1fr;
		grid-gap: 25px;
		// @media screen and (min-width: $lg) {
		// 	grid-template-columns: repeat(2, 1fr);
		// }
		// @media screen and (min-width: $sm) {
		// 	grid-template-columns: repeat(2, 1fr);
		// }
		// @media screen and (min-width: $xl) {
		// 	grid-template-columns: repeat(2, 1fr);
		// }

		& .ListItems {
			width: 100%;
			grid-template-columns: 1fr;
			border: 2px solid rgba($white, .1);
			border-radius: 20px;
			padding: 20px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			@media screen and (min-width: $lg) {
				grid-template-columns: 1fr;
				width: 100%;
			}

			@media screen and (min-width: $sm) {
				grid-template-columns: 1fr;
			}

			@media screen and (min-width: $xl) {
				grid-template-columns: repeat(2, 1fr);
			}

			.EmptyMessage {
				display: flex;
				align-items: center;
				justify-content: center;

				.Name {
					color: rgba($white, .5);
				}
			}
		}
	}

	.ListItem {
		display: flex;
		align-items: center;
		height: 45px;
		background: rgba($white, .1);
		padding: 8px 16px;
		border-radius: 10px;
		width: 100%;

		@media screen and (max-width: $sm) {
			padding: 8px;
		}

		.Avatar {
			margin-right: 15px;
			min-width: 15px;
			display: flex;
			justify-content: center;
			align-items: center;

			@media screen and (max-width: $sm) {
				margin-right: 10px;
				min-width: 15px;
			}
		}

		&.ListItemTeam {
			background: none;
			padding: 0;
			height: fit-content
		}

		.TeamName,
		.Name,
		.NewTeamName {
			color: $white;
			flex: 1;
			font-weight: bold;

			@media screen and (max-width: $md) {
				font-size: 14px;
			}
		}

		.NewTeamName {
			font-size: 12px;
			opacity: .8;
			text-transform: uppercase;
		}

		.TeamName {
			font-size: 22px;
			font-weight: bold;
		}

		.Value {
			// min-width: 45px;
			background: white;
			border-radius: 10px;
			padding: 4px 8px;
			white-space: nowrap;
			font-size: 12px;
			font-weight: bold;

			@media screen and (max-width: $sm) {
				// min-width: 28px;
				font-size: 12px;
			}
		}

	}
}
</style>
