<template>
    <div class="ForgotPasswordScreen">
        <form @submit.prevent="handleSubmit">
            <h1 class="Title">
                ¿Has olvidado tu contraseña?
            </h1>
            <p>
                Te enviaremos un enlace para restablecer tu contraseña.
            </p>
            <FieldError
                #default="{ errorMessage, listeners }"
                :validator="$v.email"
                label="Email"
            >
                <Input
                    v-model="email"
                    name="email"
                    placeholder="correo electrónico"
                    type="text"
                    iconLeft="user"
                    iconColor="primary"
                    :error="errorMessage"
                    :disabled="isLoading"
                    v-on="listeners"
                />
            </FieldError>
            <Button
                text="Enviar enlace"
                type="submit"
                styleType="gradient"
                :loading="isLoading"
                :disabled="isLoading || $v.$invalid"
            />
        </form>
    </div>
</template>

<script>
import auth from '@/api/auth'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

export default {
    name: 'ForgotPasswordScreen',
    mixins: [validationMixin],
    validations () {
        return {
            email: { required, email },
        }
    },
    data () {
        return {
            isLoading: false,
            email: '',
        }
    },
    methods: {
        async handleSubmit () {
            // TODO: comment on build
            this.isLoading = true
            await auth.requestPasswordRecovery({ email: this.email })
            this.$router.push({ name: 'Signin' })
        },
    },
}
</script>
<style lang="scss" scoped>

.ForgotPasswordScreen {
    width: 100%;
    min-height: 100vh;
    background: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    form {
      width: 300px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

        .Title {
            color: white;
            line-height: 40px;
        }
        p {
           line-height: 24px;
           color: white;
        }
        .Input {
            margin-top: 50px;
        }
        .Button {
            margin-top: 40px;
            width: 100%;
            &:hover {
               background: $white;
               color: #333;
            }
         }
   }
}
</style>
