export const teams = {
    TENERIFE: [
        {
            name: 'Panteras',
            createdAt: '2011',
            link: 'https://www.instagram.com/clubpanteraskkb/',
        },
        {
            name: 'Relámpago',
            createdAt: '2013',
            link: 'https://www.instagram.com/cdrelampago_kickingball/',
        },
        {
            name: 'Sox',
            createdAt: '2014',
            link: 'https://www.instagram.com/soxkickingball/',
        },
        {
            name: 'DreamTeam',
            createdAt: '2015',
            link: 'https://www.instagram.com/dreamteamtenerife/',
        },
        {
            name: 'Deportivo',
            createdAt: '2021',
            link: 'https://www.instagram.com/deportivotenerifekickingball/',
        },
    ],
    MADRID: [
        {
            name: 'Espartanas',
            createdAt: '2015',
            link: 'https://www.instagram.com/espartanaskickingball/',
        },
        {
            name: 'Panteras',
            createdAt: '2017',
            link: 'https://www.instagram.com/kickingball_panterasdemadrid/',
        },
        {
            name: 'Murallas',
            createdAt: '2017',
            link: 'https://www.instagram.com/murallasdemadridkickingball/',
        },
        {
            name: 'Tropicales',
            createdAt: '2019',
            link: 'https://www.instagram.com/tropicaleskickingballmadrid/',
        },
        {
            name: 'Vikingas',
            createdAt: '2020',
            link: 'https://www.instagram.com/vikingaskickingballmadrid/',
        },
        {
            name: 'Las Panas',
            createdAt: '2020',
            link: 'https://www.instagram.com/laspanasdevenezuelakickingball/',
        },
        {
            name: 'Semillitas',
            createdAt: '2020 - Infantil',
            link: 'https://www.instagram.com/laspanasdevenezuelakickingball/',
        },
        {
            name: 'Águilas',
            createdAt: '2020',
            link: 'https://www.instagram.com/aguilaskickingballmadrid/',
        },
        {
            name: 'Gladiadoras',
            createdAt: '2023',
            link: 'https://www.instagram.com/gladiadoraskickingballmadrid/',
        },
        {
            name: 'Goodies',
            createdAt: '2023',
            link: 'https://www.instagram.com/goodies_kickingball/',
        },
        // {
        //     name: 'Ateneas',
        //     createdAt: '2021',
        //     link: 'https://www.instagram.com/ateneasdemadridkickingball/',
        // },
    ],
    BARCELONA: [
        {
            name: 'Amazonas',
            createdAt: '2016',
            link: 'https://www.instagram.com/kickingball_amazonas/',
        },
        {
            name: 'Valkirias',
            createdAt: '2020',
            link: 'https://www.instagram.com/valkirias_kickingball/',
        },
        {
            name: 'Las Panas Bcn',
            createdAt: '2022',
            link: 'https://www.instagram.com/laspanasbcn_kickingball/',
        },
    ],
    // BILBAO: [
    //     {
    //         name: 'Guerreras',
    //         createdAt: '2018',
    //         link: 'https://www.instagram.com/guerreraskickingball/',
    //     },
    //     {
    //         name: 'Ateneas',
    //         createdAt: '2018',
    //         link: 'https://www.instagram.com/ateneas_kickingball/',
    //     },
    // ],
    // VALENCIA: [
    //     {
    //         name: 'Turpiales',
    //         createdAt: '2020',
    //         link: 'https://www.instagram.com/turpialesturiakickingball/',
    //     },
    // ],
    // MALAGA: [
    //     {
    //         name: 'Las Makinas',
    //         createdAt: '2021',
    //         link: '',
    //     },
    // ],
}

export const allTeams = [
    {
        key: 1,
        name: 'Panteras',
        city: 'Tenerife',
        email: '',
        createdAt: '2011',
        instagramLink: '/clubpanteraskkb',
        contact: '',
    },
    {
        key: 2,
        name: 'Relámpago',
        city: 'Tenerife',
        email: 'clubrelampago.info@gmail.com',
        createdAt: '2013',
        instagramLink: '/cdrelampago_kickingball',
        contact: 'Gloria - 618695105',
    },
    {
        key: 3,
        name: 'Sox',
        city: 'Tenerife',
        email: '',
        createdAt: '2014',
        instagramLink: '/soxkickingball',
        contact: '',
    },
    {
        key: 4,
        name: 'Dream Team',
        city: 'Tenerife - Tijoco',
        email: '',
        createdAt: '2015',
        instagramLink: '/dreamteamtenerife',
        contact: 'Yonni Castillo - 634234931',
    },
    {
        key: 5,
        name: 'Espartanas',
        city: 'Madrid',
        email: '',
        createdAt: 'Julio 2015',
        instagramLink: '/espartanaskickingball',
        contact: '',
    },
    {
        key: 6,
        name: 'Panteras',
        city: 'Madrid',
        email: 'clubdeportivokickingballmadrid@gmail.com',
        createdAt: 'Abril 2017',
        instagramLink: '/kickingball_panterasdemadrid',
        contact: 'Adriana Ferrer - 612561878',
    },
    {
        key: 7,
        name: 'Murallas',
        city: 'Madrid',
        email: 'teammurallas@gmail.com',
        createdAt: 'Julio 2017',
        instagramLink: '/teammurallas',
        contact: 'Juan Carlos - 677283306',
    },
    {
        key: 8,
        name: 'Tropicales',
        city: 'Madrid',
        email: 'tropicaleskickingmadrid@gmail.com',
        createdAt: 'Febrero 2019',
        instagramLink: '/tropicaleskickingmadrid',
        contact: 'Analys - 671297858',
    },
    {
        key: 9,
        name: 'Vikingas',
        city: 'Madrid',
        email: 'vikingaskickingball@gmail.com',
        createdAt: 'Diciembre 2020',
        instagramLink: '/vikingaskickingballmadrid',
        contact: '',
    },
    {
        key: 10,
        name: 'Las Panas',
        city: 'Madrid',
        email: '',
        createdAt: 'Enero 2020',
        instagramLink: '/laspanasdevenezuelakickingball',
        contact: '',
    },
    {
        key: 11,
        name: 'Semillitas',
        city: 'Madrid',
        email: '',
        createdAt: 'Julio 2020',
        instagramLink: '/laspanasdevenezuelakickingball',
        contact: '',
    },
    {
        key: 12,
        name: 'Águilas',
        city: 'Madrid',
        email: '',
        createdAt: 'Noviembre 2020',
        instagramLink: '/aguilaskickingballmadrid',
        contact: '',
    },
    {
        key: 13,
        name: 'Amazonas',
        city: 'Barcelona',
        email: 'kickingballbarcelona@gmail.com',
        createdAt: 'Noviembre 2016',
        instagramLink: '/kickingball_amazonas',
        contact: 'Andrea - 677185533',
    },
    {
        key: 14,
        name: 'Valkirias',
        city: 'Barcelona',
        email: '',
        createdAt: 'Septiembre 2020',
        instagramLink: '/valkirias_kickingball',
        contact: '',
    },
    {
        key: 15,
        name: 'Guerreras',
        city: 'Bilbao',
        email: 'kickingballbilbao@gmail.com',
        createdAt: 'Agosto 2018',
        instagramLink: '/guerreraskickingball',
        contact: '611448740',
    },
    {
        key: 16,
        name: 'Ateneas',
        city: 'Bilbao',
        email: 'ateneasdeeuskalherria@gmail.com',
        createdAt: 'Enero 2019',
        instagramLink: '/ateneas_kickingball',
        contact: 'Lucia - 673186984',
    },
    {
        key: 17,
        name: 'Turpiales',
        city: 'Valencia',
        email: '',
        createdAt: 'Septiembre 2020',
        instagramLink: '/turpialesturiakickingball',
        contact: '',
    },
    {
        key: 18,
        name: 'Las Makinas',
        city: 'Málaga',
        email: '',
        createdAt: '2021',
        instagramLink: '',
        contact: '',
    },
    {
        key: 19,
        name: 'Alberto Requena',
        city: 'Madrid',
        email: 'trainer.requena@gmail.com',
        createdAt: '',
        instagramLink: '/trainer.requena',
        contact: '',
    },
]
