<template>
    <div class="Payments">
        <div class="Header">
            <Icon
                v-if="isEditMode === false"
                v-permissions="{permissions: [constants.USER_ROLE.ADMIN,
                                              constants.USER_ROLE.COMITE]}"
                icon="edit"
                :size="20"
                @click="isEditMode = true"
            />
            <Icon
                v-else
                icon="close"
                :size="20"
                @click="isEditMode = false"
            />
        </div>
        <PaymentCalendar
            :paymentInformation="paymentInformation"
            :isEditable="isEditMode"
            :isLoading="isLoading"
            @changePaymentYear="handleEvent"
            @submit="handleSubmit"
        />
        <Legend
            :legend="paymentStates"
            title="Estados de pago"
        />
    </div>
</template>

<script>
import PaymentCalendar from '../../PaymentCalendar/PaymentCalendar.vue'
import VueTypes from 'vue-types'
import paymentsStates from '@/entities/payments'
import constants from '@/entities/constants'

export default {
    name: 'Payments',
    components: {
        PaymentCalendar,
    },
    props: {
        paymentInformation: VueTypes.object,
        isLoading: VueTypes.bool.def(false),
    },
    data () {
        return {
            constants,
            isEditMode: false,
            paymentStates: paymentsStates.paymentsStates,
        }
    },
    methods: {
        handleEvent (eventData) {
            this.$emit('changePaymentYear', eventData)
        },
        handleSubmit (paymentsInfo) {
            this.$emit('updatePayments', paymentsInfo)
        },
    },
}
</script>
<style lang="scss" scoped>
.Payments {
	.Header {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		margin-bottom: 30px;
		.Icon {
			cursor: pointer;
			&:hover {
				color: $primary !important;
			}
		}
	}
	.Legend {
		margin-top: 30px;
	}

}
</style>
