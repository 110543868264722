import Vue from 'vue'
import VCalendar from 'v-calendar'
// import 'v-calendar/lib/v-calendar.min.css'

Vue.use(VCalendar, {
    componentPrefix: 'vc', // Use <vc-calendar /> instead of <v-calendar />
    firstDayOfWeek: 2, // Monday
    // locale: 'es',
    // Formats: https://vcalendar.io/guide/#formatting-parsing-dates
    // weekdays: 'WWW',

})
