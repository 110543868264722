<template>
    <Transition
        name="modal"
        :duration="500"
    >
        <div
            v-if="isOpen"
            class="Modal"
        >
            <div class="Dialog">
                <div class="Header">
                    <Icon
                        icon="close"
                        class="Close"
                        @click="$emit('close')"
                    />
                </div>
                <div
                    v-if="$slots.body"
                    class="Body"
                >
                    <slot name="body" />
                </div>
                <div
                    v-if="$slots.footer"
                    class="Footer"
                >
                    <slot name="footer" />
                </div>
            </div>
            <div
                class="Backdrop"
                @click="$emit('close')"
            />
        </div>
    </Transition>
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'Modal',
    props: {
        isOpen: VueTypes.bool.def(true),
    },
    watch: {
        isOpen (val) {
            if (val) document.body.classList.add('no-scroll')
            else document.body.classList.remove('no-scroll')
        },
    },
    beforeDestroy () {
        document.body.classList.remove('no-scroll')
    },
}
</script>

<style scoped lang="scss">

.Modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    z-index: 9996;
}

.Backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($black, 0.5);
    z-index: 9997;
}
.Dialog {
    position: relative;
    width: auto;
    max-width: 100%;
    margin: 50px auto;
    border-radius: 20px;
    background: $white;
    box-shadow:  0 2px 5px 0 rgba($black, 0.2);
    z-index: 9998;
    @media screen and (max-width: $sm) {
        margin: 0px auto;
    }
    @media screen and (min-width: $md) {
        max-width: 90%;
    }
    @media screen and (min-width: $lg) {
        max-width: 70%;
    }
    @media screen and (min-width: $xl) {
        max-width: 55%;
    }
}
.Close {
    position: absolute;
    top: 20px;
    right: 30px;
    color: $black;
    font-size: 24px;
    opacity: .5;
    cursor: pointer;
}
.Header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px;
}

.modal-enter-active,
.modal-leave-active {
    .Dialog {
        transition-duration: 0.5s;
        transition-property: opacity, transform;
        transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
    }
    .Backdrop {
        transition: opacity 0.5s ease-in-out;
    }
}

.modal-enter,
.modal-leave-to {
    .Backdrop {
        opacity: 0;
    }
    .Dialog {
        opacity: 0;
        transform: translateY(-50px);
    }
}
</style>
