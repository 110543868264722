export const materials = [
    {
        key: 1,
        name: 'Cono',
        quantity: 10,
        location: 'Kendra',
    },
    {
        key: 2,
        name: 'Escalera',
        quantity: 2,
        location: 'Jessa',
    },
    {
        key: 3,
        name: 'Liga amarilla',
        quantity: 1,
        location: 'Kira',
    },
]

export const teamUniforms = {
    'game-shirt-turquoise': [
        {
            number: 1,
            userId: 4,
            size: 'M',
        },
        {
            number: 2,
            userId: null,
            size: '',
        },
        {
            number: 3,
            userId: 3,
            size: 'M',
        },
        {
            number: 4,
            userId: 10,
            size: 'S',
        },
        {
            number: 5,
            userId: 6,
            size: 'M',
        },
        {
            number: 6,
            userId: 8,
            size: 'S',
        },
        {
            number: 8,
            userId: 7,
            size: 'S',
        },
        {
            number: 9,
            userId: 9,
            size: 'M',
        },
        {
            number: 12,
            userId: 2,
            size: 'M',
        },
        {
            number: 14,
            userId: 5,
            size: 'M',
        },
        {
            number: 15,
            userId: 1,
            size: 'L',
        },
        {
            number: 17,
            userId: 11,
            size: 'M',
        },
        {
            number: 20,
            userId: 12,
            size: 'M',
        },
    ],
    'game-shirt-black': [
        {
            number: 1,
            userId: 4,
            size: 'M',
        },
        {
            number: 16,
            userId: 1,
            size: 'XXL',
        },
    ],
    'sweater-black': [
        {
            number: 16,
            userId: 1,
            size: 'XL',
        },
    ],
}
