<template>
    <div class="KickingballScreen">
        <div class="Wrapper">
            <div
                class="ImageSection"
                :style="{ backgroundImage: `url(${require('@/assets/imgs/pictures/photo-09.jpg')})`}"
            >
                <h3>Kickingball</h3>
                <div>
                    <iframe
                        class="Video"
                        src="https://www.youtube.com/embed/N85CFEU6DJ4"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    />
                </div>
            </div>
            <div class="TextSection">
                <p>
                    El kickingball es un deporte similar al béisbol, se practica en el mismo terreno de juego, con 3 bases y un home. No se utiliza el guante ni el bate,
                    ya que la pelota es un balón de fútbol #4.

                    <br><br>Se juega entre dos equipos de 10 personas cada uno.
                    La pitcher lanza el balón rodando por el piso hacia el cajón de pateo,
                    donde una jugadora del otro equipo patea el balón y corre por el campo buscando avanzar la mayor cantidad de bases posibles sin ser eliminada.
                    Gana el equipo que anote más carreras al cabo de 9 innings.
                    <br><br>
                    Este deporte empezó en 1942 en USA; se juega en Venezuela desde 1945 y en Madrid desde hace 5 años.
                    El kickingball es también conocido por el nombre de "kickball" o "futbeis".
                    <br><br>
                    En algunos países se juega en campamentos y colegios, como actividad recreacional o de educación física,
                    ya que requiere el uso de pocos materiales y la participacion de 20 personas.
                    <br><br>
                </p>
                <a
                    class="Reglamento"
                    href="https://drive.google.com/file/d/1_WuVeXRZ9Fc6D76YCqyJH8WIuaee-sKr/view"
                    target="_blank"
                >
                    <Icon
                        icon="file-text"
                        :size="20"
                        color="white"
                    />
                    <p>Reglamento</p>
                </a>
            </div>
            <div class="TeamsListContainer">
                <h3>Equipos en España</h3>
                <div
                    class="TeamsList"
                    :style="{ backgroundImage: `url(${require('@/assets/imgs/pictures/photo-10.jpg')})`}"
                >
                    <ul
                        v-for="(city, cityName) in teams"
                        :key="cityName"
                        class="City"
                        :style="{ 'grid-area': cityName }"
                    >
                        <li>
                            <div class="Team">
                                <div class="Name">
                                    <p>{{ cityName }}</p>
                                </div>
                            </div>
                        </li>
                        <li
                            v-for="({ name, link, createdAt }, index) in city"
                            :key="index"
                            @click="goTo(link)"
                        >
                            <div class="Line" />
                            <div class="Team">
                                <div class="Name">
                                    <p>{{ name }}</p>
                                </div>
                                <span>{{ createdAt }}</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { teams } from '@/data/teams.js'
export default {
    name: 'KickingballScreen',
    data () {
        return {
            teams,
        }
    },
    methods: {
        goTo (link) {
            if (link) window.open(link, '_blank')
        },
    },
}
</script>

<style lang="scss" scoped>
* { transition: all .4s }
.TeamsListContainer {
	width: 100%;
	h3 {
		text-align: center;
		margin: 0 0 2rem 0;
		font-size: 2rem;
		line-height: 3.5rem;
		font-weight: $font-weight-bold;
		color: $primary;
	}
	.TeamsList {
		width: 100%;
		min-height: fit-content;
		height: 800px;
		padding: 2rem 2rem 4rem 2rem;
		background-size: cover;
		background-repeat: no-repeat;
		background-position-x: center;
		background-position-y: center;
		display: grid;
		grid-column-gap: 50px;
		grid-row-gap: 50px;
		grid-template-columns: repeat(3, 1fr); //4
		grid-template-rows: 1fr;
		grid-template-areas:
			"TENERIFE MADRID BARCELONA";
			// "TENERIFE MADRID BARCELONA BILBAO";
			// grid-template-columns: repeat(6, 1fr);
			// "TENERIFE MADRID BARCELONA BILBAO VALENCIA MALAGA";
		@media screen and (max-width: $lg) {
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: repeat(2, 1fr);
			grid-template-areas:
			"TENERIFE MADRID BARCELONA"
			"TENERIFE MADRID BARCELONA";
			// "TENERIFE MADRID BILBAO";
			// grid-template-columns: repeat(4, 1fr);
			// "TENERIFE MADRID BARCELONA VALENCIA"
			// "TENERIFE MADRID BILBAO MALAGA";
		}
		@media screen and (max-width: $md) {
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: auto;
			grid-template-areas:
			// "TENERIFE MADRID BILBAO"
			"TENERIFE MADRID BARCELONA"
			"TENERIFE MADRID BARCELONA"
			"TENERIFE MADRID BARCELONA";
			// "TENERIFE MADRID BILBAO"
			// "TENERIFE MADRID VALENCIA"
			// "BARCELONA MADRID MALAGA";
		}
		@media screen and (max-width: $sm) {
			height: 900px;
			grid-column-gap: 30px;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: auto;
			grid-template-areas:
			"TENERIFE MADRID"
			// "TENERIFE MADRID"
			"BARCELONA MADRID"
			// "BARCELONA BILBAO";
			// "BILBAO VALENCIA"
			// "BILBAO MALAGA";
		}
		ul {
			margin: 0;
			padding: 0;
			li {
				display: flex;
				flex-direction: column;
				align-items: center;
				list-style: none;
				.Team {
					display: flex;
					flex-direction: column;
					align-items: center;
					cursor: pointer;
					.Name {
						background: white;
						width: fit-content;
						padding: 12px 25px 15px 25px;
						border-radius: 20px;
						@media screen and (max-width: $sm) {
							padding: 10px 20px 10px 20px;
						}
					}
					p {
						color: $gray-dark;
						font-size: 18px;
						line-height: 18px;
						margin: 0;
						padding: 0;
						text-align: center;
						@media screen and (max-width: $sm) {
							font-size: 16px;
							line-height: 16px;
						}
					}
					// span {
					// 	padding: 10px 0;
					// 	color: white;
					// }
					span {
						color: white;
						font-weight: bold;
						padding: 0;
						height: 0;
						visibility: hidden;
						opacity: 0;
						transition: opacity .1s, visibility .4s, height .4s, padding .4s;
					}
					&:hover {
						.Name {
							transform: scale(1.1);
							font-weight: bold;
						}
						span {
							visibility: visible;
							height: auto;
							padding: 10px 0;
							opacity: 1;
						}
					}
				}
				.Line {
					width: 2px;
					height: 20px;
					background: white;
				}
				&:first-child {
					cursor: default;
					.Team:hover .Name{
						transform: none;
					}
					.Name {
						padding: 15px 30px 18px 30px;
						background-image: linear-gradient(45deg, $primary, $info);
						@media screen and (max-width: $sm) {
							padding: 12px 20px 15px 20px;
						}
						p {
							color: white;
							font-weight: bold;
						}
					}
				}
			}
		}
	}
}
.KickingballScreen {
	width: 100%;
	background: #333;
	@media screen and (min-width: $xl) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.Wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		@media screen and (min-width: $xl) {
			width: 1300px;
		}
		.ImageSection {
			height: 30rem;
			width: 100%;
			background-size: cover;
			background-repeat: no-repeat;
			background-position-x: center;
			background-position-y: center;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			padding: 6rem 2rem 2rem 4rem;
			@media screen and (max-width: $sm) {
				padding: 2rem;
				justify-content: flex-end;
				height: 25rem;
			}
			h3 {
				font-size: 3rem;
				line-height: 3.5rem;
				font-weight: $font-weight-bold;
				color: $primary;
				@media screen and (max-width: $sm) {
					font-size: 2rem;
				}
			}
			.Video {
				width: 450px;
				height: 230px;
				margin-top: 20px;
				border-radius: 20px;
				border: 5px solid rgba(white, .1);
				box-shadow:  0 2px 5px 0 rgba(black, 0.1);
				@media screen and (max-width: $sm) {
					width: 100%;
				}
			}
			// .Reglamento {
			// 	width: 200px;
			// 	height: 230px;
			// 	margin-top: 20px;
			// 	margin-left: 20px;
			// 	border-radius: 20px;
			// 	border: 5px solid rgba(white, .1);
			// 	box-shadow:  0 2px 5px 0 rgba(black, 0.1);
			// }
		}
		.TextSection {
			height: auto;
			padding: 4rem;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			@media screen and (max-width: $sm) {
				padding: 2rem;
			}
			p {
				width: 100%;
				font-size: 1.3rem;
				text-align: justify;
				line-height: 2.2rem;
				color: white;
				@media screen and (max-width: $md) {
					font-size: 1.1rem;
					line-height: 1.8rem;
				}
			}
			.Reglamento {
				background: rgba(white, .1);
				padding: 8px 25px;
				border-radius: 20px;
				display: flex;
				align-items: center;
				.Icon { margin-right: 20px }
				p { font-size: 1.2rem }
			}
		}
	}
}
</style>
