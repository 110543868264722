import axios from 'axios'

export default {
    createUserEvent: async (body) => {
        const response = await axios.post('/user-events', body)

        return response && response.data && response.data.response
    },

    updateUserEvent: async (id, body) => {
        const response = await axios.put('/user-events/' + id, body)

        return response && response.data && response.data.response
    },

    deleteUserEvent: async (id) => {
        const response = await axios.delete('/user-events/' + id)

        return response && response.data && response.data.response
    },

    getUserEvent: async (id, params) => {
        const response = await axios.get('/user-events/' + id, { params })

        return response && response.data && response.data.response
    },

    listUserEvent: async (params) => {
        const response = await axios.get('/user-events/', { params })

        return response && response.data && response.data.response
    },
}
