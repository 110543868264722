<template>
    <div class="ProfileScreen">
        <div class="ScreenHeader">
            <Icon
                v-if="profile.id !== me.id"
                :size="30"
                icon="chevron-left"
                @click="$router.push({ name: 'Organization', params: { organizationSlug: 'jugadoras' } })"
            />
            <h1 v-if="profile.id === me.id">
                Mi perfil
            </h1>
            <h1 v-else>
                {{ profile.alias ? profile.alias : profile.name }}
            </h1>
        </div>
        <div class="Container">
            <Loading
                key="loading"
                :visible="Object.entries(profile).length === 0 || isLoadingEdit"
                :opacity="1"
                :duration="0"
            />
            <Transition
                name="main-router"
                mode="out-in"
            >
                <div
                    v-if="Object.entries(profile).length !== 0 && !isLoadingEdit"
                    class="PersonalData"
                >
                    <Avatar
                        :url="require(`@/assets/imgs/players/${profile.imagePath || 'default.jpg'}`)"
                        :size="{ height: 210, width: 190 }"
                    />
                    <div
                        v-if="activeMode === 'read'"
                        class="Info"
                    >
                        <div class="Header">
                            <h2 class="Name">
                                {{ profile.name }} {{ profile.number ? `#${profile.number}` : ''}}
                            </h2>
                            <Icon
                                v-permissions="{permissions: [constants.USER_ROLE.ADMIN,
                                                              constants.USER_ROLE.COMITE,
                                                              constants.USER_ROLE.COACH]}"
                                :size="20"
                                icon="edit"
                                @click="activeMode = 'edit'"
                            />
                        </div>
                        <div
                            v-for="{ key, label} in personalData"
                            :key="key"
                            class="Data"
                        >
                            <template
                                v-if="key === 'status'"
                                v-permissions="{permissions: [constants.USER_ROLE.ADMIN,
                                                              constants.USER_ROLE.COMITE,
                                                              constants.USER_ROLE.COACH]}"
                            >
                                <p class="Label">
                                    {{ label }}
                                </p>
                                <Icon
                                    :size="20"
                                    :icon="(statuses[profile[key]] || {}).icon"
                                    :color="(statuses[profile[key]] || {}).color"
                                    @click="activeMode = 'edit'"
                                />
                            </template>
                            <template v-else>
                                <p class="Label">
                                    {{ label }}
                                </p>
                                <p class="Value">
                                    {{ profile[key] || '-' }}
                                </p>
                            </template>
                        </div>
                    </div>
                    <div
                        v-else
                        class="Info"
                    >
                        <div class="Header end">
                            <Icon
                                :size="20"
                                icon="close"
                                @click="activeMode = 'read'"
                            />
                        </div>
                        <ProfileInput
                            :profile="profile"
                            :isLoading="isLoading"
                            :isCreationMode="false"
                            @submit="handleEditProfile"
                        />
                    </div>
                </div>
            </Transition>
        </div>
        <ProfileInfoTable
            v-bind="tableData"
            :tabs="tabs"
            :profile="profile"
            :isLoading="isLoading"
            @changePaymentYear="handlePaymentEvent"
            @changeAttendanceDate="handleAttendanceEvent"
            @updateUniforms="handleUpdateUniforms"
            @updatePayments="handleUpdatePayments"
            @updateAttendance="handleUpdateAttendance"
            @editPersonalInfo="handleEditProfile"
            @addEmergencyContact="handleAddEmergencyContact"
            @deleteEmergencyContact="handleDeleteEmergencyContact"
        />
    </div>
</template>

<script>
import { ProfileInfoTable, ProfileInput } from '@/components'
import VueTypes from 'vue-types'
import { tabs, personalData, statuses } from '@/entities/profile'
import constants from '@/entities/constants'
import attendanceConstants from '@/entities/attendance'
import userIntegration from '@/integration/userIntegration'
import profileScreenUtils from '@/utils/profileScreenUtils'
import attendanceUtils from '@/utils/attendanceScreenUtils'
import utils from '@/utils/utils'

export default {
    name: 'ProfileScreen',
    components: {
        ProfileInfoTable,
        ProfileInput,
    },
    props: {
        me: VueTypes.object,
    },
    data () {
        return {
            activeMode: 'read',
            id: (this.$route.params.id && parseInt(this.$route.params.id)) || this.me.id,
            tabs,
            statuses,
            personalData,
            constants,
            isLoading: true,
            isLoadingEdit: false,
            profile: {},
            tableData: {
                userId: (this.$route.params.id && parseInt(this.$route.params.id)) || this.me.id,
                statistics: [],
                feedback: [],
                userUniforms: {},
                userUniformsKey: 0,
                paymentInformation: {
                    balance: undefined,
                    payments: {},
                },
                userPaymentsKey: 0,
                userAttendanceKey: 0,
                events: {},
                attendance: {},
                isPlayer: false,
                attendanceDateInfo: utils.getInitialDateInfo({ startYear: attendanceConstants.attendaceStartYear }),
            },
        }
    },
    watch: {
        async id (value) {
            await this.getUserProfile(value)
            this.tableData.userId = value
        },
        async $route (to) {
            const { id } = to.params
            await this.getUserProfile(id)
            this.tableData.userId = Number(id)
        },
        profile () {
            this.isLoadingEdit = false
        },
    },
    async mounted () {
        await this.getUserProfile(this.id)
        this.tableData.paymentInformation = await profileScreenUtils.getUserInitialPaymentInformation(this.id)
        this.tableData.events = await profileScreenUtils.getAllInitialEvents()
        this.tableData.attendance = await profileScreenUtils.getAllAttendance(this.id, this.tableData.events)
        this.tableData.isPlayer = this.me.permission === constants.USER_ROLE.PLAYER
        this.isLoading = false
    },
    methods: {
        // TODO: add isLoading true and false to each api call
        async getUserProfile (id) {
            const prof = await userIntegration.getUserProfile(id)
            this.profile = Object.assign({}, prof)
            this.tableData.userUniforms = Object.assign({}, prof.uniforms)
        },
        async handlePaymentEvent (dateInfo) {
            this.tableData.paymentInformation.payments = await profileScreenUtils.getUserPaymentInformation(dateInfo, this.id)
        },
        async handleAttendanceEvent (dateInfo) {
            this.tableData.attendanceDateInfo = dateInfo
            this.tableData.events = await profileScreenUtils.getAllEvents(dateInfo)
            this.tableData.attendance = await profileScreenUtils.getAllAttendance(this.id, this.tableData.events)
            this.tableData.userAttendanceKey += 1
        },
        async handleEditProfile (value) {
            this.isLoadingEdit = true
            this.activeMode = 'read'
            const prof = await userIntegration.editUser(this.profile, value, this.me.permission)
            this.profile = Object.assign({}, prof)
        },
        async handleAddEmergencyContact (value) {
            await profileScreenUtils.createContact(value, this.id)
            const prof = await userIntegration.getUserProfile(this.id)
            this.profile = Object.assign({}, prof)
        },
        async handleDeleteEmergencyContact (value) {
            await profileScreenUtils.deleteContact(value)
            const prof = await userIntegration.getUserProfile(this.id)
            this.profile = Object.assign({}, prof)
        },
        async handleUpdateUniforms (value) {
            this.profile.uniforms = await profileScreenUtils.updateUniforms(value, this.id, this.me.permission)
            this.tableData.userUniforms = this.profile.uniforms
            this.tableData.userUniformsKey += 1
        },
        async handleUpdatePayments (value) {
            const paymentInfo = await profileScreenUtils.updatePayments(value, this.id, this.me.permission)
            this.tableData.paymentInformation = Object.assign({}, paymentInfo)
            this.tableData.userPaymentsKey += 1
        },
        async handleUpdateAttendance (value) {
            let data = {}
            for (const val of Object.values(value)) {
                data = { ...data, ...val.data }
            }
            await attendanceUtils.updateAttendance({
                id: this.profile.id,
                attendance: { data },
            }, this.me.permission)
            this.tableData.attendance = await profileScreenUtils.getAllAttendance(this.id, this.tableData.events)
            this.tableData.userAttendanceKey += 1
        },
    },
}
</script>
<style lang="scss" scoped>

.ProfileScreen {
	.ScreenHeader {
		margin-bottom: 30px;
		h1 { margin: 0 }
	}
	p, h2 {
		color: $gray-dark;
	}
	.PersonalData {
		display: flex;
		position: relative;
		@media screen and (max-width: $md) {
			flex-direction: column;
			align-items: center;
			.Name {
				text-align: center;
			}
			.Data {
				justify-content: center;
			}
		}
		.Info {
			flex: 1;
			padding: 30px 0;
		}
		.Header {
			display: flex;
			justify-content: space-between;
			@media screen and (max-width: $md) {
				justify-content: center;
			}
			.Name {
				margin-bottom: 20px;
			}
			.Icon {
				margin-left: 30px;
				height: fit-content;
				cursor: pointer;
				&:hover {
					color: $primary !important;
				}
				@media screen and (max-width: $md) {
					position: absolute;
					top: 0;
					right: 0;
				}
			}
			&.end {
				justify-content: flex-end
			}
		}
		.Data {
			margin-top: 12px;
			display: flex;
			align-items: flex-start;
			margin-right: 50px;
			@media screen and (max-width: $md) {
				margin-right: 0px;
			}
			@media screen and (max-width: $sm) {
				flex-direction: column;
				align-items: center;
			}
			.Label {
				color: $gray-03;
				font-weight: bold;
				font-size: 14px;
				margin-right: 10px;
				text-transform: uppercase;
				white-space: nowrap;
				margin-top: 2px;
				line-height: 1.5rem;
			}
			.Value {
				line-height: 1.5rem;
				@media screen and (max-width: $sm) {
					margin-top: 5px;
					text-align: center;
				}
			}
		}
		.Avatar {
			// margin-right: 40px;
			border-radius: 3rem;
			margin: 20px 50px 0 20px;
			@media screen and (max-width: $md) {
				margin: 20px 0px 0px 0px;
			}
		}
	}
}
.ProfileInfoTable {
	margin-top: 30px;
}
</style>
