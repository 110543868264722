<template>
    <div class="IconSelect">
        <Multiselect
            v-model="selectedValue"
            :options="options"
            :closeOnSelect="true"
            :optionHeight="20"
            :disabled="disabled"
            :placeholder="placeholder"
            :searchable="false"
            :hideSelected="true"
            trackBy="key"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
        >
            <template #option="singleOption">
                <Icon
                    :size="20"
                    :icon="singleOption.option.icon"
                    :color="singleOption.option.color"
                />
            </template>
            <template #singleLabel>
                <Icon
                    :size="20"
                    :icon="selectedValue.icon"
                    :color="selectedValue.color"
                />
            </template>
            <template #placeholder>
                <Icon
                    :size="20"
                    :icon="selectedValue.icon"
                    :color="selectedValue.color"
                />
            </template>
        </Multiselect>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import Multiselect from 'vue-multiselect'

export default {
    name: 'Select',
    components: {
        Multiselect,
    },
    props: {
        options: VueTypes.array.isRequired,
        value: VueTypes.oneOfType([Object, String, Array, Number, null]).isRequired,
        disabled: VueTypes.bool.def(false),
        placeholder: VueTypes.string.def(''),
    },
    computed: {
        selectedValue: {
            get () {
                return this.options.find(o => o.key === this.value)
            },
            set (value) {
                if (value) {
                    const newValue = value.key
                    this.$emit('input', newValue)
                }
            },
        },
    },
}
</script>
<style lang="scss" scoped>
.IconSelect {
	width: 100%;
	.multiselect::v-deep {
		.multiselect__select {
			display: none !important;
		}
		.multiselect__tags {
			padding: 8px;
			.multiselect__single {
				padding: 0;
				margin: 0;
				text-align: center;
			}
		}
		.multiselect__content-wrapper {
			max-height: fit-content !important;
			text-align: center;
			box-shadow:  0 2px 5px 0 rgba($black, 0.2);
		}
		.multiselect__option--highlight {
			background: $gray-light;
		}
	}
}
</style>
