<template>
    <div class="PlayersScreen">
        <div
            class="ImageSection"
            :style="{ backgroundImage: `url(${require(`@/assets/imgs/pictures/photo-12.jpg`)})` }"
        />
        <div class="Wrapper">
            <div
                class="TeamPicture"
                :style="{ backgroundImage: `url(${require(`@/assets/imgs/pictures/photo-11.jpg`)})` }"
            />
            <div class="Grid">
                <div
                    v-for="(person, index) in organization.filter(p => !!p.imagePath)"
                    :key="index"
                    class="GridItem"
                >
                    <div class="PersonInfo">
                        <p class="Name">
                            {{ person.shortName }} {{person.number}}
                        </p>
                        <p
                            v-if="person.positionText"
                            class="Position"
                        >
                            {{ person.positionText }}
                        </p>
                        <p
                            v-if="person.startDate"
                            class="Seniority"
                        >
                            <Icon
                                icon="clock"
                            />
                            {{ person.startDate }}<br>
                        </p>
                    </div>
                    <div
                        class="ProfilePicture"
                        :style="{ backgroundImage: `url(${require(`@/assets/imgs/players/${person.imagePath || 'default.jpg'}`)})`}"
                    />
                    <p class="Name">
                        {{ person.alias }} {{person.number}}
                    </p>
                </div>
            </div>
            <iframe
                class="TeamVideo"
                src="https://www.youtube.com/embed/MqC60E4dvVc"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            />
        </div>
    </div>
</template>

<script>
import organizationConstants from '@/entities/organization'
import constants from '@/entities/constants'
import publicApi from '@/api/public'
import userIntegration from '@/integration/userIntegration'
import moment from 'moment'

export default {
    name: 'PlayersScreen',
    data () {
        return {
            organizationCategories: organizationConstants.organizationCategories,
            organization: [],
        }
    },
    async mounted () {
        this.organization = await this.getPlayers()
    },
    methods: {
        async getPlayers () {
            const players = (await publicApi.listUser()).items

            return players.map((p) => {
                const player = p
                player.userPositions = p.userPositions
                    .filter(p => p.position.type === constants.POSITION_TYPE.DEFENSIVE)
                player.positionText = userIntegration.getPositionText({ items: player.userPositions })
                player.startDate = (p.startDate &&
                    moment(p.startDate).endOf('day').locale('es').fromNow(true)) || '-'
				player.number = (p.userUniforms && p.userUniforms.length && 
					`#${p.userUniforms[0].number}`) || ''
                return player
            })
        },
    },
}
</script>

<style lang="scss" scoped>

.PlayersScreen {
	width: 100%;
	background: #333;
	@media screen and (min-width: $xl) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.ImageSection {
		opacity: .2;
		position: absolute;
		height: 30rem;
		width: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position-x: center;
		background-position-y: center;
		@media screen and (max-width: $md) {
			height: 25rem;
		}
		@media screen and (max-width: $sm) {
			height: 15rem;
		}
	}
	.Wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		@media screen and (min-width: $xl) {
			width: 1300px;
		}
		.TeamPicture {
			margin-top: 120px;
			z-index: 10;
			width: 80%;
			height: 500px;
			border-radius: 20px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position-x: center;
			background-position-y: center;
			@media screen and (max-width: $md) {
				height: 350px;
			}
			@media screen and (max-width: $sm) {
				height: 250px;
				margin-top: 100px;
			}
		}
		.TeamVideo {
			margin: 50px 0 100px;
			width: 80%;
			height: 500px;
			border-radius: 20px;
			@media screen and (max-width: $md) {
				height: 350px;
			}
			@media screen and (max-width: $sm) {
				height: 250px;
				margin-top: 100px;
			}
		}
		.Grid {
			margin: 50px;
			width: 80%;
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			grid-column-gap: 20px;
			grid-row-gap: 20px;
			grid-template-rows: 1fr;
			@media screen and (max-width: $xl) {
			grid-template-columns: repeat(6, 1fr);
			}
			@media screen and (max-width: $lg) {
			grid-template-columns: repeat(4, 1fr);
			}
			@media screen and (max-width: $md) {
			grid-template-columns: repeat(3, 1fr);
			}
			@media screen and (max-width: $sm) {
			grid-template-columns: repeat(2, 1fr);
			}
			.GridItem {
				background: white;
				overflow: hidden;
				border-radius: 10px;
				box-shadow: 0px 4px 10px rgba(103, 94, 134, 0.1);
				display: flex;
				flex-direction: column;
				align-items: center;
				cursor: pointer;
				padding-bottom: 20px;
				position: relative;
				transition: all .3s;
				&:hover {
					.PersonInfo {
						opacity: 1;
						bottom: 0;
					}
				}
				.ProfilePicture{
					width: 100%;
					height: 200px;
					background-color: $gray-07;
					background-position: center;
					background-size: cover;
					background-repeat: no-repeat;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				p {
					color: $gray-dark !important;
					font-weight: 500;
					margin-top: 20px;
					font-size: 18px;
					text-align: center;
				}
				.PersonInfo {
					position: absolute;
					left: 0;
					width: 100%;
					height: 100%;
					background: $primary;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					opacity: 0;
					padding: 20px;
					transition: all .3s;
					bottom: -100%;

					p {
						color: white !important;
						font-size: 16px;
						line-height: 24px;
					}
					.Name {
						font-size: 18px;
						line-height: 26px;
						font-weight: bold;
					}
					.Icon {
						margin-right: 5px;
					}
					.Position {
						text-align: center;
						width: 100%;
					}
				}
			}
		}
	}
}
</style>
