<template>
	<div class="MadMixScreen">
		<div class="ImageSection mad" :style="{ backgroundImage: `url(${require('@/assets/imgs/MadMix/fondo4.png')})` }">
			<div class="Title">
				<div class="Organization">
					<img :src="require(`@/assets/imgs/MadMix/MadMix.png`)" class="Torneo MadMix de kickingball">
				</div>
				<PillSelectorInput v-model="activeEditionTab" :options="editionsTabs" border="none" preventDeselect
					@input="handleChangeEditionTab" :style="{ marginTop: '1rem' }" />
				<div class="Participants">
					<Avatar v-for="({ id, link }, i) in selectedEditionData.participants" :key="i"
						:url="require(`@/assets/imgs/MadMix/participants/${id}.png`)"
						:size="{ height: isMobile ? 50 : 60, width: isMobile ? 50 : 60 }" :iconSize="20" :rounded="false"
						@click.native="goTo(link)" />
				</div>
			</div>
		</div>




		<div class="PillSelectors">
			<PillSelectorInput v-model="activeTab" :options="selectedEditionData.tabs" border="none" preventDeselect
				@input="handleChangeTab" />
			<a v-if="activeEditionTab === '2024' && activeTab === 'calendar'" class="Conditions" :href="'madmix-2024'">
				Condiciones del torneo
			</a>
			<!-- <Button v-if="activeEditionTab === '2024'" type="submit" text="Condiciones del torneo" styleType="white"
				@click.native="goTo('madmix-2024')" size="sm" /> -->
			<template v-if="activeTab === 'inscriptions'">
				<PillSelectorInput v-model="activeInscriptionsTab" :options="inscriptionsTabs" border="none" preventDeselect
					disabled @input="handleChangeInscriptionsTab" />
			</template>
			<div v-if="activeTab === 'raffle'">
				<p :style="{ marginBottom: '12px', marginLeft: '16px', marginTop: '20px', color: 'white' }">
					Sortear...
				</p>
				<div :style="{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexWrap: 'wrap' }">
					<Button v-for="(  raffleTab, idx  ) in   raffleTabs  " :key="idx" type="submit" :text="raffleTab.label"
						styleType="white" size="sm" :iconLeft="raffleTab.icon" iconColor="grayDark"
						:disabled="isLoadingRaffle || raffleTab.disabled" :loading="raffleTab.loading"
						@click="handleRaffle(raffleTab)" />
				</div>
			</div>
		</div>

		<template v-if="selectedEditionData">
			<!-- <MadMixConditionsScreen v-if="activeTab === 'terms'" /> -->
			<template v-if="activeTab === 'raffle'">
				<div class="ListItems All">
					<div v-for="(  team, index  ) in newTeams" :key="`team-${index}`" class="ListItems">
						<div v-if="!team.length" class="EmptyMessage">
							<p class="Name">
								No hay datos aún
							</p>
						</div>
						<template v-else>
							<template v-for="(  item, idx  ) in   team  ">
								<template v-if="!(item || {}).positionId && !(item || {}).teamId && (item || {}).name">
									<div :key="`item-${idx}`" class="ListItem" :class="{ 'ListItemTeam': item.id }">
										<p class="TeamName">
											{{ (index + 1) }} - {{ item.name }}
										</p>
									</div>
								</template>
							</template>
							<template v-for="(  item, idx  ) in team">
								<template v-if="!(item || {}).positionId && (item || {}).teamId">
									<div :key="`item-${idx}`" class="ListItem" :class="{ 'ListItemTeam': item.id }">
										<Avatar :url="require(`@/assets/imgs/MadMix/participants/${item.teamId}.png`)"
											:size="{ height: isMobile ? 30 : 35, width: isMobile ? 30 : 35 }"
											:rounded="false" />
										<p class="Name">
											{{ item.name }}
										</p>
									</div>
								</template>
							</template>
							<template v-for="(item, idx) in team">
								<template v-if="(item || {}).positionId">
									<div :key="`item-${idx}`" class="ListItem" :class="{ 'ListItemTeam': item.id }">
										<Avatar :url="require(`@/assets/imgs/MadMix/participants/${item.teamId}.png`)"
											:size="{ height: isMobile ? 30 : 35, width: isMobile ? 30 : 35 }"
											:rounded="false" />
										<p class="Name">
											{{ item.name }}
										</p>
										<p class="Value">
											{{ defensivePositions.find(({ key }) => key ===
												item.position)?.[isMobile
													?
													'key' : 'label'] }}
										</p>
									</div>
								</template>
							</template>
						</template>
					</div>
				</div>
			</template>
			<template v-if="activeTab === 'inscriptions'">
				<template v-if="activeInscriptionsTab === 'names'">
					<div class="ListItems names">
						<div v-for="(madmixName, index) in selectedEditionData.names" :key="`madmixName-${index}`"
							class="ListItem">
							<Avatar :url="require(`@/assets/imgs/MadMix/tourist/${madmixName.key}.png`)"
								:size="{ height: isMobile ? 30 : 35, width: isMobile ? 30 : 35 }" :rounded="false" />
							<p class="Name">
								{{ madmixName.name }}
							</p>
						</div>
					</div>
				</template>
				<template v-if="activeInscriptionsTab === 'players'">
					<div class="ListItems Players">
						<div v-for="(player, index) in selectedEditionData.players" :key="`player-${index}`"
							class="ListItem">
							<Avatar :url="require(`@/assets/imgs/MadMix/participants/${player.teamId}.png`)"
								:size="{ height: isMobile ? 30 : 35, width: isMobile ? 30 : 35 }" :rounded="false" />
							<p class="Name">
								{{ player.name }}
							</p>
							<p class="Value">
								{{ (defensivePositions.find(({ key }) => key === player.position) || {})[isMobile
									?
									'key' : 'label'] }}
							</p>
							<p v-if="player.secondPosition" class="Value">
								{{ (defensivePositions.find(({ key }) => key === player.secondPosition) || {})[isMobile
									?
									'key' : 'label'] }}
							</p>
						</div>
					</div>
				</template>
				<template v-if="activeInscriptionsTab === 'managers'">
					<div class="ListItems Managers">
						<div v-for="(manager, index) in selectedEditionData.managers" :key="`manager-${index}`"
							class="ListItem">
							<Avatar :url="require(`@/assets/imgs/MadMix/participants/${manager.teamId}.png`)"
								:size="{ height: isMobile ? 30 : 35, width: isMobile ? 30 : 35 }" :rounded="false" />
							<p class="Name">
								{{ manager.name }}
							</p>
							<!-- <p v-if="manager.isManager" class="Value">
								Manager
							</p>
							<p v-if="manager.isCoach" class="Value">
								Coach
							</p> -->
						</div>
					</div>
				</template>
			</template>

			<div v-if="activeTab === 'calendar'" class="Calendar">
				<div v-if="selectedEditionData.raffleDay" class="List">
					<div class="ListHeader">
						<p>
							{{
								selectedEditionData.raffleDay.day
							}}
							{{ selectedEditionData.raffleDay.month }}
							a las {{ selectedEditionData.raffleDay.time }}
						</p>
					</div>
					<div class="ListItem">
						<div class="Time" :style="{ flex: 1 }">
							<p :style="{ flex: 1 }">
								Sorteo de equipos
							</p>
						</div>
					</div>
				</div>
				<div class="List">
					<div class="ListHeader">
						<p>
							{{ formatDate(selectedEditionData.events[0][0].date).weekDay }} {{
								formatDate(selectedEditionData.events[0][0].date).day }} {{
		formatDate(selectedEditionData.events[0][0].date).month }}
							en {{ selectedEditionData.locations.find(l => l.id ===
								selectedEditionData.events[0][0].locationId).name }}
						</p>
					</div>

					<div v-for="(event, index) in selectedEditionData.events[0]" :key="index" class="ListItem">
						<div v-if="event.scoreHomeclub !== null && !isMobile" class="ScoreHomeClub">
							<p>
								{{ event.scoreHomeclub }}
							</p>
						</div>
						<div v-if="event.scoreVisitor !== null && !isMobile" class="ScoreVisitor">
							<p>
								{{ event.scoreVisitor }}
							</p>
						</div>
						<div class="Team">
							<Avatar :url="event.TBD ? '' : require(`@/assets/imgs/MadMix/teams/${event.homeclubId}.png`)"
								:size="{ height: isMobile ? 25 : 35, width: isMobile ? 25 : 35 }" :iconSize="20"
								:rounded="false" />
							<div class="Homeclub" v-if="!event.hideIds">
								<p>{{ selectedEditionData.teams.find(t => t.id === event.homeclubId).name }}</p>
							</div>
						</div>
						<div class="Time">
							<p>{{ formatDate(event.date).time }}</p>
						</div>
						<div class="Team right">
							<div class="Visitor" v-if="!event.hideIds">
								<p>{{ selectedEditionData.teams.find(t => t.id === event.visitorId).name }}</p>
							</div>
							<Avatar :url="event.TBD ? '' : require(`@/assets/imgs/MadMix/teams/${event.visitorId}.png`)"
								:size="{ height: isMobile ? 25 : 35, width: isMobile ? 25 : 35 }" :iconSize="20"
								:rounded="false" />
						</div>
					</div>
					<div v-if="selectedEditionData.extraCompetition" class="ListItem">
						<div class="Time" :style="{ flex: 1 }">
							<p :style="{ flex: 1 }">
								Competencia de habilidades
							</p>
						</div>
					</div>
				</div>

				<div class="List">
					<div class="ListHeader">
						<p>
							{{ formatDate(selectedEditionData.events[1][0].date).weekDay }} {{
								formatDate(selectedEditionData.events[1][0].date).day }} {{
		formatDate(selectedEditionData.events[1][0].date).month }}
							en {{ selectedEditionData.locations.find(l => l.id ===
								selectedEditionData.events[1][0].locationId).name }}
						</p>
					</div>

					<div v-for="(event, index) in selectedEditionData.events[1]" :key="index" class="ListItem">
						<div v-if="event.scoreHomeclub !== null && !isMobile" class="ScoreHomeClub">
							<p>
								{{ event.scoreHomeclub }}
							</p>
						</div>
						<div v-if="event.scoreVisitor !== null && !isMobile" class="ScoreVisitor">
							<p>
								{{ event.scoreVisitor }}
							</p>
						</div>
						<div class="Team">
							<Avatar v-if="[...selectedEditionData.teams, { id: 'F1' }].find(t => t.id === event.homeclubId)"
								:url="event.TBD ? '' : require(`@/assets/imgs/MadMix/teams/${event.homeclubId}.png`)"
								:size="{ height: isMobile ? 25 : 35, width: isMobile ? 25 : 35 }" :iconSize="20"
								:rounded="false" />
							<div v-if="event.homeclubId !== 'F1' && !event.hideIds" class="Homeclub">
								<p>{{ (selectedEditionData.teams.find(t => t.id === event.homeclubId) || {}).name ||
									event.homeclubId }}</p>
							</div>
						</div>
						<div class="Time">
							<p>{{ formatDate(event.date).time }}</p>
						</div>
						<div class="Team right">
							<div v-if="event.visitorId !== 'F2' && !event.hideIds" class="Visitor">
								<p>{{ (selectedEditionData.teams.find(t => t.id === event.visitorId) || {}).name ||
									event.visitorId }}</p>
							</div>
							<Avatar v-if="[...selectedEditionData.teams, { id: 'F2' }].find(t => t.id === event.visitorId)"
								:url="event.TBD ? '' : require(`@/assets/imgs/MadMix/teams/${event.visitorId}.png`)"
								:size="{ height: isMobile ? 25 : 35, width: isMobile ? 25 : 35 }" :iconSize="20"
								:rounded="false" />
						</div>
					</div>

					<div class="ListItem">
						<div class="Time" :style="{ flex: 1 }">
							<p :style="{ flex: 1 }">
								Premiación
							</p>
						</div>
					</div>
				</div>
				<!-- <Legend :legend="locationTypes" @clickOn="handleRedirectTo" /> -->
			</div>
			<template v-if="activeTab === 'teams'">
				<div class="ListItems All">
					<div v-for="(team, index) in selectedEditionData.finalTeams" :key="`team-${index}`" class="ListItems">
						<template v-for="(item, idx) in team">
							<template v-if="!(item || {}).positionId && !(item || {}).teamId && (item || {}).name">
								<div :key="`item-name-${idx}`" class="ListItem" :class="{ 'ListItemTeam': item.id }">
									<p class="TeamName"
										:style="{ 'cursor': 'pointer', display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }"
										@click="handleRedirectToRoster(item.id)">
										{{ item.name }}
										<Icon :style="{ 'margin-left': '10px' }" icon="maximize" :size="18" color="white" />
									</p>
								</div>
							</template>
						</template>
						<template v-for="(item, idx) in team">
							<template v-if="!(item || {}).positionId && (item || {}).teamId">
								<div :key="`item-player-${idx}`" class="ListItem" :class="{ 'ListItemTeam': item.id }">
									<Avatar :url="require(`@/assets/imgs/MadMix/participants/${item.teamId}.png`)"
										:size="{ height: isMobile ? 30 : 35, width: isMobile ? 30 : 35 }"
										:rounded="false" />
									<p class="Name">
										{{ item.name }}
									</p>
								</div>
							</template>
						</template>
						<template v-for="(item, idx) in team">
							<template v-if="(item || {}).positionId">
								<div :key="`item-manager-${idx}`" class="ListItem" :class="{ 'ListItemTeam': item.id }">
									<Avatar :url="require(`@/assets/imgs/MadMix/participants/${item.teamId}.png`)"
										:size="{ height: isMobile ? 30 : 35, width: isMobile ? 30 : 35 }"
										:rounded="false" />
									<p class="Name">
										{{ item.name }}
									</p>
									<p class="Value">
										{{ defensivePositions.find(({ key }) => key === item.position).label }}
									</p>
									<!-- <p class="Value">
										{{ defensivePositions.find(({ key }) => key === item.position)[isMobile ?
											'key'
											: 'label'] }}
									</p> -->
									<p class="Value" v-if="item.secondPosition">
										{{ defensivePositions.find(({ key }) => key === item.secondPosition).key }}
									</p>
								</div>
							</template>
						</template>
					</div>
				</div>
			</template>
		</template>
	</div>
</template>

<script>
import moment from 'moment'
import { mediaQueryMixin } from '@/mixins'
import { locationTypes, scoreTypes, defensivePositions, raffleTabs, inscriptionsTabs, editionsTabs } from '@/data/MadMix/general'
import data22 from '@/data/MadMix/2022/madmix'
import data23 from '@/data/MadMix/2023/madmix'
import data24 from '@/data/MadMix/2024/madmix'
// import MadMixConditionsScreen from './MadMixConditionsScreen.vue'

export default {
	name: 'MadMixScreen',
	// components: {
	// 	MadMixConditionsScreen,
	// },
	mixins: [mediaQueryMixin],
	data() {
		return {
			locationTypes,
			scoreTypes,
			newTeams: [],
			teamNames: [],
			defensivePositions,
			activeTab: 'calendar',
			activeInscriptionsTab: 'players',
			activeEditionTab: '2024',
			activeRaffleTab: '',
			raffleTabs: [],
			isLoadingRaffle: false,
			editionsData: {
				2022: data22,
				2023: data23,
				2024: data24,
			}
		}
	},
	computed: {
		selectedEditionData() {
			return this.editionsData[this.activeEditionTab]
		},
		isMobile() {
			return this.$mq.w < this.$mq.lg.min
		},
	},
	mounted() {
		this.raffleTabs = raffleTabs
	},
	created() {
		this.inscriptionsTabs = inscriptionsTabs
		this.editionsTabs = editionsTabs
	},
	methods: {
		handleRedirectToRoster(id) {
			this.$router.push({ name: 'MadMixRoster', params: { teamId: id, edition: this.activeEditionTab } })
		},
		goTo(link) {
			if (link) window.open(link, '_blank')
		},
		handleChangeEditionTab(value) {
			this.activeEditionTab = value
			if (!this.selectedEditionData.tabs.find(({ key }) => key === this.activeTab)) {
				const [first] = this.selectedEditionData.tabs
				this.activeTab = first.key
			}
		},
		handleChangeTab(value) {
			this.activeTab = value
		},
		handleChangeInscriptionsTab(value) {
			this.activeInscriptionsTab = value
		},
		timer(ms) {
			return new Promise((resolve, reject) => setTimeout(resolve, ms))
		},
		async shuffleN() {
			const names = this.selectedEditionData.playersRaffle.shuffleN(this.selectedEditionData.names)
			for (const n of names) {
				this.teamNames.push(n.name)
				this.newTeams.push([n])
				await this.timer(500)
			}
			this.raffleTabs = this.raffleTabs.map(t => {
				const tCopy = t
				if (t.key === 'managers') tCopy.disabled = false
				if (t.key === 'names') tCopy.disabled = true
				return tCopy
			})
		},
		async shuffleManagers() {
			const newManagers = this.selectedEditionData.playersRaffle.shuffleManagers(this.selectedEditionData.managers, this.teamNames)
			for (const t of this.newTeams) {
				const tCopy = t
				const team = newManagers.find(tt => tt.team === t[0].name)
				for (const m of team.managers) {
					tCopy.push(m)
					await this.timer(1000)
				}
			}
			this.raffleTabs = this.raffleTabs.map(t => {
				const tCopy = t
				if (t.key === 'players') tCopy.disabled = false
				if (t.key === 'managers') tCopy.disabled = true
				return tCopy
			})
		},
		async shufflePlayers() {
			const newTeams = this.selectedEditionData.playersRaffle.shuffle(this.selectedEditionData.players, this.teamNames)
			console.log('SE LOGROOO')
			console.log(JSON.stringify(newTeams))
			for (const t of this.newTeams) {
				const tCopy = t
				const team = newTeams.find(tt => tt.name === t[0].name)
				const players = team.players.sort((a, b) => {
					if (a.positionId > b.positionId) return 1
					if (a.positionId < b.positionId) return -1
					return 0
				})
				for (const p of players) {
					tCopy.push(p)
					await this.timer(1500)
				}
			}
			this.raffleTabs = this.raffleTabs.map(t => {
				const tCopy = t
				if (t.key === 'players') tCopy.disabled = true
				return tCopy
			})
		},
		async handleRaffle(value) {
			this.isLoadingRaffle = true
			// const restOfTabs = this.raffleTabs.filter(t => t.key !== value.key)
			this.raffleTabs = this.raffleTabs.map(t => {
				const tCopy = t
				if (t.key === value.key) tCopy.loading = true
				return tCopy
			})

			if (value.key === 'players') {
				await this.shufflePlayers()
			} else if (value.key === 'managers') {
				await this.shuffleManagers()
			} else if (value.key === 'names') {
				await this.shuffleN()
			}

			this.isLoadingRaffle = false
			this.raffleTabs = this.raffleTabs.map(t => {
				const tCopy = t
				if (t.key === value.key) tCopy.loading = false
				return tCopy
			})
		},
		formatDate(date) {
			return {
				weekDay: moment.utc(date).locale('es').format('dddd'),
				day: moment.utc(date).locale('es').format('D'),
				month: moment.utc(date).locale('es').format('MMMM'),
				time: moment.utc(date).locale('es').format('LT'),
			}
		},
		handleRedirectTo(id) {
			if (id) window.open(locationTypes[id].link, '_blank')
		},
	},
}
</script>

<style lang="scss" scoped>
.MadMixScreen {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: white;
}

.ImageSection {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	height: 15rem;
	width: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position-x: center;
	background-position-y: center;

	@media screen and (max-width: $sm) {
		height: 12rem;
	}
}

.mad {
	min-height: 30rem;

	@media screen and (max-width: $sm) {
		min-height: 28rem;
	}
}

.Title {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 8rem;

	@media screen and (max-width: $sm) {
		margin: 100px 0 50px 0;
	}

	&.down {
		margin-top: 80px;

		@media screen and (max-width: $sm) {
			margin: 60px 0 10px 0;
		}
	}

	h2 {
		font-weight: $font-weight-normal;
		text-align: center;
		color: white;

		span {
			font-weight: $font-weight-bold;
			font-size: 4rem;
			line-height: 5rem;
			color: $white;
			opacity: .4;

			@media screen and (max-width: $sm) {
				font-size: 3rem;
			}
		}
	}
}

.Calendar {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.List {
	margin-bottom: 30px;
	width: 50%;

	@media screen and (max-width: $md) {
		width: 75%;
	}

	@media screen and (max-width: $sm) {
		width: 90%;
	}

	.ListHeader {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 10px 20px;

		p {
			flex: 1;
			text-align: center;
			color: $white;
			text-transform: capitalize;
		}
	}

	.ListItem {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: white;
		height: 65px;
		padding: 0 20px;
		border-radius: 1rem;
		position: relative;

		.ScoreHomeClub,
		.ScoreVisitor {
			position: absolute;
			background: rgba($white, .05);
			border-radius: 15px;
			height: 55px;
			width: 55px;
			display: flex;
			align-items: center;
			justify-content: center;

			p {
				color: $white;
				font-size: 20px;
			}
		}

		.ScoreHomeClub {
			left: -75px;
		}

		.ScoreVisitor {
			right: -75px;
		}

		&+.ListItem {
			margin-top: 12px;
		}

		.Time {
			text-align: center;

			@media screen and (max-width: $sm) {
				font-size: .9rem;
			}

			.Cancelled {
				color: $white;
				background: rgba($danger, .7);
				margin-top: 8px;
				padding: 3px 6px;
				border-radius: 5px;
				font-size: 10px;
				text-transform: uppercase;
			}
		}

		.Team {
			display: flex;
			align-items: center;
			min-width: 180px;

			@media screen and (max-width: $sm) {
				min-width: 120px;
			}

			&.right {
				justify-content: flex-end;
			}
		}

		.Homeclub,
		.Visitor {
			margin: 0 20px;

			@media screen and (max-width: $sm) {
				font-size: .9rem;
				margin: 0 10px;
			}
		}
	}
}

.Legend {
	width: 100%;
	margin-top: 10px;
	cursor: pointer;

	&::v-deep {
		.Title {
			color: white;
			font-size: 20px;

			@media screen and (max-width: $sm) {
				font-size: 18px;
			}
		}

		.ItemsWrapper {
			background: transparent !important;
			justify-content: center;

			.Item {
				background: rgba(white, .1) !important;
				padding: 10px 20px 10px 12px;

				.ItemText {
					color: white;
					font-size: 14px;
				}
			}
		}
	}
}

.ScoreList {
	width: 90%;

	@media screen and (min-width: $lg) {
		width: 70%;
	}

	.OutsiderName {
		font-size: 24px;
		text-align: center;
		font-weight: bold;
		color: $white;
		margin-bottom: 25px;
		display: none;

		@media screen and (max-width: $sm) {
			display: block;
		}
	}

	.Team {
		display: flex;
		align-items: center;
		margin-bottom: 15px;
		background: rgba($white, .1);
		padding: 8px 16px;
		border-radius: 10px;

		@media screen and (max-width: $sm) {
			padding: 8px;
		}

		&.Header {
			background: none;

			.Name {
				font-size: 20px;
				font-weight: bold;

				@media screen and (max-width: $sm) {
					font-size: 10px;
					color: transparent;
				}
			}
		}

		.Avatar {
			margin-right: 15px;
			min-width: 40px;
			display: flex;
			justify-content: center;
			align-items: center;

			@media screen and (max-width: $sm) {
				margin-right: 10px;
				min-width: 20px;
			}

			img {
				margin: 0 auto;
			}
		}

		.Name {
			color: $white;
			min-width: 150px;
			flex: 1;

			@media screen and (max-width: $md) {
				min-width: 100px;
				font-size: 14px;
			}

			@media screen and (max-width: $sm) {
				min-width: 70px;
				font-size: 12px;
			}
		}

		.Value {
			color: $white;
			min-width: 45px;

			@media screen and (max-width: $sm) {
				min-width: 28px;
				font-size: 12px;
			}
		}
	}
}

.ScoreLegend {
	margin-top: 10px;

	&::v-deep {
		.ItemText {
			font-size: 14px !important;

			@media screen and (max-width: $sm) {
				font-size: 12px !important;
			}
		}

		.Item {
			padding: 5px 15px 5px 8px;
		}
	}
}

.Organization {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: auto;
		height: 220px;
	}
}

.Participants {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 2rem;
	flex-wrap: wrap;

	.Avatar {
		margin-right: 20px;
		transition: all 0.3s ease-in-out;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;

		&:hover {
			transform: scale(1.2);
		}

	}
}



.PillSelectors {
	margin-top: 7rem;
	margin-bottom: 2rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	width: 90%;

	@media screen and (min-width: $lg) {
		width: 70%;
	}

	@media screen and (min-width: $md) {
		flex-direction: row;
		justify-content: space-between;
	}

}

.PillSelectorInput {
	width: fit-content;

	&+.PillSelectorInput {
		margin-left: 0px;
		margin-top: 20px;

		@media screen and (min-width: $md) {
			margin-left: 20px;
			margin-top: 0px;
		}
	}

	&::v-deep .OptionGroup {
		background: rgba($white, .05);

		.Option {
			color: $white;
			background: transparent;

			&.selected,
			&:hover {
				color: $black;
				background: $white;
			}
		}
	}
}

.ListItems {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 15px;
	width: 90%;
	margin-bottom: 3rem;

	@media screen and (min-width: $lg) {
		width: 70%;
	}

	@media screen and (min-width: $sm) {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 20px;
	}

	@media screen and (min-width: $xl) {
		grid-template-columns: repeat(3, 1fr);
	}

	&.names {
		margin-bottom: 3rem;
		grid-template-columns: repeat(2, 1fr);

		@media screen and (min-width: $sm) {
			grid-template-columns: repeat(3, 1fr);
		}

		@media screen and (min-width: $xl) {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	// &.Players {}
	&.All {
		grid-template-columns: 1fr;
		grid-gap: 25px;

		@media screen and (min-width: $md) {
			grid-template-columns: repeat(2, 1fr);
		}

		// @media screen and (min-width: $sm) {
		// 	grid-template-columns: repeat(2, 1fr);
		// }

		// @media screen and (min-width: $xl) {
		// 	grid-template-columns: repeat(2, 1fr);
		// }

		& .ListItems {
			width: 100%;
			grid-template-columns: 1fr;
			border: 2px solid rgba($white, .1);
			border-radius: 20px;
			padding: 20px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			@media screen and (min-width: $lg) {
				grid-template-columns: 1fr;
				width: 100%;
			}

			@media screen and (min-width: $sm) {
				grid-template-columns: 1fr;
			}

			@media screen and (min-width: $xl) {
				grid-template-columns: repeat(2, 1fr);
			}

			.EmptyMessage {
				display: flex;
				align-items: center;
				justify-content: center;

				.Name {
					color: rgba($white, .5);
				}
			}
		}
	}

	.ListItem {
		display: flex;
		align-items: center;
		height: 45px;
		background: rgba($white, .1);
		padding: 8px 16px;
		border-radius: 10px;
		width: 100%;

		@media screen and (max-width: $sm) {
			padding: 8px;
		}

		.Avatar {
			margin-right: 15px;
			min-width: 15px;
			display: flex;
			justify-content: center;
			align-items: center;

			@media screen and (max-width: $sm) {
				margin-right: 10px;
				min-width: 15px;
			}
		}

		&.ListItemTeam {
			background: none;
			padding: 0;
			height: fit-content
		}

		.TeamName,
		.Name {
			color: $white;
			// min-width: 150px;
			flex: 1;

			@media screen and (max-width: $md) {
				// min-width: 100px;
				font-size: 14px;
			}

			@media screen and (max-width: $sm) {
				// min-width: 70px;
			}
		}

		.TeamName {
			font-size: 22px;
			font-weight: bold;
		}

		.Value {
			// min-width: 45px;
			background: white;
			border-radius: 10px;
			padding: 4px 8px;
			white-space: nowrap;
			font-size: 12px;
			font-weight: bold;
			margin-left: 6px;

			@media screen and (max-width: $sm) {
				// min-width: 28px;
				font-size: 12px;
			}
		}

	}
}


.Conditions {
	color: white;
	font-size: .8rem;
	text-decoration: underline;
	margin-top: 1rem
}
</style>
