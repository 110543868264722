import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import VueCookies from 'vue-cookies'
import Vuelidate from 'vuelidate'
import '@/plugins'

import { Icon, Button, Input, Modal, Avatar, Tooltip, FieldError, Legend, PillSelectorInput, Loading, Spinner,
    DateSelector, IconSelect, Select, ButtonSwitch, FadeHeightTransitionGroup, FadeGroupTransition, ListTransition } from './components'

/**/
import axios from 'axios'
import VueAxios from 'vue-axios'
// TODO: Comment to develop
axios.defaults.baseURL = 'https://espartanas-be.herokuapp.com'
axios.defaults.withCredentials = true
Vue.use(VueAxios, axios)
/**/

Vue.use(VueCookies)

Vue.component('Icon', Icon)
Vue.component('Button', Button)
Vue.component('Input', Input)
Vue.component('Modal', Modal)
Vue.component('Avatar', Avatar)
Vue.component('Tooltip', Tooltip)
Vue.component('FieldError', FieldError)
Vue.component('Legend', Legend)
Vue.component('PillSelectorInput', PillSelectorInput)
Vue.component('Loading', Loading)
Vue.component('Spinner', Spinner)
Vue.component('DateSelector', DateSelector)
Vue.component('IconSelect', IconSelect)
Vue.component('Select', Select)
Vue.component('ButtonSwitch', ButtonSwitch)
Vue.component('FadeHeightTransitionGroup', FadeHeightTransitionGroup)
Vue.component('FadeGroupTransition', FadeGroupTransition)
Vue.component('ListTransition', ListTransition)

Vue.directive('permissions', {
    bind: function (el, binding) {
        const skipPermissions = binding && binding.value &&
            binding.value.skipPermissions
        const hasPermissions = binding && binding.value &&
            binding.value.permissions && binding.value.permissions.length
        const allowedPermissions = hasPermissions && binding.value.permissions
        const userPermission = store.getters.me && store.getters.me.permission
        const hasUserId = binding && binding.value && binding.value.userId
        const isSelf = hasUserId && store.getters.me && binding.value.userId === store.getters.me.id
        let display = false

        if (skipPermissions) {
            display = true
        }

        if ((allowedPermissions && allowedPermissions.includes(userPermission)) ||
            (hasUserId && isSelf)) {
            display = true
        }

        if (!display) {
            el.style.display = 'none'
        }
        // How to use v-permissions="{permissions: ['ADMIN']}"
    },
})

const app = new Vue({
    router,
    store,
    Vuelidate,
    render: h => h(App),
}).$mount('#app')

window.app = app
