import moment from 'moment'

export default {
    getInitialDateInfo: (dateInfo) => {
        const currentDate = moment.utc()
        const currentYear = currentDate.format('YYYY')

        const newDateInfo = {
            ...dateInfo,
            currentDate: currentDate,
            selectedDate: currentDate,
            currentYear: currentYear,
            selectedYear: currentYear,
            endYear: moment.utc().add(1, 'year').format('YYYY'),
            selectedMonth: parseInt(currentDate.format('MM')),
        }
        return newDateInfo
    },

    handleSelectedYear: (value, dateInfo) => {
        const newDateInfo = dateInfo
        if (value === 'back' && dateInfo.selectedYear !== dateInfo.startYear) {
            if (newDateInfo.selectedMonth === 1) {
                newDateInfo.selectedDate = moment.utc(dateInfo.selectedDate).subtract(1, 'month')
                newDateInfo.selectedMonth = parseInt(dateInfo.selectedDate.format('MM'))
                newDateInfo.selectedYear = newDateInfo.selectedDate.format('YYYY')
            } else {
                newDateInfo.selectedDate = moment.utc(dateInfo.selectedDate).subtract(1, 'year')
                newDateInfo.selectedYear = newDateInfo.selectedDate.format('YYYY')
            }
        } else if (value === 'forward' && dateInfo.selectedYear !== dateInfo.endYear) {
            if (newDateInfo.selectedMonth === 12) {
                newDateInfo.selectedDate = moment.utc(dateInfo.selectedDate).add(1, 'month')
                newDateInfo.selectedMonth = parseInt(dateInfo.selectedDate.format('MM'))
                newDateInfo.selectedYear = newDateInfo.selectedDate.format('YYYY')
            } else {
                newDateInfo.selectedDate = moment.utc(dateInfo.selectedDate).add(1, 'year')
                newDateInfo.selectedYear = newDateInfo.selectedDate.format('YYYY')
            }
        }
        return newDateInfo
    },

    handleSelectedMonth: (value, dateInfo) => {
        const newDateInfo = dateInfo
        if (value === 'back' && dateInfo.selectedMonth !== 1) {
            newDateInfo.selectedDate = moment.utc(dateInfo.selectedDate).subtract(1, 'month')
            newDateInfo.selectedMonth = parseInt(dateInfo.selectedDate.format('MM'))
        } else if (value === 'forward' && dateInfo.selectedMonth !== 12) {
            newDateInfo.selectedDate = moment.utc(dateInfo.selectedDate).add(1, 'month')
            newDateInfo.selectedMonth = parseInt(dateInfo.selectedDate.format('MM'))
        }
        return newDateInfo
    },
}
