<template>
    <TransitionGroup
        name="fade"
        :duration="250"
        :tag="tag"
    >
        <slot />
    </TransitionGroup>
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'FadeGroupTransition',
    props: {
        tag: VueTypes.string.def('div'),
    },
}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .25s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
