<template>
    <div
        class="SelectDatepicker"
        :class="{ error, dark }"
    >
        <p
            v-if="label"
            class="Label"
        >
            {{ label }}
        </p>

        <div
            class="Container"
            :class="{ disabled }"
        >
            <Icon
                :icon="icon"
                :color="iconColor"
                :size="iconSize"
                class="Icon"
                :class="{ onlyCalendar }"
            />
            <vc-date-picker
                :id="id"
                :value="value"
                :mode="mode"
                :attributes="attrsPicker"
                :themeStyles="themePicker"
                :minDate="min"
                :maxDate="max"
                :isRequired="required"
                :inputProps="inputProps"
                :availableDates="availableDates"
                :disabledDates="disabledDate"
                :popoverVisibility="visibility"
                :paneWidth="width"
                :tintColor="tintColor"
                :showDayPopover="showDayPopover"
                :formats="formats"
                :isDoublePaned="double"
                class="Datepicker"
                :class="{ onlyCalendar }"
            />
            <!-- v-on="inputListeners" -->
            <Transition>
                <p
                    v-if="error"
                    class="Error"
                >
                    {{ error }}
                </p>
            </Transition>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { colors } from '@/theme/colors'
import { attrs, formats, theme } from './utils'

// TODO: añadir configuración de colores para modificar los días del fin de semana
export default {
    name: 'Datepicker',
    props: {
        id: VueTypes.string,
        dataCy: VueTypes.string.def('SelectDatepicker'),
        error: VueTypes.string.def(''),
        mode: VueTypes.oneOf(['single', 'multiple', 'range']).def('single'),
        formats: VueTypes.object.def(formats),
        double: VueTypes.bool.def(false),
        theme: VueTypes.oneOf([Object, null]), // https://vcalendar.io/api/theme-styles.html
        attrs: VueTypes.oneOf([Object, null]), // https://vcalendar.io/api/attribute.html#properties
        width: VueTypes.number.def(320),
        min: VueTypes.oneOfType([Date, String]),
        max: VueTypes.oneOfType([Date, String]),
        value: VueTypes.oneOfType([String, Object, Array, Date]),
        name: VueTypes.string,
        placeholder: VueTypes.string,
        label: VueTypes.string.optional,
        readonly: VueTypes.bool.def(false),
        disabled: VueTypes.bool.def(false),
        inputClass: VueTypes.string,
        required: VueTypes.bool.def(false),
        showDayPopover: VueTypes.bool.def(false), // Tooltip with day info
        availableDates: VueTypes.array,
        disabledDate: VueTypes.array,
        dark: VueTypes.bool.def(false),
        icon: VueTypes.string.def('calendar'),
        iconColor: VueTypes.string.def('grayDark'),
        iconSize: VueTypes.oneOfType([String, Number]).def(16),
        tintColor: VueTypes.string.def(colors.primary),
        visibility: VueTypes.oneOf(['hover', 'focus', 'hidden', 'visible']).def('focus'),
        onlyCalendar: VueTypes.bool.def(false),
    },
    data () {
        return {
            themePicker: this.theme || theme,
            attrsPicker: this.attrs || attrs,
        }
    },
    computed: {
        inputProps () {
            const {
                name, placeholder, readonly, disabled,
            } = this
            return {
                name,
                class: 'input',
                placeholder,
                readonly,
                disabled,
            }
        },
    },
}
</script>

<style scoped lang="scss">

.SelectDatepicker {
    .Container {
        position: relative;
        margin-bottom: 16px;

        &.disabled {
            pointer-events: none;
        }
    }
    .Datepicker {
       background: tomato;
        &::v-deep .input {
            min-height: $form-height;
            width: 100%;
            padding: 0 48px 0 16px;
            border-radius: $border-radius;
            border: none;
            box-shadow: inset 0 0 0 1px rgba($primary, .5);
            background-color: $white;
            color: $gray-01;
            font-size: $form-font-size;
            font-weight: $form-font-weight;
            box-sizing: border-box;
            cursor: pointer;
            outline: none;
            transition: box-shadow 0.3s ease-in-out;

            &:focus {
                box-shadow: inset 0 0 0 1px rgba($primary, .5);
            }
            &[readonly] {
                opacity: 0.5;
                cursor: not-allowed;
                &:focus {
                    box-shadow: inset 0 0 0 1px rgba($primary, .5);
                }
            }
            &:disabled {
                cursor: not-allowed;
                background-color: $gray-05;
                color: $gray-05;
                box-shadow: none;
            }
        }

        &.onlyCalendar {
            &::v-deep .Input {
                display: none;
            }

            &::v-deep .popover-origin {
                position: relative;
            }
        }
    }

    &.error {
        .Datepicker ::v-deep .Input {
            border-color: $danger;
        }
    }

    .Label {
        margin: 0 0 4px 16px;
        color: $gray-02;
    }
    &.dark {
        .Label {
            color: $white !important;
        }
        .Datepicker {
            &::v-deep .Input {
                box-shadow: none;
            }
        }
    }
}

.Icon {
    position: absolute;
    top: 50%;
    right: 16px;
    padding: 0px;
    transform: translateY(-50%);
    z-index: 10;
    pointer-events: none;

    &.onlyCalendar {
        display: none;
    }
}

.Error {
    position: absolute;
    top: $form-height + 4px;
    left: 16px;
}
</style>
