<template>
    <Transition
        name="fade"
        :duration="duration"
    >
        <div
            v-if="visible"
            :class="position"
            class="Loading"
        >
            <Spinner
                visible
                noMargin
                :color="spinnerColor"
            />
            <p
                v-if="text"
                :color="textColor"
                class="Text"
            >
                {{ text }}
            </p>
            <slot />
        </div>
    </Transition>
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'Loading',
    props: {
        color: VueTypes.oneOf(['primary', 'secondary', 'white', 'grayMedium']).def('primary'),
        position: VueTypes.oneOf(['relative', 'absolute', 'fixed']).def('relative'),
        text: VueTypes.string.def(''),
        opacity: VueTypes.number.def(0.8),
        visible: VueTypes.bool.def(false),
        duration: VueTypes.number.def(500),
    },
    computed: {
        backdropStyles () {
            return {
                opacity: this.opacity,
            }
        },
        backdropClass () {
            return [
                this.color,
            ]
        },
        spinnerColor () {
            return this.color === 'tertiary' ? 'white' : this.color
        },
        textColor () {
            return this.color === 'grayMedium' ? 'gray03' : this.color
        },
    },
}
</script>

<style scoped lang="scss">
@import '@/theme/_variables.scss';
$z-index-loading: 2000;

.Loading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // max-height: 100%;
    // max-width: 100%;
    z-index: $z-index-loading;

    &.relative { position: relative }
    &.absolute { position: absolute }
    &.fixed { position: fixed }
}

.Spinner {
    z-index: $z-index-loading + 100;
}

.Text {
    margin-top: 30px;
    z-index: $z-index-loading + 2;
}
</style>
