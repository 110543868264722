<template>
    <div class="LineupInformation">
        <Loading
            v-if="isLoadingSelect"
            key="loading"
            :visible="isLoadingSelect"
            :opacity="1"
            :duration="0"
        />
        <div
            v-else-if="lineUps.length && !isLoadingSelect"
            class="ScreenWrapper"
        >
            <div class="ListHeader">
                <p class="Title">
                    Acciones
                </p>
            </div>
            <PillSelectorInput
                v-model="selectedActionTab"
                class="ActionButtons"
                :options="actionTabs"
                :addLoading="false"
                preventDeselect
                useIcons
            />
            <div class="ContentWrapper">
                <div class="VersionsList">
                    <div class="ListHeader">
                        <p class="Title">
                            Versiones
                        </p>
                    </div>
                    <PillSelectorInput
                        v-if="selectedActionTab !== actionTabsKeys.COMPARE"
                        v-model="selectedLineUp"
                        :options="lineUps"
                        :addLoading="false"
                        preventDeselect
                        border="gray-dark"
                        @input="handleSelectLineUp"
                    />
                    <PillSelectorInput
                        v-else
                        v-model="selectedLineUps"
                        :options="lineUps"
                        :addLoading="false"
                        preventDeselect
                        multiple
                        border="gray-dark"
                        @input="handleSelectLineUpsToCompare"
                    />
                    <Button
                        v-if="selectedActionTab === actionTabsKeys.COMPARE && selectedLineUps.length === 2"
                        text="Comparar"
                        styleType="primary"
                        class="Compare"
                        @click="handleCompareLineUp"
                    />
                </div>
                <div class="flex-1">
                    <LineupGenerator
                        v-if="selectedActionTab === actionTabsKeys.EDIT"
                        :eventId="eventId"
                        :originalLineUpData="lineUpData"
                        :admitsRename="false"
                        @viewLineUp="hanldeViewLineUp"
                    />
                    <div
                        v-if="selectedActionTab === actionTabsKeys.DUPLICATE"
                        class="ButtonWrapper"
                    >
                        <Input
                            v-model="lineUpData.name"
                            type="text"
                            placeholder="Nombre"
                            color="grayDark"
                            :name="`name`"
                        />
                        <Input
                            v-model="lineUpData.number"
                            type="number"
                            placeholder="Número"
                            color="grayDark"
                            :name="`number`"
                        />
                        <Button
                            type="submit"
                            text="Guardar"
                            :disabled="!lineUp.length"
                            iconLeft="save"
                            iconColor="grayDark"
                            styleType="white"
                            @click="handleSaveLineUp"
                        />
                    </div>
                    <div :class="{ Comparator: selectedActionTab === actionTabsKeys.COMPARE }">
                        <LineupList
                            v-if="[actionTabsKeys.READ, actionTabsKeys.DUPLICATE, actionTabsKeys.COMPARE].includes(selectedActionTab)"
                            :lineUp="selectedActionTab !== actionTabsKeys.COMPARE ? lineUp : lineUpToCompare1.lineUp"
                            :bench="bench"
                            :isLoading="isLoading"
                            :displayBench="selectedActionTab !== actionTabsKeys.COMPARE"
                            :isSuperThin="[actionTabsKeys.COMPARE].includes(selectedActionTab)"
                        />
                        <LineupList
                            v-if="selectedActionTab === actionTabsKeys.COMPARE"
                            :lineUp="lineUpToCompare2.lineUp"
                            :bench="bench"
                            :isLoading="isLoading"
                            :displayBench="false"
                            :displayNumbers="false"
                            :isSuperThin="true"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div
            v-else-if="!eventId && !isLoading && !isLoadingSelect"
            class="Empty"
        >
            Selecciona un partido del listado.
        </div>
        <div
            v-else-if="eventId && !lineUps.length && !isLoading && !isLoadingSelect"
            class="Empty"
        >
            No ha sido creado ningún lineup para este partido.
        </div>
        <Modal
            :isOpen="isModalOpen"
            @close="handleCloseModal"
        >
            <template #body>
                <div
                    v-for="(inf, index) in formattedInfo"
                    :key="index"
                >
                    <div
                        v-for="(line, i) in inf"
                        :key="i"
                    >
                        {{ line }}
                    </div>
                    <br>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import lineUpIntegration from '@/integration/lineUpIntegration'
import LineupGenerator from './LineupGenerator.vue'
import LineupList from './LineupList.vue'
import { actionTabsKeys, actionTabs } from '@/entities/lineUp'
import lineUpScreenUtils from '@/utils/lineUpScreenUtils'

export default {
    name: 'LineupInformation',
    components: {
        LineupGenerator,
        LineupList,
    },
    props: {
        eventId: VueTypes.oneOfType([Number, null]),
    },
    data () {
        return {
            actionTabs,
            actionTabsKeys,
            lineUps: [],
            lineUp: [],
            lineUpToCompare1: { lineUp: [] },
            lineUpToCompare2: { lineUp: [] },
            bench: [],
            selectedLineUp: null,
            lineUpData: {},
            isLoading: false,
            isLoadingSelect: false,
            selectedLineUps: [],
            selectedActionTab: actionTabsKeys.READ,
            isModalOpen: false,
            formattedInfo: [],
        }
    },
    watch: {
        async eventId () {
            this.isLoadingSelect = true
            const lineups = await lineUpIntegration.listLineUps(this.eventId, { group: true })

            if (lineups.length) {
                this.lineUps = lineups
                this.selectedLineUp = lineups[0].id
                this.handleSelectLineUp(lineups[0].id)
            } else {
                this.lineUp = []
                this.lineUps = []
                this.bench = []
                this.selectedLineUp = null
            }
            this.isLoadingSelect = false
        },
    },
    async mounted () {
        this.isLoadingSelect = true
        const lineups = await lineUpIntegration.listLineUps(this.eventId, { group: true })
        this.lineUps = lineups
        if (lineups.length) {
            this.selectedLineUp = lineups[0].id
            this.handleSelectLineUp(lineups[0].id)
        }
        this.isLoadingSelect = false
    },
    methods: {
        hanldeViewLineUp () {
            this.selectedActionTab = actionTabsKeys.READ
        },
        async handleSelectLineUp (lineUpId) {
            this.isLoading = true
            if (lineUpId) {
                const { lineUp, bench, lineUpData } = await this.searchLineUpInDB(lineUpId)
                this.lineUp = lineUp
                this.bench = bench
                this.lineUpData = lineUpData
            } else {
                this.lineUp = []
                this.bench = []
            }
            this.isLoading = false
        },
        async searchLineUpInDB (lineUpId) {
            const lineUpData = this.lineUps.find(l => l.id === lineUpId)
            const { lineUp, bench } = await lineUpIntegration.getLineUp(lineUpData.eventId,
                {
                    number: lineUpData.number,
                    name: lineUpData.name,
                })
            return { lineUp, bench, lineUpData }
        },
        async handleSelectLineUpsToCompare (lineUpId, a) {
            if (this.selectedLineUps.length > 2) this.selectedLineUps.pop()
            this.isLoading = true
            if (this.selectedLineUps.length) {
                if (!this.lineUpToCompare1.lineUp.length) this.lineUpToCompare1 = await this.searchLineUpInDB(lineUpId[0])
                else if (this.selectedLineUps.length === 2 && !this.lineUpToCompare2.lineUp.length) this.lineUpToCompare2 = await this.searchLineUpInDB(lineUpId[1])
                else if (this.selectedLineUps.length === 1 && this.lineUpToCompare1.lineUp.length && this.lineUpToCompare2.lineUp.length) {
                    if (this.lineUpToCompare1.lineUpData.id === this.selectedLineUps[0]) this.lineUpToCompare2 = { lineUp: [] }
                    else {
                        this.lineUpToCompare1 = this.lineUpToCompare2
                        this.lineUpToCompare2 = { lineUp: [] }
                    }
                }
            } else {
                this.lineUpToCompare1 = { lineUp: [] }
                this.lineUpToCompare2 = { lineUp: [] }
            }
            this.isLoading = false
        },
        async handleSaveLineUp () {
            this.lineUpData.eventId = this.eventId
            await lineUpIntegration.saveLineUp(this.lineUp, this.bench, this.lineUpData, true)
        },
        handleCompareLineUp () {
            this.formattedInfo = lineUpScreenUtils.compareLineUps(this.lineUpToCompare1.lineUp, this.lineUpToCompare2.lineUp)
            this.isModalOpen = true
        },
        handleCloseModal () {
            this.formattedInfo = []
            this.isModalOpen = false
        },
    },
}
</script>
<style lang="scss" scoped>
.flex {
	display: flex;
	max-width: 100%;
	flex: 1;
}
.Comparator {
	display: flex;
	max-width: 100%;
	flex: 1;
	.LineupList {
		max-width: 45%;
		&:first-child {
			max-width: 55%;
		}
	}
	@media screen and (min-width: $lg) {
		max-width: fit-content;
	}
}
.flex-1 {
	flex: 1;
}
.ScreenWrapper {
	.PillSelectorInput {
		width: fit-content;
		@media screen and (max-width: $sm) {
			&.ActionButtons {
				width: 100%;
			}
		}
		&::v-deep .OptionGroup .Option {
			@media screen and (max-width: $sm) {
				font-size: 12px;
				padding: 7px 10px 7px 8px;
				.Icon {
					margin-right: 1px;
					font-size: 14px !important;
				}
			}
		}
	}
	// display: flex;
	// overflow: hidden;
	// box-sizing: border-box;
	// 	flex-direction: column;
}
.ContentWrapper {
	display: flex;
	overflow: hidden;
	box-sizing: border-box;
	margin-top: 40px;
	@media screen and (max-width: $sm) {
		margin-top: 30px;
		flex-direction: column;
	}
}
// .ActionButtons {
// 	//
// }
.ml-2 {
	margin-left: 5px;
}
.ListHeader {
		display: flex;
		margin-bottom: 10px;
		// @media screen and (max-width: $sm) {
		// 	margin-bottom: 0px;
		// }
		.Title {
			text-transform: uppercase;
			font-size: 14px;
			color: $gray-02;
			@media screen and (max-width: $sm) {
				font-size: 12px;
			}
		}
	}
.VersionsList {
	display: flex;
	flex-direction: column;
	.PillSelectorInput {
		width: fit-content;
		margin-right: 30px;
		border-radius: 15px;
		height: fit-content;
		@media screen and (max-width: $sm) {
			margin-right: 0px;
		}
		&::v-deep {
			.OptionGroup {
				background-color: transparent;
				flex-direction: column;
				@media screen and (max-width: $sm) {
					flex-direction: row;
				}
				.Option {
					color: $gray-dark;
					background-color: transparent;
					&.selected,
					&:hover {
						font-weight: $font-weight-semibold;
						color: $white;
					}
					&:hover {
						background-color: rgba($primary, 0.7);
					}
					&.selected {
						background-color: $primary;
					}
				}
			}
		}
	}
}
.LineupGenerator {
	@media screen and (max-width: $sm) {
		margin-top: 30px;
	}
}
.ButtonWrapper {
	display: flex;
	margin-bottom: 40px;
	border-radius: 15px;
	border: 1px solid $gray-04;
	max-width: 300px;
	@media screen and (max-width: $sm) {
		margin: 30px 0 0 0;
		max-width: 100%;
	}
	.Input {
		margin-left: 10px;
		&::v-deep input {
			border: none;
		}
	}
	.Button {
		background: transparent;
		transform: scale(.8);
		&:hover {
			color: $black;
		}
	}
}
.Compare {
	margin: 20px 30px 0 0 !important;
	border-radius: 15px;
	padding: 10px 18px !important;
	font-size: 16px;
	width: fill-available;
	transform: scale(1) !important;
	@media screen and (max-width: $sm) {
		width: fit-content;
		align-self: flex-end;
		margin: 20px 0px 0 0 !important;
		font-size: 14px;
	}
}
</style>
