<template>
	<div class="HomepageScreen">
		<div class="Wrapper">
			<div class="Cover" :style="{ backgroundImage: `url(${require(`@/assets/imgs/pictures/photo-01.jpg`)})` }">
				<h2>
					Club deportivo<br />
					<span>ESPARTANAS</span><br />
					<b>Kickingball</b> Madrid
				</h2>
				<iframe
					v-if="!isMobile"
					class="Video"
					src="https://www.youtube.com/embed/HlB4pF1Ge50"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen
				/>
			</div>
			<div class="TextSection">
				<iframe
					v-if="isMobile"
					class="Video"
					src="https://www.youtube.com/embed/HlB4pF1Ge50"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen
				/>
				<div class="flex">
					<div v-if="events.length" class="Calendar">
						<h3>Partidos</h3>
						<Splide :options="sliderOptions">
							<SplideSlide v-for="{ id, name, location, date, score, link } in events || []" :key="id">
								<div class="EventBox">
									<div class="EventInfo">
										<h6 class="Name">
											{{ name }}
										</h6>
									</div>
									<div v-if="score" class="Finalized" @click="handleRedirectTo(link)">
										<p class="Score">
											RESULTADO:
											<br />
											<b>{{ score }}</b>
										</p>
										<Icon v-if="link" icon="play" :size="40" color="white" />
									</div>
									<div class="EventImage" :style="{ backgroundImage: `url(${require(`@/assets/imgs/${images[Math.floor(Math.random() * images.length)]}`)})` }" />
									<div class="Shadow">
										<div class="Date">
											<p class="Month">
												{{ formatDate(date).month }}
											</p>
											<p class="Day">
												{{ formatDate(date).day }}
											</p>
										</div>
										<div>
											<div class="Time">
												<Icon icon="clock" :size="20" color="primary" />
												<p>{{ formatDate(date).time }}</p>
											</div>
											<div class="Location">
												<Icon icon="map-pin" :size="20" color="primary" />
												<p>{{ location }}</p>
											</div>
										</div>
									</div>
								</div>
							</SplideSlide>
						</Splide>
					</div>
					<!-- <div class="StrengthsWrapper">
                        <div
                            v-for="({ name, icon }, index) in strengths"
                            :key="index"
                            class="Strength"
                        >
                            <div class="BulletIcon">
                                <Icon
                                    :icon="icon"
                                    :size="32"
                                    color="white"
                                />
                            </div>
                            <p>{{ name }}</p>
                        </div>
                    </div> -->
				</div>
			</div>
			<div class="ImageSection" :style="{ backgroundImage: `url(${require(`@/assets/imgs/pictures/photo-02.jpg`)})` }">
				<h3>Verano 2015</h3>
				<p>Iniciamos nuestros entrenamientos en verano del 2015, siendo el <b>equipo pionero</b> de Kickingball en Madrid.</p>
			</div>
			<div class="TextSection">
				<h3>_</h3>
				<p>A lo largo de estos años hemos participado en diferentes torneos y partidos amistosos, demostrando nuestro talento y espíritu deportivo.</p>
			</div>
			<div class="ImageSection" :style="{ backgroundImage: `url(${require(`@/assets/imgs/pictures/photo-03.jpg`)})` }">
				<h3>Otoño 2018</h3>
				<p>El crecimiento del Kickingball en Madrid hizo posible formalizar la primera edición de la Liga en el otoño del 2018, donde logramos ser campeonas.</p>
			</div>
			<div class="TextSection">
				<h3>_</h3>
				<!-- <p>
                    bla bla
                </p> -->
			</div>
			<div class="ImageSection" :style="{ backgroundImage: `url(${require(`@/assets/imgs/pictures/photo-03.jpg`)})` }">
				<h3>Primavera 2021</h3>
				<p>Defendimos el título en la segunda edición de la Liga, logrando ser bicampeonas.</p>
			</div>
			<div class="TextSection">
				<h3>_</h3>
				<p>
					Nos mantenemos en continua preparación con dos entrenamientos semanales.<br />
					Participamos en la tercera y cuarta liga, quedando de 3er lugar en esta última.<br />
					Esperamos con emoción la quinta edición de la Liga, prevista para la primavera del 2024.
				</p>
			</div>
			<div class="ImageSection longer" :style="{ backgroundImage: `url(${require(`@/assets/imgs/pictures/photo-04.jpg`)})` }">
				<h3>Espartanas</h3>
				<p>
					Esparta no tenía ejército, lo era.
					<a href="https://youtu.be/aN_2D_e4INY" target="_blank" class="Link"> Los soldados espartanos </a> fueron conocidos en la historia como grandes guerreros. Eran
					los más disciplinados y entrenados de la antigua Grecia; fueron admirados y temidos en su propio tiempo. Un espartano jamás se rendiría, jamás daría la espalda
					a la batalla, jamás huiría abandonando a sus hermanos.
				</p>
				<p>
					<br />
					De la misma forma, las espartanas somos leales a nuestro escudo y luchamos juntas con fuerza e inteligencia. Nos sentimos identificadas con el
					<a href="https://www.instagram.com/p/B_h5ALtIOvK/?igshid=1j243a778flqh" target="_blank" class="Link"> Código Espartano</a>. ¡AU AU!
				</p>
			</div>
			<div class="TextSection">
				<div class="PicturesWrapper">
					<div class="TeamPicture short" :style="{ backgroundImage: `url(${require(`@/assets/imgs/pictures/photo-05.jpg`)})` }" />
					<div class="TeamPicture" :style="{ backgroundImage: `url(${require(`@/assets/imgs/pictures/photo-06.jpg`)})` }" />
				</div>
				<h3>_</h3>
				<p>
					Un buen deportista debe destacar además de por su talento por los rasgos de su personalidad: disciplina, constancia, autocontrol, nobleza, voluntad, generosidad
					y honestidad.
				</p>
			</div>
			<!-- <div class="ImageSection long" :style="{ backgroundImage: `url(${require(`@/assets/imgs/pictures/photo-07.jpg`)})` }">
				<h3>Patrocinio</h3>
				<p>
					Estamos en búsqueda de empresas que estén interesadas en patrocinar nuestra participación deportiva en la próxima liga de kickingball de Madrid.
					<br /><br />El objetivo es el de crear una alianza en la cual ambas partes salgamos beneficiadas. Nos comprometemos a promover la marca y colaborar con la
					publicidad de la empresa.
				</p>
			</div> -->

			<div class="TextSection">
				<div class="StrengthsWrapper">
					<div v-for="({ name, icon }, index) in strengths" :key="index" class="Strength">
						<div class="BulletIcon">
							<Icon :icon="icon" :size="32" color="white" />
						</div>
						<p>{{ name }}</p>
					</div>
				</div>
				<!-- <h3>Únete</h3>
                <p>
                    Si estás interesada en formar parte del equipo, comunícate con nosotras.
                    No es necesario tener experiencia, de no conocer el deporte, te enseñaremos con mucho gusto.
                </p> -->
			</div>
			<div class="SocialMediaSection">
				<div class="Image" :style="{ backgroundImage: `url(${require(`@/assets/imgs/pictures/photo-08.jpg`)})` }" />
				<div class="SocialsWrapper">
					<a v-for="({ name, link, icon, url }, index) in socialMedia.slice(1)" :key="index" class="SocialMedia" :href="link" target="_blank">
						<div class="Icon">
							<Icon :icon="icon" :size="28" color="white" />
						</div>
						<div class="Info">
							<p class="Title">{{ name }}</p>
							<p>{{ url }}</p>
						</div>
					</a>
				</div>
			</div>
			<!-- TODO: Add sponsor -->
			<!-- <div class="SponsorSection">
				<p>Sponsor principal</p>
				<a v-for="({ link, image }, index) in sponsors" :key="index" :href="link" target="_blank">
					<img :src="require(`@/assets/imgs/sponsors/${image}.png`)" class="Sponsor" />
				</a>
			</div> -->
			<div class="SponsorSection">
				<p>Con la colaboración de:</p>
				<a v-for="({ link, image }, index) in sponsors" :key="index" :href="link" target="_blank">
					<img :src="require(`@/assets/imgs/sponsors/${image}.png`)" class="Sponsor" />
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import { mediaQueryMixin } from "@/mixins";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import publicApi from "@/api/public";
import moment from "moment";

export default {
	name: "HomepageScreen",
	components: {
		Splide,
		SplideSlide,
	},
	mixins: [mediaQueryMixin],
	data() {
		return {
			images: [
				"random/photo-01.jpg",
				"random/photo-02.jpg",
				"random/photo-03.jpg",
				"random/photo-04.jpg",
				"random/photo-05.jpg",
				"random/photo-06.jpg",
				"random/photo-07.jpg",
				"random/photo-08.jpg",
				"random/photo-09.jpg",
				"random/photo-10.jpg",
				"random/photo-11.jpg",
				"random/photo-12.jpg",
				"random/photo-13.jpg",
				"random/photo-14.jpg",
				"random/photo-15.jpg",
				"random/photo-16.jpg",
				"random/photo-17.jpg",
				"random/photo-18.jpg",
				"random/photo-19.jpg",
				"random/photo-20.jpg",
				"random/photo-21.jpg",
				"random/photo-22.jpg",
				"random/photo-23.jpg",
				"random/photo-24.jpg",
				"random/photo-25.jpg",
			],
			sponsors: [
				{
				    image: 'construcciones_y_reformas',
				    link: 'https://www.instagram.com/construccionesyreformasvf/',
				},
				{
				    image: 'magu_tours',
				    link: 'https://www.instagram.com/magutours/',
				},
				// {
				//     image: 'comandalasal',
				//     link: 'https://cateringcomandalasal.es/',
				// },
				// {
				//     image: 'laCanallla',
				//     link: 'https://www.lacanallalive.com/',
				// },
			],
			socialMedia: [
				{
					name: "info@espartanas.com",
					icon: "mail",
					url: "info@espartanas.com",
				},
				{
					name: "Instagram",
					icon: "instagram",
					url: "@espartanaskickingball",
					link: "https://www.instagram.com/espartanaskickingball/",
				},
				{
					name: "Facebook",
					icon: "facebook",
					url: "@espartanaskickingball",
					link: "https://www.facebook.com/EspartanasKickingball",
				},
				{
					name: "Youtube",
					icon: "youtube",
					url: "Espartanas Kickingball",
					link: "https://www.youtube.com/channel/UCmzmsqUUHx2vuUqnoXbSy1w",
				},
			],
			strengths: [
				{
					name: "Talento",
					icon: "award",
				},
				{
					name: "Compañerismo",
					icon: "link",
				},
				{
					name: "Empatía",
					icon: "heart",
				},
				{
					name: "Pasión",
					icon: "activity",
				},
			],
			events: [],
		};
	},
	computed: {
		isMobile() {
			return this.$mq.w < this.$mq.sm.min;
		},
		isTablet() {
			return this.$mq.w < this.$mq.md.min;
		},
		perPage() {
			if (this.isMobile) return 1;
			else if (this.isTablet) return 2;
			else return 3;
		},
		sliderOptions() {
			return {
				perMove: 1,
				width: "100%",
				gap: "2rem",
				perPage: this.perPage,
				pagination: true,
				autoplay: true,
				interval: 8000,
				type: "loop",
				focus: "center",
			};
		},
	},
	created() {
		this.getUpcommingEvents();
	},
	methods: {
		formatDate(date) {
			return {
				day: moment.utc(date).locale("es").format("D"),
				month: moment.utc(date).locale("es").format("MMMM"),
				time: moment.utc(date).local().locale("es").format("LT"),
			};
		},
		async getUpcommingEvents() {
			const events = (
				await publicApi.listEvents({
					from: moment.utc().startOf("day").subtract("5", "months").format(),
				})
			).items;
			this.events = events;
		},
		handleRedirectTo(link) {
			if (link) window.open(link, "_blank");
		},
	},
};
</script>
<style lang="scss" scoped>
.HomepageScreen {
	width: 100%;
	min-height: 100vh;
	background: #333;
	@media screen and (min-width: $xl) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.Wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		@media screen and (min-width: $xl) {
			width: 1300px;
		}
		.Cover {
			height: 50rem;
			width: 100%;
			background-size: cover;
			background-repeat: no-repeat;
			background-position-x: center;
			background-position-y: top;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			@media screen and (max-width: $sm) {
				height: 25rem;
			}
			h2 {
				font-weight: $font-weight-normal;
				text-align: center;
				font-size: 1.4rem;
				color: white;
				@media screen and (min-width: $md) {
					font-size: 1.5rem;
				}
				span {
					font-weight: $font-weight-bold;
					font-size: 2.8rem;
					line-height: 4rem;
					color: $primary;
					@media screen and (min-width: $md) {
						font-size: 4rem;
						line-height: 5rem;
					}
				}
			}
			.Video {
				position: absolute;
				bottom: 4rem;
				left: 4rem;
				border-radius: 20px;
				border: 5px solid rgba(white, 0.1);
				box-shadow: 0 2px 5px 0 rgba(black, 0.1);
			}
		}
		.Calendar {
			padding: 0;
			@media screen and (max-width: $md) {
				margin-top: 20px;
			}
			@media screen and (max-width: $sm) {
				margin-top: 50px;
			}
			h3 {
				margin-bottom: 15px !important;
				@media screen and (min-width: $md) {
					margin-bottom: 0 !important;
				}
			}
			&::v-deep .splide__track {
				min-height: 28rem;
				@media screen and (max-width: $sm) {
					min-height: 25rem;
				}
				.splide__list {
					align-items: center;
				}
				.splide__slide {
					height: 20rem;
					transition: all 0.5s;
					box-shadow: 0px 0px 10px 5px rgba($black, 0.1);
				}
				@media screen and (min-width: $sm) {
					.is-active {
						height: 25rem;
						transition: all 0.5s;
					}
				}
				@media screen and (max-width: $sm) {
					.is-active {
						height: 22rem;
						transition: all 0.5s;
					}
				}
			}
			.EventBox {
				height: 100%;
				position: relative;
				transition: all 0.3s ease-in-out;
				border-radius: 20px;
				overflow: hidden;
				&:hover {
					.Finalized {
						bottom: 0;
					}
				}
				.Finalized {
					background: $primary;
					height: calc(100% - 7rem);
					width: 100%;
					opacity: 1;
					z-index: 99;
					position: absolute;
					bottom: -100%;
					transition: all 0.3s ease-in-out;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: flex-start;
					cursor: pointer;
					.Score {
						color: $white;
						font-size: 1.6rem;
						text-align: center;
						padding: 20px 20px 0 20px;
						align-self: flex-end;
						b {
							padding: 2px 15px;
							box-shadow: 0px 0px 8px 10px rgba($black, 0.03);
						}
					}
					.Icon {
						flex: 1;
						display: flex;
						align-self: center;
						align-items: center;
						padding: 0 40px;
						&:hover::before {
							mix-blend-mode: overlay;
						}
					}
				}
				.EventImage {
					background-size: cover;
					background-repeat: no-repeat;
					background-position-x: center;
					background-position-y: center;
					height: 100%;
					width: 100%;
					opacity: 0.4;
					z-index: 0;
					position: absolute;
					top: 0;
				}
				.Shadow {
					z-index: 1;
					position: absolute;
					background-image: linear-gradient(0deg, $body-color, transparent);
					bottom: 0;
					width: 100%;
					height: 80%;
					display: flex;
					align-items: flex-end;
					.Date {
						background: $primary;
						padding: 20px 30px;
						margin-right: 20px;
						@media screen and (max-width: $lg) {
							padding: 15px 25px;
							margin-right: 15px;
						}
						.Month {
							font-size: 1.3rem;
							font-weight: bold;
							text-transform: uppercase;
							@media screen and (max-width: $lg) {
								font-size: 1rem;
							}
						}
						.Day {
							text-align: center;
							font-size: 2.5rem;
							@media screen and (max-width: $lg) {
								font-size: 2.2rem;
							}
						}
					}
					.Location,
					.Time {
						display: flex;
						align-items: center;
						margin-bottom: 10px;
						.Icon {
							margin-right: 15px;
						}
						p {
							margin: 0;
						}
					}
					.Location {
						margin-bottom: 20px;
					}

					.Button {
						display: none;
						transition: all 0.3s ease-in-out;
					}
				}
				.EventInfo {
					z-index: 10;
					position: relative;
					.Name {
						color: white;
						font-size: 1.6rem;
						line-height: 2.5rem;
						padding: 20px;
						@media screen and (max-width: $lg) {
							font-size: 1.5rem;
							line-height: 2.2rem;
						}
						@media screen and (max-width: $md) {
							font-size: 1.4rem;
							line-height: 2rem;
						}
					}
				}
			}
		}
		.ImageSection,
		.TextSection {
			height: 40rem;
			width: 100%;
			background-size: cover;
			background-repeat: no-repeat;
			background-position-x: center;
			background-position-y: center;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			padding: 6rem 2rem 2rem 4rem;
			@media screen and (max-width: $md) {
				height: 25rem;
				padding: 2rem;
				min-height: fit-content;
			}
			h3 {
				font-size: 2.8rem;
				line-height: 3.5rem;
				font-weight: $font-weight-bold;
				color: $primary;
				@media screen and (max-width: $md) {
					font-size: 2rem;
				}
			}
			p {
				font-size: 1.6rem;
				line-height: 2.8rem;
				width: 70%;
				color: white;
				@media screen and (max-width: $md) {
					font-size: 1.2rem;
					line-height: 2rem;
					width: 100%;
				}
			}
			.Link {
				color: $gray-medium;
				text-decoration: underline;
			}
		}
		.long {
			@media screen and (max-width: $md) {
				height: 35rem;
			}
			p {
				width: 90%;
				@media screen and (max-width: $md) {
					width: 100%;
				}
			}
		}
		.longer {
			@media screen and (max-width: $md) {
				height: 45rem;
			}
			p {
				width: 90%;
				@media screen and (max-width: $md) {
					width: 100%;
				}
			}
		}
		.TextSection {
			height: auto;
			padding: 4rem;
			@media screen and (max-width: $md) {
				padding: 2rem;
			}
			h3 {
				line-height: 2rem;
				margin: 0;
				margin-bottom: 2rem;
				@media screen and (max-width: $md) {
					line-height: 0rem;
				}
			}
			p {
				width: 100%;
				@media screen and (max-width: $md) {
					margin-bottom: 1rem;
				}
			}
			.flex {
				display: flex;
				width: 100%;
				flex-direction: column;
			}
			.PicturesWrapper {
				display: flex;
				width: 100%;
				margin-bottom: 50px;
			}
			.TeamPicture {
				width: 50%;
				flex: 1;
				height: 450px;
				border-radius: 20px;
				background-size: cover;
				background-repeat: no-repeat;
				background-position-x: center;
				background-position-y: center;
				& + .TeamPicture {
					margin-left: 20px;
				}
				&.short {
					max-width: 40%;
				}
				@media screen and (max-width: $lg) {
					height: 300px;
				}
				@media screen and (max-width: $md) {
					height: 220px;
				}
				@media screen and (max-width: $sm) {
					height: 180px;
				}
			}
			.Video {
				width: 100%;
				height: 170px;
				border-radius: 20px;
				border: 5px solid rgba(white, 0.1);
				box-shadow: 0 2px 5px 0 rgba(black, 0.1);
			}
			.StrengthsWrapper {
				display: flex;
				width: 100%;
				padding: 0 4rem;
				justify-content: space-around;
				flex-wrap: wrap;
				@media screen and (max-width: $md) {
					font-size: 16px !important;
					padding: 0;
				}
				.Strength {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					& + .Strength {
						margin-left: 20px;
					}
					&:hover {
						.Icon:before {
							transform: scale(1.2);
						}
						p {
							transform: scale(1.1);
						}
					}
					.BulletIcon {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 6rem;
						height: 6rem;
						border-radius: 50%;
						background: $primary;
						background-image: linear-gradient(45deg, $primary, $info);
						@media screen and (max-width: $md) {
							width: 3rem;
							height: 3rem;
						}
						.Icon {
							mix-blend-mode: overlay;
							display: flex;
							justify-content: center;
							align-items: center;
							&:before {
								transition: all 0.3s;
							}
							@media screen and (max-width: $md) {
								&:before {
									font-size: 20px;
								}
							}
						}
					}
					p {
						font-size: 22px !important;
						margin-top: 20px;
						text-align: center;
						transition: all 0.3s;
						@media screen and (max-width: $md) {
							font-size: 1.2rem !important;
						}
						@media screen and (max-width: $sm) {
							display: none;
						}
					}
				}
			}
		}
		.SocialMediaSection {
			display: flex;
			width: 100%;
			height: 40rem;
			position: relative;
			.Image {
				width: 50%;
				top: 0;
				left: 0;
				background-repeat: no-repeat;
				background-position-x: center;
				background-position-y: top;
				border-top-right-radius: 30px;
				overflow: hidden;
				@media screen and (max-width: $sm) {
					min-width: 100%;
					opacity: 0.5;
					border-top-right-radius: 0px;
				}
			}
			.SocialsWrapper {
				transform: translateX(-5rem);
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				@media screen and (max-width: $md) {
					transform: translateX(5rem);
				}
				@media screen and (max-width: $sm) {
					transform: translateX(0);
					position: absolute;
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.SocialMedia {
					display: flex;
					@media screen and (max-width: $md) {
						flex-direction: column;
						align-items: center;
					}
					& + .SocialMedia {
						margin-top: 3rem;
					}
					&:hover {
						.Info {
							transform: scale(1.1);
						}
						.Icon:before {
							transform: scale(1.3);
						}
					}
					.Icon {
						width: 7rem;
						height: 7rem;
						border-radius: 50%;
						background: $primary;
						display: flex;
						justify-content: center;
						align-items: center;
						&:before {
							transition: all 0.3s;
						}
						@media screen and (max-width: $md) {
							width: 4rem;
							height: 4rem;
						}
					}
					.Info {
						display: flex;
						flex-direction: column;
						justify-content: center;
						margin-left: 36px;
						transition: all 0.3s;
						@media screen and (max-width: $md) {
							align-items: center;
							margin-left: 0;
						}
						p {
							color: white;
							font-size: 20px;
							@media screen and (max-width: $md) {
								font-size: 1rem;
							}
						}
						.Title {
							font-size: 28px;
							line-height: 36px;
							margin-bottom: 8px;
							font-weight: bold;
							@media screen and (max-width: $md) {
								font-size: 1.5rem;
								margin-bottom: 0px;
								margin-top: 8px;
							}
						}
					}
				}
			}
		}
		.SponsorSection {
			width: 100%;
			padding: 3rem;
			background: white;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			p {
				color: $gray-dark;
				text-align: center;
			}
			.Sponsor {
				width: auto;
				height: 180px;
				margin-top: 20px;
				@media screen and (max-width: $md) {
					width: 100%;
					height: auto;
				}
			}
		}
	}
}
</style>
<style lang="postcss">
.splide__track {
	padding: 20px 0;
}
.splide__arrows {
	display: none;
}

.splide__pagination {
	bottom: -20px;
	width: 100%;
	z-index: 99;
}
</style>
