<template>
    <div
        class="Input"
        :for="id"
        :class="{ error, disabled }"
    >
        <Icon
            v-if="iconLeft"
            class="iconLeft"
            :icon="iconLeft"
            :color="iconColor"
            :size="18"
        />
        <input
            :id="id"
            :value="value"
            :name="name"
            :type="type"
            :placeholder="placeholder"
            :readonly="readonly"
            :disabled="disabled"
            :class="inputClass"
            :style="{ color }"
            v-on="inputListeners"
        >
        <p
            v-if="type === 'file'"
            class="File"
            :for="id"
        >
            {{ file }}
        </p>
        <Transition
            name="fade"
            :duration="500"
        >
            <span
                v-if="error"
                class="Error"
                :style="{ fontSize: '12px', color: '#EC466E' }"
            >
                {{ error }}
            </span>
        </Transition>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { merge } from 'lodash'
import { colors } from '@/theme/colors'
import short from 'short-uuid'

const TYPES = [
    'text',
    'password',
    'email',
    'number',
    'url',
    'tel',
    'search',
    'range',
    'color',
    'date',
    'time',
    'datetime',
    'datetime-local',
    'month',
    'week',
    'file',
]
export default {
    name: 'Input',
    props: {
        id: VueTypes.string.def(() => short.generate()),
        error: VueTypes.string.def(''),
        name: VueTypes.string.isRequired,
        placeholder: VueTypes.string.def(''),
        readonly: VueTypes.bool.def(false),
        disabled: VueTypes.bool.def(false),
        accept: VueTypes.string,
        type: VueTypes.oneOf(TYPES).isRequired,
        align: VueTypes.oneOf(['left', 'right', 'center']).def('left'),
        value: VueTypes.oneOfType([String, Number]),
        iconLeft: VueTypes.string.def(''),
        iconColor: VueTypes.oneOf(Object.keys(colors)).def('grayDark'),
        color: VueTypes.oneOf(Object.keys(colors)).def('white'),
    },
    data () {
        return {
            file: '',
        }
    },
    computed: {
        isFile () {
            return this.type === 'file'
        },
        inputListeners () {
            return merge({}, this.$listeners, {
                input: (event) => {
                    // this.$emit('input', event.target.value)
                    const { value, files } = event.target
                    if (this.isFile && (files || []).length) this.handleFile(files)
                    this.$emit('input', this.isFile ? files : value)
                },
            })
        },
        inputClass () {
            return [this.align]
        },
    },
    mounted () {
        if (this.isFile) {
            this.file = this.placeholder
        }
    },
    methods: {
        handleFile (files) {
            const [file] = files
            this.file = file.name
        },
    },
}
</script>

<style scoped lang="scss">

input {
	width: 100%;
	min-height: 45px;
	padding: 0 5px;
	border: none;
	border: 1px solid $gray-05;
	border-radius: 10px;
	background: transparent;
	font-size: 14px;
	font-weight: 400;
	transition: border-color 0.5s ease-in-out;
	outline: none;
	@media screen and (min-width: $xl) {
		padding: 1rem;
	}
	&:focus {
		border-color: $gray-03;
	}
	&[readonly] {
		opacity: 0.5;
		cursor: not-allowed;
	}
	&:disabled {
		background-color: $gray-light;
		cursor: not-allowed;
	}
	&.right {
		text-align: right;
	}
	&.center {
		text-align: center;
	}
	&.left {
		text-align: left;
	}
	&[type="file"] {
		// position: absolute;
		// width: 0.1px;
		// height: 0.1px;
		opacity: 0;
		overflow: hidden;
		z-index: -1;

		& + label {
			width: 100%;
			height: 42px;
			min-height: 25px;
			padding: 0 16px;
			color: $black;
			background-color: white;
			border-color: $gray-light;
			border: $form-border;
			border-radius: 6px;
			line-height: 2;
			outline: none;
			cursor: pointer;
		}

		&:focus + label {
			cursor: pointer;
			outline: none;
		}
	}
}

	.Input {
		width: 100%;
		position: relative;
		//   &.error {
		//     input {
		//       border-color: red;
		//     }
		//   }
	}

	.Icon {
		position: absolute;
		top: 22px;
		transform: translateY(-50%);
		&.iconLeft {
			left: 12px;
			~ input {
			padding-left: 40px;
			}
		}
}

.Error {
	text-align: right;
	width: 100%;
	margin-top: 5px;
	padding-right: 10px;
}
.File {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
</style>
