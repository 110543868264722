<template>
    <div class="InventoryMaterials">
        <div class="Header">
            <PillSelectorInput
                v-model="activeMaterialTab"
                :options="materialTabs"
                border="gray"
            />
            <div class="IconWrapper">
                <Icon
                    v-if="activeMode === 'read'"
                    :size="20"
                    icon="edit"
                    @click="activeMode = 'edit'"
                />
                <Icon
                    v-else
                    :size="20"
                    icon="close"
                    @click="activeMode = 'read'"
                />
            </div>
        </div>
        <div class="ListHeader">
            <p class="Name">
                Nombre
            </p>
            <p class="Quantity">
                {{ isMobile ? '#' : 'Cantidad' }}
            </p>
            <p class="Location">
                Ubicación
            </p>
        </div>
        <div
            v-for="({ key }, index) in teamMaterials"
            :key="key"
            class="ListItem"
        >
            <div class="Content">
                <div class="Name">
                    <Input
                        v-model="(mutableMaterials[index] || {}).name"
                        type="text"
                        iconLeft="package"
                        color="grayDark"
                        :disabled="activeMode === 'read' ? true : false"
                        iconColor="primary"
                        :name="`name-${index}`"
                    />
                </div>
                <div class="Quantity">
                    <Input
                        v-model="(mutableMaterials[index] || {}).quantity"
                        type="number"
                        iconLeft="hash"
                        color="grayDark"
                        :disabled="activeMode === 'read' ? true : false"
                        iconColor="primary"
                        :name="`quantity-${index}`"
                    />
                </div>
                <div class="Location">
                    <Input
                        v-model="(mutableMaterials[index] || {}).location"
                        type="text"
                        iconLeft="user"
                        color="grayDark"
                        :disabled="activeMode === 'read' ? true : false"
                        iconColor="primary"
                        :name="`location-${index}`"
                    />
                </div>
            </div>
            <p class="Action">
                <Icon
                    v-if="activeMode === 'edit'"
                    :size="15"
                    icon="trash-2"
                    color="error"
                    @click="handleDeleteMaterial(key)"
                />
            </p>
        </div>
        <div v-if="activeMode === 'read'">
            <p class="AddNew">
                Agregar
            </p>
            <div class="ListItem">
                <div class="Content">
                    <div class="Name">
                        <FieldError
                            #default="{ listeners }"
                            :validator="$v.newMaterial.name"
                            label="Nombre"
                        >
                            <Input
                                v-model="(newMaterial || {}).name"
                                type="text"
                                iconLeft="package"
                                color="grayDark"
                                iconColor="primary"
                                placeholder="Nombre"
                                name="name"
                                :disabled="isLoading"
                                v-on="listeners"
                            />
                        </FieldError>
                    </div>
                    <div class="Quantity">
                        <FieldError
                            #default="{ listeners }"
                            :validator="$v.newMaterial.quantity"
                            label="Cantidad"
                        >
                            <Input
                                v-model="(newMaterial || {}).quantity"
                                type="number"
                                iconLeft="hash"
                                color="grayDark"
                                iconColor="primary"
                                placeholder="Cantidad"
                                name="quantity"
                                :disabled="isLoading"
                                v-on="listeners"
                            />
                        </FieldError>
                    </div>
                    <div class="Location">
                        <FieldError
                            #default="{ listeners }"
                            :validator="$v.newMaterial.location"
                            label="Ubicación"
                        >
                            <Input
                                v-model="(newMaterial || {}).location"
                                type="text"
                                iconLeft="user"
                                color="grayDark"
                                iconColor="primary"
                                placeholder="Ubicación"
                                name="location"
                                :disabled="isLoading"
                                v-on="listeners"
                            />
                        </FieldError>
                    </div>
                </div>
                <p class="Action">
                    <Icon
                        :size="20"
                        icon="plus"
                        color="primary"
                        @click="handleAddNewMaterial(isLoading || $v.$invalid)"
                    />
                </p>
            </div>
        </div>

        <div class="ButtonWrapper">
            <Button
                v-if="activeMode === 'edit'"
                class="Submit"
                type="submit"
                text="GUARDAR"
                styleType="gradient"
                iconLeft="save"
                @click="handleEditMaterials"
            />
        </div>
    </div>
</template>

<script>
import { materialDefaultValues } from '@/entities/inventory'
import { mediaQueryMixin } from '@/mixins'
import { validationMixin } from 'vuelidate'
import VueTypes from 'vue-types'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'InventoryMaterials',
    mixins: [mediaQueryMixin, validationMixin],
    validations () {
        return {
            newMaterial: {
                name: { required },
                quantity: { required },
                location: {},
            },
        }
    },
    props: {
        teamMaterials: VueTypes.array,
    },
    data () {
        return {
            activeMode: 'read',
            isLoading: false,
            newMaterial: materialDefaultValues,
            mutableMaterials: materialDefaultValues,
            activeMaterialTab: 'all',
            materialTabs: [
                {
                    key: 'all',
                    label: 'Individuales',
                },
                {
                    key: 'bags',
                    label: 'Bolsos',
                },
            ],
        }
    },
    computed: {
        isMobile () {
            return this.$mq.w < this.$mq.sm.min
        },
    },
    mounted () {
        this.mutableMaterials = this.teamMaterials
    },
    methods: {
        async handleEditMaterials (value) {
            this.$emit('updateMaterials', this.newMaterial)
            this.activeMode = 'read'
        },
        handleDeleteMaterial (key) {
            console.log(`delete ${key}`)
        },
        handleAddNewMaterial (isInvalid) {
            console.log(`create`)
            console.log(`isInvalid ${isInvalid}`)
        },
    },
}
</script>
<style lang="scss" scoped>
.InventoryMaterials {
	margin: 10px 30px;
	.Header {
		display: flex;
		// justify-content: flex-end;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 50px;
		.PillSelectorInput {
			margin-right: 30px;
			width: fit-content;
		}
	}
	.ListHeader {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 10px 25px;
		margin-bottom: 30px;
		padding-bottom: 15px;
		border-bottom: 1px solid $gray-light;
		.Name, .Quantity, .Location {
			font-weight: bold;
			color: $gray-dark;
			font-size: 18px;
		}
		.Name {
			flex: 1;
		}
		.Quantity, .Location {
			min-width: 200px;
			margin-left: 20px;
			@media screen and (max-width: $md) {
				display: none;
			}
		}
		.Location {
			margin-right: 50px;
		}
	}
	.ListItem {
		display: flex;
		align-items: center;
		background: $gray-07;
		height: 50px;
		padding: 0 20px;
		border-radius: 1rem;
		position: relative;
		@media screen and (max-width: $sm) {
			height: auto;
			text-align: center;
			padding: 10px;
		}
		& + .ListItem {
			margin-top: 15px;
		}
		.Content {
			width: 100%;
			display: flex;
			margin: 10px 0;
			@media screen and (max-width: $sm) {
				flex-direction: column;
			}
		}
		.Input::v-deep input {
			border: none;
			background: transparent;
		}
		.Name {
			flex: 1;
			@media screen and (max-width: $sm) {
				text-align: left;
				font-weight: 600;
			}
		}
		.Quantity, .Location {
			min-width: 200px;
			@media screen and (max-width: $md) {
				flex: 1;
				text-align: center;
			}
			@media screen and (max-width: $sm) {
				margin-top: 10px;
				text-align: left;
			}
		}
		.Quantity, .Location, .Action {
			margin-left: 20px;
		}
		.Action {
			min-width: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			@media screen and (max-width: $sm) {
				position: absolute;
				top: 10px;
				right: 5px;
			}
		}
	}
	.AddNew {
		margin: 40px 0 15px 0;
		font-weight: bold;
		color: $gray-dark;
		margin-left: 30px;
		font-size: 18px;
	}
	.ButtonWrapper {
		display: flex;
		justify-content: flex-end;
		.Button {
			align-self: flex-end;
			font-size: 16px;
			margin-top: 20px;
			@media screen and (max-width: $sm) {
				font-size: 14px;
			}
		}
	}
}
</style>
