<template>
    <TransitionGroup
        name="ListTransition"
        :tag="tag"
        :duration="600"
    >
        <slot />
    </TransitionGroup>
</template>

<script>

import VueTypes from 'vue-types'

export default {
    name: 'ListTransition',
    props: {
        tag: VueTypes.string.def('span'),
    },
}
</script>

<style lang="scss" scoped>
.ListTransition {
    &-move {
        transition: all 600ms ease-in-out 50ms;
    }
    &-enter-active {
        transition: all 400ms ease-out;
    }
    &-leave-active {
        transition: all 400ms ease-in;
        position: absolute !important;
        left: 0;
        top: 0;
        transform: scale(0.3);
        z-index: 0 !important;
    }
    &-enter,
    &-leave-to {
        opacity: 0;
    }
    &-enter {
        transform: scale(0.9);
    }
}
</style>
