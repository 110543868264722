<template>
    <div class="Uniforms">
        <div
            class="IconWrapper"
        >
            <Icon
                v-if="activeMode === 'read'"
                v-permissions="{permissions: [constants.USER_ROLE.ADMIN,
                                              constants.USER_ROLE.COMITE]}"
                :size="20"
                icon="edit"
                @click="activeMode = 'edit'"
            />
            <Icon
                v-else
                :size="20"
                icon="close"
                @click="activeMode = 'read'"
            />
        </div>
        <Loading
            v-if="isLoadingEdition"
            key="loading"
            visible
            :opacity="1"
        />
        <div v-else-if="activeMode === 'read'">
            <div
                v-for="{ key, label } in uniforms"
                :key="key"
                class="Item"
            >
                <p class="label">
                    {{ label }}
                </p>
                <div>
                    <Icon
                        v-if="!userUniforms[key]"
                        icon="close"
                        :size="20"
                        color="error"
                    />
                    <Icon
                        v-else
                        icon="check"
                        :size="20"
                        color="success"
                    />
                </div>
                <div
                    v-if="userUniforms && userUniforms[key] && userUniforms[key].size"
                    class="Details"
                >
                    <p
                        v-if="!isMobile"
                        class="DetailedLabel"
                    >
                        Talla
                    </p>
                    <p class="DetailedValue">
                        {{ userUniforms[key].size }}
                    </p>
                </div>
                <div
                    v-if="userUniforms && userUniforms[key] && userUniforms[key].number"
                    class="Details"
                >
                    <div>
                        <p
                            v-if="!isMobile"
                            class="DetailedLabel"
                        >
                            Número
                        </p>
                        <p
                            v-else
                            class="DetailedLabel"
                        >
                            #
                        </p>
                    </div>

                    <p class="DetailedValue">
                        {{ userUniforms[key].number }}
                    </p>
                </div>
            </div>
        </div>
        <div v-else>
            <div
                v-for="{ key: uniformKey, label, size, number } in uniforms"
                :key="uniformKey"
                class="EditableItem"
            >
                <p class="Label">
                    {{ label }}
                </p>
                <ButtonSwitch
                    :key="buttonKey"
                    :value="mutableUniforms[uniformKey].isEnable"
                    @input="handleSwitch(uniformKey)"
                />
                <PillSelectorInput
                    v-if="size"
                    v-model="mutableUniforms[uniformKey].size"
                    :options="uniformSizes"
                    border="gray"
                />
                <Input
                    v-if="number"
                    v-model="mutableUniforms[uniformKey].number"
                    type="number"
                    iconLeft="hash"
                    color="grayDark"
                    iconColor="primary"
                    :name="`number-${uniformKey}`"
                />
            </div>
            <div class="ButtonWrapper">
                <Button
                    class="Submit"
                    type="submit"
                    text="GUARDAR"
                    styleType="gradient"
                    iconLeft="save"
                    :loading="isLoading"
                    @click="handleEditUniform"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { uniforms, uniformSizes } from '@/entities/uniforms'
import VueTypes from 'vue-types'
import constants from '@/entities/constants'
import { mediaQueryMixin } from '@/mixins'
import { profileDefaultValue } from '@/entities/profile'
import { clone } from 'lodash'

export default {
    name: 'Uniforms',
    mixins: [mediaQueryMixin],
    props: {
        userUniforms: VueTypes.object,
        isLoading: VueTypes.bool.def(false),
    },
    data () {
        return {
            uniforms,
            uniformSizes,
            constants,
            activeMode: 'read',
            mutableUniforms: profileDefaultValue.uniforms,
            buttonKey: 0,
            isLoadingEdition: false,
        }
    },
    computed: {
        isMobile () {
            return this.$mq.w < this.$mq.sm.min
        },
    },
    watch: {
        userUniforms () {
            this.isLoadingEdition = false
        },
    },
    mounted () {
        this.mutableUniforms = {
            ...profileDefaultValue.uniforms,
            ...clone(this.userUniforms),
        }

        for (const key in this.mutableUniforms) {
            this.mutableUniforms[key].isEnable = this.mutableUniforms[key].hasOwnProperty('isEnable')
                ? this.mutableUniforms[key].isEnable
                : !this.isEmpty(this.mutableUniforms[key])
        }
    },
    methods: {
        isEmpty (obj) {
            return Object.keys(obj).length === 0 && obj.constructor === Object
        },
        handleSwitch (key) {
            this.mutableUniforms[key].isEnable = !this.mutableUniforms[key].isEnable
            this.buttonKey += 1
        },
        async handleEditUniform (value) {
            this.isLoadingEdition = true
            this.$emit('updateUniforms', this.mutableUniforms)
            this.activeMode = 'read'
        },
    },
}
</script>
<style lang="scss" scoped>
.Uniforms {
	.Item {
		display: grid;
		grid-template-columns: 2fr 1fr 2fr 2fr;
		border-bottom: 1px solid $gray-07;
		padding: 10px 0;
		min-height: 70px;
        align-items: center;
		@media screen and (max-width: $sm) {
			grid-template-columns: 4fr 1fr 1fr 1fr;
		}
		.label {
			color: #333;
		}
		.Details {
			display: flex;
			.DetailedLabel {
				color: $gray-02;
				text-transform: uppercase;
				font-size: 14px;
				margin-left: 15px;
				margin-right: 10px;
			}
			.DetailedValue {
				color: #333;
			}
		}
	}
	.EditableItem {
		display: grid;
		grid-template-columns: 3fr auto 4fr 1fr;
		grid-template-rows: 1fr;
		grid-row-gap: 30px;
		grid-column-gap: 30px;
		border-bottom: 1px solid $gray-07;
		padding: 10px 0;
		min-height: 70px;
		align-items: center;
		@media screen and (max-width: $sm) {
			grid-template-columns: 3fr 1fr;
			grid-template-rows: 1fr;
			grid-row-gap: 10px;
			grid-column-gap: 10px;
		}
		.Label {
			color: #333;
		}
		.ButtonSwitch {
			display: flex;
			justify-content: flex-end;
		}
		.Input {
			height: 100%;
			&::v-deep input {
				height: 100%;
				border-radius: 20px;
				min-width: 80px;
			}
		}
	}
	.ButtonWrapper {
		display: flex;
		justify-content: flex-end;
		.Button {
			align-self: flex-end;
			font-size: 16px;
			margin-top: 20px;
			@media screen and (max-width: $sm) {
				font-size: 14px;
			}
		}
	}
	.IconWrapper {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}
}
</style>
