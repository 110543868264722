<template>
    <div class="text-center section">
        <vc-calendar
            ref="calendar"
            class="custom-calendar max-w-full"
            :masks="masks"
            locale="es"
            :attributes="attributes"
            disablePageSwipe
            :isExpanded="false"
            @update:from-page="getEvents"
        >
            <template v-slot:day-content="{ day, attributes: newAttributes }">
                <div class="Header">
                    <div
                        v-if="!isMobile"
                        class="Add"
                        @click="handleShowModal('add', day.date)"
                    >
                        <Icon
                            :size="isMobile ? 10 : 16"
                            icon="plus"
                        />
                    </div>
                    <div
                        class="Day"
                        @click="isMobile ? handleShowModal('add', day.date) : ''"
                    >
                        <p>{{ day.day }}</p>
                    </div>
                </div>
                <div class="Body">
                    <div
                        v-for="(attr, index) in newAttributes"
                        :key="index"
                        class="Event"
                        :class="attr.customData.color"
                        @click="handleShowModal('read', day.date, attr)"
                    >
                        {{ isMobile ? '' : attr.customData.name }}
                    </div>
                </div>
            </template>
        </vc-calendar>
        <Legend
            :legend="calendarEventTypes"
            title="Tipos de eventos"
        />
        <Legend
            :legend="calendarEventStates"
            title="Estados de los eventos"
        />
        <CalendarModal
            v-if="showModal"
            :me="me"
            :isOpen="showModal"
            :type="modalType"
            :data="modalData"
            @close="handleCloseModal"
            @submitCreateEvent="handleEventCreation"
            @submitEditEvent="handleEventEdition"
            @submitSignUpEvent="handleSignUpEvent"
        />
    </div>
</template>

<script>
import CalendarModal from './CalendarModal.vue'
import VueTypes from 'vue-types'
import { mediaQueryMixin } from '@/mixins'
import { calendarEventTypes, calendarEventStates } from '@/entities/calendar'
import moment from 'moment'

export default {
    name: 'Calendar',
    components: {
        CalendarModal,
    },
    mixins: [mediaQueryMixin],
    props: {
        attributes: VueTypes.array,
        modalData: VueTypes.object,
        me: VueTypes.object,
        showModal: VueTypes.bool.def(false),
        calendarDate: VueTypes.string,
    },
    data () {
        return {
            modalType: '',
            calendarEventTypes,
            calendarEventStates,
        }
    },
    computed: {
        isMobile () {
            return this.$mq.w < this.$mq.lg.min
        },
        masks () {
            if (this.$mq.w < this.$mq.sm.max) return { weekdays: 'W' }
            else if (this.isMobile) return { weekdays: 'WWW' }
            else return { weekdays: 'WWWW' }
        },
    },
    async mounted () {
        console.log('mounted??')
        const calendar = this.$refs.calendar
        console.log(calendar)
        const date = this.calendarDate ? moment.utc(this.calendarDate).toDate()
            : moment.utc().toDate()
        console.log(date)
        try {
            await calendar.move(date)
        } catch (err) {
            console.log(err)
        }
        
        console.log('in calendar')
        console.log(this.attributes)
    },
    methods: {
        handleShowModal (type, day, data) {
            this.$emit('handleShowModal', { day, data, type })
            this.modalType = type
        },
        handleCloseModal (type) {
            this.$emit('handleCloseModal', type)
            this.modalType = ''
        },
        handleEventCreation (value) {
            this.$emit('submitCreateEvent', value)
            this.handleCloseModal('add')
        },
        handleEventEdition (value) {
            this.$emit('submitEditEvent', value)
            this.handleCloseModal('edit')
        },
        handleSignUpEvent (value) {
            this.$emit('submitSignUpEvent', value)
            this.handleCloseModal('read')
        },
        getEvents (value) {
            this.$emit('getEvents', value)
        },
    },
}
</script>

<style lang="scss" scoped>

::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}
.custom-calendar.vc-container::v-deep {
  width: 100%;
  border: none;
  margin-top: 10px;
  .vc-title {
     color: $gray-01;
     font-size: 22px;
  }
  .vc-header {
    padding: 0;
    margin-bottom: 30px;
    text-transform: capitalize;
  }
  .vc-grid-container .vc-weeks {
      margin: 0 30px;
      @media screen and (max-width: $md) {
         margin: 0
      }
  }
  .vc-weeks {
      padding: 0;
  }
  .vc-weekday {
   font-weight: 600;
   color: $gray-03;
   border-bottom: 1px solid $gray-light;
   padding-bottom: 15px;
   text-transform: capitalize;
  }
  .vc-day {
      min-height: 100px !important;
      padding: 5px;
      text-align: left;
      display: flex;
      flex-direction: column;
      min-width: 90px;
      background-color: white;

      @media screen and (max-width: $md) {
         min-width: fit-content;
         min-height: fit-content !important;
         height: 100%;
      }

      &:not(.on-bottom) {
         border-bottom: 1px solid $gray-light;
      }
      &:not(.on-right) {
         border-right: 1px solid $gray-light;
      }
      .Header {
         display: flex;
         justify-content: space-between;
         .Day {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 5px;
            p {
               color: $gray-02 !important;
               font-weight: bold;
            }
            @media screen and (max-width: $md) {
               cursor: pointer;
               p { font-weight: normal }
            }
         }
         .Add {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $gray-07;
            border-radius: 15px;
            cursor: pointer;
         }
      }
      .Body {
         flex: 1;
         justify-content: center;
         align-items: center;
         overflow-y: auto;
         .Name {
            font-size: 14px !important;
            line-height: 18px;
         }
         .flex {
            display: flex;
            align-items: flex-end;
            margin-top: 5px;
         }
         .Event {
            padding: 8px;
            margin: 5px;
            border-radius: 5px;
            cursor: pointer;
         }
         .trainning {
            background: $secondary;
            .Name { color: white !important }
         }
         .match {
			background: $primary;
         }
         .activity {
			background: $success;
			.Name {
				color: white;
			}
         }
         .comite {
			background: $blue;
			// background: $purple;
			// background: $info;
			// background: $salmon;
			.Name {
				color: white;
			}
         }
         .rejected {
			background: $gray-01;
			.Name {
				color: white;
			}
         }
         .cancelled {
			background: $danger;
			.Name {
				color: white;
			}
         }
         .past {
			background: $gray-03;
			.Name {
				color: white;
			}
         }
         .pending {
			background: $tertiary;
         }
      }
   }
   .vc-day-dots {
      margin-bottom: 5px;
   }
   .vc-arrows-container {
      padding: 0;
   }
}
.Legend {
	margin-top: 30px;
}
</style>
