import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import {
    MainScreen,
    NotFoundScreen,
    AppLayout,
    PublicLayout,
} from '@/screens'

import appRoutes from './app'
import publicRoutes from './public'
import authRoutes from './auth'

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
    mode: 'history',
    base: '',
    routes: [
        {
            name: 'Main',
            path: '/',
            component: MainScreen,
            redirect: { name: 'Public' },
            children: [
                ...authRoutes,
                {
                    name: 'Public',
                    path: '/',
                    component: PublicLayout,
                    redirect: { name: 'Home' },
                    children: [
                        ...publicRoutes,
                    ],
                },
                {
                    name: 'Trivia',
                    path: '/trivia',
                },
                {
                    name: 'App',
                    path: '/app',
                    component: AppLayout,
                    redirect: { name: 'AppHome' },
                    children: [
                        ...appRoutes,
                    ],
                },
            ],
        },
        {
            path: '*',
            name: '404',
            component: NotFoundScreen,
        },
    ],
})

export default router
