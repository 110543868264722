import axios from 'axios'

export default {
    getStatsEvents: async () => {
        const response = await axios.get('/stats/events')

        return response && response.data && response.data.response
    },

    getKickingStatsForEvent: async (eventName) => {
        const response = await axios.get('/stats/kicking/' + eventName)

        return response && response.data && response.data.response
    },

    getKickingStatsForUser: async (userId) => {
        const response = await axios.get('/stats/kicking/user/' + userId)

        return response && response.data && response.data.response
    },

    getFieldingStatsForEvent: async (eventName) => {
        const response = await axios.get('/stats/fielding/' + eventName)

        return response && response.data && response.data.response
    },

    getFieldingStatsForUser: async (eventName) => {
        const response = await axios.get('/stats/fielding/user/' + userId)

        return response && response.data && response.data.response
    },

    getPitchingStatsForEvent: async (eventName) => {
        const response = await axios.get('/stats/pitching/' + eventName)

        return response && response.data && response.data.response
    },

    getPitchingStatsForUser: async (eventName) => {
        const response = await axios.get('/stats/pitching/user/' + userId)

        return response && response.data && response.data.response
    },
}
