<template>
    <button
        class="Button"
        :disabled="disabled || loading"
        :class="{ disabled: disabled || loading, [styleType]: styleType, [size]: size }"
        :type="type"
        @click.stop="$emit('click')"
    >
        <Spinner
            :visible="loading"
            color="white"
            small
            noMargin
        />
        <Icon
            v-if="iconLeft && !loading"
            :icon="iconLeft"
            :size="iconSize"
            class="IconLeft"
            :color="iconColor"
        />
        {{ text }}
        <Icon
            v-if="iconRight && !loading"
            :icon="iconRight"
            :size="iconSize"
            class="IconRight"
            :color="iconColor"
        />
    </button>
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'Button',
    props: {
        text: VueTypes.string,
        type: VueTypes.oneOf(['button', 'submit', 'reset']).def('button'),
        loading: VueTypes.bool.def(false),
        disabled: VueTypes.bool.def(false),
        iconRight: VueTypes.string.def(''),
        iconLeft: VueTypes.string.def(''),
        iconSize: VueTypes.number.def(20),
        iconColor: VueTypes.string.def('white'),
        styleType: VueTypes.string.def(''),
        size: VueTypes.oneOf(['sm', 'regular', 'big']).def('big'),
    },
}
</script>
<style lang="scss" scoped>

.Button {
	padding: 14px 20px;
	font-size: 18px;
	line-height: 22px;
	font-weight: $font-weight-semibold;
	cursor: pointer;
	text-align: center;
	transition: all 0.3s ease-in-out;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	border-radius: 50px;
	background-color: $primary;
	color: $white;
	border: none;

	@media screen and (max-width: $md) {
		font-size: 16px;
		padding: 12px 18px;
	}
	@media screen and (min-width: $xl) {
		font-size: 20px;
		line-height: 28px;
	}
	.Spinner {
		margin-right: 15px !important;
	}
	&:hover {
		background-color: $primary;
		background-image: linear-gradient(45deg, $primary, $info);
		color: white;
	}
	.IconRight {
		margin-left: 8px;
	}
	.IconLeft {
		margin-right: 8px;
	}
	&.sm {
		transform: scale(.8);
	}
	&.white {
		background-color: $white;
		color: $gray-dark;
		&:hover {
			background-color: rgba($white, .8) !important;
			color: $gray-dark;
			background-image: linear-gradient(45deg, rgba($white, .5), $white) !important;
			// TODO: RESTORE AFTER MADMIX
			// background: $primary;
			// color: white;
		}
	}
	&.gradient {
		background-color: $primary;
		background-image: linear-gradient(45deg, $primary, $info);
		color: white;
		&:hover {
			background: $primary;
		}
	}
	&.opacity {
		background-color: rgba($primary, 0.05);
		border: 1px solid rgba($primary, 0.05);
		color: $gray-dark;
		&:hover {
			background: $white;
			border: 1px solid $gray-05;
			color: $gray-dark;
		}
	}
	&.gray {
		background-color: #ededed;
		color: $gray-dark;
		&:hover {
			background: $gray-medium;
			color: $white;
		}
	}
}
.disabled {
	opacity: .5;
	cursor: not-allowed;
}
</style>
