<template>
	<div class="InfoFields">
		<div class="Header">
			<PillSelectorInput v-model="activeCityTab" :options="cityTabs" border="gray" @input="handleSetCity" />
		</div>
		<div class="ListHeader">
			<p class="Name">
				Nombre
			</p>
			<p class="TeamOwner">
				Equipo
			</p>
			<p class="Location">
				Ubicación
			</p>
		</div>
		<div v-for="(location, index) in filteredLocations" :key="index" class="ListItem">
			<div class="Content">
				<div class="Name">
					<Input v-model="(location || {}).name" type="text" iconLeft="hash" color="grayDark"
						:disabled="activeMode === 'read' ? true : false" iconColor="primary" :name="`name-${index}`" />
				</div>
				<div class="TeamOwner">
					<Input v-model="(location || {}).teamName" type="text" iconLeft="users" color="grayDark"
						:disabled="activeMode === 'read' ? true : false" iconColor="primary" :name="`teamOwner-${index}`" />
				</div>
				<div class="Location">
					<Input v-model="(location || {}).address" type="text" iconLeft="map-pin" color="grayDark"
						:disabled="activeMode === 'read' ? true : false" iconColor="primary" :name="`location-${index}`" />
					<Input v-model="(location || {}).city" type="text" iconLeft="map" color="grayDark"
						:disabled="activeMode === 'read' ? true : false" iconColor="primary" class="City"
						:name="`location-${index}`" />
				</div>
			</div>
			<p class="Action">
				<Icon v-permissions="{
					permissions: [constants.USER_ROLE.ADMIN,
					constants.USER_ROLE.COMITE]
				}" :size="15" icon="trash-2" color="error"
					@click="handleDeleteField(location)" />
			</p>
		</div>
		<div v-permissions="{
			permissions: [constants.USER_ROLE.ADMIN,
			constants.USER_ROLE.COMITE]
		}">
			<div class="AddNew">
				<p>Agregar</p>
			</div>
			<div class="ListItem">
				<div class="Content">
					<div class="Name">
						<Input v-model="newField.name" type="text" iconLeft="hash" color="grayDark" iconColor="primary"
							placeholder="Nombre" name="name" :disabled="isLoading" />
					</div>
					<div class="TeamOwner">
						<Select name="Equipo" placeholder="Equipo" :options="teams" :value="teams[0].key"
							@input="handleTeamSelection" />
					</div>
					<div class="Location">
						<Input v-model="newField.address" type="text" iconLeft="map-pin" color="grayDark"
							iconColor="primary" placeholder="Dirección" name="location" :disabled="isLoading" />
					</div>
				</div>
				<p class="Action">
					<Icon :size="20" icon="plus" color="primary" @click="handleAddNewField(isLoading)" />
				</p>
			</div>
		</div>

		<div class="ButtonWrapper">
			<Button v-if="activeMode === 'edit'" class="Submit" type="submit" text="GUARDAR" styleType="gradient"
				iconLeft="save" @click="handleEditFields" />
		</div>
	</div>
</template>

<script>
import { fieldDefaultValues } from '@/entities/info'
import { mediaQueryMixin } from '@/mixins'
import VueTypes from 'vue-types'
import informationIntegration from '@/integration/informationIntegration'
import constants from '@/entities/constants'

export default {
	name: 'InfoFields',
	mixins: [mediaQueryMixin],
	props: {
		fields: VueTypes.array,
	},
	data() {
		return {
			activeMode: 'read',
			isLoading: false,
			newField: {},
			mutableFields: fieldDefaultValues,
			filteredLocations: [],
			activeCityTab: 'ours',
			cityTabs: [
				{
					key: 'ours',
					label: 'Nuestros',
				},
			],
			teams: [{ label: 'dummy', key: 'key' }],
			constants,
		}
	},
	computed: {
		isMobile() {
			return this.$mq.w < this.$mq.sm.min
		},
	},
	async mounted() {
		await this.getTeams()
		this.mutableFields = await informationIntegration.listLocations()
		this.getCities()
		this.filterLocations()
		this.handleTeamSelection(this.teams[0].key)
	},
	methods: {
		async handleEditFields(value) {
			this.$emit('updateFields', this.newField)
			this.activeMode = 'read'
		},
		async handleDeleteField(location) {
			await informationIntegration.deleteLocation(location.id)
			this.mutableFields = this.mutableFields.filter(t => {
				return t.id !== location.id
			})
			this.filterLocations()
		},
		async handleAddNewField(isLoading) {
			const newLocation = await informationIntegration.createLocation(this.newField)
			this.mutableFields.push(newLocation)
			this.newLocation = {}
			this.getCities()
			this.filterLocations()
			this.handleTeamSelection(this.teams[0].key)
		},
		getCities() {
			const cities = this.mutableFields
				.map(t => t.city)
				.filter(this.unique)
				.map(c => {
					return { key: c, label: c }
				})
			this.cityTabs = [
				{
					key: 'ours',
					label: 'Nuestros',
				},
			]
			this.cityTabs.push(...cities)
		},
		async getTeams() {
			const teams = await informationIntegration.listTeams()
			this.teams = teams
				.map(t => {
					return {
						key: t.id,
						label: t.name + ' - ' + t.city,
						city: t.city,
					}
				})
		},
		unique(value, index, self) {
			return self.indexOf(value) === index
		},
		handleSetCity(val) {
			this.activeCityTab = val
			this.filterLocations()
		},
		filterLocations() {
			if (this.activeCityTab === 'ours') {
				this.filteredLocations = this.mutableFields
					.filter(l => l.teamName === 'Espartanas')
			} else {
				this.filteredLocations = this.mutableFields
					.filter(l => l.city === this.activeCityTab)
			}
		},
		handleTeamSelection(teamId) {
			const team = this.teams
				.find(l => l.key === teamId)
			this.newField.teamId = teamId
			this.newField.city = team.city
		},
	},
}
</script>
<style lang="scss" scoped>
.InfoFields {
	margin: 10px 30px;

	@media screen and (max-width: $md) {
		margin: 10px 20px;
	}

	@media screen and (max-width: $sm) {
		margin: 10px 0;
	}

	.Header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 50px;

		.PillSelectorInput {
			margin-right: 30px;
			width: fit-content;
		}
	}

	.ListHeader {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 10px 25px;
		margin-bottom: 30px;
		padding-bottom: 15px;
		border-bottom: 1px solid $gray-light;

		.Name,
		.TeamOwner,
		.Location {
			font-weight: bold;
			color: $gray-dark;
			font-size: 18px;
			min-width: 200px;
			margin-left: 20px;
		}

		.Location {
			flex: 1;
			margin-right: 50px;
		}

		.Name {
			margin-left: 0px;
		}
	}

	.ListItem {
		display: flex;
		align-items: center;
		background: $gray-07;
		height: 50px;
		padding: 0 20px;
		border-radius: 1rem;
		position: relative;

		@media screen and (max-width: $sm) {
			height: auto;
			text-align: center;
			padding: 10px;
		}

		&+.ListItem {
			margin-top: 15px;
		}

		.Content {
			width: 100%;
			display: flex;
			margin: 10px 0;

			@media screen and (max-width: $sm) {
				flex-direction: column;
			}
		}

		.Input::v-deep input {
			border: none;
			background: transparent;
		}

		.Name {
			@media screen and (max-width: $sm) {
				text-align: left;
				font-weight: 600;
			}
		}

		.Location {
			flex: 1;

			.City {
				width: fit-content;
			}
		}

		.TeamOwner,
		.Name {
			min-width: 200px;

			@media screen and (max-width: $md) {
				flex: 1;
				text-align: center;
			}

			@media screen and (max-width: $sm) {
				margin-top: 10px;
				text-align: left;
			}
		}

		.TeamOwner,
		.Location,
		.Action {
			margin-left: 20px;
		}

		.Action {
			min-width: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			@media screen and (max-width: $sm) {
				position: absolute;
				top: 10px;
				right: 5px;
			}
		}
	}

	.Location {
		display: flex;
	}

	.AddNew {
		margin-top: 40px;
		margin-bottom: 20px;
		padding-bottom: 15px;
		border-bottom: 1px solid $gray-light;

		p {
			margin-left: 25px;
			font-weight: bold;
			color: $gray-dark;
			font-size: 18px;
		}
	}

	.ButtonWrapper {
		display: flex;
		justify-content: flex-end;

		.Button {
			align-self: flex-end;
			font-size: 16px;
			margin-top: 30px;

			@media screen and (max-width: $sm) {
				font-size: 14px;
			}
		}
	}
}
</style>
