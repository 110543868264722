<template>
    <div class="SigninScreen">
        <img
            class="Logo"
            :src="require('@/assets/logo.png')"
            @click="$router.push({ name: 'Home' })"
        >
        <form
            @submit.prevent="handleSubmit"
        >
            <FieldError
                #default="{ errorMessage, listeners }"
                :validator="$v.email"
                label="Email"
            >
                <Input
                    v-model="email"
                    name="email"
                    placeholder="Email"
                    type="text"
                    iconLeft="user"
                    iconColor="primary"
                    :error="errorMessage"
                    :disabled="isLoading"
                    v-on="listeners"
                />
            </FieldError>

            <FieldError
                #default="{ errorMessage, listeners }"
                :validator="$v.password"
                label="La contraseña"
            >
                <Input
                    v-model="password"
                    name="password"
                    placeholder="Contraseña"
                    type="password"
                    iconLeft="lock"
                    iconColor="primary"
                    :error="errorMessage"
                    :disabled="isLoading"
                    v-on="listeners"
                />
            </FieldError>
            <p @click="$router.push({ name: 'ForgotPassword' })">
                ¿Olvidaste tu contraseña?
            </p>
            <Button
                type="submit"
                text="Entrar"
                :loading="isLoading"
                :disabled="isLoading || $v.$invalid"
                styleType="gradient"
            />
        </form>
    </div>
</template>

<script>
import auth from '@/api/auth'
import { validationMixin } from 'vuelidate'
import { required, minLength, email } from 'vuelidate/lib/validators'

export default {
    name: 'SigninScreen',
    mixins: [validationMixin],
    validations () {
        return {
            email: { required, email },
            password: { required, minLength: minLength(6) },
        }
    },
    data () {
        return {
            // TODO: comment on build
            // email: 'kendra_petit@hotmail.com',
            // password: 'DEFAULT_VALUE',
            // isLoading: false,
            email: '',
            password: '',
            isLoading: false,
        }
    },
    methods: {
        async handleSubmit () {
            // TODO: comment on build
            this.isLoading = true

            try {
                const user = await auth.login(this.email, this.password)

                this.isLoading = false
                this.$store.dispatch('login', user)
                this.$router.push({ name: 'AppHome', params: { id: this.$store.getters.me.id } })
            } catch (err) {
                // console.log('Error al iniciar sesión')
                // console.log(err)
            }

            this.isLoading = false
        },
    },
}
</script>
<style lang="scss" scoped>

.SigninScreen {
	width: 100%;
	min-height: 100%;
	background: #333;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	.Logo {
		width: 200px;
		margin-bottom: 10px;
		@media screen and (max-width: $sm) {
			width: 150px;
		}
	}
	form {
		width: 300px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	.Input::v-deep {
		&.disabled input {
			background: rgba(white, .1);
			color: $gray-light !important;
		}
	}
	.FieldError {
		margin-top: 20px;
		& + .FieldError {
			margin-top: 40px;
		}
	}
	p {
		margin-top: 40px;
		color: $gray-05;
		cursor: pointer;
	}
	.Button {
		margin-top: 30px;
		width: 100%;
		&:hover {
			background: $white;
			color: #333;
		}
	}
}
</style>
