import {
    AppHomePageScreen,
    ProfileScreen,
    OrganizationScreen,
    CalendarScreen,
    InventoryScreen,
    InfoScreen,
    EconomyScreen,
    StatsScreen,
    RegulationScreen,
    AttendanceScreen,
    ExercisesScreen,
    LineupScreen,
} from '@/screens'

export default [
    {
        path: '/app',
        name: 'AppHome',
        component: AppHomePageScreen,
    },
    {
        path: '/app/perfil/:id/:profileSlug',
        name: 'Profile',
        component: ProfileScreen,
    },
    {
        path: '/app/organizacion/:organizationSlug',
        name: 'Organization',
        component: OrganizationScreen,
    },
    {
        path: '/app/calendario',
        name: 'Calendar',
        component: CalendarScreen,
    },
    {
        path: '/app/inventario/:inventorySlug',
        name: 'Inventory',
        component: InventoryScreen,
    },
    {
        path: '/app/informaciones/:infoSlug',
        name: 'Informations',
        component: InfoScreen,
    },
    {
        path: '/app/economia/:economySlug',
        name: 'Economy',
        component: EconomyScreen,
    },
    {
        path: '/app/stats',
        name: 'Stats',
        component: StatsScreen,
    },
    {
        path: '/app/reglamento',
        name: 'Regulation',
        component: RegulationScreen,
    },
    {
        path: '/app/asistencias/:attendanceSlug',
        name: 'Attendance',
        component: AttendanceScreen,
    },
    {
        path: '/app/ejercicios',
        name: 'Exercises',
        component: ExercisesScreen,
    },
    {
        path: '/app/alineacion/:eventId?',
        name: 'Lineups',
        component: LineupScreen,
    },
]
