<template>
    <div class="CalendarScreen">
        <div
            v-if="!isMobile"
            class="ScreenHeader"
        >
            <h1>Calendario</h1>
        </div>
        <div class="Container">
            <Calendar
                :key="calendarKey"
                :me="me"
                :attributes="events"
                :modalData="modalData"
                :showModal="showModal"
                :calendarDate="calendarDate"
                @submitCreateEvent="handleEventCreation"
                @submitEditEvent="handleEventEdition"
                @submitSignUpEvent="handleSignUpEvent"
                @getEvents="getEvents"
                @handleShowModal="handleShowModal"
                @handleCloseModal="handleCloseModal"
            />
        </div>
    </div>
</template>

<script>
import { Calendar } from '@/components'
import { mediaQueryMixin } from '@/mixins'
import calendarScreenUtils from '@/utils/calendarScreenUtils'
import moment from 'moment'

export default {
    name: 'CalendarScreen',
    components: {
        Calendar,
    },
    mixins: [mediaQueryMixin],
    data () {
        return {
            events: [],
            me: this.$store.getters.me,
            modalData: {},
            showModal: false,
            date: {},
            calendarKey: 0,
            calendarDate: undefined,
        }
    },
    computed: {
        isMobile () {
            return this.$mq.w < this.$mq.lg.min
        },
    },
    methods: {
        async handleEventCreation (value) {
            await calendarScreenUtils.createEvent(value)
        },
        async handleEventEdition (value) {
            await calendarScreenUtils.updateEvent(value, this.me.permission)
        },
        async handleSignUpEvent (value) {
            await calendarScreenUtils.signToEvent(value.data.id, this.me.id, value.state)
        },
        async getEvents (value) {
            this.events = await calendarScreenUtils.getEventsBaseOnPermission(value, this.me.permission)
            this.date = value
        },
        async handleShowModal (value) {
            if (value.type === 'read') {
                this.modalData = await calendarScreenUtils.getEvent(value.data.customData.id)
                this.showModal = true
            } else {
                this.modalData = {
                    date: value.day,
                    formattedDate: calendarScreenUtils.getFormattedDate(value.day),
                }
                this.showModal = true
            }
        },
        async handleCloseModal (type) {
            this.showModal = false
            if (type !== 'read') {
                this.events = await calendarScreenUtils.getEventsBaseOnPermission(this.date, this.me.permission)
                this.calendarDate = moment.utc(this.date.month + '/' + this.date.year, 'MM/YYYY')
                    .startOf('month').format()
                this.calendarKey += 1
            }
        },
    },
}
</script>
