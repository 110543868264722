import moment from "moment";
// import data22 from "./2022/madmix";
// import data23 from "./2023/madmix";
// import kickmix23 from "./ARG/kickmix";

export const editionsData = {
	2022: {},
	// 2022: data22,
	// 2023: data23,
	// 	kickmix23,
};
export const locationTypes = {
	1: {
		icon: "map-pin",
		color: "secondary",
		label: "Jarama: Av. de San Sebastián, 15",
		link: "https://goo.gl/maps/gxmee9ZKVYunv4rB9",
	},
};

export const scoreTypes = {
	1: {
		abbrev: "PJ",
		color: "secondary",
		label: "Partidos jugados",
	},
	2: {
		abbrev: "PG",
		color: "secondary",
		label: "Partidos Ganados",
	},
	3: {
		abbrev: "PP",
		color: "secondary",
		label: "Partidos Perdidos",
	},
	4: {
		abbrev: "PE",
		color: "secondary",
		label: "Partidos Empatados",
	},
	5: {
		abbrev: "CF",
		color: "secondary",
		label: "Carreras a favor",
	},
	6: {
		abbrev: "CC",
		color: "secondary",
		label: "Carreras en contra",
	},
	7: {
		abbrev: "DIF",
		color: "secondary",
		label: "Diferencia",
	},
	8: {
		abbrev: "PTS",
		color: "secondary",
		label: "Puntos",
	},
};

export const defensivePositions = [
	{
		key: "SF",
		label: "Short field",
		number: 10,
	},
	{
		key: "P",
		label: "Pitcher",
		number: 1,
	},
	{
		key: "C",
		label: "Catcher",
		number: 2,
	},
	{
		key: "1B",
		label: "1ra base",
		number: 3,
	},
	{
		key: "2B",
		label: "2da base",
		number: 4,
	},
	{
		key: "3B",
		label: "3ra base",
		number: 5,
	},
	{
		key: "SS",
		label: "Short stop",
		number: 6,
	},
	{
		key: "LF",
		label: "Left field",
		number: 7,
	},
	{
		key: "CF",
		label: "Center field",
		number: 8,
	},
	{
		key: "RF",
		label: "Right field",
		number: 9,
	},
];

const screenTab_CALENDAR = {
	key: "calendar",
	label: "Calendario",
	slug: "calendar",
};
const screenTab_INSCRIPTIONS = {
	key: "inscriptions",
	label: "Inscripciones",
	slug: "inscriptions",
};
const screenTab_RAFFLE = {
	key: "raffle",
	label: "Sorteo",
	slug: "raffle",
};
const screenTab_TEAMS = {
	key: "teams",
	label: "Equipos Sorteados",
	slug: "teams",
};
// const screenTab_TERMS = {
// 	key: "terms",
// 	label: "Condiciones",
// 	slug: "terms",
// };

export const defaultScreenTabs = [screenTab_CALENDAR, screenTab_TEAMS];
export const currentScreenTabs = [screenTab_CALENDAR, screenTab_INSCRIPTIONS, screenTab_RAFFLE, screenTab_TEAMS]; // TODO: screenTab_RAFFLE // screenTab_TERMS
export const raffleTabs = [
	{
		key: "players",
		label: "Jugadoras",
		disabled: true,
		loading: false,
		icon: "users",
		slug: "players",
	},
	{
		key: "managers",
		label: "Managers",
		disabled: true,
		loading: false,
		icon: "user",
		slug: "managers",
	},
	{
		key: "names",
		label: "Nombres",
		disabled: false,
		loading: false,
		icon: "package",
		slug: "names",
	},
];
export const inscriptionsTabs = [
	{
		key: "players",
		label: "Jugadoras",
		slug: "players",
	},
	{
		key: "managers",
		label: "Managers",
		slug: "managers",
	},
	{
		key: "names",
		label: "Nombres",
		slug: "names",
	},
];
export const editionsTabs = [
	{
		key: "2022",
		label: "2022",
		slug: "2022",
	},
	{
		key: "2023",
		label: "2023",
		slug: "2023",
	},
	{
		key: "2024",
		label: "2024",
		slug: "2024",
	},
];

export function formatDate(date) {
	return {
		weekDay: moment.utc(date).locale("es").format("dddd"),
		day: moment.utc(date).locale("es").format("D"),
		month: moment.utc(date).locale("es").format("MMMM"),
		time: moment.utc(date).locale("es").format("LT"),
	};
}
