<template>
    <div class="WelcomeScreen">
        <div
            class="ImageSection"
            :style="{ backgroundImage: `url(${require('@/assets/imgs/pictures/photo-13.jpg')})`}"
        >
            <!-- :style="{ backgroundImage: `url('imgs/pictures/photo-02.jpg')` }" -->
            <!-- <div class="Title">
                <h2>
                    BIENVENIDA A<br>
                    <span>ESPARTANAS</span><br>
                    <b>Kickingball</b> Madrid
                </h2>
            </div> -->
        </div>
        <div class="Title down">
            <h2>
                BIENVENIDA A<br>
                <span>ESPARTANAS</span><br>
                <b>Kickingball</b> Madrid
            </h2>
        </div>
        <div class="Definition">
            <iframe
                class="Video"
                src="https://www.youtube.com/embed/HlB4pF1Ge50"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            />
            <div class="Text">
                <h3>
                    Sobre nosotras...
                </h3>
                <p>
                    Iniciamos nuestros entrenamientos en verano del 2015, siendo el <b>equipo pionero</b> de Kickingball en Madrid.
                    <br><br>
                    A lo largo de estos años hemos participado en diferentes torneos y partidos amistosos, demostrando nuestro talento y espíritu deportivo.
                    <br><br>
                    El crecimiento del Kickingball en Madrid hizo posible formalizar la primera edición de la Liga en el otoño del 2018, donde
					<!-- participaron los 4 equipos ya existentes en ese año y donde  -->
					logramos ser campeonas. En primavera del 2021, defendimos el título en la segunda edición de la Liga,
                    <!-- donde participaron 6 equipos  -->
					y logramos ser bicampeonas.
                    <br>
                    En 2022 no logramos conseguir los resultados a nuestro favor. Y en el 2023, conseguimos el 3er lugar de la cuarta edición.
                    <!-- En 2022 participamos en la tercera edición, en la que no logramos conseguir los resultados a nuestro favor. -->
                   <br><br>
                    Ahora, esperamos con emoción la quinta edición de dicha Liga, prevista para el 2024.
                </p>
            </div>
        </div>
        <div class="Organization">
            <h3>Nuestra organización</h3>
            <div
                class="Picture"
                :style="{ backgroundImage: `url(${require('@/assets/imgs/pictures/photo-03.jpg')})`}"
            >
                <p class="align-center">
                    Tenemos un comité organizativo encargado de realizar toda la organización y gestión general.
                </p>
                <div class="Grid">
                    <div
                        v-for="({ name, role, image }, index) in comiteOrganizativo"
                        :key="index"
                        class="GridItem"
                    >
                        <div
                            class="ProfilePicture"
                            :style="{ backgroundImage: `url(${require(`@/assets/imgs/players/${image}`)})` }"
                        />
                        <p class="Name">
                            {{ name }}
                        </p>
                        <p class="Role">
                            {{ role }}
                        </p>
                    </div>
                </div>
            </div>
            <!-- <div
                class="Picture small"
            >
                <p class="align-center">
                    Tenemos un comité técnico encargado de organizar los entrenamientos y estrategias de juego.
                </p>
                <div class="Grid">
                    <div
                        v-for="({ name, role, image }, index) in comiteTecnico"
                        :key="index"
                        class="GridItem"
                    >
                        <div
                            class="ProfilePicture"
                            :style="{ backgroundImage: `url(${require(`@/assets/imgs/players/${image}`)})` }"
                        />
                        <p class="Name">
                            {{ name }}
                        </p>
                        <p class="Role">
                            {{ role }}
                        </p>
                    </div>
                </div>
            </div> -->
            <div class="Text">
                <!-- <Bullet icon="user">
                    Nuestra Entrenadora, Jessamar López, lleva representando este Club desde sus inicios.
                    Asumió el liderazgo del equipo con gran entusiasmo y valentía creciendo junto a sus jugadoras y
                    trabajando cada día en nuestras estrategias de juego y preparación.
                </Bullet> -->
                <Bullet icon="calendar">
                    Organizamos dos entrenamientos semanales, con horarios sujetos a cambios según la época del año.
                    En el área privada de nuestra web mantenemos nuestro calendario de actividades actualizado con horario y ubicación de las actividades.
                </Bullet>
                <Bullet icon="map-pin">
                    <div class="Grid Four">
                        <a
                            v-for="({ name, location, metro, when, image, link }, index) in places"
                            :key="index"
                            class="GridItem"
                            :href="link"
                            target="_blank"
                        >
                            <!-- @click="openModal(image)" -->
                            <div
                                class="ProfilePicture"
                                :style="{ backgroundImage: `url(${require(`@/assets/imgs/fields/${image}.jpg`)})` }"
                            />
                            <p class="Name">{{ name }}</p>
                            <p
                                v-if="location"
                                class="Location"
                            >
                                {{ location }},<br>
                                metro <span
                                    v-if="metro"
                                    class="nowrap"
                                > {{ metro }}</span>
                            </p>
                            <p class="Role">{{ when }}</p>
                        </a>
                    </div>
                </Bullet>
                <Bullet icon="briefcase">
                    Recomendamos llevar a los entrenamientos:
                    <br><span class="bullet-dot">&bull;</span> Pantalon deportivo largo (para proteger las piernas)
                    <br><span class="bullet-dot">&bull;</span> Toalla y/o guantes para el entrenamiento físico
                    <br><span class="bullet-dot">&bull;</span> Agua
                </Bullet>
                <Bullet icon="dollar-sign">
                    Para financiar nuestras actividades, cada jugadora aporta <b>10 euros mensuales</b>.
                    No obstante, las chicas que quieran entrar al equipo tienen el primer mes gratis para adaptarse y decidir si quieren ser parte del club.
                    Nuestra tesorera es la encargada de administrar esta caja chica.
                </Bullet>
                <Bullet icon="message-square">
                    Buscamos siempre mejorar, y nos nutrimos del feedback que recibimos de cada jugadora que ha pasado por el equipo.
                    Si tienes algún comentario, duda o inquietud, puedes hablar con nosotras sin problema. Estamos abiertas a escuchar tu opinión.
                    También tenemos de un <b>Buzón de Sugerencias online</b>, así que si prefieres, puedes dejar un comentario (con nombre o anónimo).
                    <!-- <a href="https://n9.cl/buzon-sugerencias" target="_blank">Buzón de Sugerencias online</a> -->
                </Bullet>
                <Bullet icon="flag">
                    Tenemos un <b>Reglamento Interno</b>, en el cual están definidos los objetivos del club. Para formar parte del equipo es necesario firmarlo.
                    <!-- <a href="https://n9.cl/reglamento-interno" target="_blank">Reglamento Interno</a> -->
                </Bullet>
            </div>
        </div>
        <Modal
            :isOpen="isModalOpen"
            @close="isModalOpen = false"
        >
            <template #body>
                <div
                    class="ImageWrapper"
                    :style="{ backgroundImage: `url(${require(`@/assets/imgs/fields/${modalImage}.jpg`)})` }"
                />
            </template>
        </Modal>
    </div>
</template>

<script>
import { Bullet } from '@/components'

export default {
    name: 'WelcomeScreen',
    // metaInfo () {
    //   return {
    //     title: 'Bienvenida',
    //   }
    // },
    components: {
        Bullet,
    },
    data () {
        return {
            modalImage: '',
            isModalOpen: false,
            comiteOrganizativo: [
                // {
                //     name: 'Jessamar',
                //     role: 'Entrenadora',
                //     image: 'Jessamar_Lopez.jpg',
                // },
                {
                    name: 'Kendra',
                    role: 'Logística y redes',
                    image: 'Kendra_Petit.jpg',
                },
                {
                    name: 'Wendy',
                    role: 'Tesorería',
                    image: 'Wendy_Cruz.jpg',
                },
                {
                    name: 'Kira',
                    role: 'Gestiones',
                    image: 'Kira_Petit.jpg',
                },
                // {
                //     name: 'Vony',
                //     role: 'Relaciones públicas',
                //     image: 'Vony_Vasquez.jpg',
                // },
                {
                    name: 'Mariana',
                    role: 'Logística',
                    image: 'Mariana_Fernandez.jpg',
                },
                // {
                //     name: 'Mary',
                //     role: 'Comunicaciones',
                //     image: 'Mary_Dorazio.jpg',
                // },
                {
                    name: 'Vero',
                    role: 'Relaciones públicas',
                    image: 'Veronica_Ponte.jpg',
                },
            ],
            comiteTecnico: [
                {
                    name: 'Jessamar',
                    // role: 'Entrenadora',
                    image: 'Jessamar_Lopez.jpg',
                },
                {
                    name: 'Kira',
                    // role: 'Infield',
                    image: 'Kira_Petit.jpg',
                },
                {
                    name: 'Desireé',
                    // role: 'Outfield',
                    image: 'Desiree_Perez.jpg',
                },
                {
                    name: 'Morella',
                    // role: '2do Coach',
                    image: 'Morella_Santoro.jpg',
                },
                {
                    name: 'Joselin',
                    // role: 'Pitchers',
                    image: 'Joselin_Perez.jpg',
                },
            ],
            places: [
                {
                    name: 'Lago',
                    location: 'Paseo Puerta del Ángel 13',
                    image: 'lago',
                    link: 'https://goo.gl/maps/1dE79Xxa2GaSgH1t7',
                    metro: 'línea 10',
                    when: 'Domingos 11am',
                },
                {
                    name: 'Colombia',
                    location: 'Calle de Puerto Rico 54',
                    image: 'pio',
                    link: 'https://maps.app.goo.gl/tsgUhQtqwe1L6gbw9',
                    metro: 'líneas 8 y 9',
                    when: 'Miércoles 8pm',
                },
                // {
                //     name: 'Príncipe Pío',
                //     location: 'Calle Mozart 25',
                //     image: 'pio',
                //     link: 'https://goo.gl/maps/vChQHsiGxEA1DaY27',
                //     metro: 'líneas 6 y 10',
                //     when: 'Miércoles 7pm',
                // },
                // {
                //     name: 'Pacífico',
                //     location: 'Calle de las Cocheras 5',
                //     image: 'pacifico',
                //     link: 'https://goo.gl/maps/756jmACGS7beJEy29',
                //     metro: 'líneas 1 y 6',
                //     when: 'En caso de lluvia',
                // },
                {
                    name: 'Google Meet',
                    // location: 'https://meet.google.com/pps-ogyx-gpn',
                    image: 'meet',
                    when: 'Remoto',
                },
            ],
            socialMedia: [
                {
                    name: 'Instagram',
                    icon: 'instagram',
                    url: '@espartanaskickingball',
                    link: 'https://www.instagram.com/espartanaskickingball/',
                },
                {
                    name: 'Facebook',
                    icon: 'facebook',
                    url: '@espartanaskickingball',
                    link: 'https://www.facebook.com/EspartanasKickingball',
                },
                {
                    name: 'Youtube',
                    icon: 'youtube',
                    url: 'Espartanas Kickingball',
                    link: 'https://www.youtube.com/channel/UCmzmsqUUHx2vuUqnoXbSy1w',
                },
            ],
        }
    },
    methods: {
        openModal (value) {
            this.isModalOpen = true
            this.modalImage = value
        },
    },
}
</script>

<style lang="scss" scoped>

.WelcomeScreen {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: white;
}
.ImageSection {
	// position: absolute;
	// z-index: 1;
	opacity: .3;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	height: 17rem;
	// height: 25rem;
	width: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position-x: center;
	background-position-y: center;
	@media screen and (max-width: $sm) {
		height: 12rem;
		// height: 25rem;
	}
}
.Title {
	// z-index: 2;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 160px;
	// margin: 120px 0 80px 0;
	@media screen and (max-width: $sm) {
		margin: 100px 0 50px 0;
	}
	&.down {
		margin-top: 80px;
		@media screen and (max-width: $sm) {
			margin: 60px 0 10px 0;
		}
	}
	h2 {
		font-weight: $font-weight-normal;
		text-align: center;
		color: white;

		span {
			font-weight: $font-weight-bold;
			font-size: 4rem;
			line-height: 5rem;
			color: $primary;
			@media screen and (max-width: $sm) {
				font-size: 3rem;
			}
			//  background: -webkit-linear-gradient(45deg, $primary, $info);
			// -webkit-background-clip: text;
			// -webkit-text-fill-color: transparent;
		}
	}
}
.Definition {
	// z-index: 2;
	margin-top: 50px;
	width: 80%;
	display: flex;
	justify-content: center;
	align-items: center;
	@media screen and (max-width: $lg) {
		flex-direction: column;
	}
	.Video {
		border-radius: 30px;
		min-width: calc(50% - 6rem);
		@media screen and (min-width: $xl) {
			height: 18rem;
		}
		@media screen and (max-width: $xl) {
			height: 14rem;
		}
		@media screen and (max-width: $lg) {
			width: 80%;
			height: 25rem;
		}
		@media screen and (max-width: $md) {
			width: 100%;
			height: 20rem;
		}
		@media screen and (max-width: $sm) {
			height: 10rem;
		}
	}
	.Text {
		font-size: 1.2rem;
		font-weight: $font-weight-normal;
		line-height: 2rem;
		padding-left: 6rem;
		margin-left: 6rem;
		border-left: 5px solid rgba(white, .2);
		text-align: left;
		p {
			color: white;
		}
		@media screen and (max-width: $lg) {
			border: none;
			margin: 2rem 0 0 0;
			padding: 0;
		}
	}
	h3 {
		color: white;
		font-size: 2rem;
		font-weight: $font-weight-bold;
		line-height: 3rem;
		span {
		color: $primary;
		}
	}
}

.Organization {
	width: 100%;
	margin-top: 70px;
	margin-bottom: 70px;
	display: flex;
	flex-direction: column;
	align-items: center;
	p {
		color: white;
		width: 80%;
		line-height: 2rem;
	}
	h3 {
		color: $primary;
		text-align: center;
		font-size: 1.5rem;
		margin-bottom: 30px;
	}
	.Picture {
		height: 32rem;
		width: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position-x: center;
		background-position-y: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		padding-top: 30px;
		@media screen and (max-width: $md) {
			height: auto;
		}
		p {
		font-size: 1.2rem;
		}
		&.small {
			height: 23rem;
			@media screen and (max-width: $md) {
				height: auto;
			}
		}
	}
	.Text {
		width: 80%;
	}
}
.Grid {
	width: 80%;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-column-gap: 20px;
	grid-row-gap: 20px;
	grid-template-rows: 1fr;
	margin: 30px 0;
	@media screen and (min-width: $lg) {
		width: 70%;
	}
	@media screen and (max-width: $md) {
		grid-template-columns: repeat(3, 1fr);
	}
	@media screen and (max-width: $sm) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media screen and (min-width: $xl) {
		width: 50%;
	}
	.GridItem {
		background: white;
		overflow: hidden;
		border-radius: 10px;
		box-shadow: 0px 4px 10px rgba(103, 94, 134, 0.1);
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 15px;

		.ProfilePicture{
			width: 100%;
			height: 180px;
			background-position-x: center;
			background-position-y: top;
			background-size: cover;
			background-repeat: no-repeat;
		}
		.Name {
			margin-top: 5px;
			color: $gray-dark !important;
			font-weight: 500;
			font-size: 16px;
			text-align: center;
		}
		.Role {
			text-transform: uppercase;
			font-size: 12px;
			line-height: 16px;
			color: $gray-03;
			font-weight: 600;
			margin-top: 5px;
			text-align: center;
		}
		.Location {
			font-size: 14px;
			color: $gray-01;
			font-weight: 500;
			margin-top: 5px;
			line-height: 16px;
			text-align: center;
			.nowrap {
				white-space: nowrap;
			}
		}
	}
}
.Four {
	grid-template-columns: repeat(4, 1fr);
	width: 100%;
	margin: 0;
	@media screen and (max-width: $md) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media screen and (max-width: $sm) {
		grid-template-columns: repeat(1, 1fr);
	}
	.GridItem {
		margin-top: 0;
		cursor: pointer;
		transition: all 0.3s;
		&:hover {
			transform: scale(1.05);
		}
		.Name {
			font-weight: 600;
			font-size: 18px;
		}
		.Role {
			font-size: 14px;
			margin-top: 15px;
		}
	}
}
.align-center {
	text-align: center;
}
.Modal::v-deep {
	.Dialog {
		max-width: 80%;
	}
	.Body {
		display: flex;
		align-items: center;
		justify-content: center;

		.ImageWrapper {
			width: calc(100% - 40px);
			border-radius: 10px;
			min-height: 700px;
			margin-bottom: 20px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position-x: center;
			background-position-y: center;
		}
	}
}
</style>
