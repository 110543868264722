<template>
	<div class="LeagueFourthScreen">
		<div class="ImageSection" :style="{ backgroundImage: `url(${require('@/assets/imgs/pictures/photo-10.jpg')})` }">
			<div class="Title">
				<h2>
					4ta Liga<br />
					<span>CALENDARIO</span><br />
					<b>Kickingball</b> Madrid
				</h2>
			</div>
		</div>
		<Legend :legend="locationTypes" @clickOn="handleRedirectTo" />
		<div class="PillSelectors">
			<PillSelectorInput v-model="activeFilter" :options="filters" border="none" preventDeselect @input="handleChangeFilter" />
			<PillSelectorInput v-model="activeTimeFilter" :options="timeFilters" border="none" preventDeselect @input="handleChangeTimeFilter" />
		</div>
		<div class="Calendar">
			<div v-for="(eventList, i) in filteredEvents" :key="i" class="List">
				<div class="ListHeader">
					<p>
						{{ formatDate(eventList[0].date).weekDay }} {{ formatDate(eventList[0].date).day }} {{ formatDate(eventList[0].date).month }} en
						{{ locations.find((l) => l.id === eventList[0].locationId).name }}
					</p>
					<span v-if="isDayCancelled(eventList)" class="Cancelled"> Jornada cancelada </span>
				</div>
				<div v-for="(event, index) in eventList" :key="index" class="ListItem" :class="isPastDate(event.date) ? 'isPastDate' : ''">
					<div v-if="event.scoreHomeclub !== null && !isMobile" class="ScoreHomeClub">
						<p>
							{{ event.scoreHomeclub }}
						</p>
					</div>
					<div v-if="event.scoreVisitor !== null && !isMobile" class="ScoreVisitor">
						<p>
							{{ event.scoreVisitor }}
						</p>
					</div>
					<div class="Team">
						<Avatar
							:url="event.TBD ? '' : require(`@/assets/imgs/fourth-league-teams/${event.homeclubId}.png`)"
							:size="{ height: isMobile ? 30 : 40, width: isMobile ? 30 : 40 }"
							:iconSize="20"
							:rounded="false"
						/>
						<div class="Homeclub">
							<p>{{ teams.find((t) => t.id === event.homeclubId).name }}</p>
						</div>
					</div>
					<div v-if="event.title" class="Time center">
						<p>{{ event.title }}</p>
					</div>
					<div v-else-if="event.homeclubId" class="Time">
						<p>{{ formatDate(event.date).time }}</p>
						<p v-if="!isDayCancelled(eventList) && event.cancelled" class="Cancelled">Partido cancelado</p>
					</div>
					<div class="Team right">
						<div class="Visitor">
							<p>{{ teams.find((t) => t.id === event.visitorId).name }}</p>
						</div>
						<Avatar
							:url="event.TBD ? '' : require(`@/assets/imgs/fourth-league-teams/${event.visitorId}.png`)"
							:size="{ height: isMobile ? 30 : 40, width: isMobile ? 30 : 40 }"
							:iconSize="20"
							:rounded="false"
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="ScoreList">
			<p class="OutsiderName">Tabla de clasificación</p>
			<div class="Team Header">
				<p class="Name">Clasificación</p>
				<p v-for="{ abbrev } in scoreTypes" :key="`scoreType-${abbrev}`" class="Value">
					{{ abbrev }}
				</p>
			</div>
			<div v-for="(team, index) in updatedTeams" :key="`team-${index}`" class="Team">
				<Avatar
					:url="require(`@/assets/imgs/fourth-league-teams/${team.id}.png`)"
					:size="{ height: isMobile ? 30 : 40, width: isMobile ? 30 : 40 }"
					:iconSize="20"
					:rounded="false"
				/>
				<p class="Name">
					{{ team.name }}
				</p>
				<p class="Value">
					{{ team.partidosJugados }}
				</p>
				<p class="Value">
					{{ team.partidosGanados }}
				</p>
				<p class="Value">
					{{ team.partidosPerdidos }}
				</p>
				<p class="Value">
					{{ team.partidosEmpatados }}
				</p>
				<p class="Value">
					{{ team.carrerasAfavor }}
				</p>
				<p class="Value">
					{{ team.carrerasEnContra }}
				</p>
				<p class="Value">
					{{ team.diferencia }}
				</p>
				<p class="Value">
					{{ team.puntos }}
				</p>
			</div>
		</div>
		<Legend :legend="scoreTypes" class="ScoreLegend" />

		<!-- <div class="Ref">
			<div class="Organization">
				<p>Organizador de la Liga</p>
				<a href="https://www.instagram.com/kickingballmadrid/" target="_blank">
					<img :src="require(`@/assets/imgs/fourth-league-teams/liga.png`)" class="Liga kickingball Madrid" />
				</a>
			</div>
			<div class="Organization">
				<p>Sponsor de Espartanas</p>
				<a v-for="({ link, image }, index) in sponsors" :key="index" :href="link" target="_blank">
					<img :src="require(`@/assets/imgs/sponsors/${image}.png`)" class="Sponsor" />
				</a>
			</div>
		</div> -->
	</div>
</template>

<script>
import moment from "moment";
import { mediaQueryMixin } from "@/mixins";
import { teams, locations, events, locationTypes, scoreTypes } from "@/data/fourth-league";
const ESPARTANAS_ID = 7

export default {
	name: "LeagueFourthScreen",
	mixins: [mediaQueryMixin],
	data() {
		return {
			activeFilter: "all",
			activeTimeFilter: "all",
			locationTypes,
			scoreTypes,
			sponsors: [
				// {
				//     image: 'laCanallla',
				//     link: 'https://www.lacanallalive.com/',
				// },
			],
		};
	},
	computed: {
		isMobile() {
			return this.$mq.w < this.$mq.lg.min;
		},
		filteredEvents() {
			if (this.activeFilter === "all") {
				if (this.activeTimeFilter === "all") return events;
				else return events.filter((e) => !this.isPastDate(e[0].date));
			} else {
				const filteredEvents = [];
				events.forEach((event) => {
					const e = event.filter((e) => e.homeclubId === ESPARTANAS_ID || e.visitorId === ESPARTANAS_ID);
					if (e.length) filteredEvents.push(e);
				});
				if (this.activeTimeFilter === "all") return filteredEvents;
				else return filteredEvents.filter((e) => !this.isPastDate(e[0].date));
			}
		},
		updatedTeams() {
			let updatedTeams = [];
			teams.slice(0, 7).forEach((team) => {
				updatedTeams.push(this.findTeamStatus(team));
			});
			const teamsSortedByPoints = updatedTeams.sort((a, b) => {
				if (a.puntos < b.puntos) return 1;
				else if (a.puntos === b.puntos) {
					if (a.diferencia < b.diferencia) return 1;
					else return -1;
				} else return -1;
			});
			return teamsSortedByPoints;
		},
	},
	created() {
		this.filters = [
			{
				key: "all",
				label: "Todos",
			},
			{
				key: "ours",
				label: "Espartanas",
			},
		];
		this.timeFilters = [
			{
				key: "all",
				label: "Todos",
			},
			{
				key: "commingUp",
				label: "Próximos",
			},
		];
		this.teams = teams;
		this.locations = locations;
		this.events = events;
	},
	methods: {
		formatDate(date) {
			return {
				weekDay: moment.utc(date).locale("es").format("dddd"),
				day: moment.utc(date).locale("es").format("D"),
				month: moment.utc(date).locale("es").format("MMMM"),
				time: moment.utc(date).locale("es").format("LT"),
			};
		},
		handleChangeFilter(value) {
			this.activeFilter = value;
		},
		handleChangeTimeFilter(value) {
			this.activeTimeFilter = value;
		},
		isPastDate(date) {
			const today = moment();
			return moment(date).isBefore(today);
		},
		handleRedirectTo(id) {
			if (id) window.open(locationTypes[id].link, "_blank");
		},
		findTeamStatus(team) {
			let partidosJugados = 0;
			let partidosGanados = 0;
			let partidosPerdidos = 0;
			let partidosEmpatados = 0;
			let carrerasAfavor = 0;
			let carrerasEnContra = 0;
			let puntos = 0;
			events.forEach((event) => {
				event.forEach((e) => {
					if (!e.finals) {
						if (this.isPastDate(e.date) && !e.cancelled && e.scoreHomeclub !== null) {
							if (e.homeclubId === team.id || e.visitorId === team.id) {
								partidosJugados += 1;
								if (e.homeclubId === team.id) {
									if (e.scoreHomeclub > e.scoreVisitor) {
										partidosGanados += 1;
										puntos += 3;
									}
									if (e.scoreHomeclub < e.scoreVisitor) partidosPerdidos += 1;
									if (e.scoreHomeclub === e.scoreVisitor) {
										partidosEmpatados += 1;
										puntos += 1;
									}
									carrerasAfavor += e.scoreHomeclub;
									carrerasEnContra += e.scoreVisitor;
								} else if (e.visitorId === team.id) {
									if (e.scoreVisitor > e.scoreHomeclub) {
										partidosGanados += 1;
										puntos += 3;
									}
									if (e.scoreVisitor < e.scoreHomeclub) partidosPerdidos += 1;
									if (e.scoreVisitor === e.scoreHomeclub) {
										partidosEmpatados += 1;
										puntos += 1;
									}
									carrerasAfavor += e.scoreVisitor;
									carrerasEnContra += e.scoreHomeclub;
								}
							}
						}
					}
				});
			});
			return {
				...team,
				partidosJugados,
				partidosGanados,
				partidosPerdidos,
				partidosEmpatados,
				carrerasAfavor,
				carrerasEnContra,
				diferencia: carrerasAfavor - carrerasEnContra,
				puntos,
			};
		},
		isDayCancelled(eventList) {
			const isCancelledArray = [];
			eventList.forEach((e) => {
				if (e.cancelled) isCancelledArray.push(e.cancelled);
				else isCancelledArray.push(false);
			});
			return !isCancelledArray.includes(false);
		},
	},
};
</script>

<style lang="scss" scoped>
.LeagueFourthScreen {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: white;
}
.ImageSection {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	height: 15rem;
	width: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position-x: center;
	background-position-y: center;
	@media screen and (max-width: $sm) {
		height: 12rem;
	}
}
.Title {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 9.5rem;
	@media screen and (max-width: $sm) {
		margin: 100px 0 50px 0;
	}
	&.down {
		margin-top: 80px;
		@media screen and (max-width: $sm) {
			margin: 60px 0 10px 0;
		}
	}
	h2 {
		font-weight: $font-weight-normal;
		text-align: center;
		color: white;

		span {
			font-weight: $font-weight-bold;
			font-size: 4rem;
			line-height: 5rem;
			color: $white;
			opacity: 0.4;
			@media screen and (max-width: $sm) {
				font-size: 3rem;
			}
		}
	}
}
.Calendar {
	display: flex;
	// flex-direction: row;
	// flex-wrap: wrap;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-bottom: 50px;
	// padding-right: 50px;
	// padding-left: 50px;
	// margin: 50px 0;
}
.List {
	margin-top: 20px;
	// margin-right: 15px;
	// margin-left: 15px;
	// flex: 1;
	width: 45%;
	@media screen and (max-width: $md) {
		width: 75%;
	}
	@media screen and (max-width: $sm) {
		width: 90%;
	}
	.ListHeader {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 10px 20px;
		p {
			flex: 1;
			text-align: center;
			color: $white;
			text-transform: capitalize;
		}
		.Cancelled {
			color: $white;
			background: rgba($danger, 0.7);
			margin-top: 0px;
			padding: 4px 8px;
			border-radius: 5px;
			font-size: 12px;
			text-transform: uppercase;
		}
	}
	.isPastDate {
		opacity: 0.6;
	}
	.ListItem {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: white;
		height: 65px;
		padding: 0 20px;
		border-radius: 1rem;
		position: relative;
		.ScoreHomeClub,
		.ScoreVisitor {
			position: absolute;
			background: rgba($white, 0.05);
			border-radius: 15px;
			height: 55px;
			width: 55px;
			display: flex;
			align-items: center;
			justify-content: center;
			p {
				color: $white;
				font-size: 20px;
			}
		}
		.ScoreHomeClub {
			left: -75px;
		}
		.ScoreVisitor {
			right: -75px;
		}

		& + .ListItem {
			margin-top: 12px;
		}
		.Time {
			text-align: center;
			@media screen and (max-width: $sm) {
				font-size: 0.9rem;
			}
			.Cancelled {
				color: $white;
				background: rgba($danger, 0.7);
				margin-top: 8px;
				padding: 3px 6px;
				border-radius: 5px;
				font-size: 10px;
				text-transform: uppercase;
			}
		}
		.Team {
			display: flex;
			align-items: center;
			min-width: 180px;
			@media screen and (max-width: $sm) {
				min-width: 120px;
			}
			&.right {
				justify-content: flex-end;
			}
		}
		.Homeclub,
		.Visitor {
			margin: 0 20px;
			@media screen and (max-width: $sm) {
				font-size: 0.9rem;
				margin: 0 10px;
			}
		}
	}
}
.Ref {
	width: 100%;
	padding: 2rem 1rem;
	background: white;
	display: flex;
	justify-content: center;
	@media screen and (max-width: $sm) {
		flex-direction: column;
	}
}
.Organization {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-right: 60px;
	margin-top: 0px;
	@media screen and (max-width: $sm) {
		margin-top: 30px;
		margin-right: 0px;
	}

	p {
		color: $gray-dark;
		text-align: center;
	}
	img {
		width: auto;
		height: 140px;
		margin-top: 20px;
	}
}

.Legend {
	margin-top: 50px;
	margin-bottom: 50px;
	width: 90%;
	display: flex;
	justify-content: center;

	&::v-deep {
		.Title {
			color: white;
			font-size: 20px;
			@media screen and (max-width: $sm) {
				font-size: 18px;
			}
		}
		.ItemsWrapper {
			background: transparent !important;
			.Item {
				background: rgba(white, 0.1) !important;
				padding: 10px 20px 10px 12px;
				.ItemText {
					color: white;
					font-size: 14px;
				}
			}
		}
	}
}

.PillSelectors {
	// margin-top: 1rem;
	display: flex;
	justify-content: center;
	width: 50%;
	@media screen and (max-width: $md) {
		width: 75%;
	}
	@media screen and (max-width: $sm) {
		width: 90%;
	}
	.PillSelectorInput {
		width: fit-content;
		& + .PillSelectorInput {
			margin-left: 10px;
		}
		&::v-deep .OptionGroup {
			background: rgba($white, 0.05);
			.Option {
				color: $white;
				background: transparent;
				&.selected,
				&:hover {
					color: $black;
					background: $white;
				}
			}
		}
	}
}
.ScoreList {
	width: 90%;
	@media screen and (min-width: $lg) {
		width: 50%;
	}
	.OutsiderName {
		font-size: 24px;
		text-align: center;
		font-weight: bold;
		color: $white;
		margin-bottom: 25px;
		display: none;
		@media screen and (max-width: $sm) {
			display: block;
		}
	}
	.Team {
		display: flex;
		align-items: center;
		margin-bottom: 15px;
		background: rgba($white, 0.1);
		padding: 8px 16px;
		border-radius: 10px;
		@media screen and (max-width: $sm) {
			padding: 8px;
		}
		&.Header {
			background: none;
			.Name {
				font-size: 20px;
				font-weight: bold;
				@media screen and (max-width: $sm) {
					font-size: 10px;
					color: transparent;
				}
			}
		}
		.Avatar {
			margin-right: 15px;
			min-width: 40px;
			@media screen and (max-width: $sm) {
				margin-right: 10px;
				min-width: 20px;
			}
		}
		.Name {
			color: $white;
			min-width: 150px;
			flex: 1;
			@media screen and (max-width: $md) {
				min-width: 100px;
				font-size: 14px;
			}
			@media screen and (max-width: $sm) {
				min-width: 70px;
				font-size: 12px;
			}
		}
		.Value {
			color: $white;
			min-width: 45px;
			@media screen and (max-width: $sm) {
				min-width: 28px;
				font-size: 12px;
			}
		}
	}
}
.ScoreLegend {
	margin-top: 10px;
	&::v-deep {
		.ItemText {
			font-size: 14px !important;
			@media screen and (max-width: $sm) {
				font-size: 12px !important;
			}
		}
		.Item {
			padding: 5px 15px 5px 8px;
		}
	}
}
</style>
