export default {
    DEFAULT_LIMIT: 50,

    DEFAULT_OFFSET: 0,

    HTTP_SUCCESS_CODES: {
        OK: 200,
        RESOURCE_CREATED: 201,
        RESOURCE_DELETED: 204,
        NO_CONTENT: 204,
    },

    HTTP_ERROR_CODES: {
        BAD_REQUEST: 400,
        UNAUTHORIZED: 401,
        FORBIDDEN: 403,
        NOT_FOUND: 404,
        METHOD_NOT_ALLOWED: 405,
        CONFLICT: 409,
        INTERNAL_SERVER_ERROR: 500,
        SERVICE_UNAVAILABLE: 503,
        GATEWAY_TIMEOUT: 504,
    },

    ERROR_MESSAGES: {
        UNKNOWN_SUBERROR_MESSAGE: 'UNKNOWN_SUBERROR_MESSAGE',
        ENTITY_NOT_FOUND: 'ENTITY_NOT_FOUND',
        VALIDATION_ERROR: 'VALIDATION_ERROR',
        INVALID_PARAMETERS: 'INVALID_PARAMETERS',
        CANNOT_LOG_IN: 'CANNOT_LOG_IN',
        INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
        UNAUTHORIZED: 'UNAUTHORIZED',
        FORBIDDEN: 'FORBIDDEN',
        USER_ALREADY_EXIST: 'USER_ALREADY_EXIST',
    },

    DIRECTION: {
        next: 'next',
        prev: 'prev',
    },

    USER_ROLE: {
        ADMIN: 'ADMIN',
        COMITE: 'COMITE',
        COACH: 'COACH',
        PLAYER: 'PLAYER',
    },

    USER_STATUS: {
        ACTIVE: 'ACTIVE',
        INACTIVE: 'INACTIVE',
        INJURED: 'INJURED',
        OUT: 'OUT',
    },

    ACCOUNT_TYPE: {
        ACTIVE: 'ACTIVE',
        PASIVE: 'PASIVE',
        DEBT: 'DEBT',
    },

    UNIFORM_TYPE: {
        SWEATER: 'SWEATER',
        GAME_SHIRT: 'GAME SHIRT',
        TRAINING_SHIRT: 'TRAINING SHIRT',
        SOCKS: 'SOCKS',
        HAT: 'HAT',
    },

    UNIFORM_COLOR: {
        BLACK: 'BLACK',
        BLACK_NEW: 'BLACK_NEW',
        WHITE: 'WHITE',
        TURQUOISE: 'TURQUOISE',
        TURQUOISE_NEW: 'TURQUOISE_NEW',
    },

    POSITION_AREA: {
        INFIELD: 'INFIELD',
        OUTFIELD: 'OUTFIELD',
    },

    POSITION_TYPE: {
        OFFENSIVE: 'OFFENSIVE',
        DEFENSIVE: 'DEFENSIVE',
    },

    EVENT_TYPE: {
        PHYSICAL_TRAINING: 'PHYSICAL_TRAINING',
        THEORIC_TRAINING: 'THEORIC_TRAINING',
        GAME: 'GAME',
        ADDITIONAL_ACTIVITY: 'ADDITIONAL_ACTIVITY',
        COMITE_MEETING: 'COMITE_MEETING',
    },

    EVENT_TYPE_NAMES: {
        PHYSICAL_TRAINING: 'Entrenamientos',
        THEORIC_TRAINING: 'Entrenamientos teóricos',
        GAME: 'Partidos',
        ADDITIONAL_ACTIVITY: 'Actividades',
        COMITE_MEETING: 'Reuniones del comité',
    },

    EVENT_STATE: {
        ACCEPTED: 'ACCEPTED',
        REJECTED: 'REJECTED',
        CANCELED: 'CANCELED',
        PENDING: 'PENDING',
    },

    USER_EVENT_STATE: {
        GOING: 'GOING',
        NOT_GOING: 'NOT_GOING',
        MAYBE: 'MAYBE',
        PENDING: 'PENDING',
        INJURED: 'INJURED',
        OUT: 'OUT',
        SUPPORT: 'SUPPORT',
    },

    USER_EVENT_FINAL_STATE: {
        ACCEPTED: 'ACCEPTED',
        REJECTED: 'REJECTED',
        NOT_GOING: 'NOT_GOING',
        INJURED: 'INJURED',
        OUT: 'OUT',
        SUPPORT: 'SUPPORT',
    },

    MATERIAL_STATE: {
        DAMAGED: 'DAMAGED',
        WORKING: 'WORKING',
        MISSING: 'MISSING',
    },

    PAYMENT_STATE: {
        RECEIVED: 'RECEIVED',
        FREE: 'FREE',
        OWES: 'OWES',
    },

    PAYMENT_START_YEAR: '2019',

    EVENT_TYPE_COLOR: {
        PHYSICAL_TRAINING: 'trainning',
        THEORIC_TRAINING: 'trainning',
        GAME: 'match',
        ADDITIONAL_ACTIVITY: 'activity',
        COMITE_MEETING: 'comite',
    },
}
