import lineUp from '../api/lineUp'
import constants from '../entities/constants'
import _ from 'lodash'

export default {
    async getEventAttendance (eventId) {
        const event = await lineUp.getEventAttendance(eventId)
        event.users = this.getCleanUserEvents(event.userEvents)
        event.uniform = (event.eventUniforms && event.eventUniforms.length &&
            event.eventUniforms[0] && event.eventUniforms[0].uniform) || {}
        event.uniform.uniformId = (event.eventUniforms && event.eventUniforms.length &&
            event.eventUniforms[0] && event.eventUniforms[0].uniformId) || null
        delete event.userEvents
        delete event.eventUniforms
        return event
    },

    getCleanUserEvents (userEvents) {
        return userEvents.map((userEvent) => {
            const user = userEvent.user
            user.defensivePositions = user.userPositions.filter(u => u.position &&
                u.position.type === constants.POSITION_TYPE.DEFENSIVE)
            user.offensivePositions = user.userPositions.filter(u => u.position &&
                u.position.type === constants.POSITION_TYPE.OFFENSIVE)
            user.defensivePositionsText = this.getPositionText(user.defensivePositions, true)
            user.offensivePositionsText = this.getPositionText(user.offensivePositions, true)
            user.userUniform = (user.userUniforms && user.userUniforms.length &&
                user.userUniforms[0]) || {}

            delete user.userPositions
            delete user.userUniforms
            return user
        })
    },

    getPositionText (userPositions, useAbv = false) {
        return _.chain(userPositions)
            .map(item => item.position && useAbv ? item.position.abbreviation : item.position.name)
            .join(', ')
            .value() || ''
    },

    async getLineUp (eventId, params) {
        let counter = 500
        const lineUpData = await lineUp.getLineUp(eventId, params)
        const lineUpInfo = lineUpData.filter(l => l.defensiveId)
            .map(l => {
                return {
                    ...l.user,
                    userUniform: l.userUniform,
                    lineUpPosition: {
                        ...l.DEFENSIVE,
                        id: l.defensiveId,
                        key: l.defensiveId,
                        label: l.abbreviation,
                    },
                }
            }).sort(this.sortLineUp)
        const benchInfo = lineUpData.filter(l => !l.defensiveId)
            .map(l => {
                const benchUser = {
                    ...l.user,
                    userUniform: l.userUniform,
                    lineUpPosition: {
                        ...l.DEFENSIVE,
                        id: l.defensiveId,
                        key: l.defensiveId,
                        label: l.abbreviation,
                    },
                }
                if (!benchUser.id) benchUser.removeId = true
                benchUser.id = benchUser.id || (counter)
                counter += 1
                return benchUser
            }).sort(this.sortLineUp)
        return { lineUp: lineUpInfo, bench: benchInfo }
    },

    async listLineUps (eventId, params) {
        const lineUps = await lineUp.getLineUp(eventId, params)
        return lineUps.map(l => {
            return {
                ...l,
                key: l.id,
                label: l.name + ' - ' + l.number,
            }
        })
    },

    async createLineUp (params, list, isDuplicate = false) {
        if (!isDuplicate) {
            await lineUp.bulkDeleteLineUp(params)
        }
        await lineUp.bulkCreateLineUp(list)
    },

    async deleteLineUp (params, list) {
        await lineUp.bulkDeleteLineUp(params)
    },

    sortLineUp (a, b) {
        if (a.lineUpPosition.offensiveId > b.lineUpPosition.offensiveId) {
            return 1
        }
        if (a.lineUpPosition.offensiveId < b.lineUpPosition.offensiveId) {
            return -1
        }
        return 0
    },

    saveLineUp (lineUpList, benchList, lineUpData, duplicate = false) {
        const preparedLineUp = []
        for (let i = 0; i < lineUpList.length; i++) {
            const person = lineUpList[i]
            const lineUpEntry = {
                ...lineUpData,
                userId: person.id,
                userUniformId: person.userUniform && person.userUniform.id,
                defensiveId: person.lineUpPosition.id,
                offensiveId: i + 1,
            }
            delete lineUpEntry.id
            preparedLineUp.push(lineUpEntry)
        }
        for (let i = 0; i < benchList.length; i++) {
            const person = benchList[i]
            const lineUpEntry = {
                ...lineUpData,
                userId: person.id,
                userUniformId: person.userUniform && person.userUniform.id,
                offensiveId: i + 1,
                defensiveId: null,
            }
            delete lineUpEntry.id
            if (person.removeId) delete lineUpEntry.userId
            preparedLineUp.push(lineUpEntry)
        }
        this.createLineUp(lineUpData, preparedLineUp, duplicate)
    },
}
