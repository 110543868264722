<template>
    <i
        :class="iconClass"
        class="Icon"
        :style="[iconStyles, { color: cmpColor }]"
        @click="$emit('click')"
    />
</template>

<script>
import VueTypes from 'vue-types'
import { colors } from '@/theme/colors'

export default {
    name: 'Icon',
    props: {
        icon: VueTypes.string.isRequired.def(''),
        color: VueTypes.oneOf(Object.keys(colors)).def('grayDark'),
        size: VueTypes.number.def(0),
        clickable: VueTypes.bool.def(false),
    },
    data () {
        return {}
    },
    computed: {
        cmpColor () {
            return colors[this.color] || this.color
        },
        iconClass () {
            return [
                [
                    `icon-${this.icon}`,
                    this.clickable ? 'clickable' : '',
                ],
            ]
        },
        iconStyles () {
            const styles = {}
            if (this.size) styles.fontSize = `${this.size}px`
            return styles
        },
    },
}
</script>

<style scoped lang="scss">

.Icon {
    vertical-align: middle;
    color: $gray-01;
    transition: all .3s ease-in-out;
    &.clickable {
        cursor: pointer;
        &:hover{
            opacity: 0.5;
        }
    }
}
</style>
