<template>
    <div class="MainScreen">
        <Transition
            name="main-router"
            mode="out-in"
        >
            <RouterView />
        </Transition>
    </div>
</template>

<script>
export default {
    name: 'MainScreen',
}
</script>

<style scoped lang="scss">
@import "@/theme/theme.scss";

.MainScreen {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
</style>
