<template>
    <div class="InfoScreen">
        <div class="ScreenHeader">
            <h1>Informaciones</h1>
            <PillSelectorInput
                v-model="activeTab"
                :options="tabs"
                border="gray"
                preventDeselect
                @input="handleChangeTab"
            />
        </div>
        <div class="Container">
            <Transition
                name="main-router"
                mode="out-in"
            >
                <InfoFields
                    v-if="activeTab === 'fields'"
                    :fields="fields"
                />
                <Informations
                    v-if="activeTab === 'info'"
                />
                <InfoTeams
                    v-if="activeTab === 'teams'"
                    :allTeams="allTeams"
                />
            </Transition>
        </div>
    </div>
</template>

<script>
import {
    InfoFields,
    InfoTeams,
    Informations,
} from './InfoSections'
import { tabs } from '@/entities/info'
import VueTypes from 'vue-types'
import { fields } from '@/data/informations'
import { allTeams } from '@/data/teams'

export default {
    name: 'InfoScreen',
    components: {
        InfoFields,
        Informations,
        InfoTeams,
    },
    props: {
        me: VueTypes.object,
    },
    data () {
        return {
            tabs,
            fields,
            allTeams,
            activeTab: '',
        }
    },
    watch: {
        $route (to) {
            const { infoSlug } = to.params
            this.activeTab = infoSlug ? (this.tabs.find(t => t.slug === infoSlug) || {}).key : this.tabs[0].key
        },
    },
    mounted () {
        const { infoSlug } = this.$route.params || {}
        this.activeTab = infoSlug ? (this.tabs.find(t => t.slug === infoSlug) || {}).key : this.tabs[0].key
    },
    methods: {
        handleChangeTab (value) {
            const { infoSlug } = this.$route.params || {}
            const selectedSlug = (this.tabs.find(t => t.key === value) || {}).slug
            if (infoSlug !== selectedSlug) {
                this.$router.push({
                    name: 'Informations',
                    params: { infoSlug: selectedSlug },
                })
            }
        },
    },
}
</script>
<style lang="scss" scoped>

.ScreenHeader {
	width: 100%;
	display: flex;
	justify-content: space-between;
	.PillSelectorInput {
		width: fit-content !important;
	}
	@media screen and (max-width: $sm) {
		flex-direction: column;
		h1 {
			margin-bottom: 15px !important;
		}
		.PillSelectorInput {
			margin-bottom: 20px;
			align-self: flex-end;
		}
	}
}
</style>
