<template>
	<div class="Avatar" :style="{
		'max-height': `${size.height}px`,
		'max-width': `${size.width}px`,
		width: `${size.width}px`,
		heigth: `${size.width}px`,
		'min-height': `${size.height}px`,
		'min-width': `${size.width}px`,
		overflow: rounded ? 'hidden' : 'inherit'
	}">
		<!-- :width="size ? size.width : ''"
            :height="size ? size.height : ''" -->
		<img v-if="url" class="Image" :src="url" :style="{ backgroundColor: url ? '' : 'gray' }" alt="logo"
			@click="handleClick">
		<div v-else class="Empty" :style="{ width: `${size.width}px`, height: `${size.height}px` }">
			<Icon icon="user" :size="iconSize" />
		</div>
	</div>
</template>

<script>
import VueTypes from 'vue-types'

export default {
	name: 'Avatar',
	props: {
		url: VueTypes.string,
		iconSize: VueTypes.number.def(60),
		isClickable: VueTypes.bool.def(false),
		rounded: VueTypes.bool.def(true),
		size: VueTypes.objectOf(VueTypes.shape({
			height: VueTypes.oneOfType([Number, String]),
			width: VueTypes.oneOfType([Number, String]),
		}).loose).loose,
	},
	methods: {
		handleClick() {
			const { isClickable } = this
			if (isClickable) this.$emit('click')
		},
	},
}
</script>
<style lang="scss" scoped>
.Avatar {
	border-radius: 50%;
	// overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.Image {
	vertical-align: middle;
	width: 100%;
	margin: 0 auto;
}

.Empty {
	background: $gray-light;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}
</style>
