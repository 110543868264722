import axios from 'axios'

export default {
    createUser: async (body) => {
        const response = await axios.post('/users', body)

        return response && response.data && response.data.response
    },

    updateUser: async (id, body) => {
        const response = await axios.put('/users/' + id, body)

        return response && response.data && response.data.response
    },

    deleteUser: async (id) => {
        const response = await axios.delete('/users/' + id)

        return response && response.data && response.data.response
    },

    getUser: async (id) => {
        const response = await axios.get('/users/' + id)

        return response && response.data && response.data.response
    },

    listUser: async (params) => {
        const response = await axios.get('/users', { params })

        return response && response.data && response.data.response
    },
}
