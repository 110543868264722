// IMPORTANT: Old Doc https://vcalendar-legacy.netlify.com/#welcome-to-v-calendar
import { colors } from '@/theme/colors'

// Theme: https://vcalendar.io/api/theme-styles.html
export const theme = {
    wrapper: {
        backgroundColor: 'white',
        border: 0,
        borderRadius: '6px',
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.07)',
        padding: '16px !important',
    },
    headerTitle: {
        letterSpacing: 0.5,
        fontSize: '14px',
        fontWeight: 'bold',
    },
    headerArrows: {
        width: '16px',
        height: '16px',
    },
    weekdays: {
        paddingBottom: '5px',
        borderBottom: '1px dashed #ecebed',
        color: '#c8c8c8',
        fontSize: '12px',
    },
    dayContent: {
        width: '32px',
        height: '32px',
        color: '#444',
        fontSize: '12px',
        fontWeight: 600,
    },
    dots: {
        backgroundColor: 'tomato',
    },
}

// Attributes: https://vcalendar.io/api/attribute.html#properties
export const attrs = [
    {
        key: 'today',
        highlight: {
            backgroundColor: '#eee',
        },
        contentStyle: {
            color: '#444',
        },
        dates: new Date(),
    },
    {
        key: 'saturdays',
        contentStyle: {
            color: colors.primary,
        },
        dates: {
            weekdays: [1],
        },
    },
    {
        key: 'start',
    },
]

// Ol Doc: https://vcalendar-legacy.netlify.com/#formatting--parsing
export const formats = {
    weekdays: 'WWW',
}

// Transform date's functions
const pad = n => (n < 10 ? '0' : '') + n
const getTimezone = (date = new Date()) => date.getTimezoneOffset() * (-1)

const formatDate = (date) => {
    const timezone = getTimezone(date) / 60
    return new Date(`${date.getUTCFullYear()}-${pad(date.getUTCMonth() + 1)}-${pad(date.getDate())}T${pad(timezone)}:00:00.000Z`)
}

// Normalize date according to its timezone
export const normalizeDate = (date) => {
    if (date) return formatDate(date)
    return date
}

export const normalizeDateRange = ({ start, end }) => ({ start: formatDate(start), end: formatDate(end) })

export default {
    attrs,
    formats,
    theme,
}
