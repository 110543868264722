<template>
    <div class="ProfileInfoTable">
        <Tabs
            class="mb-0"
            :value="activeTab"
            :tabs="tabs"
            :userId="userId"
            @input="handleChangeTab"
        />
        <div class="Container">
            <Transition
                name="main-router"
                mode="out-in"
            >
                <Loading
                    v-if="isLoading"
                    key="loading"
                    visible
                    :opacity="1"
                />
                <Payments
                    v-else-if="activeTab === 'payments'"
                    :key="userPaymentsKey"
                    :isLoading="isLoading"
                    :paymentInformation="paymentInformation"
                    @changePaymentYear="handlePaymentEvent"
                    @updatePayments="updatePayments"
                />
                <Attendance
                    v-else-if="activeTab === 'attendance'"
                    :isLoading="isLoading"
                    :events="events"
                    :attendance="attendance"
                    :isPlayer="isPlayer"
                    :dateInfo="attendanceDateInfo"
                    @changeAttendanceDate="handleAttendanceEvent"
                    @updateAttendance="updateAttendance"
                />
                <Feedback
                    v-else-if="activeTab === 'feedback'"
                    :isLoading="isLoading"
                    :feedback="feedback"
                />
                <Contact
                    v-else-if="activeTab === 'contact'"
                    :isLoading="isLoading"
                    :profile="profile"
                    @deleteEmergencyContact="handleDeleteEmergencyContact"
                    @addEmergencyContact="handleAddEmergencyContact"
                    @editPersonalInfo="handleEditPersonalInfo"
                />
                <Statistics
                    v-else-if="activeTab === 'statistics'"
                    :isLoading="isLoading"
                    :statistics="statistics"
                />
                <Uniforms
                    v-else-if="activeTab === 'uniform'"
                    :key="userUniformsKey"
                    :isLoading="isLoading"
                    :userUniforms="userUniforms"
                    @updateUniforms="updateUniforms"
                />
            </Transition>
        </div>
    </div>
</template>

<script>
import { Tabs } from '@/components'
import VueTypes from 'vue-types'
import { Payments, Attendance, Feedback, Statistics, Uniforms, Contact } from './components'

export default {
    name: 'ProfileInfoTable',
    components: {
        Tabs,
        Payments,
        Attendance,
        Feedback,
        Statistics,
        Uniforms,
        Contact,
    },
    props: {
        profile: VueTypes.object,
        statistics: VueTypes.array,
        userUniforms: VueTypes.object,
        userUniformsKey: VueTypes.integer.isRequired,
        feedback: VueTypes.array,
        tabs: VueTypes.array,
        paymentInformation: VueTypes.object,
        userPaymentsKey: VueTypes.integer.isRequired,
        userAttendanceKey: VueTypes.integer.isRequired,
        events: VueTypes.object,
        attendance: VueTypes.object,
        userId: VueTypes.integer,
        isPlayer: VueTypes.bool,
        attendanceDateInfo: VueTypes.object,
        isLoading: VueTypes.bool.def(false),
    },
    data () {
        return {
            activeTab: '',
        }
    },
    watch: {
        $route (to) {
            const { profileSlug } = to.params
            this.activeTab = profileSlug ? (this.tabs.find(t => t.slug === profileSlug) || {}).key : this.tabs[0].key
        },
    },
    mounted () {
        const { profileSlug } = this.$route.params || {}
        this.activeTab = profileSlug ? (this.tabs.find(t => t.slug === profileSlug) || {}).key : this.tabs[0].key
    },
    methods: {
        handleChangeTab (value) {
            const { profileSlug } = this.$route.params || {}
            const selectedSlug = (this.tabs.find(t => t.key === value) || {}).slug
            if (profileSlug !== selectedSlug) {
                this.$router.push({
                    name: 'Profile',
                    params: {
                        id: Number(this.userId),
                        profileSlug: selectedSlug,
                    },
                })
            }
        },
        handlePaymentEvent (eventData) {
            this.$emit('changePaymentYear', eventData)
        },
        handleAttendanceEvent (eventData) {
            this.$emit('changeAttendanceDate', eventData)
        },
        updateUniforms (uniforms) {
            this.$emit('updateUniforms', uniforms)
        },
        updatePayments (paymentsInfo) {
            this.$emit('updatePayments', paymentsInfo)
        },
        updateAttendance (attendance) {
            this.$emit('updateAttendance', attendance)
        },
        handleEditPersonalInfo (profile) {
            this.$emit('editPersonalInfo', profile)
        },
        handleAddEmergencyContact (contact) {
            this.$emit('addEmergencyContact', contact)
        },
        handleDeleteEmergencyContact (contactId) {
            this.$emit('deleteEmergencyContact', contactId)
        },
    },
}
</script>
<style lang="scss" scoped>

.ProfileInfoTable {
	.Container {
		background: white;
		border-radius: 10px;
		padding: 20px;
		color: $gray-dark; //#333;
		margin-top: 20px;
	}
}
</style>
