<template>
    <div class="EconomyReports">
        Informes
    </div>
</template>

<script>

export default {
    name: 'EconomyReports',
}
</script>
<style lang="scss" scoped>
// .EconomyReports {
// }

</style>
