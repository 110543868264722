import _ from 'lodash'
import moment from 'moment'
import event from '../api/event'
import { uniforms } from '@/entities/uniforms'

export default {
    getEvents: async (params) => {
        const events = await event.listEvent(params)

        return _.map(events.items, (item) => {
            const newItem = {
                ...item,
                day: moment.utc(item.date).format('DD'),
                isFutureDay: moment.utc().isBefore(moment.utc(item.date)),
            }
            return newItem
        })
    },

    getEvent: async (eventId) => {
        const ev = await event.getEvent(eventId, { includeUsers: true, includeUniform: true })

        if (ev.users) {
            ev.users = ev.users.map((u) => {
                const user = u
                user.name = u.user.name
                user.alias = u.user.alias
                delete user.user
                return user
            })
        }

        if (ev.eventUniforms) {
            ev.eventUniforms = ev.eventUniforms.map(u => {
                const uniform = u.uniform
                const key = (u.uniform.type + '-' + u.uniform.color).toLowerCase().replaceAll(' ', '-').replaceAll('_', '-')
                const uniformTranslation = uniforms.find(u => key === u.key)
                uniform.mandatory = u.mandatory
                uniform.label = uniformTranslation ? uniformTranslation.label : '-'
                uniform.key = key
                uniform.eventUniformId = u.id
                return uniform
            })
        }
        return ev
    },

    updateEvent (eventId, body) {
        return event.updateEvent(eventId, body)
    },

    createEvent (body) {
        return event.createEvent(body, { sendEmail: true })
    },
}
