<template>
	<div class="Select" :class="{ error }">
		<Multiselect
			v-model="selectedValue"
			:searchable="searchable || multiple"
			:options="selectOptions"
			:multiple="multiple"
			:placeholder="placeholder"
			:closeOnSelect="closeOnSelect"
			:optionHeight="30"
			:disabled="disabled"
			:showLabels="showLabels"
			:preselectFirst="preselectFirst"
			trackBy="key"
			label="label"
			:groupValues="selectAll ? 'options' : undefined"
			:groupLabel="selectAll ? 'title' : undefined"
			:groupSelect="selectAll ? true : undefined"
		>
			<template #option="{ option }">
				<span v-if="!useDateProp">
					{{ option.label }}
				</span>
				<span v-else name="Option" :option="option">
					{{ option.label }} <br />
					{{ formatDate(option.date) }}
				</span>
			</template>
			<template #selection="{ values }">
				<span v-if="values.length" class="multiselect__single">
					<template v-for="(item, index) in values">
						{{ item.label }}
						<template v-if="index !== values.length - 1"> , </template>
					</template>
				</span>
			</template>
			<template #noResult>
				<span>
					{{ noResultText ? noResultText : "No se han encontrado elementos." }}
				</span>
			</template>
		</Multiselect>
		<Transition name="fade" :duration="500">
			<p v-if="error" class="Error">
				{{ error }}
			</p>
		</Transition>
	</div>
</template>

<script>
import VueTypes from "vue-types";
import Multiselect from "vue-multiselect";
import moment from "moment";

export default {
	name: "Select",
	components: {
		Multiselect,
	},
	props: {
		name: VueTypes.string.isRequired,
		options: VueTypes.array.isRequired,
		placeholder: VueTypes.string.def(""),
		value: VueTypes.oneOfType([Object, String, Array, Number, null]).isRequired,
		error: VueTypes.string.def(""),
		multiple: VueTypes.bool.def(false),
		disabled: VueTypes.bool.def(false),
		selectAll: VueTypes.bool.def(false),
		showLabels: VueTypes.bool.def(false),
		preselectFirst: VueTypes.bool.def(false),
		searchable: VueTypes.bool.def(false),
		closeOnSelect: VueTypes.bool.def(true),
		useDateProp: VueTypes.bool.def(false),
		noResultText: VueTypes.string,
	},
	computed: {
		selectOptions() {
			return !this.selectAll
				? this.options
				: [
						{
							title: "Seleccionar todos",
							options: this.options,
						},
				  ];
		},
		selectedValue: {
			get() {
				if (Array.isArray(this.value)) return this.options.filter((o) => this.value.includes(o.key));
				return this.options.find((o) => o.key === this.value);
			},
			set(value) {
				if (value) {
					const newValue = Array.isArray(value) ? value.map((el) => el.key) : value.key;
					this.$emit("input", newValue);
				}
			},
		},
	},
	methods: {
		formatDate(date) {
			return moment.utc(date).locale("es").format("DD/MM/YY");
		},
	},
};
</script>
<style lang="scss" scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";

.Select {
	position: relative;
	width: 100%;
	.multiselect {
		min-height: 45px;
		// margin-bottom: 15px;
		// padding: 0 15px;
		// border: none;
		border-radius: 50px;
		// color: $gray-medium;
		// background: $white;
		font-size: 14px;
		font-weight: regular;
		transition: border-color 0.5s ease-in-out;
		&__tags {
			min-height: 31px;
			margin-right: 40px;
			padding: 10px 0px 20px 10px !important;
			border: none !important;
			// border-radius: 20px;
			// overflow: hidden;
			background: transparent;
		}
		&__single {
			margin-bottom: 0;
			border-radius: 0;
			background: transparent;
			font-size: 14px;
			line-height: 26px;
			vertical-align: middle;
			white-space: nowrap;
			box-shadow: none;
			text-overflow: ellipsis;
		}
		&__input {
			left: -5px;
			// min-height: 45px;
			margin-bottom: 0;
			border-radius: 0;
			background: transparent;
			color: $gray-medium;
			font-size: 14px !important;
			box-shadow: none;
		}
		&__placeholder {
			margin-bottom: 0px;
			padding-top: 7px;
			font-size: 14px !important;
			color: $gray-medium;
		}
		&__select {
			height: 100%;
			&:before {
				display: inline-block;
				top: 15%;
				width: 10px;
				height: 10px;
				margin-top: 0;
				box-shadow: none;
				// border-color: $black;
				// border-style: solid;
				// border-width: 1px 1px 0 0;
				box-sizing: inherit;
				vertical-align: text-top;
				// transform: rotate(133deg);
			}
		}
		&__content-wrapper {
			padding: 0 0 5px 0;
			top: 46px;
			border: none;
			// border-bottom-left-radius: 4px;
			// border-bottom-right-radius: 4px;
			background-color: $white;
			box-shadow: none;
			// box-shadow: 0 3px 6px -3px rgba(0, 0, 0, 0.2);
			z-index: 5;
		}
		&__option {
			min-height: 30px;
			padding: 7px;
			&:after {
				line-height: 30px;
			}
		}
		&__option--group {
			background: transparent;
			color: $gray-medium;
		}
		&__option--group-selected {
			background: rgba($gray-light, 0.6);
			color: $gray-medium;
		}
		&__option--selected {
			background: rgba($gray-light, 0.6) !important;
			color: $gray-medium !important;
			font-weight: regular;
		}
		&__option--highlight {
			background: rgba($primary, 0.6) !important;
			color: $gray-medium !important;
			&:after {
				background: transparent;
				color: $gray-medium;
			}
			&:hover {
				background: rgba($primary, 0.6) !important;
			}
		}
	}
	&.error {
		.multiselect,
		.multiselect__content-wrapper {
			border-color: $primary;
		}
	}
}

.Error {
	position: absolute;
	top: 45px + 5px;
	left: 5px;
}
</style>
