export const tabs = [
    {
        key: 'fields',
        label: 'Campos',
        slug: 'campos',
    },
    /* {
        key: 'info',
        label: 'Informaciones',
        slug: 'informaciones',
    }, */
    {
        key: 'teams',
        label: 'Equipos',
        slug: 'equipos',
    },
]

export const fieldDefaultValues = {
    name: '',
    city: '',
    teamOwner: '',
    address: '',
}

export const teamDefaultValues = {
    name: '',
    city: '',
    email: '',
    createdAt: '',
    instagramLink: '',
    contact: '',
}
