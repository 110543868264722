import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const getDefaultState = () => {
    return {
        logged: false,
        user: {},
    }
}

export default new Vuex.Store({
    strict: true,
    plugins: [createPersistedState()],
    state: getDefaultState(),
    getters: {
        isLoggedIn: state => state.logged,
        me: state => state.user,
    },
    mutations: {
        SET_LOGGED: (state, logged) => { state.logged = logged },
        SET_USER: (state, user) => { state.user = user },
        RESET: state => Object.assign(state, getDefaultState()),
    },
    actions: {
        login: (params, user) => {
            params.commit('SET_LOGGED', true)
            params.commit('SET_USER', user)
        },
        logout: ({ commit }) => commit('RESET', ''),
    },
})
