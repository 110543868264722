import axios from 'axios'

export default {
    listUser: async (params) => {
        const response = await axios.get('/public/users', { params })

        return response && response.data && response.data.response
    },

    listEvents: async (params) => {
        const response = await axios.get('/public/events', { params })

        return response && response.data && response.data.response
    },
}
