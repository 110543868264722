import uniform from '../api/uniform'

export default {
    async listUniforms () {
        const uniformList = (await uniform.listUniforms()).items
        const uniformMap = {}

        uniformList.forEach(u => {
            const key = this.getUniformKey(u)
            uniformMap[key] = u
        })

        return uniformMap
    },

    getUniformKey (uniform) {
        return (uniform.type + ' ' + uniform.color).toLowerCase().replaceAll(' ', '-').replaceAll('_', '-')
    },
}
