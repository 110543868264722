
import moment from 'moment'
import constants from '../entities/constants'
import eventIntegration from '../integration/eventIntegration'
import uniformIntegration from '../integration/uniformIntegration'
import userEventApi from '../api/userEvent'
import eventUniformApi from '../api/eventUniform'
import { clone } from 'lodash'

export default {
    async getEvents (value, state = constants.EVENT_STATE.ACCEPTED, type) {
        const dateString = value.month + '/' + value.year
        const from = moment.utc(dateString, 'MM/YYYY').startOf('month').format()
        const to = moment.utc(dateString, 'MM/YYYY').endOf('month').format()
        const events = await eventIntegration.getEvents({ from, to, state, type })

        return events.map((e) => {
            const newE = {
                key: e.id,
                customData: e,
                dates: moment.utc(e.date).local().toDate(),
            }

            if (!e.isFutureDay) {
                newE.customData.color = 'past'
            } else if (e.state === constants.EVENT_STATE.CANCELED) {
                newE.customData.color = 'cancelled'
            } else if (e.state === constants.EVENT_STATE.REJECTED) {
                newE.customData.color = 'rejected'
            } else if (e.state === constants.EVENT_STATE.PENDING) {
                newE.customData.color = 'pending'
            } else {
                newE.customData.color = constants.EVENT_TYPE_COLOR[e.type]
            }

            return newE
        })
    },

    getEventsBaseOnPermission (value, permission) {
        const types = Object.values(constants.EVENT_TYPE)
        switch (permission) {
            case constants.USER_ROLE.ADMIN:
            case constants.USER_ROLE.COMITE:
                return this.getEvents(value,
                    Object.values(constants.EVENT_STATE),
                    types)
            case constants.USER_ROLE.COACH:
                return this.getEvents(value,
                    Object.values(constants.EVENT_STATE),
                    types.filter(t => t !== constants.EVENT_TYPE.COMITE_MEETING))
            default:
                return this.getEvents(value,
                    constants.EVENT_STATE.ACCEPTED,
                    types.filter(t => t !== constants.EVENT_TYPE.COMITE_MEETING))
        }
    },

    async getEvent (eventId) {
        const event = await eventIntegration.getEvent(eventId)
        const uniform = (event.eventUniforms && event.eventUniforms.length)
            ? event.eventUniforms.find(e => e.type === constants.UNIFORM_TYPE.GAME_SHIRT)
            : {}

        event.formattedDate = moment.utc(event.date).locale('es').format('dddd L')
        event.formattedTime = moment.utc(event.date).local().locale('es').format('LT')
        event.isFutureDay = moment.utc().isBefore(moment.utc(event.date))
        event.uniform = uniform && uniform.color

        return event
    },

    async signToEvent (eventId, userId, state = constants.USER_EVENT_STATE.GOING) {
        const userEvent = await userEventApi.getUserEvent(userId, { eventId })

        if (userEvent && userEvent.items && userEvent.items.length) {
            userEvent.items[0].state = state
            return userEventApi.updateUserEvent(userEvent.items[0].id, userEvent.items[0])
        } else {
            return userEventApi.createUserEvent({
                userId,
                eventId,
                state,
            })
        }
    },

    async updateEvent (data, permission) {
        const eventDB = await this.getEvent(data.id)
        const eventToEdit = clone(data)

        if (permission === constants.USER_ROLE.ADMIN ||
            permission === constants.USER_ROLE.COMITE ||
            permission === constants.USER_ROLE.COACH) {
            if (this.compareEvent(eventToEdit, eventDB)) {
                await eventIntegration.updateEvent(eventToEdit.id, this.cleanEvent(eventToEdit))
            }

            await this.handleEventUniform(eventDB, eventToEdit)
        }
    },

    async handleEventUniform (eventDB, eventToEdit) {
        if (eventDB.uniform !== eventToEdit.uniform) {
            const uniforms = (await uniformIntegration.listUniforms())
            const gameShirts = Object.entries(uniforms)
                .filter(([key, value]) => value.type === constants.UNIFORM_TYPE.GAME_SHIRT)
                .map(([key, value]) => value)
            const uniform = eventDB.eventUniforms && eventDB.eventUniforms
                .find(u => u.type === constants.UNIFORM_TYPE.GAME_SHIRT)

            if (!eventDB.uniform && eventToEdit.uniform) {
                await eventUniformApi.createEventUniform({
                    eventId: eventToEdit.id,
                    uniformId: (gameShirts.find(u => u.color === eventToEdit.uniform)).id,
                    mandatory: true,
                })
            } else if (eventDB.uniform && !eventToEdit.uniform) {
                await eventUniformApi.deleteEventUniform(uniform.eventUniformId)
            } else {
                await eventUniformApi.updateEventUniform(uniform.eventUniformId, {
                    eventId: eventToEdit.id,
                    uniformId: (gameShirts.find(u => u.color === eventToEdit.uniform)).id,
                    mandatory: true,
                })
            }
        }
    },

    compareEvent (srcEv, dstEv) {
        const srcE = this.cleanEvent(srcEv)
        const dstE = this.cleanEvent(dstEv)
        return !this.isSameObject(srcE, dstE)
    },

    cleanEvent (ev) {
        const e = clone(ev)

        e.date = this.setCorrectDate(ev)
        delete e.createdAt
        delete e.updatedAt
        delete e.eventUniforms
        delete e.uniform
        delete e.users
        delete e.isFutureDay
        delete e.formattedDate
        delete e.formattedTime
        return e
    },

    setCorrectDate (ev) {
        const dateFormat = 'YYYYMMDD'
        const date = moment.utc(ev.date).local().format(dateFormat)
        return moment(date + ev.formattedTime, dateFormat + 'HH:mm').utc().format()
    },

    isSameObject (obj1, obj2) {
        let isSameObject = true
        for (const key in obj1) {
            if ((obj1[key] && !obj2[key]) || obj1[key] !== obj2[key]) {
                isSameObject = false
                break
            }
        }

        return isSameObject
    },

    getFormattedDate (date) {
        return moment.utc(date).local().locale('es').format('dddd L')
    },

    async createEvent (data) {
        const eventToCreate = clone(data)

        eventToCreate.state = constants.EVENT_STATE.PENDING
        eventToCreate.type = eventToCreate.type || constants.EVENT_TYPE.PHYSICAL_TRAINING

        const newEvent = await eventIntegration.createEvent(this.cleanEvent(eventToCreate))
        eventToCreate.id = newEvent.id
        await this.handleEventUniform({}, eventToCreate)
    },
}
