<template>
    <div class="NotFoundScreen">
        <h1 class="Title">
            404
        </h1>
        <p class="Text">
            Esta página de espartanas no existe
        </p>
    </div>
</template>

<script>
export default {
    name: 'NotFoundScreen',
}
</script>
<style lang="scss" scoped>
.NotFoundScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 125px);
  width: 100%;
}
.Title {
  color: white;
  font-size: 120px;
  line-height: 124px;
  text-align: center;
}
.Text {
  color: white;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}
</style>
