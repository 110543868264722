<template>
    <div class="LineupComparator">
        <div class="Header">
            <PillSelectorInput
                v-if="lineUps && lineUps.length"
                v-model="lineUpSelected1"
                label="Alineación 1"
                :options="lineUps"
                :addLoading="false"
                @input="handleLineUp1"
            />
            <PillSelectorInput
                v-if="lineUps && lineUps.length"
                v-model="lineUpSelected2"
                label="Alineación 2"
                :options="lineUps"
                :addLoading="false"
                @input="handleLineUp2"
            />
        </div>
        <div>
            <h3>LineUp</h3>
            <div class="ButtonWrapper">
                <Button
                    v-if="lineUp1 && lineUp1.length && lineUp2 && lineUp2.length"
                    class="Submit"
                    type="submit"
                    text="COMPARAR"
                    styleType="gradient"
                    iconLeft="save"
                    @click="handleCompareLineUp"
                />
            </div>
            <div class="List">
                <div
                    class="Block1"
                >
                    <div
                        v-for="(person, index) in lineUp1"
                        :key="index"
                        class="ListItem"
                    >
                        <p class="Name">
                            {{ index + 1 }}
                        </p>
                        <p class="Number">
                            {{ person.uniform && person.uniform.number || 'SN' }}
                        </p>
                        <p class="Name">
                            {{ person.alias }}
                        </p>
                        <p class="Positions">
                            {{ person.lineUpPosition.number }}
                        </p>
                        <p class="Positions">
                            {{ person.lineUpPosition.abbreviation }}
                        </p>
                    </div>
                </div>
                <div
                    class="Block2"
                >
                    <div
                        v-for="(person, index) in lineUp2"
                        :key="index"
                        class="ListItem"
                    >
                        <p class="Name">
                            {{ index + 1 }}
                        </p>
                        <p class="Number">
                            {{ person.uniform && person.uniform.number || 'SN' }}
                        </p>
                        <p class="Name">
                            {{ person.alias }}
                        </p>
                        <p class="Positions">
                            {{ person.lineUpPosition.number }}
                        </p>
                        <p class="Positions">
                            {{ person.lineUpPosition.abbreviation }}
                        </p>
                    </div>
                </div>
            </div>
            <Modal
                :isOpen="isModalOpen"
                @close="handleCloseModal"
            >
                <template #body>
                    <div
                        v-for="(inf, index) in formattedInfo"
                        :key="index"
                    >
                        <div
                            v-for="(line, i) in inf"
                            :key="i"
                        >
                            {{ line }}
                        </div>
                        <br>
                    </div>
                </template>
            </Modal>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import lineUpIntegration from '@/integration/lineUpIntegration'

export default {
    name: 'LineupComparator',
    props: {
        eventId: VueTypes.integer.isRequired,
    },
    data () {
        return {
            lineUps: [],
            lineUp1: [],
            lineUp2: [],
            lineUpSelected1: null,
            lineUpSelected2: null,
            isModalOpen: false,
            info: [],
            formattedInfo: [],
        }
    },
    watch: {
        async eventId () {
            this.lineUps = await lineUpIntegration.listLineUps(this.eventId, { group: true })
            if (!this.lineUps.lenght) {
                this.lineUp1 = []
                this.lineUp2 = []
            }
        },
    },
    async mounted () {
        this.lineUps = await lineUpIntegration.listLineUps(this.eventId, { group: true })
    },
    methods: {
        async handleLineUp1 (lineUpId) {
            this.lineUp1 = await this.handleSelectLineUp(lineUpId)
        },
        async handleLineUp2 (lineUpId) {
            this.lineUp2 = await this.handleSelectLineUp(lineUpId)
        },
        async handleSelectLineUp (lineUpId) {
            if (lineUpId) {
                const lineUpData = this.lineUps.find(l => l.id === lineUpId)
                const { lineUp } = await lineUpIntegration.getLineUp(lineUpData.eventId,
                    {
                        number: lineUpData.number,
                        name: lineUpData.name,
                    })
                return lineUp
            } else return []
        },
        handleCompareLineUp () {
            this.getDifferentUsers()
            this.getInternalChanges()

            if (this.info.length) this.formatMessage()
            else this.formattedInfo = ['No hay diferencias']

            this.isModalOpen = true
        },
        handleCloseModal () {
            this.info = []
            this.formattedInfo = []
            this.isModalOpen = false
        },
        formatMessage () {
            for (const i of this.info) {
                let line1
                let line2
                let line3
                let index1Value
                let index2Value
                switch (i.type) {
                    case 1:
                        index1Value = i.index1 + 1
                        index2Value = i.index2 + 1
                        line1 = '* La jugadora ' + (i.person1 && i.person1.shortName) +
                            ' se encuentra en diferente orden en las alineacions.'
                        line2 = '  ** alineación 1: ' + (index1Value) + ' - ' +
                            (i.person1 && i.person1.lineUpPosition && i.person1.lineUpPosition.number) +
                            ' (' + (i.person1 && i.person1.lineUpPosition && i.person1.lineUpPosition.abbreviation) + ')'
                        line3 = '  ** alineación 2: ' + (index2Value) + ' - ' +
                            (i.person2 && i.person2.lineUpPosition && i.person2.lineUpPosition.number) +
                            ' (' + (i.person2 && i.person2.lineUpPosition && i.person2.lineUpPosition.abbreviation) + ')'
                        this.formattedInfo.push([line1, line2, line3])
                        break
                    case 2:
                        index1Value = i.index + 1
                        line1 = '* Cambio de jugadoras.'
                        line2 = '  ** Sale: ' + (i.person1 && i.person1.shortName) + ' - ' +
                            (index1Value) + ' - ' +
                            (i.person1 && i.person1.lineUpPosition && i.person1.lineUpPosition.number) +
                            ' (' + (i.person1 && i.person1.lineUpPosition && i.person1.lineUpPosition.abbreviation) + ')'
                        line3 = '  ** Entra: ' + (i.person2 && i.person2.shortName) + ' - ' +
                            (index1Value) + ' - ' +
                            (i.person2 && i.person2.lineUpPosition && i.person2.lineUpPosition.number) +
                            ' (' + (i.person2 && i.person2.lineUpPosition && i.person2.lineUpPosition.abbreviation) + ')'
                        this.formattedInfo.push([line1, line2, line3])
                        break
                    case 3:
                        line1 = '* Cambio interno, la jugadora ' + (i.person && i.person.shortName)
                        line2 = '  ** Estaba en : ' + (i.lineUpPosition1 && i.lineUpPosition1.number) +
                            ' (' + (i.lineUpPosition1 && i.lineUpPosition1.abbreviation) + ')'
                        line3 = '  ** Cambia a: ' + (i.lineUpPosition2 && i.lineUpPosition2.number) +
                            ' (' + (i.lineUpPosition2 && i.lineUpPosition2.abbreviation) + ')'
                        this.formattedInfo.push([line1, line2, line3])
                        break
                }
            }
        },
        getDifferentUsers () {
            for (let i = 0; i < this.lineUp1.length; i++) {
                const personId1 = this.lineUp1[i] && this.lineUp1[i].id
                const personId2 = this.lineUp2[i] && this.lineUp2[i].id
                if (personId1 !== personId2) {
                    const indexLineUp1 = this.lineUp1.findIndex(p => p.id === personId2)
                    const indexLineUp2 = this.lineUp2.findIndex(p => p.id === personId1)
                    if (indexLineUp1 !== -1) {
                        this.info.push({
                            type: 1,
                            person1: this.lineUp1[indexLineUp1],
                            person2: this.lineUp2[i],
                            index1: indexLineUp1,
                            index2: indexLineUp2,
                        })
                    }
                    if (indexLineUp2 !== -1) {
                        this.info.push({
                            type: 1,
                            person1: this.lineUp1[i],
                            person2: this.lineUp2[indexLineUp2],
                            index1: indexLineUp1,
                            index2: indexLineUp2,
                        })
                    }
                    if (indexLineUp1 === -1 && indexLineUp2 === -1) {
                        this.info.push({
                            type: 2,
                            person1: this.lineUp1[i],
                            person2: this.lineUp2[i],
                            index: i,
                        })
                    }
                }
            }
        },
        getInternalChanges () {
            for (let i = 0; i < this.lineUp1.length; i++) {
                const personId1 = this.lineUp1[i] && this.lineUp1[i].id
                const personId2 = this.lineUp2[i] && this.lineUp2[i].id
                const lineUpPosition1 = this.lineUp1[i] && this.lineUp1[i].lineUpPosition && this.lineUp1[i].lineUpPosition.number
                const lineUpPosition2 = this.lineUp2[i] && this.lineUp2[i].lineUpPosition && this.lineUp2[i].lineUpPosition.number
                const isInternalChange = personId1 === personId2 && lineUpPosition1 !== lineUpPosition2
                if (isInternalChange) {
                    this.info.push({
                        type: 3,
                        person: this.lineUp1[i],
                        lineUpPosition1: this.lineUp1[i].lineUpPosition,
                        lineUpPosition2: this.lineUp2[i].lineUpPosition,
                    })
                }
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    .PillSelectorInput {
        margin-right: 30px;
        margin-top: 10px;
        width: fit-content;
    }
}
.Table {
	.TableHeader, .TableRow {
		display: flex;
		.TableCell {
			flex: 1;
		}
	}
}
h3 {
	margin-top: 50px;
}
.List {
		margin-top: 10px;
		.ListHeaer {
			display: flex;
			align-items: center;
			padding: 10px 20px;
			// @media screen and (max-width: $sm) {
			// 	display: none;
			// }
			p {
				color: $gray-02;
				margin-left: 40px;
			}
			.Name {
				flex: 1;
				margin-left: 60px;
			}
			.Positions, .Number {
				min-width: 100px;
				@media screen and (max-width: $md) {
				min-width: fit-content;
				}
			}
			.Status {
				min-width: 70px;
				text-align: center;
				@media screen and (max-width: $sm) {
				min-width: 50px;
				}
			}
		}
		.ListItem {
			display: flex;
			align-items: center;
			background: white;
			padding: 10px;
			border-radius: 10px;
			@media screen and (max-width: $sm) {
				height: auto;
				flex-direction: column;
				text-align: center;
				padding: 10px;
			}

			& + .ListItem {
				margin-top: 10px;
			}
			p, .Status {
				margin-left: 40px;
				@media screen and (max-width: $sm) {
				margin-left: 0px;
				margin-top: 10px;
				font-size: 12px;
				}
			}
			.Name {
				flex: 1;
				margin-left: 20px;
				display: flex;
				flex-direction: column;
				@media screen and (max-width: $sm) {
					// p { display: none; }
					.FullName { margin-top: 10px; display: block; }
				}
				p { margin: 0 }
				.FullName {
					color: $gray-03;
					font-size: 14px;
					margin-top: 5px;
					margin-left: 0px;
					@media screen and (max-width: $sm) {
						margin-top: 10px;
					}
				}
			}

			.Positions, .Number {
				min-width: 100px;
				@media screen and (max-width: $md) {
					min-width: fit-content;
				}
			}
			.Status {
				min-width: 70px;
				display: flex;
				align-items: center;
				justify-content: center;
				@media screen and (max-width: $sm) {
					// display: none;
					min-width: 50px;
				}
			}

			.StatusIcon {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
        .Block1 {
            width: 45%;
            display: inline-block;
            margin-right: 5px;
        }
        .Block2 {
            width: 45%;
            display: inline-block;
            margin-right: 5px;
        }
	}
	.PillSelectorInput::v-deep {
		.OptionGroup {
			padding: 0;
			.Option {
				margin-top: 0;
				padding: 4px 10px;
				border-radius: 10px;
			}
		}
	}
</style>
