<template>
    <Transition
        name="modal"
        :duration="500"
    >
        <div
            v-if="isOpen"
            class="CalendarModal"
        >
            <div class="Dialog">
                <Icon
                    icon="x"
                    class="Close"
                    @click="handleClose"
                />
                <div class="Body">
                    <div class="Title">
                        <p v-if="currentType === 'add'">
                            Crear evento
                        </p>
                        <p
                            v-else-if="currentType === 'read'"
                            class="Link"
                        >
                            {{ data.name }}
                            <Icon
                                v-permissions="{permissions: [constants.USER_ROLE.ADMIN,
                                                              constants.USER_ROLE.COMITE]}"
                                :style="{ 'margin-left': '10px' }"
                                icon="edit"
                                :size="20"
                                color="primary"
                                @click="currentType = 'edit'"
                            />
                        </p>
                        <p
                            v-else-if="currentType === 'edit'"
                            class="Link"
                        >
                            Editar evento
                            <Icon
                                :style="{ 'margin-left': '10px' }"
                                icon="eye"
                                :size="20"
                                color="primary"
                                @click="currentType = 'read'"
                            />
                        </p>
                        <div
                            v-if="currentType !== 'read'"
                            class="Date"
                        >
                            <Icon
                                icon="calendar"
                                :size="20"
                                color="primary"
                                @click="handleClose"
                            />
                            <span>{{ data.formattedDate }}</span>
                        </div>
                    </div>
                    <div v-if="currentType === 'add' || currentType === 'edit'">
                        <div class="Row Double">
                            <div class="mr2 flex1">
                                <p class="Label">
                                    Nombre
                                </p>
                                <Input
                                    v-model="eventData.name"
                                    name="name"
                                    placeholder="Nombre"
                                    color="grayDark"
                                    type="text"
                                    iconLeft="calendar"
                                    iconColor="primary"
                                />
                            </div>
                            <div>
                                <p class="Label">
                                    Hora
                                </p>
                                <Input
                                    v-model="eventData.formattedTime"
                                    type="time"
                                    iconLeft="calendar"
                                    color="grayDark"
                                    iconColor="primary"
                                    placeholder="datetime"
                                    name="datetime"
                                />
                            </div>
                        </div>
                        <div class="Row">
                            <PillSelectorInput
                                v-model="eventData.type"
                                :label="eventType.label"
                                :options="eventType.options"
                                border="gray"
                            />
                        </div>
                        <div class="Row">
                            <p class="Label">
                                Lugar
                            </p>
                            <div class="InputOptions">
                                <PillSelectorInput
                                    v-model="eventData.location"
                                    :options="eventPlace.options"
                                    border="gray"
                                    class="mr2"
                                />
                                <Input
                                    v-model="eventData.location"
                                    name="place"
                                    placeholder="Otro"
                                    color="grayDark"
                                    type="text"
                                    iconLeft="map-pin"
                                    iconColor="primary"
                                />
                            </div>
                        </div>
                        <div class="Row">
                            <p class="Label">
                                Uniforme
                            </p>
                            <PillSelectorInput
                                v-model="eventData.uniform"
                                :options="eventUniformsList"
                                border="gray"
                            />
                        </div>
                        <div class="Row">
                            <p class="Label">
                                Descripción
                            </p>
                            <Input
                                v-model="eventData.description"
                                name="description"
                                placeholder="Descripción"
                                color="grayDark"
                                type="text"
                                iconLeft="align-left"
                                iconColor="primary"
                            />
                        </div>
                        <div
                            v-if="currentType === 'edit'"
                            v-permissions="{permissions: [constants.USER_ROLE.ADMIN,
                                                          constants.USER_ROLE.COMITE]}"
                            class="Row"
                        >
                            <p class="Label">
                                Status del evento
                            </p>
                            <PillSelectorInput
                                v-model="eventData.state"
                                :options="eventStatusList"
                                rounded
                                border="gray"
                            />
                            <div class="Row Double">
                                <div class="mr2 flex1">
                                    <p class="Label">
                                        Resultado
                                    </p>
                                    <Input
                                        v-model="eventData.score"
                                        name="score"
                                        placeholder="Score del partido"
                                        color="grayDark"
                                        type="text"
                                        iconLeft="award"
                                        iconColor="primary"
                                    />
                                </div>
                                <div class="flex1">
                                    <p class="Label">
                                        Link
                                    </p>
                                    <Input
                                        v-model="eventData.link"
                                        name="link"
                                        placeholder="Link externo"
                                        color="grayDark"
                                        type="text"
                                        iconLeft="external-link"
                                        iconColor="primary"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="Footer">
                            <Button
                                text="Cancelar"
                                styleType="opacity"
                                @click="handleClose"
                            />
                            <Button
                                type="submit"
                                :text="currentType === 'add' ? 'Proponer' : 'Guardar'"
                                styleType="gradient"
                                :disabled="$v.$invalid"
                                @click="handleSubmit"
                            />
                        </div>
                    </div>
                    <template v-else-if="currentType === 'read'">
                        <div
                            v-if="data.score || data.link"
                            class="Header"
                        >
                            <div
                                v-if="data.score"
                                class="Score"
                            >
                                <Icon
                                    icon="award"
                                    :size="20"
                                    color="primary"
                                    @click="handleClose"
                                />
                                <p>Resultado: <b>{{ data.score }}</b></p>
                            </div>
                            <div
                                v-if="data.link"
                                class="ExternalLink"
                            >
                                <Icon
                                    icon="external-link"
                                    :size="20"
                                    color="primary"
                                    @click="handleClose"
                                />
                                <p>
                                    Link: <a
                                        :href="data.link"
                                        target="_blank"
                                    >{{ data.link }}</a>
                                </p>
                            </div>
                        </div>
                        <div class="Content">
                            <div
                                class="Image"
                                :style="{ backgroundImage: `url(${require(`@/assets/imgs/events/${data.type}.jpg`)})` }"
                            />
                            <div class="Info">
                                <div class="Date">
                                    <Icon
                                        icon="calendar"
                                        :size="20"
                                        color="primary"
                                        @click="handleClose"
                                    />
                                    <p>{{ data.formattedDate }}</p>
                                </div>
                                <div class="Type">
                                    <Icon
                                        icon="bookmark"
                                        :size="20"
                                        color="primary"
                                    />
                                    <p>{{ eventTypes[data.type] }}</p>
                                </div>
                                <div class="Time">
                                    <Icon
                                        icon="clock"
                                        :size="20"
                                        color="primary"
                                    />
                                    <p>{{ data.formattedTime }}</p>
                                </div>
                                <div class="Place">
                                    <Icon
                                        icon="map-pin"
                                        :size="20"
                                        color="primary"
                                    />
                                    <p>{{ data.location }}</p>
                                </div>
                                <div
                                    v-if="data.uniform"
                                    class="Uniform"
                                >
                                    <Icon
                                        icon="briefcase"
                                        :size="20"
                                        color="primary"
                                    />
                                    <p>Equipacion {{ eventUniforms[data.uniform] }}</p>
                                </div>
                                <div
                                    v-if="data.description"
                                    class="Description"
                                >
                                    <Icon
                                        icon="corner-down-right"
                                        :size="20"
                                        color="primary"
                                    />
                                    <p>{{ data.description }}</p>
                                </div>
                                <div class="Attending">
                                    <p>Confirmadas:</p>
                                    <ul v-if="data.users.length">
                                        <li v-if="!!findJessa">
                                            <Icon
                                                :icon="getAttendanceStateIcon(findJessa.state)"
                                                :color="getAttendanceStateColor(findJessa.state)"
                                                :size="16"
                                                class="Index"
                                            />
                                            <p class="Player">
                                                {{ findJessa.alias }}
                                            </p>
                                        </li>
                                        <li
                                            v-for="({ alias, state }, index) in orederedAttendance"
                                            :key="`status-${index}`"
                                        >
                                            <p
                                                v-if="state === 'GOING'"
                                                class="Index"
                                            >
                                                {{ index + 1 }}.
                                            </p>
                                            <Icon
                                                v-else
                                                :icon="getAttendanceStateIcon(state)"
                                                :color="getAttendanceStateColor(state)"
                                                :size="16"
                                                class="Index"
                                            />
                                            <p class="Player">
                                                {{ alias }}
                                            </p>
                                        </li>
                                    </ul>
                                    <p
                                        v-else
                                        class="Empty"
                                    >
                                        No hay respuestas.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div v-if="data.isFutureDay && currentType === 'read'">
                        <p class="Response">
                            ¿Asistirás al evento?
                        </p>
                        <PillSelectorInput
                            v-model="userAttendanceResponse"
                            :options="attendanceStates"
                            useIcons
                            @input="handleSetAttendanceState"
                        />
                    </div>
                </div>
            </div>
            <div
                class="Backdrop"
                @click="handleClose"
            />
        </div>
    </Transition>
</template>

<script>
import VueTypes from 'vue-types'
import { eventPlaces, eventTypes, eventDefaultValue, eventStatus, eventUniforms } from '@/entities/calendar'
import { clone } from 'lodash'
import constants from '@/entities/constants'
import attendanceConstants from '@/entities/attendance'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'CalendarModal',
    mixins: [validationMixin],
    validations () {
        return {
            eventData: {
                formattedTime: { required },
                type: { required },
                location: { required },
            },
        }
    },
    props: {
        isOpen: VueTypes.bool.def(true),
        type: VueTypes.oneOf(['add', 'read', 'edit']).isRequired,
        data: VueTypes.object,
        me: VueTypes.object,
    },
    data () {
        return {
            userAttendanceResponse: constants.USER_EVENT_STATE.PENDING,
            attendanceStates: attendanceConstants.attendanceStates,
            finalAttendanceStates: attendanceConstants.finalAttendanceState,
            constants,
            eventUniforms,
            eventStatus,
            eventUniformsList: this.convertToList(eventUniforms),
            eventStatusList: this.convertToList(eventStatus),
            eventTypes,
            currentType: '',
            eventData: eventDefaultValue,
            description: '',
            eventType: {
                label: 'Tipo de evento',
                selected: undefined,
                options: this.convertToList(eventTypes),
            },
            eventPlace: {
                selected: undefined,
                text: '',
                options: this.convertToList(eventPlaces),
            },
            eventTime: new Date().getTime(),
        }
    },
    computed: {
        findJessa () {
            return this.data.users.find(a => a.alias === 'Jessa')
        },
        orederedAttendance () {
            const manager = []
            const going = []
            const accepted = []
            const maybe = []
            const support = []
            const injured = []
            const notgoing = []
            const out = []
            const other = []

            this.data.users.map(d => {
                if (d.alias === 'Jessa') manager.push(d)
                else if (d.state === constants.USER_EVENT_STATE.GOING) going.push(d)
                else if (d.state === constants.USER_EVENT_FINAL_STATE.ACCEPTED) accepted.push(d)
                else if (d.state === constants.USER_EVENT_STATE.MAYBE) maybe.push(d)
                else if (d.state === constants.USER_EVENT_STATE.SUPPORT) support.push(d)
                else if (d.state === constants.USER_EVENT_STATE.INJURED) injured.push(d)
                else if (d.state === constants.USER_EVENT_STATE.NOT_GOING) notgoing.push(d)
                else if (d.state === constants.USER_EVENT_STATE.OUT) out.push(d)
                else other.push(d)
            })
            return [
                ...going,
                ...accepted,
                ...maybe,
                ...support,
                ...injured,
                ...notgoing,
                ...out,
                ...other,
            ]
        },
    },
    watch: {
        isOpen (val) {
            if (val) document.body.classList.add('no-scroll')
            else document.body.classList.remove('no-scroll')
        },
    },
    beforeDestroy () {
        document.body.classList.remove('no-scroll')
    },
    mounted () {
        const { data, me } = this
        this.currentType = this.type
        this.eventData = clone(data)
        this.userAttendanceResponse = ((data.users || []).find(u => u.userId === me.id) || {}).state || constants.USER_EVENT_STATE.PENDING
    },
    methods: {
        convertToList (obj) {
            const arr = []
            for (const key in obj) {
                arr.push({ key, label: obj[key] })
            }
            return arr
        },
        resetValues () {
            this.eventType.selected = undefined
            this.eventPlace.selected = undefined
            this.eventPlace.text = ''
        },
        handleClose () {
            this.resetValues()
            this.$emit('close', this.currentType)
        },
        handleInput (value, type) {
            this.value = value
        },
        handleSetAttendanceState (value) {
            this.userAttendanceResponse = value
            this.$emit('submitSignUpEvent', { data: this.data, state: this.userAttendanceResponse })
        },
        handleSubmit () {
            const { name, type } = this.eventData
            if (!name) this.eventData.name = this.eventTypes[type]
            if (this.currentType === 'add') {
                this.$emit('submitCreateEvent', this.eventData)
            } else if (this.currentType === 'edit') {
                this.$emit('submitEditEvent', this.eventData)
            }
        },
        getAttendanceStateIcon (state) {
            return (this.attendanceStates[state] && this.attendanceStates[state].icon) ||
                (this.finalAttendanceStates[state] && this.finalAttendanceStates[state].icon)
        },
        getAttendanceStateColor (state) {
            return (this.attendanceStates[state] && this.attendanceStates[state].color) ||
                (this.finalAttendanceStates[state] && this.finalAttendanceStates[state].color)
        },
    },
}
</script>

<style scoped lang="scss">
@import '~vue-multiselect/dist/vue-multiselect.min.css';

.CalendarModal {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	overflow-x: hidden;
	overflow-y: auto;
	outline: 0;
	z-index: 9996;
}
.Backdrop {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba($black, 0.7);
	z-index: 9997;
}
.Dialog {
	position: relative;
	width: auto;
	max-width: 100%;
	margin: 0px auto;
	border-radius: 20px;
	overflow: hidden;
	background: $white;
	box-shadow:  0 2px 5px 0 rgba($black, 0.2);
	z-index: 9998;
	padding: 40px 0;
	@media screen and (min-width: $md) {
		max-width: 700px;
		margin: 50px auto;
	}
}
.Close {
	position: absolute;
	top: 20px;
	right: 30px;
	color: $black;
	font-size: 24px;
	opacity: .5;
	cursor: pointer;
}
.Body {
	padding: 15px 35px;
	color: $gray-dark;
	@media screen and (max-width: $md) {
		padding: 0px 30px;
	}
	.Title {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 35px;
		@media screen and (max-width: $md) {
			flex-direction: column;
			align-items: flex-start;
			margin-bottom: 25px;
		}
		.Link { cursor: pointer }
		p {
			color: $gray-01;
			font-size: 32px;
			line-height: 38px;
			font-weight: bold;
			@media screen and (max-width: $md) {
				font-size: 28px;
			}
		}
		.Date {
			display: flex;
			align-items: flex-end;
			margin-bottom: 5px;
			.Icon {
				margin-left: 5px;
			}
			span {
				font-size: 20px;
				color: $gray-02;
				margin-left: 10px;
				text-transform: capitalize;
				@media screen and (max-width: $md) {
					margin-top: 15px;
					font-size: 16px;
				}
			}
		}
	}
	.Header {
		margin-bottom: 40px;
	}
	.Score, .ExternalLink {
		display: flex;
		margin-bottom: 20px;
		p {
			font-size: 20px;
			color: $gray-01;
			@media screen and (max-width: $sm) {
				font-size: 18px;
			}
		}
		.Icon {
			margin-right: 10px;
		}
	}
	.Content {
		display: flex;
		@media screen and (max-width: $sm) {
			flex-direction: column;
			align-items: center;
		}
		.Image {
			border-radius: 3rem;
			height: 230px;
			width: 200px;
			min-width: 200px;
			background-size: cover;
			background-position: center;
		}

		.Info {
			display: flex;
			flex-direction: column;
			margin-left: 50px;

			@media screen and (max-width: $sm) {
				width: 100%;
				margin-top: 20px;
				margin-right: 20px;
			}

			.Time, .Place, .Date, .Type, .Uniform, .Description, .Attending {
				display: flex;
				margin-top: 30px;
				p {
					font-size: 20px;
					color: $gray-01;
					@media screen and (max-width: $sm) {
						font-size: 18px;
					}
				}
				.Icon {
					margin-right: 10px;
				}
			}
			.Date {
				margin-top: 10px;
			}
			.Attending {
				@media screen and (max-width: $sm) {
					flex-direction: column;
				}
				.Empty {
					padding-top: 3px;
					padding-left: 20px;
					color: $gray-medium;
					font-size: 16px;
				}
				ul {
					display: flex;
					flex-direction: column;
					padding-left: 25px;
					@media screen and (max-width: $sm) {
						padding-left: 40px;
						margin-top: 20px;
					}
					li {
						display: flex;
						flex-direction: row;
						align-items: flex-end;
						& + li { margin-top: 15px }

						.Index {
							color: $primary;
							font-size: 18px;
							margin-right: 15px;
							font-weight: bold;
							&.Icon {
								font-weight: normal;
							}
						}
						.Player {
							font-size: 18px;
						}
					}
				}
			}
		}
	}
	.Response {
		font-size: 16px;
		font-weight: bold;
		color: $gray-dark;
		margin: 50px 0 10px;
	}
	.Footer {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		margin-top: 30px;
		.Button + .Button {
			margin-left: 10px;
		}
	}
}

.modal-enter-active,
.modal-leave-active {
	.Dialog {
		transition-duration: 0.5s;
		transition-property: opacity, transform;
		transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
	}
	.Backdrop {
		transition: opacity 0.5s ease-in-out;
	}
}
.modal-enter,
.modal-leave-to {
	.Backdrop {
		opacity: 0;
	}
	.Dialog {
		opacity: 0;
		transform: translateY(-50px);
	}
}

.Row {
	margin-top: 30px;
	.flex1 { flex: 1 }
	.mr2 { margin-right: 20px }
	&.Double {
		display: flex;
	}
	.Label {
		margin: 0 16px 10px;
		color: $gray-02;
		font-weight: $font-weight-semibold;
		@media screen and (max-width: $md) {
			font-size: 14px;
		}
	}
	.Input::v-deep input {
		border-radius: 20px !important;
		height: 100%;
	}
	.InputOptions {
		display: flex;
		@media screen and (max-width: $sm) {
			flex-direction: column;
			.Input {
				margin-top: 20px;
			}
		}
	}
}
</style>
