<template>
    <div class="AttendanceScreen">
        <div class="ScreenHeader">
            <h1>Asistencias</h1>
            <PillSelectorInput
                v-model="eventType"
                :options="eventTypes"
                rounded
                preventDeselect
                @input="handleChangeTab"
            />
        </div>
        <div class="Container">
            <div class="Top">
                <DateSelector
                    v-if="!isEditMode"
                    :isEditable="isEditMode"
                    :startYear="attendanceConstants.attendaceStartYear"
                    :proposedDate="proposedDate"
                    @changeDate="handleSelectedDate"
                />
                <p
                    v-else
                    class="Date"
                >
                    {{ months[dateInfo.selectedMonth - 1].label }}, {{ dateInfo.selectedYear }}
                </p>
                <div
                    v-if="!isLoading"
                    class="Buttons"
                >
                    <Button
                        v-if="isEditMode"
                        class="Submit"
                        type="submit"
                        text="GUARDAR"
                        styleType="primary"
                        iconLeft="save"
                        :iconSize="16"
                        @click="handleSubmit"
                    />
                    <Icon
                        v-if="isEditMode === false"
                        icon="edit"
                        :size="20"
                        @click="isEditMode = true"
                    />
                    <Icon
                        v-else
                        icon="close"
                        :size="20"
                        @click="handleClose"
                    />
                </div>
            </div>
            <Loading
                key="loading"
                :visible="isLoading"
                :opacity="1"
            />
            <Transition
                v-if="!isLoading"
                name="main-router"
                mode="out-in"
            >
                <template v-if="events.length">
                    <div class="TableBody">
                        <div
                            v-for="(user, index) in users"
                            :key="user.id"
                        >
                            <AttendanceCalendar
                                :key="attendanceCalendarKey"
                                isTableMode
                                :firstRow="index === 0"
                                :type="user.shortName"
                                :event="events"
                                :attendance="user.attendance"
                                :isEditable="isEditMode"
                            />
                        </div>
                    </div>
                </template>
                <div
                    v-else
                    class="Empty"
                >
                    No hay eventos
                </div>
            </Transition>
            <Legend
                :legend="attendanceStates"
                title="Tipos de asistencia"
            />
        </div>
    </div>
</template>

<script>
import { AttendanceCalendar } from '@/components'
import utils from '@/utils/utils'
import attendanceUtils from '@/utils/attendanceScreenUtils'
import attendanceConstants from '@/entities/attendance'
import constants from '@/entities/constants'
import { months } from '@/entities/calendar'

export default {
    name: 'AttendanceScreen',
    components: {
        AttendanceCalendar,
    },
    data () {
        return {
            isEditMode: false,
            months,
            events: [],
            users: [],
            attendanceConstants,
            attendanceStates: { ...attendanceConstants.attendanceStates, ...attendanceConstants.finalAttendanceState },
            eventTypes: attendanceConstants.eventTypes,
            eventTabs: attendanceConstants.eventTabs,
            typeNames: constants.EVENT_TYPE_NAMES,
            eventType: '',
            dateInfo: utils.getInitialDateInfo({ startYear: attendanceConstants.attendaceStartYear }),
            attendanceCalendarKey: 0,
            me: this.$store.getters.me,
            proposedDate: undefined,
            isLoading: true,
        }
    },
    async mounted () {
        const { attendanceSlug } = this.$route.params || {}
        const eventKey = attendanceSlug ? (this.eventTabs.find(t => t.slug === attendanceSlug) || {}).key : this.eventTabs[0].key
        this.eventType = eventKey
        await this.getAttendance(this.dateInfo, eventKey)
    },
    methods: {
        async handleChangeTab (value) {
            this.isLoading = true
            const { attendanceSlug } = this.$route.params || {}
            const selectedSlug = this.eventTabs.find(t => t.key === value)
            if (attendanceSlug !== selectedSlug.slug) {
                await this.getAttendance(this.dateInfo, selectedSlug.key)
                this.$router.push({
                    name: 'Attendance',
                    params: { attendanceSlug: selectedSlug.slug },
                })
            }
        },
        getAttendanceState (user = {}, eventId) {
            return (user.attendance && user.attendance.data &&
                user.attendance.data[eventId] &&
                user.attendance.data[eventId].state) ||
                constants.USER_EVENT_STATE.PENDING
        },
        async getAttendance (value, eventType, proposedDate) {
            const { events, attendance } = await attendanceUtils.getAttendance(value, eventType)
            this.events = events
            this.users = attendance
            this.proposedDate = proposedDate
            this.attendanceCalendarKey += 1
            this.isLoading = false
        },
        async handleSelectedDate (value) {
            this.isLoading = true
            this.dateInfo = value
            await this.getAttendance(value, this.eventType)
        },
        async handleSubmit () {
            this.isLoading = true
            for (const user of this.users) {
                if (user.attendance.toEdit) {
                    await attendanceUtils.updateAttendance(user, this.me.permission)
                }
            }
            await this.getAttendance(this.dateInfo, this.eventType, this.dateInfo)
            this.isEditMode = false
            this.isLoading = false
        },
        async handleClose () {
            this.isLoading = true
            await this.getAttendance(this.dateInfo, this.eventType)
            this.isEditMode = false
        },
    },
}
</script>
<style lang="scss" scoped>

.ScreenHeader {
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	h1 { margin: 0 20px 0 0 !important }
	.PillSelectorInput {
		width: fit-content;
	}
	@media screen and (max-width: $sm) {
		flex-direction: column;
		h1 { margin-bottom: 20px !important }
		.PillSelectorInput {
			align-self: flex-end;
			&::v-deep {
				.OptionGroup {
					border-radius: 20px;
					align-items: flex-end;
					justify-content: flex-end;
				}
			}
		}
	}
}
.AttendanceCalendar {
	&::v-deep {
		.Container {
			padding: 0 !important;
			align-items: flex-end;
			margin-bottom: 10px;
			@media screen and (max-width: $sm) {
				margin-bottom: 10px;
			}
			.SingleGoalRing {
				width: 40px;
				@media screen and (max-width: $sm) {
					margin-bottom: 10px;
					display: none;
				}
			}
			.Header {
				margin-bottom: 10px;
				border-radius: 8px;
				overflow: hidden;
				@media screen and (max-width: $sm) {
					margin-right: 10px;
				}
			}
			.Body {
				border-radius: 8px;
				overflow: hidden;
				@media screen and (max-width: $sm) {
					margin-bottom: 10px;
				}
			}
		}
		.Name {
			min-width: 200px;
			padding: 0 10px;
			text-align: center;
			@media screen and (max-width: $lg) {
				min-width: 150px;
			}
		}
	}
}
.Top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 10px;
	margin-bottom: 30px;
	@media screen and (max-width: $sm) {
		margin-top: 10px;
		margin-bottom: 20px;
	}
	.DateSelector, .Date {
		margin-left: 60px;
		margin-bottom: 0;
	}
	.Date {
		font-size: 20px;
		font-weight: bold;
		color: $gray-dark;
		@media screen and (max-width: $sm) {
			align-self: flex-start;
		}
	}
	.Buttons {
		display: flex;
		@media screen and (max-width: $sm) {
			margin-right: 0;
			margin-top: 20px;
		}
		.Icon:hover {
			cursor: pointer;
		}
		.Submit {
			margin-right: 25px;
			padding: 8px 15px;
			font-size: 14px;
			height: fit-content;
		}
	}
	@media screen and (max-width: $sm) {
		flex-direction: column;
		align-items: flex-end;
		.DateSelector, .Date {
			margin-left: 0px;
		}
	}
}
// .TableBody {
// 	height: calc(100vh - 320px);
// 	overflow-y: scroll;
// 	overflow-x: hidden;
// 	width: 100%;
// 	@media screen and (max-width: $sm) {
// 		overflow-x: scroll;
// 	}
// 	.AttendanceCalendar::v-deep {
// 		.firstRow {
// 			position: relative;
// 			.Header {
// 				position: fixed;
// 			}
// 			.Body {
// 				margin-top: 50px;
// 			}
// 		}
// 	}
// }
.Legend {
	margin-top: 50px;
}
.Empty {
	width: 100%;
	background: rgba($gray-light, .5);
	padding: 15px 25px;
	margin: 10px 0;
	color: $gray-dark;
	border-radius: 5px;
}
</style>
