<template>
    <div class="PaymentCalendar">
        <div
            v-if="!isTableMode"
            class="Wrapper"
        >
            <p class="Balance">
                <span>Saldo:</span> {{ paymentInformation.balance }} euros
            </p>
            <div v-if="mutablePaymentInformation">
                <DateSelector
                    :isEditable="isEditable"
                    :displayMonth="false"
                    :startYear="paymentConstants.paymentStartYear"
                    @changeDate="handleSelectedDate"
                />
            </div>
        </div>
        <div class="Table">
            <div
                v-if="!isTableMode"
                class="Header"
            >
                <div
                    v-for="{ id, label } in months"
                    :key="`month-${id}`"
                    class="Month"
                >
                    <p>{{ label.slice(0,3) }}.</p>
                </div>
            </div>
            <div
                class="Body"
            >
                <div
                    v-if="isTableMode"
                    class="Check Name"
                >
                    <p>{{ paymentInformation.shortName }}</p>
                </div>
                <div
                    v-for="{ id, key } in months"
                    :key="`check-${id}`"
                    class="Check"
                    :class="{ blocked: false }"
                    :style="{ 'cursor': isEditable && !isLoadingUpdate ? 'pointer' : 'default' }"
                    @click="isEditable && !isLoadingUpdate ? handleChagePaymentState(id, key) : ''"
                >
                    <Spinner
                        :visible="isChangingDate"
                        color="white"
                        small
                        noMargin
                    />
                    <Tooltip
                        v-if="!isChangingDate"
                        placement="bottom"
                        :text="isEditable ? 'Haz click para modificar el estado del pago' : tooltipDate(key)"
                    >
                        <Icon
                            :icon="(paymentState(key) || {}).icon"
                            :color="(paymentState(key) || {}).color"
                            :size="20"
                        />
                    </Tooltip>
                </div>
                <div
                    v-if="isTableMode"
                    class="Check"
                >
                    <p>{{ paymentInformation.balance }}</p>
                </div>
            </div>
        </div>
        <div
            v-if="isEditable && !isTableMode"
            class="Footer"
        >
            <Button
                class="Submit"
                type="submit"
                text="GUARDAR"
                styleType="primary"
                iconLeft="save"
                :iconSize="16"
                :loading="isLoading || isLoadingUpdate"
                @click="handleSubmit"
            />
        </div>
    </div>
</template>

<script>
import { months } from '@/entities/calendar'
import paymentConstants from '@/entities/payments'
import constants from '@/entities/constants'
import VueTypes from 'vue-types'
import utils from '@/utils/utils'
import moment from 'moment'

export default {
    name: 'PaymentCalendar',
    props: {
        paymentInformation: VueTypes.object,
        isEditable: VueTypes.bool.def(false),
        isTableMode: VueTypes.bool.def(false),
        isLoading: VueTypes.bool.def(false),
    },
    data () {
        return {
            months,
            paymentConstants,
            paymentsStates: paymentConstants.paymentsStates,
            dateInfo: utils.getInitialDateInfo({ startYear: paymentConstants.paymentStartYear }),
            mutablePaymentInformation: this.paymentInformation,
            isLoadingUpdate: false,
            isChangingDate: false,
        }
    },
    watch: {
        paymentInformation: {
            deep: true,
            handler () {
                this.isChangingDate = false
            },
        },
    },
    mounted () {
        this.mutablePaymentInformation = this.paymentInformation
    },
    methods: {
        tooltipDate (key) {
            const date = (((this.paymentInformation || {}).payments || {})[key] || {}).paymentDate
            return date ? `Fecha de pago: ${moment.utc(date || '').format('DD/MM/YYYY')}` : ''
        },
        paymentState (key) {
            const { mutablePaymentInformation, paymentsStates } = this
            if (mutablePaymentInformation.payments[key] && mutablePaymentInformation.payments[key].state &&
			paymentsStates[mutablePaymentInformation.payments[key].state]) return paymentsStates[mutablePaymentInformation.payments[key].state]
        },
        handleSelectedDate (value) {
            this.dateInfo = value
            this.isChangingDate = true
            this.$emit('changePaymentYear', value)
        },
        async handleSubmit () {
            this.isLoadingUpdate = true
            this.$emit('submit', {
                payments: this.mutablePaymentInformation.payments,
                dateInfo: this.dateInfo,
                user: {
                    id: this.paymentInformation.id,
                    name: this.paymentInformation.name,
                    shortName: this.paymentInformation.shortName,
                },
            })
        },
        handleChagePaymentState (id, key) {
            let currentState = this.mutablePaymentInformation.payments[key].state
            if (currentState === constants.PAYMENT_STATE.FREE) currentState = constants.PAYMENT_STATE.OWES
            else if (currentState === constants.PAYMENT_STATE.OWES) currentState = constants.PAYMENT_STATE.RECEIVED
            else currentState = constants.PAYMENT_STATE.FREE
            this.mutablePaymentInformation.payments[key].state = currentState
            this.mutablePaymentInformation.toEdit = true
        },
    },
}
</script>
<style lang="scss" scoped>

.PaymentCalendar {
	.Wrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
		.Balance {
			color: $gray-dark;
			span {
				font-size: 18px;
				font-weight: bold;
			}
		}
	}
	.Table {
		width: 100%;
		display: flex;
		flex-direction: column;
		border-radius: 8px;
		overflow: hidden;
		@media screen and (max-width: $sm) {
			flex-direction: row;
		}
		.Header, .Body {
			display: grid;
			grid-template-columns: repeat(12, 1fr);
			grid-template-rows: 1fr;
			grid-column-gap: 5px;
			grid-row-gap: 5px;
			@media screen and (max-width: $sm) {
				grid-template-rows: repeat(12, 1fr);
				grid-template-columns: 1fr;
				width: 50%;
			}
			.Month {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 10px 0;
				background: $primary;
			}
			.Check {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 10px 0;
				background: $gray-07;
				&.blocked {
					background: rgba(black, .1);
				}
				&.Name {
					min-width: 200px;
					padding: 0 10px;
					text-align: center;
					// @media screen and (max-width: $lg) {
					// 	min-width: 150px;
					// }
					// @media screen and (max-width: $sm) {
					// 	min-width: 100px;
					// }
				}
			}
		}
	}
	.Footer {
		display: flex;
		justify-content: flex-end;
		margin-top: 30px;
		.Button {
			width: fit-content;
			font-size: 16px;
			@media screen and (max-width: $sm) {
				font-size: 14px;
			}
	}
	}
}
</style>
