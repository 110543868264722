import moment from 'moment'
import _ from 'lodash'
import constants from '../entities/constants'
import eventIntegration from '../integration/eventIntegration'
import userEventApi from '../api/userEvent'

export default {
    async getAttendance (dateInfo, eventType) {
        const events = await this.getEvents(dateInfo, eventType, constants.EVENT_STATE.ACCEPTED)
        const userEvents = await userEventApi.listUserEvent({ eventId: events.map(e => e.id) })

        for (const user of userEvents.items) {
            const attendance = {
                data: this.createEventIdMap(user.userEvents),
                percentage: 0,
                percentageValue: 0,
            }
            attendance.data = this.fillWithDummyEvents(events, user.userEvents, attendance.data)
            const percentage = this.getAttendancePercentage(events, Object.values(attendance.data))
            attendance.percentage = Math.round(percentage)
            attendance.percentageValue = percentage / 100
            user.attendance = attendance
        }

        return {
            events,
            attendance: userEvents.items,
        }
    },

    getEvents (dateInfo, trainingType, trainingState) {
        const from = moment.utc(dateInfo.selectedDate).startOf('month').format()
        const to = moment.utc(dateInfo.selectedDate).endOf('month').format()

        return eventIntegration.getEvents({ from,
            to,
            type: trainingType,
            state: trainingState })
    },

    getAttendancePercentage (events, attendance) {
        const eventList = _.chain(events)
            .filter(item => moment.utc().endOf('day').isAfter(moment.utc(item.date)))
            .map(item => item.id)
            .value()
        const attendanceNumber = attendance.filter(item => item.state === constants.EVENT_STATE.ACCEPTED &&
            eventList.indexOf(item.eventId) !== -1).length
        return eventList.length ? ((attendanceNumber * 100) / eventList.length) : 0
    },

    createEventIdMap (userEvents) {
        const eventMap = {}
        for (const ev of userEvents) {
            eventMap[ev.eventId] = ev
        }
        return eventMap
    },

    fillWithDummyEvents (events, userEvents, eventMap) {
        const newEventMap = eventMap
        if (events.length > userEvents.length) {
            const missingEvents = _.difference(
                events.map(e => e.id),
                _.chain(eventMap).keys().map(e => parseInt(e)).value(),
            )

            for (const eventId of missingEvents) {
                const ev = events.find(e => e.id === eventId)
                newEventMap[eventId] = {
                    state: ev.isFutureDay ? constants.USER_EVENT_STATE.PENDING
                        : constants.USER_EVENT_FINAL_STATE.REJECTED,
                    isDummyEvent: true,
                    eventId,
                }
            }
        }
        return newEventMap
    },

    async updateAttendance (user, permission) {
        if (permission === constants.USER_ROLE.ADMIN ||
            permission === constants.USER_ROLE.COACH) {
            for (const eventId in user.attendance.data) {
                if (user.attendance.data[eventId] && user.attendance.data[eventId].toEdit) {
                    if (user.attendance.data[eventId].isDummyEvent) {
                        await userEventApi.createUserEvent({
                            eventId,
                            userId: user.id,
                            state: user.attendance.data[eventId].state,
                        })
                    } else {
                        const userEventId = user.attendance.data[eventId].id
                        const editedAttendance = user.attendance.data[eventId]
                        delete editedAttendance.id
                        delete editedAttendance.toEdit
                        delete editedAttendance.createdAt
                        delete editedAttendance.updatedAt
                        await userEventApi.updateUserEvent(userEventId, editedAttendance)
                    }
                }
            }
        }
    },
}
