<template>
    <component
        :is="tag"
        v-tooltip="{ content: text, placement: placement, trigger: 'hover click' }"
        class="Tooltip"
    >
        <slot />
    </component>
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'Tooltip',
    props: {
        tag: VueTypes.string.def('span'),
        text: VueTypes.string.isRequired,
        placement: VueTypes.oneOf([
            'auto',
            'auto-start',
            'auto-end',
            'top',
            'top-start',
            'top-end',
            'right',
            'right-start',
            'right-end',
            'bottom',
            'bottom-start',
            'bottom-end',
            'left',
            'left-start',
            'left-end',
        ]).def('auto'),
    },
}
</script>

<style lang="scss">
@import '@/theme/_variables.scss';
@import "@/theme/_breakpoints.scss";

$border-width: 12px;
$backgroundColor: $white;
$color: $gray-02;
$shadow-02: 0px 8px 24px rgba($gray-01, 0.2);

.Tooltip {
	cursor: pointer;
}

.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    box-shadow: $shadow-02;
    background: $white;
    line-height: 140%;
    color: $color;
    padding: 16px;
    border-radius: 16px;
    max-width: 280px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 8px;
    border-color: $white;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 12px;

    .tooltip-arrow {
      border-width: $border-width $border-width 0 $border-width;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -$border-width;
      left: calc(50% - #{$border-width});
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 12px;

    .tooltip-arrow {
      border-width: 0 $border-width $border-width $border-width;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -$border-width;
      left: calc(50% - #{$border-width});
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 4px;

    .tooltip-arrow {
      border-width: $border-width $border-width $border-width 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -$border-width;
      top: calc(50% - #{$border-width});
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 4px;

    .tooltip-arrow {
      border-width: $border-width 0 $border-width $border-width;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -$border-width;
      top: calc(50% - #{$border-width});
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    .popover-inner {
      background: $backgroundColor;
      color: $color;
      padding: 24px;
      border-radius: 5px;
      box-shadow: $shadow-02;
    }

    .popover-arrow {
      border-color: $backgroundColor;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}

</style>
