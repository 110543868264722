<template>
    <div class="SingleGoalRing">
        <svg :viewBox="viewBox">
            <circle
                cx="0"
                cy="0"
                :r="radius"
                :stroke-width="strokeWidth"
                :stroke="circleBackgroundColor"
                :opacity="negative ? '0.20' : '1'"
                fill="none"
            />
            <SvgCircleArc
                :from="0"
                :length="progress"
                :color="color"
                :radius="radius"
                :strokeWidth="strokeWidth"
                :show="hasBeenMounted"
            />
            <text
                x="0"
                y="60"
                text-anchor="middle"
                :fill="negative ? 'white' : '#585656'"
                font-size="10rem"
                letter-spacing="-10px"
            >{{ text }}<tspan font-size="6rem"> {{ suffix }}</tspan>
            </text>
        </svg>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import SvgCircleArc from '../SvgCircleArc/SvgCircleArc.vue'

export default {
    name: 'SingleGoalRing',
    components: { SvgCircleArc },
    props: {
        color: VueTypes.string.def('primary'),
        progress: VueTypes.number,
        text: VueTypes.oneOfType([String, Number]),
        suffix: VueTypes.string.optional,
        negative: VueTypes.bool.def(false),
    },
    data () {
        return {
            hasBeenMounted: false,
            radius: 250,
        }
    },
    created () {
        this.viewBoxSize = 500
        this.viewBox = `${-this.viewBoxSize / 2} ${-this.viewBoxSize / 2} ${this.viewBoxSize} ${this.viewBoxSize}`
        this.strokeWidth = 80
        this.circleBackgroundColor = '#eee'
    },
    mounted () {
        setTimeout(() => (this.hasBeenMounted = true), 1000)
    },
}
</script>

<style scoped lang="scss">
.SingleGoalRing {
	position: relative;
	svg {
		width: 100%;
		height: 100%;
		overflow: initial;
		// text { font: italic 12px serif }
		// tspan {
			// font-size: 12px;
			// font: bold 10px sans-serif; fill: red;
		// }
	}
}
</style>
