<template>
    <div class="EconomyExpenses">
        Gastos
    </div>
</template>

<script>

export default {
    name: 'EconomyExpenses',
}
</script>
<style lang="scss" scoped>
// .EconomyExpenses {
// }

</style>
