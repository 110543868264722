import CONSTANTS from './constants'
import { values } from 'lodash'

export const tabs = [
    {
        key: 'contact',
        text: 'Contacto',
        slug: 'contacto',
        permissions: values(CONSTANTS.USER_ROLE),
    },
    {
        key: 'payments',
        text: 'Pagos',
        slug: 'pagos',
        permissions: [CONSTANTS.USER_ROLE.ADMIN, CONSTANTS.USER_ROLE.COMITE],
    },
    {
        key: 'attendance',
        text: 'Asistencias',
        slug: 'asistencias',
        permissions: [CONSTANTS.USER_ROLE.ADMIN, CONSTANTS.USER_ROLE.COACH],
    },
    {
        key: 'uniform',
        text: 'Uniformes',
        slug: 'uniformes',
        permissions: values(CONSTANTS.USER_ROLE),
    },
    //  {
    //    key: 'statistics',
    //    text: 'Estadísticas',
    //  },
    //  {
    //    key: 'feedback',
    //    text: 'Feedback',
    //  },
]

export const statuses = {
    [CONSTANTS.USER_STATUS.ACTIVE]: {
        key: CONSTANTS.USER_STATUS.ACTIVE,
        icon: 'check',
        color: 'success',
        colorCode: '#A6D04D',
        label: 'Activa',
    },
    [CONSTANTS.USER_STATUS.OUT]: {
        key: CONSTANTS.USER_STATUS.OUT,
        icon: 'close',
        color: 'error',
        colorCode: '#EC466E',
        label: 'Abandonó',
    },
    [CONSTANTS.USER_STATUS.INACTIVE]: {
        key: CONSTANTS.USER_STATUS.INACTIVE,
        icon: 'alert-triangle',
        color: 'warning',
        colorCode: '#FBE05F',
        label: 'Inactiva',
    },
}

export const profileDefaultValue = {
    name: '',
    shortName: '',
    alias: '',
    phoneNumber: '',
    email: '',
    birthdate: undefined,
    startDate: undefined,
    permission: 'PLAYER',
    status: 'active',
    imagePath: '',
    defensivePositions: [],
    offensivePositions: [],
    contacts: [],
    uniforms: {
        'game-shirt-black': {},
        'game-shirt-black-new': {},
        'game-shirt-turquoise': {},
        'game-shirt-turquoise-new': {},
        'sweater-black': {},
        'socks-turquoise': {},
        'socks-black': {},
        'hat-white': {},
        // gameShirtBlack: {}, //'game-shirt-black'
        // gameShirtTurquoise: {},
        // sweaterBlack: {},
        // socksTurquoise: {},
        // socksBlack: {},
        // hatWhite: {},
    },
}

export const inputFields = [
    {
        key: 'name',
        label: 'Nombre completo',
        icon: 'user',
        type: 'text',
        permissions: [CONSTANTS.USER_ROLE.ADMIN, CONSTANTS.USER_ROLE.COMITE],
    },
    {
        key: 'shortName',
        label: 'Nombre corto',
        icon: 'user',
        type: 'text',
        permissions: [CONSTANTS.USER_ROLE.ADMIN, CONSTANTS.USER_ROLE.COMITE],
    },
    {
        key: 'alias',
        label: 'Alias',
        icon: 'user-minus',
        type: 'text',
        permissions: [CONSTANTS.USER_ROLE.ADMIN, CONSTANTS.USER_ROLE.COMITE],
    },
    {
        key: 'phoneNumber',
        label: 'Teléfono',
        icon: 'phone',
        type: 'number',
        permissions: [CONSTANTS.USER_ROLE.ADMIN, CONSTANTS.USER_ROLE.COMITE],
    },
    {
        key: 'email',
        label: 'Email',
        icon: 'mail',
        type: 'text',
        permissions: [CONSTANTS.USER_ROLE.ADMIN, CONSTANTS.USER_ROLE.COMITE],
    },
    {
        key: 'birthdate',
        label: 'Cumpleaños',
        icon: 'calendar',
        type: 'date',
        permissions: [CONSTANTS.USER_ROLE.ADMIN, CONSTANTS.USER_ROLE.COMITE],
    },
    {
        key: 'startDate',
        label: 'Fecha de ingreso',
        icon: 'clock',
        type: 'date',
        permissions: [CONSTANTS.USER_ROLE.ADMIN, CONSTANTS.USER_ROLE.COMITE],
    },
    {
        key: 'imagePath',
        label: 'Imagen',
        icon: 'image',
        type: 'text',
        permissions: [CONSTANTS.USER_ROLE.ADMIN, CONSTANTS.USER_ROLE.COMITE],
    },
]

export const personalData = [
    {
        key: 'birthdateFormatted',
        label: 'Cumpleaños',
    },
    {
        key: 'startDateFormatted',
        label: 'Incorporación',
    },
    {
        key: 'defensivePositionsText',
        label: 'Posiciones Defensivas',
    },
    {
        key: 'offensivePositionsText',
        label: 'Posiciones Ofensivas',
    },
    {
        key: 'status',
        label: 'Status',
    },
]
