<template>
    <div class="Tabs">
        <a
            v-for="tab in tabs"
            :key="tab.key"
            v-permissions="{permissions: tab.permissions, userId}"
            class="Tab"
            :class="{ Selected: tab.key === value, disabled: tab.disabled }"
            @click="!tab.disabled && $emit('input', tab.key)"
        >
            <p
                class="TabText"
            >
                {{ tab.text }}
            </p>
        </a>
        <div class="Line" />
    </div>
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'Tabs',
    props: {
        tabs: VueTypes.arrayOf(VueTypes.shape({
            key: VueTypes.string,
            text: VueTypes.string,
            disabled: VueTypes.boolean,
        }).loose).isRequired,
        value: VueTypes.string.isRequired,
        size: VueTypes.string.def('16'),
        userId: VueTypes.integer,
    },
}
</script>

<style lang="scss" scoped>

.Tabs {
    position: relative;
	display: flex;
    align-items: center;
    margin-bottom: 12px;
    overflow-x: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
}
.Line {
	height: 3px;
	width: 100%;
	background: $gray-05;
	position: absolute;
	bottom: 0;
	z-index: 1;
}
.Tab {
    $tabWidth: calc(100% / 3);
	border-bottom: 3px solid $gray-05;
    position: relative;
    padding: 12px;
    color: #585656;
	z-index: 2;
    cursor: pointer;

    &.disabled {
        opacity: 0.7;
    }
    &:not(.disabled):hover {
        color: $primary;
    }
    &.Selected {
		color: $primary;
		p {
			font-weight: bold;
		}
        &:after {
            content: "";
            position: absolute;
            bottom: -3px;
            left: $tabWidth;
            width: $tabWidth;
            height: 3px;
            background: $primary;
            transition: all 0.3s ease-in-out;
        }
    }
}

.TabText {
    color: #585656;
    transition: all .3s ease-in-out;
}

</style>
