<template>
    <div
        class="ExerciseCard"
        @click="$emit('click')"
    >
        <div
            class="Image"
            :style="{ backgroundImage: `url(${require(`@/assets/imgs/${image}.png`)})` }"
        />
        <!-- :style="{ backgroundImage: `url(imgs/${image}.png)` }" -->
        <!-- :style="{ backgroundImage: `url(${require(`imgs/${image}.png`)})` }" -->
        <!-- :style="{ backgroundImage: `url(${require(`@/assets/images/home/breakingnews_${image}.svg`)})` }" -->
        <div class="Wrapper">
            <p
                class="Category"
            >
                {{ type }}
            </p>
            <p
                class="Title"
            >
                {{ name }}
            </p>
            <p
                class="Description"
            >
                {{ descriptionText }}
            </p>
            <p
                class="Description"
            >
                powers: {{ powers }}
                indications: {{ indications }}
                repetitions: {{ repetitions }}
                duration: {{ duration }}
            </p>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'ExerciseCard',
    props: {
        type: VueTypes.string.optional,
        name: VueTypes.string.optional,
        powers: VueTypes.string.optional,
        description: VueTypes.string.optional,
        indications: VueTypes.string.optional,
        image: VueTypes.string.optional,
        repetitions: VueTypes.string.optional,
        duration: VueTypes.string.optional,
    },
    computed: {
        descriptionText () {
            return this.description.length > 80 ? `${this.description.slice(0, 80)} ...` : this.description
        },
    },
}
</script>

<style lang="scss" scoped>

.ExerciseCard {
	padding: 0;
    overflow: hidden;
	position: relative;
	border-radius: 4px;
	background: white;
	box-shadow:  0 2px 5px 0 rgba(black, 0.2);

	p {
        color: #000;
    }
	.Image {
		width: 100%;
		height: 180px;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.Wrapper {
		padding: 16px;
		overflow: hidden;
		.WText {
			& + .WText  {
				margin-top: 10px;
			}
			&.Title, &.Description {
				line-height: 16px;
			}
		}
		.Date {
			text-transform: capitalize;
		}
	}
}

.Category {
    font-size: 10px;
    color: #ACABAB;
    margin-bottom: 10px;
}
.Title {
    font-size: 12px;
    color: #585656;
    margin-bottom: 10px;
}
.Description {
    font-size: 10px;
    color: #585656;
}
</style>
