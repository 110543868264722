<template>
    <div class="OrganizationScreen">
        <div class="Header">
            <div>
                <Button
                    v-permissions="{permissions: [constants.USER_ROLE.ADMIN,
                                                  constants.USER_ROLE.COMITE]}"
                    text="CREAR"
                    iconLeft="plus"
                    @click="isModalOpen = true"
                />
            </div>
            <div class="Buttons">
                <PillSelectorInput
                    v-model="groupCategory.selected"
                    :options="groupCategory.options"
                    rounded
                    preventDeselect
                    @input="handleChangeTab"
                />
                <PillSelectorInput
                    v-model="statusFilter"
                    :options="userStatusFilters"
                    rounded
                    preventDeselect
                    @input="handleChangeFilter"
                />
                <Icon
                    :size="26"
                    class="Display"
                    :icon="displayAs === 'grid' ? 'list' : 'grid'"
                    @click="toggleDisplay"
                />
            </div>
        </div>
        <Loading
            v-if="isLoading"
            key="loading"
            visible
            :opacity="1"
        />
        <div v-else>
            <div
                v-if="displayAs === 'grid'"
                class="Grid"
            >
                <div
                    v-for="(person, index) in filteredUsers"
                    :key="index"
                    class="GridItem"
                    @click="handleClick(person)"
                >
                    <div class="PersonInfo">
                        <p class="Name">
                            {{ person.shortName }} {{ person.number ? `#${person.number}` : ''}}
                        </p>
                        <p
                            v-if="person.defensivePositionsText"
                            class="Positions"
                        >
                            {{ person.defensivePositionsText }}<br>
                        </p>
                        <div class="Icons">
                            <p class="Seniority">
                                <Icon
                                    icon="clock"
                                />
                                {{ person.startDate }}<br>
                            </p>
                            <p class="Birthday">
                                <Icon
                                    icon="cake"
                                />
                                {{ person.birthdate }}<br>
                            </p>
                        </div>
                    </div>
                    <div
                        class="ProfilePicture"
                        :style="{ backgroundImage: `url(${require(`@/assets/imgs/players/${((person || {}).imagePath) || 'default.jpg'}`)})` }"
                    />
                    <p class="Name">
                        {{ person.alias }} {{ person.number ? `#${person.number}` : ''}}
                    </p>
                </div>
            </div>
            <div
                v-else
                class="List"
            >
                <div class="ListHeader">
                    <p class="Name">
                        Nombre
                    </p>
                    <p class="Positions">
                        Posiciones
                    </p>
                    <p class="Seniority">
                        Antiguedad
                    </p>
                    <p class="Birthday">
                        Cumpleaños
                    </p>
                    <p
                        v-permissions="{permissions: [constants.USER_ROLE.ADMIN,
                                                      constants.USER_ROLE.COMITE]}"
                        class="Status"
                    >
                        Estado
                    </p>
                </div>
                <div
                    v-for="(person, index) in filteredUsers"
                    :key="index"
                    class="ListItem"
                    @click="handleClick(person)"
                >
                    <Avatar
                        :url="require(`@/assets/imgs/players/${person.imagePath || 'default.jpg'}`)"
                        :size="{ height: 50, width: 50 }"
                        :iconSize="20"
                    />
                    <div class="Name">
                        <p>{{ person.alias }} {{ person.number ? `#${person.number}` : ''}}</p>
                        <p class="FullName">
                            {{ person.name }}
                        </p>
                    </div>
                    <p class="Positions">
                        {{ person.defensivePositionsText }}
                    </p>
                    <p class="Seniority">
                        {{ person.startDate }}
                    </p>
                    <p class="Birthday">
                        {{ person.birthdate }}
                    </p>
                    <div
                        v-permissions="{permissions: [constants.USER_ROLE.ADMIN,
                                                      constants.USER_ROLE.COMITE]}"
                        class="Status"
                    >
                        <div
                            class="StatusIcon"
                            :style="{ background: statuses[person.status].colorCode }"
                        >
                            <Icon
                                :size="15"
                                :icon="statuses[person.status].icon"
                                :color="statuses[person.status].color === 'warning' ? '#333' : 'white'"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal
            :isOpen="isModalOpen"
            @close="isModalOpen = false"
        >
            <template #body>
                <ProfileInput
                    title="Nueva jugadora"
                    @submit="handleCreateProfile"
                />
            </template>
        </Modal>
    </div>
</template>

<script>
import organizationConstants from '@/entities/organization'
import VueTypes from 'vue-types'
import userIntegration from '@/integration/userIntegration'
import { ProfileInput } from '@/components'
import { statuses } from '@/entities/profile'
import constants from '@/entities/constants'

export default {
    name: 'OrganizationScreen',
    components: {
        ProfileInput,
    },
    props: {
        me: VueTypes.object,
    },
    data () {
        return {
            isModalOpen: false,
            statuses,
            displayAs: 'grid',
            image: '',
            organizationCategories: organizationConstants.organizationCategories,
            organization: {},
            groupCategory: {
                selected: '',
                options: organizationConstants.categories,
            },
            statusFilter: 'all',
            constants,
            isLoading: true,
        }
    },
    computed: {
        filteredUsers () {
            if (this.statusFilter === constants.USER_STATUS.ACTIVE) return this.organization[this.groupCategory.selected].filter(u => u.status === constants.USER_STATUS.ACTIVE)
            else return this.organization[this.groupCategory.selected]
        },
    },
    watch: {
        $route (to) {
            const { organizationSlug } = to.params
            this.groupCategory.selected = organizationSlug ? (this.groupCategory.options.find(t => t.slug === organizationSlug) || {}).key : constants.USER_ROLE.PLAYER
        },
    },
    async mounted () {
        const { organizationSlug } = this.$route.params || {}
        this.groupCategory.selected = organizationSlug ? (this.groupCategory.options.find(t => t.slug === organizationSlug) || {}).key : constants.USER_ROLE.PLAYER

        this.organization = {
            [constants.USER_ROLE.COACH]: [],
            [constants.USER_ROLE.COMITE]: [],
            [constants.USER_ROLE.PLAYER]: [],
        }

        const actions = []
        for (const key in this.organization) {
            actions.push(userIntegration.listUsersByRole(key)
                .then((data) => {
                    this.organization[key] = data
                }))
        }
        Promise.all(actions)
            .then(this.isLoading = false)
    },
    created () {
        this.userStatusFilters = organizationConstants.status
    },
    methods: {
        handleChangeTab (value) {
            const { organizationSlug } = this.$route.params || {}
            const selectedSlug = (this.groupCategory.options.find(t => t.key === value) || {}).slug
            if (organizationSlug !== selectedSlug) {
                this.$router.push({
                    name: 'Organization',
                    params: { organizationSlug: selectedSlug },
                })
            }
        },
        handleClick (person) {
            const isAllowed = this.me.permission !== constants.USER_ROLE.PLAYER
            if (isAllowed) this.$router.push({ name: 'Profile', params: { id: person.id } })
        },
        toggleDisplay () {
            this.displayAs === 'grid' ? this.displayAs = 'list' : this.displayAs = 'grid'
        },
        async handleCreateProfile (value) {
            this.isModalOpen = false
            const newUser = await userIntegration.createUser(value, this.me.permission)
            this.$router.push({ name: 'Profile', params: { id: newUser.id } })
        },
        handleChangeFilter (value) {
            this.statusFilter = value
        },
    },
}
</script>
<style lang="scss" scoped>

.OrganizationScreen {
	display: flex;
	flex-direction: column;
	.Header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 30px;
		@media screen and (max-width: $sm) {
			flex-wrap: wrap;
		}
		.Buttons {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			flex-wrap: wrap;
			gap: 1rem;
		}
		.Display {
			cursor: pointer;
			font-size: 30px !important;
			@media screen and (max-width: $sm) {
				flex-grow: inherit;
			}
		}
		.PillSelectorInput {
			width: fit-content;
			@media screen and (max-width: $sm) {
				margin-right: 0px;
			}
			&::v-deep .Option {
				font-size: 16px;
				@media screen and (max-width: $sm) {
					font-size: 14px;
					padding: 6px 10px;
				}
			}
		}
	}
	.Grid {
		display: grid;
		grid-template-columns: repeat(8, 1fr);
		grid-column-gap: 20px;
		grid-row-gap: 20px;
		grid-template-rows: 1fr;
		@media screen and (max-width: $xl) {
			grid-template-columns: repeat(5, 1fr);
		}
		@media screen and (max-width: $lg) {
			grid-template-columns: repeat(4, 1fr);
		}
		@media screen and (max-width: $md) {
			grid-template-columns: repeat(3, 1fr);
		}
		@media screen and (max-width: $sm) {
			grid-template-columns: repeat(2, 1fr);
		}
		.GridItem {
			background: white;
			overflow: hidden;
			border-radius: 10px;
			box-shadow: 0px 4px 10px rgba(103, 94, 134, 0.1);
			display: flex;
			flex-direction: column;
			align-items: center;
			cursor: pointer;
			padding-bottom: 20px;
			position: relative;
			transition: all .3s;
			&:hover {
				.PersonInfo {
					opacity: 1;
					bottom: 0;
				}
			}
			.ProfilePicture {
				width: 100%;
				height: 200px;
				background-color: $gray-07;
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			p {
				color: $gray-dark !important;
				font-weight: 500;
				margin-top: 20px;
				font-size: 18px;
				text-align: center;
			}
			.PersonInfo {
				position: absolute;
				left: 0;
				width: 100%;
				height: 100%;
				background: $primary;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				opacity: 0;
				padding: 20px;
				transition: all .3s;
				bottom: -100%;
				p {
					color: white !important;
					font-size: 16px;
					line-height: 24px;
				}
				.Name {
					font-size: 18px;
					line-height: 26px;
					font-weight: bold;
					margin-top: 0;
				}
				.Positions {
					margin-top: 10px;
				}
				.Icon {
					margin-right: 10px;
				}
				.Icons {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					margin-top: 20px;
				}
				.Seniority, .Birthday {
					margin-top: 0;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.Birthday {
					margin-top: 10px;
				}
			}
		}
	}
	.List {
		margin-top: 10px;
		.ListHeader {
			display: flex;
			align-items: center;
			padding: 10px 20px;
			@media screen and (max-width: $md) {
				display: none;
			}
			p {
				color: $gray-02;
				margin-left: 40px;
			}
			.Name {
				flex: 1;
				margin-left: 60px;
			}
			.Positions, .Birthday, .Seniority {
				min-width: 100px;
				@media screen and (max-width: $md) {
					min-width: fit-content;
				}
			}
			.Birthday {
				min-width: 100px;
				@media screen and (max-width: $md) {
					display: none
				}
			}
			.Status {
				min-width: 70px;
				text-align: center;
				@media screen and (max-width: $sm) {
				min-width: 50px;
				}
			}
		}
		.ListItem {
			display: flex;
			align-items: center;
			background: white;
			height: 65px;
			padding: 0 20px;
			border-radius: 1rem;
			cursor: pointer;
			@media screen and (max-width: $md) {
				height: auto;
				flex-direction: column;
				text-align: center;
				padding: 10px;
			}

			& + .ListItem {
				margin-top: 15px;
			}
			p, .Status {
				margin-left: 40px;
				@media screen and (max-width: $md) {
				margin-left: 0px;
				margin-top: 10px;
				font-size: 12px;
				}
			}
			.Name {
				flex: 1;
				margin-left: 20px;
				display: flex;
				flex-direction: column;
				@media screen and (max-width: $md) {
					p { display: none; }
					.FullName { margin-top: 10px; display: block; }
				}
				p { margin: 0 }
				.FullName {
					color: $gray-03;
					font-size: 14px;
					margin-top: 5px;
					margin-left: 0px;
					@media screen and (max-width: $md) {
						margin-top: 10px;
					}
				}
			}

			.Positions, .Birthday, .Seniority {
				min-width: 100px;
				@media screen and (max-width: $md) {
					min-width: fit-content;
				}
			}
			.Status {
				min-width: 70px;
				display: flex;
				align-items: center;
				justify-content: center;
				@media screen and (max-width: $md) {
					display: none;
					min-width: 50px;
				}
			}

			.StatusIcon {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				background: yellow;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}
.Button {
	padding: 8px 20px;
	@media screen and (max-width: $sm) {
		margin-bottom: 10px;
	}
}

.Modal::v-deep {
	.Body {
		padding: 0 30px 40px 30px;
	}
}
</style>
