import CONSTANTS from './constants'

export default {
    paymentsStates: {
        [CONSTANTS.PAYMENT_STATE.RECEIVED]: {
            icon: 'check',
            color: 'success',
            label: 'Pagado',
        },
        [CONSTANTS.PAYMENT_STATE.FREE]: {
            icon: 'minus',
            color: 'grayDark',
            label: 'Gratis',
        },
        [CONSTANTS.PAYMENT_STATE.OWES]: {
            icon: 'close',
            color: 'error',
            label: 'No Pagado',
        },
    },
    paymentStartYear: '2019',
}
