<template>
    <div class="Feedback">
        Feedback
    </div>
</template>

<script>
import VueTypes from 'vue-types'
export default {
    name: 'Feedback',
    props: {
        feedback: VueTypes.array,
        isLoading: VueTypes.bool.def(false),
    },
}
</script>
<style lang="scss" scoped>

// .Feedback {

// }
</style>
