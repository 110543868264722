import MadMixPlayers from "./jugadoras.json";
import MadMixManagers from "./managers.json";
import { defensivePositions, defaultScreenTabs, formatDate } from "../general";
import MadMixParticipants from "./participants.json";
import MadMixNames from "./names.json";
import castellana from "./newTeams/castellana.json";
import malasana from "./newTeams/malasana.json";
import moncloa from "./newTeams/moncloa.json";
import salamanca from "./newTeams/salamanca.json";
import playersRaffle22 from "./playersRaffle";

export const playersRaffle = playersRaffle22;

const compare = (a, b) => {
	if (a.positionId > b.positionId) return 1;
	if (b.positionId > a.positionId) return -1;
	return 0;
};
export const players = MadMixPlayers.map((player) => {
	const position = defensivePositions.find((p) => p.key === player.position);
	const team = MadMixParticipants.find((p) => p.name === player.team);
	return {
		...player,
		positionId: position.number,
		teamId: (team || {}).id || "user",
	};
}).sort(compare);

export const managers = MadMixManagers.map((manager) => {
	const team = MadMixParticipants.find((p) => p.name === manager.team);
	return {
		...manager,
		teamId: (team || {}).id || "user",
	};
});

const newTeams = [castellana, malasana, moncloa, salamanca];

export const finalTeams = newTeams.map((t) => {
	return t.map((p) => {
		const pCopy = p;
		if (p && p.position) {
			const position = defensivePositions.find((a) => a.key === p.position);
			const secondPosition = defensivePositions.find((a) => a.key === p.secondPosition);
			const team = MadMixParticipants.find((b) => b.name === p.team);
			pCopy.positionId = position.number;
			pCopy.secondPositionId = secondPosition && secondPosition.number;
			pCopy.teamId = (team || {}).id || "user";
		} else if (p && p.isManager) {
			const team = MadMixParticipants.find((b) => b.name === p.team);
			pCopy.teamId = (team || {}).id || "user";
		}
		return pCopy;
	});
});

const participants = MadMixParticipants;
const names = MadMixNames;

const teams = [
	{
		id: 1,
		name: "Castellana",
	},
	{
		id: 2,
		name: "Salamanca",
	},
	{
		id: 3,
		name: "Malasaña",
	},
	{
		id: 4,
		name: "Moncloa",
	},
];

const locations = [
	{
		id: 1,
		name: "Jarama",
		address: "Av. de San Sebastián, 15",
	},
];

const events = [
	[
		{
			id: 1,
			homeclubId: 1,
			visitorId: 4,
			locationId: 1,
			date: "2022-02-05T10:00:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
		},
		{
			id: 2,
			homeclubId: 2,
			visitorId: 3,
			locationId: 1,
			date: "2022-02-05T12:30:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
		},
		{
			id: 3,
			homeclubId: 1,
			visitorId: 2,
			locationId: 1,
			date: "2022-02-05T15:00:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
		},
	],
	[
		{
			id: 4,
			homeclubId: 3,
			visitorId: 4,
			locationId: 1,
			date: "2022-02-06T10:00:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
		},
		{
			id: 5,
			homeclubId: 1,
			visitorId: 3,
			locationId: 1,
			date: "2022-02-06T12:30:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
		},
		{
			id: 6,
			homeclubId: 2,
			visitorId: 4,
			locationId: 1,
			date: "2022-02-06T15:00:00.000Z",
			scoreHomeclub: null,
			scoreVisitor: null,
		},
	],
];

export default {
	locations,
	events,
	raffleDay: formatDate("2022-01-29T21:00:00.000Z"),
	participants,
	names,
	teams,
	finalTeams,
	managers,
	players,
	playersRaffle,
	tabs: defaultScreenTabs,
};
