<template>
    <div class="AppMainScreen">
        <Navbar
            :me="me"
        />
        <Sidebar
            v-if="!isMobile"
            :me="me"
        />
        <div
            class="Container"
            :class="{ mobile: isMobile }"
        >
            <Transition
                name="main-router"
                mode="out-in"
            >
                <RouterView
                    :me="me"
                />
            </Transition>
        </div>
    </div>
</template>

<script>
import { Navbar, Sidebar } from '@/components'
import { mediaQueryMixin } from '@/mixins'

export default {
    name: 'AppMainScreen',
    components: {
        Navbar,
        Sidebar,
    },
    mixins: [mediaQueryMixin],
    data () {
        return {
            me: {},
        }
    },
    computed: {
        isMobile () {
            return this.$mq.w < this.$mq.lg.min
        },
    },
    beforeMount () {
        this.me = this.$store.getters.me
    },
}
</script>
<style lang="scss" scoped>

.AppMainScreen {
  width: 100%;
  min-height: 100vh;
  background: #F2F2F2;
  .Container {
    margin: 0 auto;
    width: calc(100% - 250px);
    height: calc(100% - 60px);
    margin-left: 250px;
    margin-top: 60px;
    padding: 40px;
    &::v-deep {
      .ScreenHeader {
        display: flex;
        .Icon {
          margin-right: 15px;
          cursor: pointer;
        }
      }
      h1 {
        margin-bottom: 30px;
      }
      .Container {
        background: white;
        border-radius: 10px;
        padding: 20px;
      }
    }
    &.mobile {
      width: 100%;
      margin-left: 0;
      padding: 20px;
    }
  }
}

.main-router-enter-active,
.main-router-leave-active {
    transition-duration: 0.5s;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
}

.main-router-enter {
    opacity: 0;
    transform: translate(0, -15px);
}

.main-router-leave-active {
    opacity: 0;
    transform: translate(0, -15px);
}
</style>
