import axios from 'axios'

export default {
    createUserUniform: async (body) => {
        const response = await axios.post('/user-uniforms', body)

        return response && response.data && response.data.response
    },

    deleteUserUniform: async (id) => {
        const response = await axios.delete('/user-uniforms/' + id)

        return response && response.data && response.data.response
    },

    getUserUniform: async (id) => {
        const response = await axios.get('/user-uniforms/' + id)

        return response && response.data && response.data.response
    },
}
