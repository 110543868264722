<template>
    <aside class="Sidebar">
        <div class="Container">
            <div
                v-for="({ key, label, icon, permissions }, index) in menu"
                :key="`navLink-${index}`"
                v-permissions="{permissions}"
                class="Link"
                :class="{ activeTab: key === activeTab || key === $route.name }"
                @click="handleClickOption(key)"
            >
                <Icon
                    :icon="icon"
                    :size="20"
                    color="primary"
                />
                <p>
                    {{ label }}
                </p>
            </div>
        </div>
    </aside>
</template>

<script>
import { menu } from '@/entities/menu'
import VueTypes from 'vue-types'

export default {
    name: 'Sidebar',
    props: {
        me: VueTypes.object,
    },
    data () {
        return {
            menu,
            activeTab: undefined,
        }
    },
    watch: {
        $route (to) {
            const { name } = to.params
            this.activeTab = name
        },
    },
    methods: {
        handleClickOption (key) {
            this.activeTab = key
            this.$router.push({
                name: key,
                params: {
                    id: this.me.id,
                    economySlug: 'pagos-de-jugadoras',
                    profileSlug: 'contacto',
                    organizationSlug: 'jugadoras',
                    attendanceSlug: 'entrenamientos-fisicos',
                    inventorySlug: 'materiales',
                    infoSlug: 'campos',
                },
            }).catch(() => {})
        },
    },
}
</script>

<style scoped lang="scss">

.Sidebar {
	position: fixed;
	height: 100%;
	width: 250px;
	background: white;
	box-shadow: 0px 4px 10px rgba(103, 94, 134, 0.1);
	z-index: 2;

	.Container {
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		padding-top: 90px;

		.Link {
			position: relative;
			display: flex;
			align-items: center;
			font-size: 12px;
			font-weight: $font-weight-bold;
			transition: all 350ms ease;
			padding: 20px 40px;
			cursor: pointer;

			.Icon {
				margin-right: 10px;
				transition: none;
			}

			p {
				color: $gray-dark;
				line-height: 12px;
				font-size: 18px;
				font-weight: 500;
			}
			&.activeTab {
				background: $primary;
				p, .Icon {
					color: $white !important;
				}
			}

			&:hover, &.router-link-active {
				background: $primary;
				// background-image: linear-gradient(45deg, $primary, $info);
				text-decoration: none;
				p, .Icon {
				color: $white !important;
				}
			}

		}
	}
}
</style>
