import axios from 'axios'

export default {
    getEventAttendance: async (eventId, params) => {
        const response = await axios.get('/line-up/attendance/' + eventId, { params })

        return response && response.data && response.data.response
    },

    getLineUp: async (eventId, params) => {
        const response = await axios.get('/line-up/' + eventId, { params })

        return response && response.data && response.data.response
    },

    createLineUp: async (body) => {
        const response = await axios.post('/line-up', body)

        return response && response.data && response.data.response
    },

    deleteLineUp: async (id) => {
        const response = await axios.delete('/line-up/' + id)

        return response && response.data && response.data.response
    },

    bulkCreateLineUp: async (list) => {
        const response = await axios.post('/line-up/bulk', list)

        return response && response.data && response.data.response
    },

    bulkDeleteLineUp: async (params) => {
        const response = await axios.delete('/line-up/bulk/' + params.eventId + '/' + params.number + '/' + params.name)

        return response && response.data && response.data.response
    },
}
