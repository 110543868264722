<template>
    <transition
        name="fade"
        :duration="250"
        :mode="mode"
    >
        <slot />
    </transition>
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'FadeTransition',
    props: {
        mode: VueTypes.string.def('out-in'),
    },
}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .25s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
