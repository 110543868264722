import axios from 'axios'

export default {
    createLocation: async (body, params) => {
        const response = await axios.post('/locations', body, { params })

        return response && response.data && response.data.response
    },

    updateLocation: async (id, body) => {
        const response = await axios.put('/locations/' + id, body)

        return response && response.data && response.data.response
    },

    deleteLocation: async (id) => {
        const response = await axios.delete('/locations/' + id)

        return response && response.data && response.data.response
    },

    getLocation: async (id, params) => {
        const response = await axios.get('/locations/' + id, { params })

        return response && response.data && response.data.response
    },

    listLocations: async (params) => {
        const response = await axios.get('/locations', { params })

        return response && response.data && response.data.response
    },
}
