<template>
	<div class="StatsScreen">
		<div class="ScreenHeader">
			<h1>Estadísticas</h1>
			<div class="Buttons">
				<Select name="Eventos" placeholder="Selecciona una liga" :options="events" :value="selectedEvent" @input="handleSelectEvent" />
				<PillSelectorInput v-model="statType" :options="statTypes" border="gray" />
			</div>
		</div>
		<div class="">
			<Transition name="main-router" mode="out-in">
				<Stats v-if="!!currentEvent" :statType="statType" :event="currentEvent" />
			</Transition>
		</div>
	</div>
</template>

<script>
import _ from "lodash";
import VueTypes from "vue-types";
import statsIntegration from "@/integration/statsIntegration";
import { statTypes, kickingStatType } from "@/entities/stats";
import { Stats } from "@/components";

// COVERT CSV TO JSON
// https://www.convertcsv.com/csv-to-json.htm

export default {
	name: "StatsScreen",
	components: {
		Stats,
	},
	props: {
		me: VueTypes.object,
	},
	data() {
		return {
			statTypes,
			statType: kickingStatType,
			selectedEvent: "",
			events: [],
		};
	},
	computed: {
		currentEvent() {
			return this.events.find((e) => e.key === this.selectedEvent);
		},
	},
	async mounted() {
		this.getEvents()
	},
	methods: {
		async handleSelectEvent(eventId) {
			const event = this.events.find(e => e.key === eventId)
			const hasStats = event && event.kicking

			if (!hasStats) {
				const stats = await statsIntegration.getStatsForEvent(event.label)
				const index = this.events.findIndex(e => e.key === eventId)
				this.events[index] = {...this.events[index], ...stats}
			}

			this.selectedEvent = eventId;
		},
		async getEvents () {
            const events = await statsIntegration.getEvents()
			const firstEventStats = await statsIntegration.getStatsForEvent(events[0].label)
			events[0] = {...events[0], ...firstEventStats}
			this.events = events
			this.selectedEvent = events[0].key
        },
	},
};
</script>
<style lang="scss" scoped>
.StatsScreen {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}
.ScreenHeader {
	width: 100%;
	display: flex;
	flex-direction: column;
	.Buttons {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 0.5rem;
		@media screen and (max-width: $sm) {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
		}
	}
	.PillSelectorInput {
		width: fit-content !important;
	}
	.Select {
		min-width: fit-content !important;
		width: 200px;
	}
}
</style>
