import moment from 'moment'
import _ from 'lodash'
import utils from './utils'
import constants from '../entities/constants'
import userIntegration from '../integration/userIntegration'
import eventIntegration from '../integration/eventIntegration'
import uniformIntegration from '../integration/uniformIntegration'
import userContact from '../api/userContact'

export default {
    async getUserInitialPaymentInformation (userId) {
        const dateInfo = utils.getInitialDateInfo({})
        const from = moment.utc(dateInfo.selectedDate).startOf('year').format()
        const to = moment.utc(dateInfo.selectedDate).endOf('year').format()
        const response = {}

        response.balance = await userIntegration.getUserBalanceInformation(userId)
        response.payments = await userIntegration.getUserPaymentInformation(userId, { from, to })

        return response
    },

    getUserPaymentInformation (dateInfo, userId) {
        const from = moment.utc(dateInfo.selectedDate).startOf('year').format()
        const to = moment.utc(dateInfo.selectedDate).endOf('year').format()

        return userIntegration.getUserPaymentInformation(userId, { from, to })
    },

    getAttendancePercentage (events, attendace) {
        const eventList = _.chain(events)
            .filter(item => moment.utc().endOf('day').isAfter(moment.utc(item.date)))
            .map(item => item.id)
            .value()
        const attendaceNumber = _.filter(attendace, item => item.state === constants.EVENT_STATE.ACCEPTED &&
            eventList.indexOf(item.eventId) !== -1).length
        return eventList.length ? ((attendaceNumber * 100) / eventList.length) : 0
    },

    getEvents (dateInfo, trainingType, trainingState) {
        const from = moment.utc(dateInfo.selectedDate).startOf('month').format()
        const to = moment.utc(dateInfo.selectedDate).endOf('month').format()

        return eventIntegration.getEvents({ from,
            to,
            type: trainingType,
            state: trainingState })
    },

    async getUserAttendance (userId, events) {
        const response = {
            data: {},
            percentage: 0,
            percentageValue: 0,
        }

        if (events && events.length) {
            const userEvents = await userIntegration.getUserEvents(userId,
                { eventId: _.map(events, item => item.id) })

            for (const ev of userEvents) {
                response.data[ev.eventId] = ev
            }
            if (events.length > userEvents.length) {
                const missingEvents = _.difference(
                    _.map(events, e => e.id),
                    _.chain(response.data).keys().map(e => parseInt(e)).value(),
                )

                for (const eventId of missingEvents) {
                    const ev = events.find(e => e.id === eventId)
                    response.data[eventId] = {
                        state: ev.isFutureDay ? constants.USER_EVENT_STATE.PENDING
                            : constants.USER_EVENT_STATE.NOT_GOING,
                        isDummyEvent: true,
                        eventId,
                    }
                }
            }

            const percentage = this.getAttendancePercentage(events, _.values(response.data))
            response.percentage = Math.round(percentage)
            response.percentageValue = percentage / 100
        }
        return response
    },

    async getAllEvents (dateInfo) {
        const events = {
            [constants.EVENT_TYPE.PHYSICAL_TRAINING]: {},
            [constants.EVENT_TYPE.THEORIC_TRAINING]: {},
            [constants.EVENT_TYPE.GAME]: {},
            [constants.EVENT_TYPE.ADDITIONAL_ACTIVITY]: {},
            [constants.EVENT_TYPE.COMITE_MEETING]: {},
        }

        for (const type in events) {
            events[type] = await this.getEvents(dateInfo, type, constants.EVENT_STATE.ACCEPTED)
            if (!events[type].length) delete events[type]
        }

        return events
    },

    getAllInitialEvents () {
        const dateInfo = utils.getInitialDateInfo({})

        return this.getAllEvents(dateInfo)
    },

    async getAllAttendance (userId, events) {
        if (_.isEmpty(events)) return {}

        const attendance = {
            [constants.EVENT_TYPE.PHYSICAL_TRAINING]: {},
            [constants.EVENT_TYPE.THEORIC_TRAINING]: {},
            [constants.EVENT_TYPE.GAME]: {},
            [constants.EVENT_TYPE.ADDITIONAL_ACTIVITY]: {},
            [constants.EVENT_TYPE.COMITE_MEETING]: {},
        }

        for (const type in attendance) {
            attendance[type] = await this.getUserAttendance(userId, events[type])
        }

        return attendance
    },

    async updateUniforms (uniforms, userId, permission) {
        const uniformsDB = await uniformIntegration.listUniforms()
        const userUniforms = await userIntegration.getUserUniform(userId)

        if (permission === constants.USER_ROLE.ADMIN ||
            permission === constants.USER_ROLE.COMITE) {
            for (const key in uniforms) {
                if (!uniforms[key].isEnable && uniforms[key].id) {
                    await userIntegration.deleteUserUniform(uniforms[key].userUniformId)
                } else if (userUniforms[key]) {
                    userUniforms[key].isEnable = true
                    if (!this.isSameObject(userUniforms[key], uniforms[key])) {
                        await userIntegration.updateUserUniform(uniforms[key].userUniformId, userId, uniforms[key])
                    }
                } else if (Object.keys(uniforms[key]).length !== 0 && uniforms[key].isEnable) {
                    const uniformId = uniformsDB[key].id
                    await userIntegration.createUserUniform(uniforms[key], uniformId, userId)
                }
            }
        }

        return userIntegration.getUserUniform(userId)
    },

    isSameObject (obj1, obj2) {
        let isSameObject = true
        for (const key in obj1) {
            if (!obj2[key] || obj1[key] !== obj2[key]) {
                isSameObject = false
                break
            }
        }

        return isSameObject
    },

    async updatePayments (paymentsInfo, userId, permission) {
        const currentPaymentInformation = await this.getUserPaymentInformation(paymentsInfo.dateInfo, userId)

        if (permission === constants.USER_ROLE.ADMIN ||
            permission === constants.USER_ROLE.COMITE) {
            for (const key in paymentsInfo.payments) {
                if (paymentsInfo.payments[key].state !== currentPaymentInformation[key].state) {
                    if (currentPaymentInformation[key].id) {
                        if (paymentsInfo.payments[key].state === constants.PAYMENT_STATE.OWES) {
                            await userIntegration.deleteUserPayment(paymentsInfo.payments[key].id)
                        } else {
                            await userIntegration.updateUserPayment(paymentsInfo.payments[key].id, paymentsInfo.payments[key])
                        }
                    } else {
                        if (paymentsInfo.payments[key].state !== constants.PAYMENT_STATE.OWES) {
                            const userPaymentData = {
                                month: moment.utc(key + '-' + paymentsInfo.dateInfo.selectedYear, 'MMMM-YYYY')
                                    .startOf('month').format(),
                                state: paymentsInfo.payments[key].state,
                            }
                            await userIntegration.createUserPayment(userPaymentData, userId)
                        }
                    }
                }
            }
        }

        return this.getUserInitialPaymentInformation(userId)
    },

    createContact (contact, userId) {
        const userContactToCreate = contact
        userContactToCreate.userId = userId
        return userContact.createUserContact(userContactToCreate)
    },

    deleteContact (userContactId) {
        return userContact.deleteUserContact(userContactId)
    },
}
