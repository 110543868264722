export const colors = {
	primary: "#3edad8",
	secondary: "#EC466E",
	tertiary: "#FBE05F",

	salmon: "#ec6056",
	purple: "#CC7EFE",
	orange: "#F28C3E",
	blue: "#2E92AD",

	white: "#FFFFFF",
	black: "#000000",

	gray01: "#5f5f70",
	gray02: "#9191a3",
	gray03: "#aeaec1",
	gray04: "#cfcfdc",
	gray05: "#e6e6f2",
	gray06: "#f5f5fc",
	gray07: "#f5f5f5",

	grayLight: "#f2f2f2",
	grayMedium: "#c8c8c8",
	grayDark: "#707070",
	grayDarker: "#333",

	// dark: '#5f5f70',
	// grayLight: '#f5f5fc',
	// grayMedium: '#9191a3',
	// grayDark: '#5f5f70',

	yellow: "#ebc85c",
	red: "#bf404f",

	// States
	// success: '#239945',
	// warning: '#EFC13F',
	// error: '#dc3545',

	success: "#A6D04D",
	warning: "#FBE05F",
	error: "#EC466E",
	danger: "#dc3545",
	info: "#17a2b8",
};

export default {
	brand: colors.brand,
	brandAlt: colors.brandAlt,

	primary: colors.primary,
	primaryDark: colors.primaryDark,
	primaryLight: colors.primaryLight,
	secondary: colors.secondary,
	white: colors.white,
	black: colors.black,
	red: colors.red,
	grayMedium: colors.gray,
	grayDark: colors.grayDark,
	grayLight: colors.grayLight,
	grayBrown: colors.grayBrown,
};
