var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Avatar",style:({
		'max-height': `${_vm.size.height}px`,
		'max-width': `${_vm.size.width}px`,
		width: `${_vm.size.width}px`,
		heigth: `${_vm.size.width}px`,
		'min-height': `${_vm.size.height}px`,
		'min-width': `${_vm.size.width}px`,
		overflow: _vm.rounded ? 'hidden' : 'inherit'
	})},[(_vm.url)?_c('img',{staticClass:"Image",style:({ backgroundColor: _vm.url ? '' : 'gray' }),attrs:{"src":_vm.url,"alt":"logo"},on:{"click":_vm.handleClick}}):_c('div',{staticClass:"Empty",style:({ width: `${_vm.size.width}px`, height: `${_vm.size.height}px` })},[_c('Icon',{attrs:{"icon":"user","size":_vm.iconSize}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }