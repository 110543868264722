<template>
    <div class="InventoryUniforms">
        <PillSelectorInput
            v-model="activeUniformStatus"
            :options="uniformStatus"
            border="gray"
        />
        <div class="ListHeader">
            <p class="ListItemValue Number">
                #
            </p>
            <p
                v-for="(teamUniform, key) in teamUniforms"
                :key="`header-${key}`"
                class="ListItemValue"
            >
                {{ uniforms.find(u => u.key === key).label }}
            </p>
        </div>
        <div
            v-for="number in uniformNumbers"
            :key="`uniformNumbers-${number}`"
            class="ListRow"
        >
            <div class="ListItem Number">
                <p>
                    {{ number }}
                </p>
            </div>
            <div
                v-for="(teamUniform, index) in teamUniforms"
                :key="`teamUniforms-${index}`"
                class="ListItem"
            >
                {{ getUser((teamUniform.find(t => t.number === number) || {}).userId) }}
                <!-- <span>-</span> -->
                <div
                    v-if="(teamUniform.find(t => t.number === number) || {}).size"
                    class="Size"
                >
                    {{ (teamUniform.find(t => t.number === number) || {}).size }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { uniformNumbers } from '@/entities/inventory'
import { uniforms } from '@/entities/uniforms'
import VueTypes from 'vue-types'
import user from '@/api/user'

export default {
    name: 'InventoryUniforms',
    props: {
        teamUniforms: VueTypes.object,
    },
    data () {
        return {
            activeMode: 'read',
            isLoading: false,
            uniformNumbers,
            uniforms,
            users: [],
            activeUniformStatus: 'active',
            uniformStatus: [
                {
                    key: 'all',
                    label: 'Todos',
                },
                {
                    key: 'active',
                    label: 'Activos',
                },
                {
                    key: 'available',
                    label: 'Disponibles',
                },
                {
                    key: 'out',
                    label: 'Inactivos',
                },
            ],
        }
    },
    mounted () {
        this.fetchUsers()
    },
    methods: {
        async fetchUsers () {
            const userList = await user.listUser()
            this.users = userList.items
        },
        getUser (userId) {
            return (this.users.find(u => u.id === userId) || {}).shortName
        },
    },
}
</script>
<style lang="scss" scoped>
.InventoryUniforms {
	margin: 10px 30px;
	display: flex;
	flex-direction: column;

	.PillSelectorInput {
		margin-bottom: 50px;
		width: fit-content;
		// align-self: flex-end;
	}
	.ListHeader {
		display: flex;
		margin-bottom: 10px;
		text-align: center;
		padding-bottom: 15px;
		border-bottom: 1px solid $gray-light;
		.ListItemValue {
			font-weight: bold;
			color: $gray-dark;
			font-size: 18px;
			flex: 1;
			& + .ListItemValue {
				margin-left: 15px;
			}
			&.Number { max-width: 60px; }
		}
	}
	.ListRow {
		display: flex;
		.ListItem {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: $gray-07;
			height: 50px;
			padding: 0 20px;
			border-radius: 1rem;
			margin-top: 15px;
			flex: 1;
			flex-direction: row;
			@media screen and (max-width: $sm) {
				height: auto;
				text-align: center;
				padding: 10px;
			}
			& + .ListItem {
				margin-left: 15px;
			}
			&.Number {
				max-width: 50px;
				display: flex;
				justify-content: center;
				background: $primary;
				box-shadow:  0 2px 5px 0 rgba($black, 0.1);
				p { color: white }
			}
			.Size {
				padding: 10px;
				background: rgba(white, .5);
				min-width: 40px;
				text-align: center;
				width: fit-content;
				border-radius: 5px;
				margin-left: 10px;
			}
		}
	}
}
</style>
