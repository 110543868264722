import axios from 'axios'

export default {
    listPayments: async (params) => {
        const response = await axios.get('/payments/', { params })

        return response && response.data && response.data.response
    },

    listBalances: async (params) => {
        const response = await axios.get('/payments/balances/', { params })

        return response && response.data && response.data.response
    },
}
