<template>
	<div class="MadMixConditionsScreen">
		<div class="ImageSection" :style="{ backgroundImage: `url(${require('@/assets/imgs/MadMix/fondo4.png')})` }">
			<div class="Title">
				<img :src="require(`@/assets/imgs/MadMix/LOGO2.png`)" class="Torneo MadMix de kickingball" />
			</div>
		</div>

		<div class="TeamHeader ScreenHeader">
			<p class="TeamName">MadMix 2024</p>
		</div>
		<div class="TeamContent">
			<p><b>Fecha del torneo</b>: 20 y 21 de enero de 2024</p>
			<p><b>Fecha del sorteo</b>: 13 de enero de 2024 a las 20:30h</p>
			<p><b>Equipos participantes</b>: Espartanas, Vikingas, Águilas, Panteras, Tropicales, Goodies, Panas, Murallas,
				Valkirias, Amazonas, Club Barcelona, Gladiadoras y Semillitas.</p>
			<p><b>Lugar</b>: Jarama.</p>
			<p><b>Horario</b>: 8:00 - 17:00</p>
			<p><b>Duración de los partidos</b>: 7 innings / 1:20h. La final de 9 innings / 2h</p>
			<p><b>Arbitraje</b>: Por cada partido, se contará con un árbitro y un anotador.</p>
			<p><b>Inscripción</b>: 7€ por jugadora, abonado a nombre de "Club Deportivo Elemental Espartanas" en la cuenta
				ES9300730100560506429285.</p>
			<p>El dinero de la inscripción será destinado a arbitraje, premiación, refrigerio y otras sorpresas.</p>
		</div>

		<!-- <div class="TeamHeader">
			<p class="TeamName">Roster</p>
		</div>
		<div class="TeamContent">
			<p>Cada equipo deberá registrar a sus jugadoras en la <a class="bold"
					href="https://drive.google.com/file/d/1cUpay29frb4ktxepuqTDsz2H6K86DRYr/view?usp=share_link"
					target="_blank">hoja de roster</a>; indicando el nombre, el apellido, dos posiciones y disponibilidad.
			</p>

			<p>PS: La hoja de roster tiene 16 líneas, en caso de necesitar inscribir a más de 16 jugadoras, deberán rellenar
				2 hojas.</p>
			<p>En caso de que una jugadora sea menor de 15 años, deberá anexar una autorización del representante.</p>
			<p>Todos los equipos deberán registrar, como mínimo, un manager.</p>
			<p>¡Animamos a los técnicos a apuntarse al torneo!. Como bien sabemos, la ausencia de un manager puede
				condenar el performance de un equipo.</p>
		</div> -->

		<!-- <div class="TeamHeader">
			<p class="TeamName">Pago</p>
		</div>
		<div class="TeamContent">
			<p>El costo de inscripción, de 7€ por jugadora, deberá ser abonado a la cuenta de Espartanas antes del 11 de
				diciembre.</p>
			<p>A nombre de "Club Deportivo Elemental Espartanas" en la cuenta ES9300730100560506429285.</p>
		</div> -->

		<!-- <div class="TeamHeader">
			<p class="TeamName">Información relevante</p>
		</div>
		<div class="TeamContent">
			<p>Cada equipo jugará 2 partidos de clasificación, uno como homeclub y
				otro como visitante.</p>
			<p>El sábado serán homeclub: Matadero, Retiro, Debod y Sol</p>
			<p>El domingo serán homeclub: Bernabeu, Cibeles y El Rastro</p>
			<p>En el partido de la final, el homeclub será sorteado</p>
			<br />
			<p>
				Sólo el equipo Homeclub usará petos. Hay 12 petos disponibles, que se deberán asignar a las jugadoras
				titulares. Al hacer cambios, deberán cambiar los petos entre las jugadoras y avisar al anotador sobre el
				cambio (importante, de cara a las estadísticas).
			</p>
			<p>
				El equipo que no lleve petos, deberá apuntar en lineup los números de las camisetas originales de equipo. En
				caso de tener números repetidos, se apuntará el número + la inicial del equipo (ej: 16E).
			</p>
			<ul class="ItemsList">
				<li>Águilas: A</li>
				<li>Amazonas: AM</li>
				<li>Barcelona: B</li>
				<li>Espartanas: E</li>
				<li>Gladiadoras: G</li>
				<li>Goodies: GO</li>
				<li>Las Panas: L</li>
				<li>Murallas: M</li>
				<li>Panteras: P</li>
				<li>Semillitas: S</li>
				<li>Tropicales: T</li>
				<li>Valkirias: V</li>
				<li>Vikingas: VI</li>
			</ul>
			<br />
			<p>
				Los técnicos de cada <a href="#equipo_madmix">equipo del MadMix</a> deben asegurarse de que todas sus
				jugadoras participen por lo menos 2
				innings (por partido) en una de las posiciones registradas. En caso de no ser así, el equipo será penalizado
				con -1
				carrera al momento de la clasificación, por cada jugadora que no participe.
				Se permite el uso de <u>sólo una pateadora agregada por partido</u>, y esta persona no podrá ser pateadora
				agregada
				en otro juego.
			</p>
		</div> -->

		<div class="TeamHeader">
			<p class="TeamName">Uniformes</p>
		</div>
		<div class="TeamContent">
			<p>Todas las jugadoras deberán ir con el uniforme correspondiente de su <a href="#original">equipo original</a>.
			</p>
			<p>En caso de que un equipo tenga más de una equipación, todas las jugadoras deberán usar la misma en un mismo
				día.</p>
			<p>Los técnicos deben ir con una vestimenta apropiada para un evento deportivo.</p>
			<p>
				Sólo el equipo Homeclub usará petos. Hay 12 petos disponibles, que se deberán asignar a las jugadoras
				titulares. Al hacer cambios, deberán cambiar los petos entre las jugadoras y avisar al anotador sobre el
				cambio (importante, de cara a las estadísticas).
			</p>
			<p>
				El equipo que no lleve petos, deberá apuntar en lineup los números de las camisetas originales de equipo. En
				caso de tener números repetidos, se apuntará el número + la inicial del equipo (ej: 16E).
			</p>
			<ul class="ItemsList">
				<li>Águilas: A</li>
				<li>Amazonas: AM</li>
				<li>Barcelona: B</li>
				<li>Espartanas: E</li>
				<li>Gladiadoras: G</li>
				<li>Goodies: GO</li>
				<li>Las Panas: L</li>
				<li>Murallas: M</li>
				<li>Panteras: P</li>
				<li>Semillitas: S</li>
				<li>Tropicales: T</li>
				<li>Valkirias: V</li>
				<li>Vikingas: VI</li>
			</ul>

		</div>

		<!-- <div class="TeamHeader">
			<p class="TeamName">Sorteo</p>
		</div>
		<div class="TeamContent">
			<p>
				<a id="equipo_madmix">Los equipos del MadMix</a> se formarán mediante el uso de una app web. Ésta creará 7
				equipos, a los que asignará
				jugadoras de los diferentes <a href="#original">equipos originales</a>, tomando en cuenta la posición de
				juego y su disponibilidad.
			</p>

		</div> -->

		<div class="TeamHeader">
			<p class="TeamName">Formato</p>
		</div>
		<div class="TeamContent">
			<p>El formato del torneo será de 7 equipos. Cada equipo jugará 2 partidos de clasificación, uno como homeclub y
				otro como visitante.</p>
			<p>El sábado serán homeclub: Matadero, Retiro, Debod y Sol</p>
			<p>El domingo serán homeclub: Bernabeu, Cibeles y El Rastro</p>
			<p>En el partido de la final, el homeclub será sorteado.</p>


			<!-- <p>El formato del torneo se confirmará una vez hayan entregado los rosters todos los equipos.</p>
			<p>PS: Cuantas más jugadoras, mayor probabilidad de llevar a cabo un formato chuli! :)</p>
			<p>Tentativamente, se usará un formato de 8 equipos, en el que todos jueguen al menos 2 partidos (uno cada día).
			</p>
			<p>Todos los equipos serán homeclub en un partido, y visitante en el otro. En el partido de la final, el
				homeclub será sorteado.</p> -->
			<div class="TablesWrapper">
				<div class="TableWrapper">
					<div class="Table">
						<div class="ColumnWrapper Day">
							<div class="Header">Registro</div>
							<div class="Content"><b>20/01</b></div>
							<div class="Footer">Competencias</div>
						</div>
						<div class="ColumnWrapper Time">
							<div>8:00</div>
							<div>9:00-10:20</div>
							<div>10:40-12:00</div>
							<div>12:20-13:40</div>
							<div>14:00-15:20</div>
							<div>15:40-17:00</div>
						</div>
						<div class="ColumnWrapper Game">
							<div class="empty">-</div>
							<div class="one">Matadero</div>
							<div class="three">Retiro</div>
							<div class="five">Debod</div>
							<div class="seven">Sol</div>
							<div class="empty">-</div>
						</div>
						<div class="ColumnWrapper Game">
							<div class="empty">-</div>
							<div class="two">El Rastro</div>
							<div class="four">Sol</div>
							<div class="six">Bernabeu</div>
							<div class="eight">Cibeles</div>
							<div class="empty">-</div>
						</div>
					</div>
					<p><a href="#competencia_habilidades">*Competencias o juegos de habilidades</a></p>
				</div>
				<div class="Table">
					<div class="ColumnWrapper Day">
						<div class="Header">Registro</div>
						<div class="Content"><b>21/01</b></div>
						<div class="Footer">Premiación</div>
					</div>
					<div class="ColumnWrapper Time">
						<div>8:00</div>
						<div>9:00-10:20</div>
						<div>10:40-12:00</div>
						<div>12:20-13:40</div>
						<div>14:00-16:00</div>
						<div>16:30</div>
						<!-- <div>14:00-15:40</div>
						<div>17:10</div> -->
					</div>
					<div class="ColumnWrapper Game">
						<div class="empty">-</div>
						<div class="eight">Bernabeu</div>
						<div class="two">Cibeles</div>
						<div class="four">El Rastro</div>
						<div class="Final">F1</div>
						<div class="empty">-</div>
					</div>
					<div class="ColumnWrapper Game">
						<div class="empty">-</div>
						<div class="three">Retiro</div>
						<div class="five">Matadero</div>
						<div class="seven ">Debod</div>
						<div class="Final">F2</div>
						<div class="empty">-</div>
					</div>
				</div>
			</div>

		</div>
		<div class="TeamHeader">
			<p class="TeamName">Clasificación</p>
		</div>
		<div class="TeamContent">
			<p>Por cada partido ganado, el equipo se llevará 3 puntos:</p>
			<p>En caso de que un partido acabe en empate, se desempatará de la siguiente manera:
			<ol>
				<li>Se jugará UN extra inning con bases llenas</li>
				<li>Mayor cantidad de hits</li>
			</ol>
			</p>
			<p>Adicionalmente, se hará una competencia de habilidades en la que podrá participar una persona por
				<a id="equipo_madmix">equipo del MadMix</a>. Por
				cada competencia ganada, el equipo sumará 1 punto para la clasificación.
			</p>

			<p>La clasificación a la final se determinará por:
			<ol>
				<li>Puntos</li>
				<li>Diferencia de carreras</li>
				<li>Mayor cantidad de hits</li>
				<li>Menor cantidad de hits recibidos</li>
				<li>Mayor número de carreras anotadas</li>
			</ol>
			</p>
		</div>
		<div class="TeamHeader">
			<p class="TeamName">Juego</p>
		</div>
		<div class="TeamContent">
			<p>
				Al llegar al campo, tanto jugadoras como managers, deben acudir a la mesa de registro para notificar que han
				llegado.
			</p>
			<p>
				Los técnicos de cada <a href="#equipo_madmix">equipo del MadMix</a> deben asegurarse de que todas sus
				jugadoras participen por lo menos 2
				innings (por partido) en una de las posiciones registradas. En caso de no ser así, el equipo será penalizado
				con -1
				carrera al momento de la clasificación, por cada jugadora que no participe.
				Se permite el uso de sólo una pateadora agregada por partido, y esta persona no podrá ser pateadora agregada
				en otro juego.
			</p>
			<p>En esta edición, habrán suficientes jugadoras por equipo, por lo que cada equipo deberá participar con sus
				jugadoras asignadas (no se usará la repesca de refuerzos). En el caso remoto de que algún equipo esté
				incompleto para jugar, será reforzado por: Armary Buergo, Kira Petit o Angie Rivero.</p>
			<!-- <p>En esta edición, no se usará la repesca de refuerzos, cada equipo deberá participar con sus jugadoras
				asignadas.</p> -->
			<!-- <p>Espartanas tendrá jugadoras en reserva, para poner en juego en caso de que un equipo esté incompleto, y
				podrán jugar sólo en caso de ser necesario.</p> -->
			<p>Espartanas no cuenta con balón oficial, motivo por el cual, el torneo se jugará con un balón talla 4 del
				decathlon.</p>
			<p>PS: En caso de que algún equipo, que tenga un balón oficial, esté dispuesto a prestarlo para el
				torneo, bienvenido sea!</p>
		</div>

		<div class="TeamHeader">
			<p class="TeamName">Premiación</p>
		</div>
		<div class="TeamContent">
			<p>Se premiará a todas las jugadoras del equipo campeón. </p>
			<p>Se dará un premio especial a la jugadora más destacada por cada posición, a la mejor pateadora y al mejor
				técnico.</p>
			<p>* Para que una jugadora tenga más posibilidades de ganar un premio individual, deberá jugar mas tiempo en esa
				posición.</p>
		</div>


		<div class="TeamHeader">
			<p class="TeamName">Glosario</p>
		</div>
		<div class="TeamContent">
			<p><b id="original">• Equipo original</b>: equipo con el que las jugadoras hacen vida en el kickigball
				Madrid / Barcelona.</p>
			<p><b id="equipo_madmix">• Equipo MadMix</b>: equipo mezclado, con el que las jugadoras participarán en el
				MadMix.</p>
		</div>

		<div class="TeamHeader">
			<p class="TeamName" id="competencia_habilidades">Competencias de habilidades</p>
		</div>
		<div class="TeamContent">
			<p>El día sábado en el registro, se le entregará al manager una hoja para apuntar a las jugadoras que
				representarán al equipo en las competencias de habilidades. Los equipos deberán ponerse de acuerdo y
				postular a una jugadora para cada competencia. Una misma jugadora no
				deberá participar en más de una.</p>
			<p><b>• Velocidad "21 metros planos"</b>: una jugadora de cada equipo deberá correr desde home
				hasta
				1ra
				base. Empezará pisando con un pie el homeplate, y a la cuenta de 1,2,3 arrancará a correr hasta 1ra base,
				pudiendo continuar la carrera tras pisar la almohadilla. Ganará esta competencia la corredora que consiga el
				menor tiempo en su carrera.</p>
			<p><b>• Velocidad "42 metros curvos"</b>: una jugadora de cada equipo deberá correr desde
				home
				hasta
				2da base. Empezará pisando con un pie el homeplate, y a la cuenta de 1,2,3 arrancará a correr hasta 2da
				base, teniendo que frenar sin despegar el pie de la almohadilla. La corredora que no logre frenar en la
				base o levante el pie de apoyo, quedará descalificada. Ganará esta competencia la corredora que consiga
				el menor tiempo en su carrera.
			</p>
			<p><b>• Defensa rápida</b>: A la cuenta de 1,2,3 y partiendo desde los límites del cajón de
				la receptora, una jugadora de cada equipo deberá buscar el balón (que estará ubicado sobre el home) y
				lanzarlo a la 3ra base. Para considerar válido el lanzamiento, la pelota debe impactar la zona delimitada
				como "buena". Ganará esta competencia la defensora que consiga
				el menor tiempo en su fildeo y lanzamiento. La corredora podrá acercarse a la base todo lo que quiera, sin
				límites para lanzar.
				<br />
				<i><u>Nota</u>: No es una competencia de catcher. Usaremos el cajón de la receptora para aprovechar los
					límites
					delimitados. El ángulo de lanzamiento de home a 3ra es muy similar a muchos otros (3ra a 1ra, SF a 1ra,
					CF a 1ra, etc).</i>
			</p>
			<p><b>• Defensa efectiva</b>: Partiendo desde los límites del cajón de la receptora, una jugadora de cada equipo
				deberá buscar el rebote de un balón que será soltado sobre el
				home, y hacer un lanzamiento a 2da base. Para considerar válido el turno, la defensora no debe pasarse
				del
				círculo del ilegal, la pelota debe impactar la zona delimitada como "buena", y debe conseguir hacerlo en un
				tiempo inferior
				a 5 segundos. Cada jugadora dispondrá de 3 lanzamientos, y ganará esta competencia la defensora que consiga
				hacer la mayor cantidad de tiros efectivos. En caso de empate, harán lanzamientos adicionales a muerte
				súbita.
				<br />
				<i><u>Nota</u>: No es una competencia de catcher. Usaremos el cajón de la receptora para aprovechar los
					límites
					delimitados. Es un lanzamiento en línea recta.</i>
			</p>
			<p><b>• Homerun</b>: Con un mismo pitcheo, una jugadora de cada equipo deberá patear el balón de aire lo más
				lejos posible. Cada pateadora dispondrá de 3 intentos, y ganará esta competencia la que consiga que su balón
				caiga más lejos. En caso de empate, harán pateos adicionales a muerte
				súbita.
			</p>
			<p><b>• Pitcheo</b>: Una lanzadora de cada equipo deberá impactar con sus pitcheos un objeto que estará ubicado
				sobre el home. Cada pitcher dispondrá de 6 lanzamientos, 2 de cada tipo (recta, slider y curva). Para
				considerar válida una curva o un slider, esta deberá superar los límites
				mínimos de trayectoria dibujados en el terreno. Ganará esta competencia la pitcher que consiga impactar la
				mayor cantidad de veces con lanzamientos válidos. En caso de empate, harán lanzamientos adicionales en ese
				orden y a muerte súbita.
			</p>
			<!-- <p><b>• Pitcheo</b>: Una lanzadora de cada equipo deberá impactar con sus pitcheos un objeto que estará ubicado
				sobre el home. Cada pitcher dispondrá de 6 lanzamientos, 2 de cada tipo: 1. debe ser recta e impactar la
				esquina izquierda del home, 2. debe ser slider e impactar el medio del home, y 3. debe ser curva e impactar
				la esquina derecha del home. Para considerar válida una curva o un slider, esta deberá superar los límites
				mínimos de trayectoria dibujados en el terreno. Ganará esta competencia la pitcher que consiga impactar la
				mayor cantidad de veces con lanzamientos válidos. En caso de empate, harán lanzamientos adicionales (en ese
				orden: recta, slider, curva) a muerte
				súbita.
			</p> -->
		</div>
	</div>
</template>

<script>
import { mediaQueryMixin } from "@/mixins";

export default {
	name: "MadMixConditionsScreen",
	mixins: [mediaQueryMixin],
};
</script>

<style lang="scss" scoped>
.MadMixConditionsScreen {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: $gray-01;
	padding-bottom: 4rem;
	background: white;
}

.ImageSection {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	background-size: cover;
	background-color: white;
	background-repeat: no-repeat;
	background-position-x: center;
	background-position-y: center;
	height: auto;
	min-height: auto;

	@media screen and (max-width: $sm) {
		// height: 6rem;
		// min-height: 22rem;
		height: auto;
		min-height: auto;
	}
}

.Title {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 6rem;

	@media screen and (max-width: $sm) {
		margin: 100px 0 50px 0;
	}

	img {
		width: auto;
		height: 200px;
	}
}

.TeamHeader {
	margin-bottom: 1rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid $gray-01;
	display: flex;
	width: 90%;

	@media screen and (min-width: $lg) {
		width: 70%;
	}

	@media screen and (min-width: $md) {
		flex-direction: row;
		justify-content: space-between;
	}

	.TeamName {
		font-size: 18px;
		font-weight: bold;
	}
}

.ScreenHeader .TeamName {
	font-size: 28px;
	font-weight: bolder;
}

.TeamContent {
	width: 90%;

	@media screen and (min-width: $lg) {
		width: 70%;
	}

	margin-bottom: 2rem;

	p {
		font-size: 12px;
		line-height: 18px;
		margin-bottom: 12px;

		b {
			font-size: 14px;
		}

		a {
			color: #333;
			text-decoration: underline;

		}

		.bold {
			font-weight: bold;
		}
	}

	.ItemsList {
		display: flex;
		flex-wrap: wrap;
		color: #333;
		gap: 10px;

		li {
			min-width: 100px;
			font-size: 12px;
			line-height: 16px;
		}
	}
}

.TablesWrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	gap: 1rem;

	.TableWrapper {
		font-size: 12px;
		color: #333;

		p {
			margin-top: 8px;
		}
	}
}

.Table {
	font-size: 12px;
	display: flex;
	width: fit-content;
	border: 1px solid rgba(#333, .6);

	.ColumnWrapper {
		display: flex;
		flex-direction: column;
		text-align: center;

		&+.ColumnWrapper {
			div {
				border-left: 1px solid rgba(#333, .6);
			}

			.empty {
				border-left: none;
			}
		}

		.Header {
			border-bottom: 1px solid rgba(#333, .3);
		}

		.Footer {
			border-top: 1px solid rgba(#333, .3);
			max-height: fit-content;
			font-size: 12px;

			@media screen and (max-width: $sm) {
				font-size: 10px
			}
		}

		.Content {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			width: 100%;
		}

		div {
			padding: 12px;

			&:last-child {
				flex: 1
			}
		}

		&.Day {
			min-width: 80px;

			@media screen and (min-width: $sm) {
				min-width: 110px;
			}

			div {
				padding: 12px 8px;
			}
		}

		&.Time {
			min-width: 80px;

			@media screen and (min-width: $sm) {
				min-width: 120px;
			}

			div+div {
				border-top: 1px solid rgba(#333, .3);
			}
		}


		&.Game {
			min-width: 50px;
			font-weight: bold;

			@media screen and (min-width: $sm) {
				min-width: 60px;
			}


			div+div {
				border-top: 1px solid rgba(#333, .3);
			}

			.empty {
				color: white
			}

			.one {
				background: #51B28A
			}

			.two {
				background: #333;
				color: white;
			}

			.three {
				background: rgba(#51B28A, .7)
			}

			.four {
				background: rgba(#333, .8);
				color: white;
			}

			.five {
				background: rgba(#51B28A, .4)
			}

			.six {
				background: rgba(#333, .5);
				color: white;
			}

			.seven {
				background: rgba(#51B28A, .1);
			}

			.eight {
				background: rgba(#333, .3);
				color: white;
			}

			.Final {
				background: rgba(#333, .1);

			}
		}
	}
}
</style>
