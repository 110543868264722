<template>
    <div class="Footer">
        <div class="Wrapper">
            <a
                v-for="({ name, icon, link }, index) in contactInfo"
                :key="`info-${index}`"
                class="Contact Info"
                target="_blank"
                :href="link"
            >
                <Icon
                    :icon="icon"
                    :size="20"
                    color="white"
                />
                <p>{{ name }}</p>
            </a>
            <a
                v-for="({ name, icon, link }, index) in socialMedia"
                :key="`social-${index}`"
                class="Contact Social"
                target="_blank"
                :href="link"
            >
                <Icon
                    :icon="icon"
                    :size="20"
                    color="white"
                />
                <p>{{ name }}</p>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    components: {
    },
    data () {
        return {
            contactInfo: [
                {
                    name: 'info@espartanas.com',
                    icon: 'mail',
                    link: 'mailto:info@espartanas.com',
                },
                // {
                //     name: 'Casa de campo, Madrid',
                //     icon: 'map-pin',
                //     link: 'https://goo.gl/maps/1dE79Xxa2GaSgH1t7',
                // },
            ],
            socialMedia: [
                {
                    name: 'Instagram',
                    icon: 'instagram',
                    url: '@espartanaskickingball',
                    link: 'https://www.instagram.com/espartanaskickingball/',
                },
                {
                    name: 'Facebook',
                    icon: 'facebook',
                    url: '@espartanaskickingball',
                    link: 'https://www.facebook.com/EspartanasKickingball',
                },
                {
                    name: 'Youtube',
                    icon: 'youtube',
                    url: 'Espartanas Kickingball',
                    link: 'https://www.youtube.com/channel/UCmzmsqUUHx2vuUqnoXbSy1w',
                },
            ],
        }
    },
}
</script>

<style lang="scss" scoped>
.Footer {
	background: #888;
	width: 100%;
	display: flex;
	justify-content: center;

	.Wrapper {
		width: 80%;
		padding: 50px 0;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		// justify-content: flex-end;

		.Contact {
			display: flex;
			cursor: pointer;
			align-items: center;
			margin-top: 15px;
			transition: all 0.3s;
			& + .Contact {
				margin-left: 50px;
				@media screen and (max-width: $sm) {
					margin-left: 20px;
				}
			}
			.Icon { margin-right: 10px }
			p {
				color: white;
				font-size: 18px;
				@media screen and (max-width: $sm) {
					display: none;
				}
			}
			&:hover {
				transform: scale(1.1);
			}
		}
		.Social p {
			@media screen and (max-width: $md) {
				display: none;
			}
		}
	}
}
</style>
