export const tabs = [
    {
        key: 'materials',
        label: 'Materiales',
        slug: 'materiales',
    },
    {
        key: 'uniforms',
        label: 'Uniformes',
        slug: 'uniformes',
    },
]

export const materialDefaultValues = {
    label: '',
    quantity: '',
    location: '',
}

export const uniformDefaultValues = {
    name: '',
    city: '',
    teamOwner: '',
    address: '',
}

export const uniformNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
