import CONSTANTS from './constants'

export default {
    organizationCategories: {
        [CONSTANTS.USER_ROLE.COACH]: {
            label: 'Equipo Técnico',
        },
        [CONSTANTS.USER_ROLE.COMITE]: {
            label: 'Comité',
        },
        [CONSTANTS.USER_ROLE.PLAYER]: {
            label: 'Jugadoras',
        },
    },
    categories: [
        {
            key: CONSTANTS.USER_ROLE.COACH,
            label: 'Equipo Técnico',
            slug: 'equipo-tecnico',
        },
        {
            key: CONSTANTS.USER_ROLE.COMITE,
            label: 'Comité',
            slug: 'comite',
        },
        {
            key: CONSTANTS.USER_ROLE.PLAYER,
            label: 'Jugadoras',
            slug: 'jugadoras',
        },
    ],
    status: [
        {
            key: 'all',
            label: 'Todas',
            slug: 'todas',
        },
        {
            key: CONSTANTS.USER_STATUS.ACTIVE,
            label: 'Activas',
            slug: 'activas',
        },
    ],
}
