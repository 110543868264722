export const uniforms = [
    {
        key: 'game-shirt-turquoise',
        label: 'Camiseta turquesa',
        size: 'talla',
        number: 'Número',
    },
    {
        key: 'game-shirt-turquoise-new',
        label: 'Camiseta turquesa 2',
        size: 'talla',
        number: 'Número',
    },
    {
        key: 'game-shirt-black',
        label: 'Camiseta negra',
        size: 'talla',
        number: 'Número',
    },
    {
        key: 'game-shirt-black-new',
        label: 'Camiseta negra 2',
        size: 'talla',
        number: 'Número',
    },
    {
        key: 'sweater-black',
        label: 'Sudadera negra',
        size: 'talla',
        number: 'Número',
    },
    {
        key: 'socks-turquoise',
        label: 'Calcetines turquesa',
    },
    {
        key: 'socks-black',
        label: 'Calcetines negros',
    },
    {
        key: 'hat-white',
        label: 'Gorra blanca',
    },
]

export const uniformSizes = [
    {
        key: 'XS',
        label: 'XS',
    },
    {
        key: 'S',
        label: 'S',
    },
    {
        key: 'M',
        label: 'M',
    },
    {
        key: 'L',
        label: 'L',
    },
    {
        key: 'XL',
        label: 'XL',
    },
    {
        key: 'XXL',
        label: 'XXL',
    },
]
