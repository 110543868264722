<template>
    <div class="LineupGenerator">
        <Loading
            v-if="isLoading"
            key="loading"
            :visible="isLoading"
            :opacity="1"
            :duration="0"
        />
        <div v-else>
            <div
                v-if="attendance && attendance.length"
                class="List"
            >
                <div
                    v-for="person in attendance"
                    :key="`player-${person.id}`"
                    class="ListItem"
                >
                    <p class="Player">
                        {{ person.shortName }}
                    </p>
                    <PillSelectorInput
                        v-model="person.selectedPosition"
                        class="Positions"
                        :options="positions.defensivePositions"
                        :highlightReference="person.defensivePositions"
                        :addLoading="false"
                        @input="handleSelectPosition($event, person)"
                    />
                </div>
            </div>
            <div
                v-else
                class="Empty"
            >
                No hay jugadoras apuntadas a este evento.
            </div>
            <div class="flex">
                <div
                    v-if="lineUp && lineUp.length"
                    class="FinalList"
                >
                    <div class="FinalListHeader">
                        <h2>Alineación</h2>
                        <Button
                            v-if="!admitsRename"
                            type="submit"
                            text="Guardar"
                            :disabled="!lineUp.length || isLoading || $v.$invalid "
                            iconLeft="save"
                            iconColor="grayDark"
                            styleType="white"
                            size="sm"
                            @click="handleSaveLineUp"
                        />
                    </div>
                    <div
                        v-if="!isLoading && admitsRename"
                        class="ButtonWrapper"
                    >
                        <div class="bordered">
                            <Input
                                v-model="lineUpData.name"
                                type="text"
                                placeholder="Nombre"
                                color="grayDark"
                                :name="`name`"
                            />
                            <Input
                                v-model="lineUpData.number"
                                type="number"
                                placeholder="Número"
                                color="grayDark"
                                :name="`number`"
                            />
                        </div>
                        <Button
                            type="submit"
                            :text="isMobile ? '' : 'Guardar'"
                            :disabled="!lineUp.length || $v.$invalid || isLoading"
                            iconColor="white"
                            :iconLeft="isMobile ? 'save': ''"
                            styleType="primary"
                            size="regular"
                            @click="handleSaveLineUp"
                        />
                    </div>
                    <LineupDraggableList
                        :lineUp="lineUp"
                        :bench="bench"
                        :isLoading="isLoading"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import lineUpIntegration from '@/integration/lineUpIntegration'
import positionsIntegration from '@/integration/positionsIntegration'
import LineupDraggableList from './LineupDraggableList.vue'
import { mediaQueryMixin } from '@/mixins'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'LineupGenerator',
    components: {
        LineupDraggableList,
    },
    mixins: [mediaQueryMixin, validationMixin],
    validations () {
        return {
            lineUpData: {
                name: { required },
                number: { required },
            },
        }
    },
    props: {
        eventId: VueTypes.oneOfType([Number, null]),
        originalLineUpData: VueTypes.object,
        admitsRename: VueTypes.bool.def(true),
    },
    data () {
        return {
            eventInfo: {},
            mutableLineup: {},
            lineUps: [],
            lineUp: [],
            bench: [],
            attendance: [],
            originalLineUp: [],
            positions: {},
            lineUpData: {},
            selectedLineUp: null,
            isLoading: true,
        }
    },
    computed: {
        isMobile () {
            return this.$mq.w < this.$mq.sm.min
        },
    },
    watch: {
        async eventId (value) {
            if (value) await this.getData()
        },
        originalLineUpData: {
            deep: true,
            async handler (value) {
                if (value) await this.getData()
            },
        },
    },
    async mounted () {
        this.positions = await this.getPositions()
        await this.getData(this.eventId)
    },
    methods: {
        async getData () {
            this.lineUps = await lineUpIntegration.listLineUps(this.eventId, { group: true })
            this.event = await lineUpIntegration.getEventAttendance(this.eventId)
            this.attendance = this.event.users
            this.lineUpData.eventId = this.event.id
            if (this.originalLineUpData && this.originalLineUpData.name) {
                this.lineUpData = this.originalLineUpData
                await this.handleSelectLineUp(true)
            }
            this.isLoading = false
        },
        handleSelectPosition (positionId, person) {
            if (positionId) {
                const position = this.positions.defensivePositions.find(p => p.id === positionId)
                const isPositionInLineUp = this.lineUp.find(p => p.lineUpPosition.id === positionId)
                const infoForLineUp = person
                if (!isPositionInLineUp) {
                    infoForLineUp.lineUpPosition = position
                    if (!person.isInLineUp) this.addToLineUp(infoForLineUp)
                    else this.handleInternalChange(infoForLineUp)
                    infoForLineUp.isInLineUp = true
                } else {
                    if (!person.isInLineUp) infoForLineUp.selectedPosition = null
                    else {
                        infoForLineUp.selectedPosition = person.lineUpPosition.id
                        this.handleInternalChange(infoForLineUp)
                    }
                }
            } else {
                const outOfLineUp = person
                outOfLineUp.isInLineUp = false
                this.lineUp = this.lineUp.filter(p => p.id !== person.id)
            }

            if (this.lineUp.length >= 10 && this.lineUp.length <= 11) this.handleBench()
            else this.handleBench(false)
        },
        handleBench (fill = true) {
            if (fill) {
                this.bench = this.attendance.filter(p => !p.isInLineUp)
                for (let i = 0; this.bench.length < this.lineUp.length; i++) {
                    this.bench.push({ id: (i + 200), removeId: true })
                }
            } else this.bench = []
        },
        async handleSelectLineUp (retrieveLineUp = false) {
            if (retrieveLineUp) {
                let counter = 500
                const { lineUp, bench } = await lineUpIntegration.getLineUp(this.lineUpData.eventId,
                    {
                        number: this.lineUpData.number,
                        name: this.lineUpData.name,
                    })
                this.lineUp = lineUp.map(p => {
                    const person = this.attendance.find(pp => pp.id === p.id)
                    return {
                        ...person,
                        isInLineUp: true,
                        selectedPosition: p.lineUpPosition.id,
                        lineUpPosition: p.lineUpPosition,
                    }
                })
                this.bench = bench.map(p => {
                    const person = this.attendance.find(pp => pp.id === p.id)
                    const benchUser = {
                        ...person,
                        lineUpPosition: p.lineUpPosition,
                    }
                    if (!benchUser.id) benchUser.removeId = true
                    benchUser.id = benchUser.id || counter
                    counter += 1
                    return benchUser
                })
                for (let i = 0; i < this.attendance.length; i++) {
                    const personInLineUp = this.lineUp.find(p => this.attendance[i].id === p.id)
                    const personInBench = this.bench.find(p => this.attendance[i].id === p.id)

                    if (personInLineUp) this.attendance[i] = personInLineUp
                    else if (personInBench) this.attendance[i] = personInBench
                }
                this.originalLineUp = JSON.parse(JSON.stringify(lineUp))
            } else {
                this.cleanValues()
            }
        },
        async handleSaveLineUp () {
            this.isLoading = true
            await lineUpIntegration.saveLineUp(this.lineUp, this.bench, this.lineUpData)
            // TODO: pasar el ID para ver ese lineup
            this.$emit('viewLineUp')
        },
        async handleDeleteLineUp () {
            await lineUpIntegration.deleteLineUp(this.lineUpData)
            this.cleanValues()
        },
        async getPositions () {
            const positions = await positionsIntegration.getPositionsByType(true)
            positions.defensivePositions.push(
                positions.offensivePositions[positions.offensivePositions.length - 1])
            return positions
        },
        addToLineUp (infoForLineUp) {
            if (this.originalLineUp.length) {
                const index = this.originalLineUp
                    .findIndex(ol => ol.lineUpPosition &&
                    ol.lineUpPosition.number === infoForLineUp.lineUpPosition.number)
                if (index) this.lineUp.splice(index, 0, infoForLineUp)
                else this.lineUp.push(infoForLineUp)
            } else this.lineUp.push(infoForLineUp)
        },
        handleInternalChange (infoForLineUp) {
            const findPreviousUser = this.originalLineUp
                .find(p => p.lineUpPosition.number === infoForLineUp.lineUpPosition.number)
            const findUser = this.originalLineUp
                .find(p => p.id === infoForLineUp.id)
            findPreviousUser.lineUpPosition = JSON.parse(JSON.stringify(findUser.lineUpPosition))
            findUser.lineUpPosition = JSON.parse(JSON.stringify(infoForLineUp.lineUpPosition))
        },
        cleanValues () {
            this.lineUp = []
            this.bench = []
            this.lineUpData = { eventId: this.event.id }
            for (let i = 0; i < this.attendance.length; i++) {
                delete this.attendance[i].selectedPosition
                delete this.attendance[i].isInLineUp
                delete this.attendance[i].lineUpPosition
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.flex {
	display: flex;
}
.List {
	margin-top: 10px;
	.ListItem {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: white;
		padding: 10px;
		border-radius: 10px;
		margin-top: 10px;
		.Player {
			min-width: 100px;
			line-height: 20px;
			@media screen and (max-width: $sm) {
				font-size: 14px;
			}
		}
		.Reference {
			color: $gray-02;
			min-width: 100px;
		}
		.Positions {
			width: fit-content;
		}
		.PillSelectorInput::v-deep {
			.OptionGroup {
				padding: 0;
				.Option {
					margin-top: 0;
					padding: 4px 10px;
					border-radius: 10px;
				}
			}
		}
	}
}
.FinalList {
	max-width: 100%;
	width: 100%;
	margin-top: 50px;
	.FinalListHeader {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
		h2 {
			font-size: 22px;
			margin: 0;
		}
	}
	.ButtonWrapper {
		display: flex;
		margin-bottom: 30px;
		@media screen and (max-width: $md) {
			width: 100%;
		}
		.Input {
			margin-left: 10px;
			&::v-deep input {
				border: none;
			}
		}
		.bordered {
			border-radius: 15px;
			border: 1px solid $gray-04;
			display: flex;
			width: 300px;
			@media screen and (max-width: $md) {
				flex: 1
			}
		}
		.Button {
			margin-left: 15px;
			border-radius: 15px;
			height: 100%;
			transform: scale(1);
			@media screen and (max-width: $sm) {
				padding: 15px;
			}
			&::v-deep {
				.Icon { margin: 0 }
			}
		}
	}
}

</style>
