// import CONSTANTS from './constants'
// import { values } from 'lodash'

export const tabs = [
    // {
    //     key: 'expenses',
    //     label: 'Gastos',
    //     slug: 'gastos',
    // },
    {
        key: 'incomes',
        label: 'Pagos de jugadoras',
        slug: 'pagos-de-jugadoras',
    },
    // {
    //     key: 'reports',
    //     label: 'Informes',
    //     slug: 'informes',
    // },
]
// export const tabs = [
//     {
//         key: 'incomes',
//         text: 'Pagos',
//         permissions: values(CONSTANTS.USER_ROLE),
//     },
//     {
//         key: 'expenses',
//         text: 'Gastos',
//         permissions: values(CONSTANTS.USER_ROLE),
//     },
//     {
//         key: 'reports',
//         text: 'Informes',
//         permissions: values(CONSTANTS.USER_ROLE),
//     },
// ]
