<template>
    <div class="Bullet">
        <Icon
            :icon="icon"
            :size="38"
            color="primary"
        />
        <p><slot /></p>
    </div>
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'Bullet',
    props: {
        icon: VueTypes.string,
    },
}
</script>

<style lang="scss" scoped>

.Bullet {
   display: flex;
   margin-top: 40px;

   .Icon {
      margin-right: 40px;
      @media screen and (max-width: $sm) {
        margin-right: 30px;
        font-size: 30px !important;
      }
   }

   p {
      font-size: 1.2rem;
      line-height: 1.8rem;
      color: white;
      width: 100%;
   }
   a {
      color: $primary;
      cursor: pointer;
      font-weight: bold;
   }
   .bullet-dot {
      color: $primary;
      margin-right: 10px;
   }
}
</style>
