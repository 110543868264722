<template>
    <div class="AppHomePageScreen">
        <div class="Grid">
            <div
                v-for="{ key, label, icon, image, permissions} in menu"
                :key="key"
                v-permissions="{permissions}"
                class="GridItem"
                :style="{ backgroundImage: image ? `url(${require(`@/assets/imgs/illustrations/${image}.png`)})` : '' }"
                @click="$router.push({ name: key })"
            >
                <div
                    v-if="image"
                    class="Name"
                >
                    {{ label }}
                </div>
                <div v-else>
                    <Icon
                        :icon="icon"
                        :size="60"
                        color="primary"
                    />
                    <p class="IconName">
                        {{ label }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { menu } from '@/entities/menu'

export default {
    name: 'AppHomePageScreen',
    data () {
        return {
            menu,
            value: undefined,
        }
    },
}
</script>
<style lang="scss" scoped>

.AppHomePageScreen {
	width: 100%;
	min-height: 100vh;
	.Grid {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-column-gap: 20px;
		grid-row-gap: 20px;
		grid-template-rows: 1fr;
		@media screen and (max-width: $sm) {
			grid-template-columns: 1fr;
		}
		.GridItem {
			background: white;
			height: 240px;
			border-radius: 10px;
			box-shadow: 0px 4px 10px rgba(103, 94, 134, 0.1);
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			position: relative;
			overflow: hidden;

			.Name {
				position: absolute;
				left: 0;
				width: 100%;
				height: 100%;
				font-size: 24px;
				font-weight: 500;
				background: $primary;
				// background-image: linear-gradient(45deg, $primary, $info);
				color: white;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				opacity: 0;
				padding: 20px;
				transition: all .3s;
				bottom: -100%;
			}

			&:hover {
				.Name {
					opacity: 1;
					bottom: 0;
				}
				// background: $primary;
				// background-image: linear-gradient(45deg, $primary, $info);
				.IconName {
					color: white !important
				}
				.Icon {
					color: white !important
				}
			}
			p {
				color: $gray-dark;
				font-weight: 500;
				margin-top: 20px;
				font-size: 18px;
			}
			.Icon {
				transition: none;
			}
		}
	}
}
</style>
