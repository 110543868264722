import _ from 'lodash'
import moment from 'moment'
import user from '../api/user'
import userPosition from '../api/userPosition'
import userUniform from '../api/userUniform'
import userContact from '../api/userContact'
import userPayment from '../api/userPayment'
import userEvent from '../api/userEvent'
import constants from '../entities/constants'

export default {
    getPositionText (userPositions, useAbv = false) {
        return _.chain(userPositions.items)
            .map(item => item.position && useAbv ? item.position.abbreviation : item.position.name)
            .join(', ')
            .value() || ''
    },

    async getUserUniform (userId) {
        const userUniforms = await userUniform.getUserUniform(userId)
        const uniforms = {}

        _.forEach(userUniforms.items, item => {
            const uniform = item.uniform
            const key = (item.uniform.type + ' ' + item.uniform.color).toLowerCase()
            uniform.number = item.number || '-'
            uniform.size = item.size || '-'
            uniform.userUniformId = item.id
            uniforms[key.replaceAll(' ', '-').replaceAll('_', '-')] = uniform
        })

        return uniforms
    },

    getUniformText (userUniforms) {
        const uniforms = {}

        _.forEach(userUniforms.items, item => {
            const uniform = item.uniform
            const key = (item.uniform.type + ' ' + item.uniform.color).toLowerCase()
            uniform.number = item.number || '-'
            uniform.size = item.size || '-'
            uniform.userUniformId = item.id
            uniforms[key.replace(/\s/g, '-')] = uniform
        })

        return uniforms
    },

    formatDate (date, format = 'YYYY-MM-DD') {
        return (date && moment.utc(date).format(format)) || null
    },

    async getUserProfile (userId) {
        const userInformation = await user.getUser(userId)
        const userDefensivePositions = await userPosition.getUserPosition(userId, {
            type: constants.POSITION_TYPE.DEFENSIVE,
        })
        const userOffensivePositions = await userPosition.getUserPosition(userId, {
            type: constants.POSITION_TYPE.OFFENSIVE,
        })

        userInformation.birthdateFormatted = this.formatDate(userInformation.birthdate, 'DD-MM-YYYY')
        userInformation.birthdate = this.formatDate(userInformation.birthdate)
        userInformation.startDateFormatted = this.formatDate(userInformation.startDate, 'DD-MM-YYYY')
        userInformation.startDate = this.formatDate(userInformation.startDate)
        userInformation.defensivePositionsText = this.getPositionText(userDefensivePositions)
        userInformation.offensivePositionsText = this.getPositionText(userOffensivePositions)
        userInformation.defensivePositions = userDefensivePositions.items
        userInformation.offensivePositions = userOffensivePositions.items
        userInformation.defensivePositionsIds = userDefensivePositions.items.map(p => p.positionId)
        userInformation.offensivePositionsIds = userOffensivePositions.items.map(p => p.positionId)
        userInformation.contacts = (await userContact.getUserContact(userId)).items
        userInformation.uniforms = await this.getUserUniform(userId)

        return userInformation
    },

    async getUserPaymentInformation (userId, params) {
        const userPaymentInformation = await userPayment.getUserPayment(userId, params)
        const months = moment.months()
        const paymentMap = {}

        for (const month of months) {
            const payment = _.filter(userPaymentInformation.items,
                (item) => moment.utc(item.month).format('MMMM') === month)
            paymentMap[month] = (payment && payment.length && payment[0]) || {
                state: constants.PAYMENT_STATE.OWES,
            }
        }
        return paymentMap
    },

    async getUserBalanceInformation (userId) {
        const data = await userPayment.getUserBalance(userId)
        return data && data.balance
    },

    async getUserEvents (userId, params) {
        const data = await userEvent.getUserEvent(userId, params)
        return data && data.items
    },

    async listUsersByRole (role) {
        const status = _.chain(constants.USER_STATUS)
            .values()
            .reject(val => val === constants.USER_STATUS.OUT)
            .value()
        const userList = (await user.listUser({ role, status, includePosition: true })).items

        for (const user of userList) {
            const userDefensivePositions = user.userPositions
                .filter(p => p.position.type === constants.POSITION_TYPE.DEFENSIVE)
            const userOffensivePositions = user.userPositions
                .filter(p => p.position.type === constants.POSITION_TYPE.OFFENSIVE)
            user.birthdate = (user.birthdate &&
                moment.utc(user.birthdate).locale('es').format('D MMMM')) || '-'
            user.startDate = (user.startDate &&
                moment(user.startDate).endOf('day').locale('es').fromNow(true)) || '-'
            user.defensivePositionsText = this.getPositionText({ items: userDefensivePositions })
            user.defensivePositionsTextAbv = this.getPositionText({ items: userDefensivePositions }, true)
            user.offensivePositionsText = this.getPositionText({ items: userOffensivePositions })
            user.offensivePositionsTextAbv = this.getPositionText({ items: userOffensivePositions }, true)
            user.number = (user.userUniforms && user.userUniforms.length && user.userUniforms[0].number) || null
        }

        return userList
    },

    async editUser (original, edited, permission = '') {
        const userToEdit = this.cleanUser(edited)

        if (permission === constants.USER_ROLE.ADMIN ||
            permission === constants.USER_ROLE.COMITE) {
            await user.updateUser(original.id, userToEdit)
        }

        if (permission === constants.USER_ROLE.ADMIN ||
            permission === constants.USER_ROLE.COACH) {
            await this.handleDeletePositions(original, edited)
            await this.handleCreatePositions(original, edited)
        }

        return this.getUserProfile(original.id)
    },

    cleanUser (editedUser) {
        const userCleaned = _.clone(editedUser)
        delete userCleaned.birthdateFormatted
        delete userCleaned.startDateFormatted
        delete userCleaned.contacts
        delete userCleaned.uniforms
        delete userCleaned.defensivePositions
        delete userCleaned.defensivePositionsIds
        delete userCleaned.defensivePositionsText
        delete userCleaned.offensivePositions
        delete userCleaned.offensivePositionsIds
        delete userCleaned.offensivePositionsText
        delete userCleaned.password
        delete userCleaned.id
        userCleaned.birthdate = (userCleaned.birthdate &&
            moment.utc(userCleaned.birthdate).format()) || null
        userCleaned.startDate = (userCleaned.startDate &&
            moment.utc(userCleaned.startDate).format()) || null
        return userCleaned
    },

    async handleDeletePositions (originalUser, editedUser) {
        const defensivePositionsToDelete = this.getPositionsToDelete(
            originalUser.defensivePositions, originalUser.defensivePositionsIds,
            editedUser.defensivePositionsIds)
        const offensivePositionsToDelete = this.getPositionsToDelete(
            originalUser.offensivePositions, originalUser.offensivePositionsIds,
            editedUser.offensivePositionsIds)

        for (const id of defensivePositionsToDelete) {
            await userPosition.deleteUserPosition(id)
        }

        for (const id of offensivePositionsToDelete) {
            await userPosition.deleteUserPosition(id)
        }
    },

    async handleCreatePositions (originalUser, editedUser) {
        const defensivePositionsToCreate = this.getPositionsToCreate(
            originalUser.defensivePositionsIds, editedUser.defensivePositionsIds,
            originalUser.id)
        const offensivePositionsToCreate = this.getPositionsToCreate(
            originalUser.offensivePositionsIds, editedUser.offensivePositionsIds,
            originalUser.id)

        for (const p of defensivePositionsToCreate) {
            await userPosition.createUserPosition(p)
        }

        for (const p of offensivePositionsToCreate) {
            await userPosition.createUserPosition(p)
        }
    },

    getPositionsToDelete (originalUserPositionList, originalPositionIds, newPositionIds) {
        const missingPositionIds = _.difference(originalPositionIds, newPositionIds)
        const positionsToDelete = []

        if (missingPositionIds.length) {
            for (const id of missingPositionIds) {
                const userPosition = originalUserPositionList.find(p => p.positionId === id)
                positionsToDelete.push(userPosition.id)
            }
        }

        return positionsToDelete
    },

    getPositionsToCreate (originalPositionIds, newPositionIds, userId) {
        const missingPositionIds = _.difference(newPositionIds, originalPositionIds)
        const positionsToCreate = []

        if (missingPositionIds.length) {
            for (const positionId of missingPositionIds) {
                const userPosition = {
                    userId,
                    positionId,
                }
                positionsToCreate.push(userPosition)
            }
        }

        return positionsToCreate
    },

    async createUser (newUser, permission = '') {
        const userToCreate = this.cleanUser(newUser)
        userToCreate.permission = constants.USER_ROLE.PLAYER
        userToCreate.status = constants.USER_STATUS.ACTIVE
        let createdUser = {}

        if (permission === constants.USER_ROLE.ADMIN ||
            permission === constants.USER_ROLE.COMITE) {
            createdUser = await user.createUser(userToCreate)
        }

        createdUser.defensivePositionsIds = newUser.defensivePositionsIds || []
        createdUser.offensivePositionsIds = newUser.offensivePositionsIds || []

        if (permission === constants.USER_ROLE.ADMIN) {
            await this.handleCreatePositions({
                id: createdUser.id,
                defensivePositionsIds: [],
                offensivePositionsIds: [],
            }, createdUser)
        }

        return createdUser
    },

    async updateUserUniform (userUniformId, userId, body) {
        const userUniformCleaned = _.clone(body)
        userUniformCleaned.uniformId = body.id
        userUniformCleaned.userId = userId
        delete userUniformCleaned.color
        delete userUniformCleaned.id
        delete userUniformCleaned.type
        delete userUniformCleaned.userUniformId
        delete userUniformCleaned.createdAt
        delete userUniformCleaned.updatedAt
        await userUniform.deleteUserUniform(userUniformId)
        await userUniform.createUserUniform(userUniformCleaned)
    },

    async createUserUniform (userUniformData, uniformId, userId) {
        const userUniformToCreate = {
            uniformId,
            userId,
            size: userUniformData.size,
            number: userUniformData.number,
        }
        await userUniform.createUserUniform(userUniformToCreate)
    },

    async deleteUserUniform (userUniformId) {
        await userUniform.deleteUserUniform(userUniformId)
    },

    async updateUserPayment (userPaymentId, body) {
        const userPaymentCleaned = _.clone(body)
        userPaymentCleaned.paymentDate = moment.utc().format()
        delete userPaymentCleaned.id
        delete userPaymentCleaned.createdAt
        delete userPaymentCleaned.updatedAt
        await userPayment.deleteUserPayment(userPaymentId)
        await userPayment.createUserPayment(userPaymentCleaned)
    },

    async createUserPayment (userPaymentData, userId) {
        const userPaymentToCreate = {
            paymentDate: moment.utc().format(),
            userId,
            state: userPaymentData.state,
            month: userPaymentData.month,
        }
        await userPayment.createUserPayment(userPaymentToCreate)
    },

    async deleteUserPayment (userPaymentId) {
        await userPayment.deleteUserPayment(userPaymentId)
    },
}
