export const kickingStatType = "kicking";
export const fieldingStatType = "fielding";
export const pitchingStatType = "pitching";

export const statTypes = [
	{
		key: kickingStatType,
		label: "Ofensiva",
	},
	{
		key: fieldingStatType,
		label: "Defensiva",
	},
	{
		key: pitchingStatType,
		label: "Pitcheo",
	},
];
export const defaultKickingStatsKey = "AVG";
export const kickingStatsKeys = {
	G: "Partidos jugados",
	PA: "Turnos al bate",
	// SEQ: "Orden de pateo",
	// AB: "Turnos al bate eficiente",
	H: "Hits",
	B: "Toques",
	"1B": "Single",
	"2B": "Doble",
	"3B": "Triple",
	HR: "Home run",
	BB: "Base por bola",
	Kc: "Ponche parada",
	Ks: "Ponche con foul",
	SO: "Ponches",
	ROE: "Reach on error",
	FC: "Reach on fielders choice",
	CI: 'Interferencia del catcher o fildeadoras',
	R: "Carreras",
	SAC: "Sacrificios",
	SB: "Bases robadas",
	CS: "Outs robando base",
	PK: 'Outs después de 1b',
	LOBi: "Veces que dejaste gente en base",
	LOB: "Veces que te quedaste en base",
	// TB: "Bases totales", // ===> ¿que es?
	// OB: "En base",
	RC: "Carreras creadas", // ===> ¿que es?
	RBI: "Carreras impulsadas",	
	// 'PA/RSP': 'Turnos al bate con corredor en 3b',
	OBP: "Porcentaje en base", // %,
	SLG: 'Porcentaje de slugging', // porcentaje de slugging
	AVG: "Average (Promedio)",
	OBPE: 'OBP + ROE',
	// OPS: '', // es OBP + SLG
	// // ==> NEW
	// "FB%": "",
	// "GB%": "",
	// "LD%": "",
	// "PU%": "",
};
export const formatKickingValueByKey = ({ key, value }) => {
	if (key === "AVG" || key === "OBP" || key === "SLG" || key === "OBPE") return parseFloat(value).toFixed(2);
	else return value;
};
export const positions = {
	1: "Pitcher",
	2: "Catcher",
	3: "1B",
	4: "2B",
	5: "3B",
	6: "Short stop",
	7: "Left field",
	8: "Center field",
	9: "Right field",
	10: "Short field",
};
export const defaultFieldingStatsKey = "FP";
export const fieldingStatsKeys = {
	G: "Partidos jugados",
	IP: "Innings jugados",
	ST: "Titular",
	OP: "Outs jugados",
	// Et: "Error en lanzamiento",
	// Ef: "Error en fildeo",
	ERR: "Errores totales",
	PO: "Outs",
	A: "Asistencias",
	SBA: "Robo de bases permitidas",
	CS: "Outs en intento de robo de base",
	DP: "Doble play",
	TP: "Triple play",
	PK: "Pick Off (después de primera)",
	FP: "Porcentaje de fildeo",
	// A1: "",
	// A10: "",
	// A2: "",
	// A3: "",
	// A4: "",
	// A5: "",
	// A6: "",
	// A7: "",
	// A8: "",
	// A9: "",
	// AP1: "",
	// AP10: "",
	// AP2: "",
	// AP3: "",
	// AP4: "",
	// AP5: "",
	// AP6: "",
	// AP7: "",
	// AP8: "",
	// AP9: "",
	// Ef1: "",
	// Ef10: "",
	// Ef2: "",
	// Ef3: "",
	// Ef4: "",
	// Ef5: "",
	// Ef6: "",
	// Ef7: "",
	// Ef8: "",
	// Ef9: "",
	// Et1: "",
	// Et10: "",
	// Et2: "",
	// Et3: "",
	// Et4: "",
	// Et5: "",
	// Et6: "",
	// Et7: "",
	// Et8: "",
	// Et9: "",
	// FP1: "",
	// FP10: "",
	// FP2: "",
	// FP3: "",
	// FP4: "",
	// FP5: "",
	// FP6: "",
	// FP7: "",
	// FP8: "",
	// FP9: "",
	// PO1: "",
	// PO10: "",
	// PO2: "",
	// PO3: "",
	// PO4: "",
	// PO5: "",
	// PO6: "",
	// PO7: "",
	// PO8: "",
	// PO9: "",
};
export const formatFieldingValueByKey = ({ key, value }) => {
	if (key === "FP") return `${parseFloat(value).toFixed(2)}`;
	else if (key === "IP") return parseFloat(value).toFixed(0);
	else return value;
};
export const defaultPitchingStatsKey = "AVG";
export const pitchingStatsKeys = {
	G: "Partidos jugados",
	IP: "Innings pitcheados",
	// SEQ: "Orden de pitcheo",
	// W: "Juegos ganados",
	// L: "Juegos perdidos",
	// SV: "Juegos salvados",
	// HLD: "Juegos mantenidos",
	ST: "Pitcher abridor",
	FIN: "Pitcher cerrador",
	CMP: "Juegos completos",
	BF: "Número de pateadoras",
	H: "Hits",
	BB: "Base por bola",
	ER: "Carreras limpias",
	R: "Carreras recibidas",
	Kc: "Ponches paradas",
	Ks: "Ponches de foul",
	K: "Ponches",
	"K/BB": "Ponches x Base por bolas",
	"K/GI": "Ponches x innings",
	"BB/GI": "Base por bolas x innings",
	"H/GI": "Hits x innings",
	SCB: "Toques de sacrificio",
	BT: "Toques",
	"1B": "Single",
	"2B": "Doble",
	"3B": "Triple",
	HR: "Homeruns recibidos",
	WHIP: "BB + Hits por inning pitcheado",
	OBP: "On base percentage",
	O: "Outs",
	GO: "Outs de rolling",
	AO: "Outs de fly",
	123: "Innings de 123",
	"GB%": "Porcentaje de rollings",
	"LD%": "Porcentaje de líneas",
	"PU%": "Porcentaje de bombitas",
	"FB%": "Porcentaje de flys",
};
export const formatPitchingValueByKey = ({ key, value }) => {
	if (key === "K/BB" || key === "K/GI" || key === "BB/GI" || key === "H/GI" || key === "WHIP" || key === "OBP") return `${parseFloat(value).toFixed(2)}`;
	if (key === "GB%" || key === "LD%" || key === "PU%" || key === "FB%") return `${parseFloat(value).toFixed(2)}%`;
	else if (key === "IP") return parseFloat(value).toFixed(0);
	else return value;
};
