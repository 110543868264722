<template>
    <div class="ExercisesScreen">
        <div class="ScreenHeader">
            <h1>Ejercicios</h1>
        </div>
        <div class="Container">
            <p>ExercisesScreen</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ExercisesScreen',
}
</script>
<style lang="scss" scoped>

// .ExercisesScreen {
// }
</style>
