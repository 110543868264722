<template>
    <div class="EconomyPayments">
        <div class="Header">
            <DateSelector
                :isEditable="isEditMode"
                :displayMonth="false"
                :startYear="paymentConstants.paymentStartYear"
                @changeDate="handleSelectedDate"
            />
            <div
                v-if="!isLoading"
                class="Buttons"
            >
                <Button
                    v-if="isEditMode"
                    class="Submit"
                    type="submit"
                    text="GUARDAR"
                    styleType="primary"
                    iconLeft="save"
                    :iconSize="16"
                    :loading="isLoading"
                    @click="handleSubmit"
                />
                <Icon
                    v-if="isEditMode === false"
                    v-permissions="{permissions: [constants.USER_ROLE.ADMIN,
                                                  constants.USER_ROLE.COMITE]}"
                    icon="edit"
                    :size="20"
                    @click="isEditMode = true"
                />
                <Icon
                    v-else
                    icon="close"
                    :size="20"
                    @click="handleClose"
                />
            </div>
        </div>
        <div class="TableHeader">
            <div class="Month Name">
                <p>Nombre</p>
            </div>
            <div
                v-for="{ id, label } in months"
                :key="`month-${id}`"
                class="Month"
            >
                <p>{{ isMobile ? label.slice(0,1) : `${label.slice(0,3)}.` }}</p>
            </div>
            <div class="Month">
                <p>{{ isMobile ? 'S' : 'Saldo' }}</p>
            </div>
        </div>
        <div class="TableBody">
            <Loading
                key="loading"
                :visible="isLoading"
                :opacity="1"
            />
            <template v-if="!isLoading">
                <div
                    v-for="user in users"
                    :key="user.id"
                >
                    <PaymentCalendar
                        :key="paymentCalendarKey"
                        isTableMode
                        :isEditable="isEditMode"
                        :paymentInformation="user"
                    />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import paymentIntegration from '@/integration/paymentIntegration'
import moment from 'moment'
import { PaymentCalendar } from '@/components'
import { months } from '@/entities/calendar'
import paymentConstants from '@/entities/payments'
import constants from '@/entities/constants'
import utils from '@/utils/utils'
import profileScreenUtils from '@/utils/profileScreenUtils'
import { mediaQueryMixin } from '@/mixins'

export default {
    name: 'EconomyPayments',
    components: {
        PaymentCalendar,
    },
    mixins: [mediaQueryMixin],
    data () {
        return {
            users: [],
            months,
            isEditMode: false,
            paymentConstants,
            constants,
            dateInfo: utils.getInitialDateInfo({ startYear: paymentConstants.paymentStartYear }),
            paymentCalendarKey: 0,
            me: this.$store.getters.me,
            isLoading: true,
        }
    },
    computed: {
        isMobile () {
            return this.$mq.w < this.$mq.xl.min
        },
    },
    async mounted () {
        await this.listPayments()
    },
    methods: {
        async listPayments (date) {
            this.users = await paymentIntegration.listUserPayments({
                selectedDate: (date && date.selectedDate) || moment.utc(),
            })
            this.paymentCalendarKey += 1
            this.isLoading = false
        },
        async handleSubmit (paymentsInfo) {
            this.isLoading = true
            for (const userPayment of this.users) {
                if (userPayment.toEdit) {
                    userPayment.dateInfo = this.dateInfo
                    await profileScreenUtils.updatePayments(userPayment, userPayment.id, this.me.permission)
                }
            }
            await this.listPayments(this.dateInfo)
            this.isEditMode = false
        },
        async handleClose () {
            this.isLoading = true
            await this.listPayments(this.dateInfo)
            this.isEditMode = false
        },
        async handleSelectedDate (value) {
            this.isLoading = true
            this.dateInfo = value
            await this.listPayments(this.dateInfo)
        },
    },
}
</script>
<style lang="scss" scoped>
.Header {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	.Buttons {
		// margin-right: 15px; // TODO: calculate scroll bar width
		display: flex;
		// @media screen and (max-width: $sm) {
		// 	margin-right: 0;
		// }
		.Submit {
			margin-right: 25px;
			padding: 8px 15px;
			font-size: 14px;
			height: fit-content;
		}
	}
}
.TableHeader {
	// margin-right: 15px; // TODO: calculate scroll bar width
	display: grid;
	grid-template-columns: repeat(14, 1fr);
	grid-template-rows: 1fr;
	grid-column-gap: 5px;
	grid-row-gap: 5px;
	@media screen and (max-width: $sm) {
		overflow-x: scroll;
		// margin-right: 0;
	}
	.Month {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px 0;
		background: $primary;
		@media screen and (max-width: $sm) {
			width: 25px;
		}
		&.Name {
			min-width: 200px;
			padding: 0 10px;
			text-align: center;
			@media screen and (max-width: $lg) {
				min-width: 150px;
			}
		}
	}
}
.TableBody {
	height: calc(100vh - 320px);
	overflow-y: scroll;
	overflow-x: hidden;
	@media screen and (max-width: $sm) {
		overflow-x: scroll;
	}
	.PaymentCalendar {
		margin-top: 10px;
		&::v-deep {
			.Table {
				@media screen and (max-width: $sm) {
					// overflow-x: scroll;
					width: fit-content;
				}
			}
			.Body {
				grid-template-columns: repeat(14, 1fr);
				grid-template-rows: 1fr;
				@media screen and (max-width: $sm) {
					grid-template-columns: repeat(14, 1fr);
					grid-template-rows: 1fr;
					width: 100%;
					.Check {
						width: 25px;
					}
				}
				.Name {
					@media screen and (max-width: $lg) {
						min-width: 150px !important;
					}
					// @media screen and (max-width: $sm) {
					// 	min-width: 80px !important;
					// }
				}
			}
		}
	}
}
.Loading {
	margin-top: 20px;
}
</style>
