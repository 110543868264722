<template>
    <div class="InfoTeams">
        <div class="Header">
            <PillSelectorInput
                v-model="activeCity"
                :options="cities"
                border="gray"
                @input="handleSetCity"
            />
            <div
                v-permissions="{permissions: [constants.USER_ROLE.ADMIN,
                                              constants.USER_ROLE.COMITE]}"
                class="IconWrapper"
            >
                <div />
                <Icon
                    v-if="activeMode === 'read'"
                    :size="20"
                    icon="edit"
                    @click="activeMode = 'edit'"
                />
                <Icon
                    v-else
                    :size="20"
                    icon="close"
                    @click="activeMode = 'read'"
                />
            </div>
        </div>
        <Loading
            v-if="isLoading"
            key="loading"
            :visible="isLoading"
            :opacity="1"
            :duration="0"
        />
        <template v-else>
            <div
                v-for="(team, index) in filteredTeams"
                :key="index"
                class="ListItem"
            >
                <div class="Content">
                    <div
                        class="Name"
                        :style="{ 'grid-area': 'Name' }"
                    >
                        <Input
                            v-model="team.name"
                            type="text"
                            placeholder="Equipo"
                            color="white"
                            :disabled="activeMode === 'read' ? true : false"
                            :name="`name-${index}`"
                        />
                    </div>
                    <div
                        class="City"
                        :style="{ 'grid-area': 'City' }"
                    >
                        <Input
                            v-model="team.city"
                            type="text"
                            placeholder="Ciudad"
                            iconLeft="map-pin"
                            color="grayDark"
                            :disabled="activeMode === 'read' ? true : false"
                            iconColor="primary"
                            :name="`city-${index}`"
                        />
                    </div>
                    <div
                        class="CreatedAt"
                        :style="{ 'grid-area': 'CreatedAt' }"
                    >
                        <Input
                            v-model="team.startDate"
                            type="text"
                            placeholder="Creación"
                            iconLeft="clock"
                            color="grayDark"
                            :disabled="activeMode === 'read' ? true : false"
                            iconColor="primary"
                            :name="`createdAt-${index}`"
                        />
                    </div>
                    <div
                        class="Email"
                        :style="{ 'grid-area': 'Email' }"
                    >
                        <Input
                            v-model="team.email"
                            type="text"
                            placeholder="Email"
                            iconLeft="mail"
                            color="grayDark"
                            :disabled="activeMode === 'read' ? true : false"
                            iconColor="primary"
                            :name="`email-${index}`"
                        />
                    </div>
                    <div
                        class="Link"
                        :style="{ 'grid-area': 'Link' }"
                    >
                        <Input
                            v-model="team.instagram"
                            type="text"
                            placeholder="Instagram"
                            iconLeft="instagram"
                            color="grayDark"
                            :disabled="activeMode === 'read' ? true : false"
                            iconColor="primary"
                            :name="`link-${index}`"
                        />
                    </div>
                    <div
                        class="Contact"
                        :style="{ 'grid-area': 'Contact' }"
                    >
                        <Input
                            v-model="team.contact"
                            type="text"
                            placeholder="Contacto"
                            iconLeft="phone"
                            color="grayDark"
                            :disabled="activeMode === 'read' ? true : false"
                            iconColor="primary"
                            :name="`contact-${index}`"
                        />
                    </div>
                </div>
                <p
                    v-if="activeMode === 'edit'"
                    class="Action"
                >
                    <Icon
                        :size="15"
                        icon="trash-2"
                        color="error"
                        @click="handleDeleteTeam(team)"
                    />
                </p>
            </div>
            <div
                v-if="activeMode === 'read'"
                v-permissions="{permissions: [constants.USER_ROLE.ADMIN,
                                              constants.USER_ROLE.COMITE]}"
            >
                <div class="AddNew">
                    <p>Agregar</p>
                </div>
                <div class="ListItem">
                    <div class="Content">
                        <div
                            class="Name"
                            :style="{ 'grid-area': 'Name' }"
                        >
                            <Input
                                v-model="(newTeam || {}).name"
                                type="text"
                                color="white"
                                placeholder="Equipo"
                                :name="`name`"
                            />
                        </div>
                        <div
                            class="City"
                            :style="{ 'grid-area': 'City' }"
                        >
                            <Input
                                v-model="(newTeam || {}).city"
                                type="text"
                                placeholder="Ciudad"
                                iconLeft="map-pin"
                                color="grayDark"
                                iconColor="primary"
                                :name="`city`"
                            />
                        </div>
                        <div
                            class="CreatedAt"
                            :style="{ 'grid-area': 'CreatedAt' }"
                        >
                            <Input
                                v-model="(newTeam || {}).startDate"
                                type="text"
                                placeholder="Creación"
                                iconLeft="clock"
                                color="grayDark"
                                iconColor="primary"
                                :name="`createdAt`"
                            />
                        </div>
                        <div
                            class="Email"
                            :style="{ 'grid-area': 'Email' }"
                        >
                            <Input
                                v-model="(newTeam || {}).email"
                                type="text"
                                placeholder="Email"
                                iconLeft="mail"
                                color="grayDark"
                                iconColor="primary"
                                :name="`email`"
                            />
                        </div>
                        <div
                            class="Link"
                            :style="{ 'grid-area': 'Link' }"
                        >
                            <Input
                                v-model="(newTeam || {}).instagram"
                                type="text"
                                placeholder="Instagram"
                                iconLeft="instagram"
                                color="grayDark"
                                iconColor="primary"
                                :name="`link`"
                            />
                        </div>
                        <div
                            class="Contact"
                            :style="{ 'grid-area': 'Contact' }"
                        >
                            <Input
                                v-model="(newTeam || {}).contact"
                                type="text"
                                placeholder="Contacto"
                                iconLeft="phone"
                                color="grayDark"
                                iconColor="primary"
                                :name="`contact`"
                            />
                        </div>
                    </div>
                    <p class="Action">
                        <Button
                            styleType="primary"
                            iconLeft="plus"
                            :disabled="$v.newTeam.$invalid"
                            @click="handleAddNewTeam"
                        />
                    </p>
                </div>
            </div>
        </template>
        <div class="ButtonWrapper">
            <Button
                v-if="activeMode === 'edit'"
                class="Submit"
                type="submit"
                text="GUARDAR"
                styleType="gradient"
                iconLeft="save"
                @click="handleEditTeams"
            />
        </div>
    </div>
</template>

<script>
import { teamDefaultValues } from '@/entities/info'
import { mediaQueryMixin } from '@/mixins'
import VueTypes from 'vue-types'
import constants from '@/entities/constants'
import informationIntegration from '@/integration/informationIntegration'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'InfoTeams',
    mixins: [mediaQueryMixin, validationMixin],
    props: {
        allTeams: VueTypes.array,
    },
    validations () {
        return {
            newTeam: {
                name: { required },
                city: { required },
            },
        }
    },
    data () {
        return {
            activeMode: 'read',
            isLoading: false,
            newTeam: {},
            mutableTeams: teamDefaultValues,
            filteredTeams: teamDefaultValues,
            cities: [],
            activeCity: '',
            value: '',
            constants,
        }
    },
    computed: {
        isMobile () {
            return this.$mq.w < this.$mq.sm.min
        },
    },
    async mounted () {
        this.mutableTeams = await informationIntegration.listTeams()
        this.getCities()
        this.filterTeams()
    },
    methods: {
        async handleEditTeams () {
            this.isLoading = true
            await informationIntegration.updateTeams(this.mutableTeams)
            this.mutableTeams = await informationIntegration.listTeams()
            this.getCities()
            this.filterTeams()
            this.activeMode = 'read'
            this.isLoading = false
        },
        async handleDeleteTeam (team) {
            await informationIntegration.deleteTeam(team.id)
            this.mutableTeams = this.mutableTeams.filter(t => {
                return t.id !== team.id
            })
            this.filterTeams()
        },
        async handleAddNewTeam () {
            const newTeam = await informationIntegration.createTeam(this.newTeam)
            this.mutableTeams.push(newTeam)
            this.newTeam = {}
            this.filterTeams()
        },
        unique (value, index, self) {
            return self.indexOf(value) === index
        },
        handleSetCity (val) {
            this.activeCity = val
            this.filterTeams()
        },
        getCities () {
            this.cities = this.mutableTeams
                .map(t => t.city)
                .filter(this.unique)
                .map(c => {
                    return { key: c, label: c }
                })
            this.activeCity = this.cities.length && this.cities[0].key
        },
        filterTeams () {
            this.filteredTeams = this.mutableTeams.filter(t => {
                return t.city === this.activeCity
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.InfoTeams {
	margin: 10px 30px;
	@media screen and (max-width: $md) {
		margin: 10px 20px;
	}
	@media screen and (max-width: $sm) {
		margin: 10px 0;
	}
    .Header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 50px;
		.PillSelectorInput {
			margin-right: 30px;
			width: fit-content;
		}
        .IconWrapper {
            // width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            padding-left: 20px;
            h2 { color: $gray-dark }
        }
	}
	.ListItem {
		display: flex;
		align-items: center;
		height: 50px;
		border-radius: 1rem;
		position: relative;
		transition: all .3s;
		@media screen and (max-width: $xl) {
			height: auto;
		}
		& + .ListItem {
			margin-top: 15px;
		}
		&:hover {
			transform: scale(1.02);
		}
		.Content {
			width: 100%;
			margin: 10px 0;
			display: grid;
			grid-column-gap: 10px;
			grid-row-gap: 10px;
			grid-template-columns: 1fr 1fr 1fr 2fr 2fr 2fr;
			grid-template-rows: auto;
			grid-template-areas:
				"Name City CreatedAt Email Link Contact";
			@media screen and (max-width: $xl) {
				grid-template-columns: 1fr 1fr 1fr;
				grid-template-rows: auto;
				grid-template-areas:
					"Name Email CreatedAt"
					"City Contact Link";
			}
			@media screen and (max-width: $lg) {
				grid-template-columns: 2fr 3fr 3fr;
				grid-template-rows: auto;
				grid-template-areas:
					"Name Email CreatedAt"
					"City Contact Link";
			}
			@media screen and (max-width: $md) {
				grid-template-columns: 2fr 2fr 3fr;
				grid-template-rows: auto;
				grid-template-areas:
					"Name Name Name"
					"City CreatedAt Link"
					"Email Email Contact";
			}
			@media screen and (max-width: $sm) {
				grid-template-columns: 1fr;
				grid-template-rows: auto;
				grid-template-areas:
					"Name"
					"City"
					"CreatedAt"
					"Email"
					"Link"
					"Contact";
			}
		}
		.Input::v-deep input {
			border: none;
			background: transparent;
		}
	}
	.Name, .City, .CreatedAt, .Email, .Link, .Contact  {
		flex: 1;
		background: rgba($gray-07, .6);
		border-radius: 1rem;
	}
	.Name {
		background: $primary;
	}
	.Name .Input::v-deep input {
			font-weight: 600;
			font-size: 18px;
			padding: 10px;
			padding-left: 20px;
			color: $white;
			margin-left: 10px;
			&::placeholder {
				color: $white;
			}
	}
	.Action {
		margin-left: 20px;
		min-width: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		.Button {
			padding: 14px 16px;
			&::v-deep .Icon {
				margin-right: 0;
			}
		}
		@media screen and (max-width: $sm) {
			position: absolute;
			top: 20px;
			right: 15px;
		}
	}
	.AddNew {
		margin-top: 40px;
		margin-bottom: 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid $gray-light;
		p {
			margin-left: 25px;
			font-weight: bold;
			color: $gray-dark;
			font-size: 18px;
		}
	}
	.ButtonWrapper {
		display: flex;
		justify-content: flex-end;
		.Button {
			align-self: flex-end;
			font-size: 16px;
			margin-top: 20px;
			@media screen and (max-width: $sm) {
				font-size: 14px;
			}
		}
	}
}
</style>
