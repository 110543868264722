<template>
    <div class="PublicLayout">
        <PublicNavbar v-if="displayNavbar" />
        <div class="Container">
            <Transition
                name="main-router"
                mode="out-in"
            >
                <RouterView />
            </Transition>
        </div>
        <Footer />
    </div>
</template>

<script>
import { PublicNavbar, Footer } from '@/components'
import { mediaQueryMixin } from '@/mixins'

export default {
    name: 'PublicLayout',
    components: {
        PublicNavbar,
        Footer,
    },
    mixins: [mediaQueryMixin],
    data () {
        return {
            displayNavbar: true,
        }
    },
    computed: {
        isMobile () {
            return this.$mq.w < this.$mq.lg.min
        },
    },
    watch: {
        $route (to) {
            const { teamId } = to.params
            if (teamId) this.displayNavbar = false
        },
    },
    mounted () {
        const { teamId } = this.$route.params || {}
        if (teamId) this.displayNavbar = false
    },
}
</script>
<style lang="scss" scoped>

.PublicLayout {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .Container {
	flex: 1;
  }
}

.main-router-enter-active,
.main-router-leave-active {
    transition-duration: 0.5s;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
}

.main-router-enter {
    opacity: 0;
    transform: translate(0, -15px);
}

.main-router-leave-active {
    opacity: 0;
    transform: translate(0, -15px);
}
</style>
