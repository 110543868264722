import {
    SigninScreen,
    ForgotPasswordScreen,
    SetPasswordScreen,
} from '@/screens'

export default [
    {
        name: 'Signin',
        path: '/signin',
        component: SigninScreen,
    },
    {
        name: 'ForgotPassword',
        path: '/forgot-password',
        component: ForgotPasswordScreen,
    },
    {
        name: 'SetPassword',
        path: '/set-password',
        component: SetPasswordScreen,
    },
]
