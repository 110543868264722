<template>
    <div class="RegulationScreen">
        <div class="ScreenHeader">
            <h1>Reglamento</h1>
        </div>
        <div class="Container">
            <div
                v-for="({ name, icon, pdf }) in regulations"
                :key="name"
                class="Regulation"
            >
                <div class="Header">
                    <div class="Title">
                        <Icon
                            :icon="icon"
                            :size="20"
                            color="primary"
                        />
                        <p class="Name">
                            {{ name }}
                        </p>
                    </div>
                    <a
                        class="Download"
                        :href="pdf"
                        target="_blank"
                    >
                        <Icon
                            icon="download"
                            :size="18"
                            color="primary"
                        />
                        <p>Descargar</p>
                    </a>
                </div>
                <iframe
                    class="PDF"
                    :src="pdf"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RegulationScreen',
    data () {
        return {
            regulations: [
                {
                    name: 'Kickingball',
                    icon: 'file-text',
                    pdf: 'https://espartanaskickingball.com/uploads/Reglamento-kickignball.pdf',
                },
                {
                    name: 'Espartanas',
                    icon: 'file-text',
                    pdf: 'https://espartanaskickingball.com/uploads/Reglamento-interno.pdf',
                },
            ],
        }
    },
}
</script>
<style lang="scss" scoped>

.RegulationScreen {
	.Container {
		display: flex;
		justify-content: space-between;
		padding: 50px !important;
		@media screen and (max-width: $md) {
			flex-direction: column;
			padding-bottom: 0px !important;
		}
		@media screen and (max-width: $sm) {
			padding: 20px !important;
		}
	}
	.Regulation {
		width: 48%;
		@media screen and (max-width: $md) {
			width: 70%;
			margin-bottom: 50px;
		}
		@media screen and (max-width: $sm) {
			width: 100%;
		}
		.Header {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			.Title {
				display: flex;
				align-items: center;
				font-weight: $font-weight-bold;
				transition: all 350ms ease;
				.Icon {
					margin-right: 10px;
				}
				.Name {
					color: $gray-dark;
					line-height: 26px;
					font-size: 20px;
					font-weight: 700;
					text-transform: uppercase;
				}
			}
			.Download {
				background: rgba($gray-dark, .1);
				padding: 5px 15px 8px 15px;
				border-radius: 10px;
				display: flex;
				align-items: center;
				width: fit-content;
				@media screen and (max-width: $lg) {
					background: white;
					padding: 0;
				}
				.Icon {
					margin-right: 10px
				}
				p {
					font-size: 1rem;
					@media screen and (max-width: $lg) {
						display: none;
					}
				}
			}
		}
		.PDF {
			width: 100%;
			min-height: 600px;
			height: auto;
			margin: 20px 0;
			@media screen and (max-width: $sm) {
				min-height: 400px;
			}
		}
	}
}

</style>
