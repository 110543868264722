<template>
    <header class="Navbar">
        <div class="Container">
            <div class="Left">
                <Icon
                    v-if="isMobile"
                    :key="2"
                    class="Menu"
                    :size="25"
                    icon="menu"
                    @click="handleShowDropdownModal"
                />
                <div
                    v-else
                    class="Logo"
                    @click="$router.push({ name: 'AppHome'})"
                >
                    <img
                        class="DesktopLogo"
                        :style="{ width: '30px', 'margin-right': '20px' }"
                        :src="require('@/assets/escudo.png')"
                    >
                    <h1>ESPARTANAS</h1>
                </div>
            </div>
            <div
                v-if="isMobile"
                class="Brand"
            >
                <div
                    class="Logo"
                    @click="$router.push({ name: 'AppHome'})"
                >
                    <img
                        :src="require('@/assets/escudo.png')"
                        class="Escudo"
                    >
                    <h1>ESPARTANAS</h1>
                </div>
            </div>
            <div class="Right">
                <Avatar
                    :url="me.imagePath ? require(`@/assets/imgs/players/${me.imagePath}`) : ''"
                    :isClickable="true"
                    :size="{ height: 25, width: 25 }"
                    :iconSize="16"
                    @click="handleClickOption({ key: 'Profile' })"
                />
                <Icon
                    class="SignOut"
                    icon="log-out"
                    :size="20"
                    @click="handleLogout"
                />
            </div>
        </div>
        <DropdownModal
            :isOpen="showDropdownModal"
            :options="menu"
            :skipPermissions="false"
            @onClick="handleClickOption"
            @onClose="showDropdownModal=false"
        />
    </header>
</template>

<script>
import VueTypes from 'vue-types'
import DropdownModal from '../DropdownModal/DropdownModal.vue'
import { mediaQueryMixin } from '@/mixins'
import { menu } from '@/entities/menu'
import auth from '@/api/auth'

export default {
    name: 'Navbar',
    components: {
        DropdownModal,
    },
    mixins: [mediaQueryMixin],
    props: {
        me: VueTypes.object,
    },
    data () {
        return {
            menu,
            showDropdownModal: false,
            user: undefined,
        }
    },
    computed: {
        isMobile () {
            return this.$mq.w < this.$mq.lg.min
        },
    },
    methods: {
        handleShowDropdownModal () {
            this.showDropdownModal = true
        },
        handleClickOption (option) {
            const { key } = option
            this.showDropdownModal = false
            if (key === 'Profile') this.$router.push({ name: key, params: { id: this.me.id } })
            else this.$router.push({ name: key })
        },
        handleClick (event) {
            this.$emit(event)
        },
        async handleLogout () {
            try {
                await auth.logout()
                this.$router.push({ name: 'Signin' })
            } catch (err) {
                console.log('Error al cerrar sesión')
                console.log(err)
            }
        },
    },
}
</script>

<style scoped lang="scss">

.MobileLogo {
    width: 20px;
}
.Navbar {
    height: 60px;
    background: white;
    box-shadow: 0px 4px 10px rgba(103, 94, 134, 0.1);
    z-index: $zindex-navbar;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    .Container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        width: 100%;
        height: 100%;
        padding: 0 30px;
        @media screen and (max-width: $sm) {
            padding: 0 20px;
        }

        .Left {
            .Logo {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
            .DesktopLogo {
                width: 100px;
            }
            h1 {
                color: #333;
                font-size: 22px;
                margin: 0;
                letter-spacing: -.5px;
            }
        }
        .Brand {
            flex: 1;
            text-align: center;
            .MobileLogo {
                width: 100px;
            }
            .Logo {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                .Escudo {
                    width: 25px
                }
            }
            h1 {
                color: #333;
                font-size: 18px;
                margin: 0;
                letter-spacing: -.5px;
                margin-left: 10px;
            }
        }
        .Right {
            display: flex;
            .Avatar {
                cursor: pointer;
            }
            .SignOut {
                margin-left: 10px;
                cursor: pointer;
            }
        }
    }
}
</style>
